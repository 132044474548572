/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";

export interface StaticResource {
  getModuleId(): string;
  getModuleIfRequired(): React.ElementType<any>;
  load(): Promise<React.ElementType<any>>;
}

export interface EntryPointPreloadGlobals {
  tenantId?: string;
  userId?: string;
}

export function createStaticResource(
  id: string,
  component: React.ElementType<any>
): StaticResource {
  return {
    getModuleId() {
      return id;
    },
    getModuleIfRequired() {
      return component;
    },
    load() {
      return Promise.resolve(component);
    },
  };
}
