import {
  Column,
  ColumnBody,
  ColumnHeader,
  Skeleton,
  TableView,
} from "@workflows/ui";
import * as React from "react";
import { useColumns } from "./MattersTableView";

export function MattersColumnSkeleton(): JSX.Element {
  const columns = useColumns();

  return (
    <Column role="main">
      <ColumnHeader title={<Skeleton />} borderless />
      <ColumnBody>
        <TableView isPending={true} data={[]} columns={columns} />
      </ColumnBody>
    </Column>
  );
}
