import { getEdgeNodes } from "@workflows/runtime-web";
import {
  Callout,
  DownloadButton,
  FileList,
  Form2,
  FormControl,
  FormInput,
  Label,
  Select,
  SelectProps,
  Stack,
  StackItem,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  UseForm,
  useTenantId,
} from "@workflows/ui";
import { FileListStaticFile } from "@workflows/ui/lib/FileList/FileListStatic";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { Error404 } from "~/core/Error404";
import { DocumentInspectorQuery } from "~/__graphql__/DocumentInspectorQuery.graphql";

export interface DocumentInspectorProps {
  documentId: string;
  form: UseForm;
}

export function DocumentInspector({
  documentId,
  form,
}: DocumentInspectorProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const tenantId = useTenantId();

  const { node, tenant, config } = useLazyLoadQuery<DocumentInspectorQuery>(
    $DocumentInspectorQuery,
    {
      documentId,
      tenantId,
    }
  );

  const statuses = getEdgeNodes(tenant?.statuses || null).map((status) => ({
    label: status.name,
    value: status.identifier,
  }));

  const { title, description, status, id, files } = node || {};
  const isDemo = (config || []).some((item) => item?.value == true);
  const fileNodes = getEdgeNodes(files || null);

  React.useEffect(() => {
    form.reset({ title, description, status: status?.identifier });
  }, [title, description, status, documentId, form]);

  if (!node) {
    return <Error404 />;
  }

  return (
    <Form2
      name="DocumentInspectorForm"
      defaultValues={{ title, description, status: status?.id }}
    >
      <FormControl label={t("DocumentInspector.inputs.title")} name="title">
        <FormInput<TextFieldProps>
          autoFocus
          component={TextField}
          name="title"
          required
        />
      </FormControl>

      <FormControl
        label={t("DocumentInspector.inputs.description")}
        name="description"
      >
        <FormInput<TextAreaProps> component={TextArea} name="description" />
      </FormControl>
      <FormControl label={t("DocumentInspector.inputs.status")} name="status">
        <FormInput<SelectProps>
          id="status"
          component={Select}
          name="status"
          options={statuses}
        />
      </FormControl>

      <Stack direction="vertical" gap="xxs">
        <Label>{t("DocumentInspector.export.label")}</Label>
        <StackItem>
          <DownloadButton
            fileName=""
            url={`/endpoints/01F4KFKV6WD81ZGBP7ZTNSFCQS/${id}`}
            disabled={isDemo}
          >
            {t("DocumentInspector.export.docx")}
          </DownloadButton>
        </StackItem>
        {isDemo && (
          <StackItem>
            <Callout intent="default">
              {t("common.exportIsDisabledInDemo")}
            </Callout>
          </StackItem>
        )}
      </Stack>

      {fileNodes.length > 0 && (
        <Stack direction="vertical" gap="xxs">
          <Label>{t("DocumentInspector.attachments.label")}</Label>
          <FileList files={fileNodes as FileListStaticFile[]} />
        </Stack>
      )}
    </Form2>
  );
}

const $DocumentInspectorQuery = graphql`
  query DocumentInspectorQuery($documentId: ID!, $tenantId: ID!) {
    config(
      tenantId: $tenantId
      nodeId: $tenantId
      keys: ["de.smartconex.vertragsgenerator.demo"]
    ) {
      id
      key
      value
    }
    node(id: $documentId) {
      id
      ... on Document {
        title
        state
        description
        status {
          id
          name
          identifier
        }
        props
      }
      ... on HasFiles {
        files {
          edges {
            node {
              id
              contentLength
              name
              path
              downloadUrl
            }
          }
        }
      }
    }
    tenant: node(id: $tenantId) {
      id
      ... on Tenant {
        statuses {
          edges {
            node {
              id
              name
              identifier
            }
          }
        }
      }
    }
  }
`;
