import { ConfirmDialog, useToast } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useDeleteTenant } from "~/de.agad.dpm/tenants/hooks/useDeleteTenant";
import { routes } from "../routes";
import { newId, useHistory } from "@workflows/runtime-web";

export interface DeleteTenantDialogProps {
  isOpen: boolean;
  onClose: () => void;
  tenantId: string;
}

export const DeleteTenantDialog = ({
  isOpen,
  onClose,
  tenantId,
}: DeleteTenantDialogProps): JSX.Element => {
  const { t } = useTranslation("de.agad.dpm");
  const { deleteTenant, isPending } = useDeleteTenant();
  const [addToast] = useToast();
  const history = useHistory();

  const handleDeleteTenant = (actionConfirmed: boolean) => {
    if (!actionConfirmed) {
      onClose();
      return;
    }

    deleteTenant({
      input: { tenantId },
      onError(error) {
        handleFailure(error);
      },
      onCompleted(data) {
        if (data.deleteTenant?.errors) {
          handleFailure(data.deleteTenant?.errors);
        } else {
          handleSuccess();
        }
      },
    });

    function handleFailure(error?: any) {
      console.error(error);
      addToast({
        id: newId(),
        message: t("DeleteTenantDialog.failure"),
        intent: "critical",
      });
    }

    function handleSuccess() {
      addToast({
        id: newId(),
        message: t("DeleteTenantDialog.success"),
        intent: "success",
      });
      onClose();
      history.replace(routes["tenants.dashboard"].build());
    }
  };

  return (
    <ConfirmDialog
      cancelText={t("DeleteTenantDialog.cancelText")}
      confirmText={t("DeleteTenantDialog.confirmText")}
      intent="critical"
      isOpen={isOpen}
      isPending={isPending}
      onConfirm={handleDeleteTenant}
      title={t("DeleteTenantDialog.title")}
    >
      {t("DeleteTenantDialog.body")}
    </ConfirmDialog>
  );
};
