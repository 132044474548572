/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MatterTimeline_timeline = {
    readonly timeline: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly creator?: {
                    readonly name: string;
                } | null;
                readonly id?: string;
                readonly bodyText?: string;
                readonly insertedAt?: string;
                readonly identifier?: string;
                readonly actor?: {
                    readonly name: string;
                } | null;
                readonly toObject?: {
                    readonly id: string;
                    readonly __typename: string;
                    readonly name?: string;
                    readonly color?: string;
                    readonly textColor?: string;
                } | null;
            } | null;
        } | null> | null;
    } | null;
    readonly id?: string;
    readonly " $refType": "MatterTimeline_timeline";
};
export type MatterTimeline_timeline$data = MatterTimeline_timeline;
export type MatterTimeline_timeline$key = {
    readonly " $data"?: MatterTimeline_timeline$data;
    readonly " $fragmentRefs": FragmentRefs<"MatterTimeline_timeline">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "timeline"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v3 = [
        (v2 /*: any*/)
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
    } as any, v6 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "textColor",
            "storageKey": null
        } as any,
        (v2 /*: any*/)
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 99,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./MatterTimelinePaginationQuery.graphql.ts'),
                "identifierField": "id"
            }
        },
        "name": "MatterTimeline_timeline",
        "selections": [
            {
                "alias": "timeline",
                "args": null,
                "concreteType": "TimelineItemConnection",
                "kind": "LinkedField",
                "name": "__MatterTimeline_timeline_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "TimelineItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "creator",
                                                "plural": false,
                                                "selections": (v3 /*: any*/),
                                                "storageKey": null
                                            },
                                            (v4 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "bodyText",
                                                "storageKey": null
                                            },
                                            (v5 /*: any*/)
                                        ],
                                        "type": "ThreadMessage",
                                        "abstractKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v4 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "identifier",
                                                "storageKey": null
                                            },
                                            (v5 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "User",
                                                "kind": "LinkedField",
                                                "name": "actor",
                                                "plural": false,
                                                "selections": (v3 /*: any*/),
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": null,
                                                "kind": "LinkedField",
                                                "name": "toObject",
                                                "plural": false,
                                                "selections": [
                                                    (v4 /*: any*/),
                                                    (v1 /*: any*/),
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": (v3 /*: any*/),
                                                        "type": "User",
                                                        "abstractKey": null
                                                    },
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": (v6 /*: any*/),
                                                        "type": "Status",
                                                        "abstractKey": null
                                                    },
                                                    {
                                                        "kind": "InlineFragment",
                                                        "selections": (v6 /*: any*/),
                                                        "type": "Tag",
                                                        "abstractKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "Activity",
                                        "abstractKey": null
                                    }
                                ],
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "kind": "InlineFragment",
                "selections": [
                    (v4 /*: any*/)
                ],
                "type": "Node",
                "abstractKey": "__isNode"
            }
        ],
        "type": "HasTimeline",
        "abstractKey": "__isHasTimeline"
    } as any;
})();
(node as any).hash = '43a6dc9f689691a9045432c982d2ad67';
export default node;
