import { Card, CardBody, Link, LinkProps } from "@workflows/ui";
import * as React from "react";

export interface DashboardShortcutProps {
  tint: string;
  icon: any;
  to: string;
}

export const DashboardShortcut: React.FC<DashboardShortcutProps> = ({
  tint,
  icon: Icon,
  children,
  to,
}) => {
  return (
    <Card<LinkProps>
      as={Link}
      className="font--medium"
      interactive
      tint={tint}
      to={to}
    >
      <CardBody className="pt-lg">
        <p>
          <Icon circle size="xl" />
        </p>
        <p className="mt-md">{children}</p>
      </CardBody>
    </Card>
  );
};
