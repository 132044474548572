import * as React from "react";
import { FormContext, useForm, useToast } from "@workflows/ui";
import { newId, useHistory, useParams } from "@workflows/runtime-web";
import { routes } from "~/de.agad.dpm/routes";
import { useTranslation } from "react-i18next";
import { useTenant } from "../tenants/hooks/useTenant";
import { useUpdateTenant } from "~/de.agad.dpm/tenants/hooks/useUpdateTenant";
import {
  BasicInformation,
  CompanySection,
  ContactSection,
} from "./TenantCreator";

export const TenantEditor = (): JSX.Element => {
  const form = useForm("tenantEditor");
  const { t } = useTranslation("de.agad.dpm");

  const { tenantId } = useParams<{ tenantId: string }>();
  const tenant = useTenant(tenantId);

  React.useEffect(() => {
    const defaultValues = {
      name: tenant?.name || "",
      companyName: tenant?.organization?.name || "",
      companyAddress: tenant?.organization?.streetAddress || "",
      companyPostalCode: tenant?.organization?.postalCode || "",
      companyCity: tenant?.organization?.locality || "",
      contactName: tenant?.contact?.name || "",
      position: tenant?.contact?.position || "",
      email: tenant?.contact?.email || "",
    };

    form.reset(defaultValues);
  }, [form, tenant]);

  const { updateTenant } = useUpdateTenant();
  const [addToast] = useToast();
  const history = useHistory();

  const handleSubmit = async () => {
    const { isValid } = await form.validate();

    if (!isValid) {
      return;
    }

    const values = { ...form.getValues(), tenantId };
    updateTenant({
      input: values,
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.updateTenant?.errors) {
          addToast({
            id: newId(),
            message: t("TenantEditor.error"),
            intent: "warning",
          });
        } else {
          history.push(routes["tenant.index"].build({ tenantId }));
        }
      },
    });
  };

  return (
    <FormContext name="tenantEditor" onSubmit={handleSubmit}>
      <BasicInformation />
      <CompanySection />
      <ContactSection />
    </FormContext>
  );
};
