import * as React from "react";
import { ErrorBoundary } from "~/core/ErrorBoundary";
import { SuspenseFallback } from "~/core/SuspenseFallback";

interface ComponentLoaderProps {
  name: string;
}

/**
 * The webpack-specific magic comments are required for this component to work
 * DO NOT REMOVE
 */
const loadLandingPage = (landingPageName: string) =>
  React.lazy(
    () =>
      // eslint-disable-next-line node/no-unsupported-features/es-syntax
      import(
        /* webpackMode: "lazy" */
        `../../landing/${landingPageName}.tsx`
      )
  );

export function DynamicLandingContent({
  name,
}: ComponentLoaderProps): JSX.Element | null {
  const [lazyComponent, setLazyComponent] = React.useState();

  React.useEffect(() => {
    async function load() {
      const LazyComponent = await loadLandingPage(name);

      // @ts-expect-error: React.lazy() returns a Promise<React.LazyExoticComponent<any>>
      // that doesn't work with React.useState<T>
      setLazyComponent(<LazyComponent />);
    }

    load();
  }, [name]);

  if (!name) {
    return null;
  }

  return (
    <>
      <ErrorBoundary>
        <React.Suspense fallback={<SuspenseFallback />}>
          {lazyComponent}
        </React.Suspense>
      </ErrorBoundary>
    </>
  );
}
