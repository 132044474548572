import * as React from "react";
import { Spinner } from "@workflows/ui";
import "./index.scss";

export const GenericDialogSkeleton: React.FC = () => {
  return (
    <div className="generic-dialog-skeleton">
      <Spinner />
    </div>
  );
};
