import {
  atom,
  atomFamily,
  ExtractNode,
  selectorFamily,
} from "@workflows/runtime-web";
import { DocumentViewerBody_files } from "~/__graphql__/DocumentViewerBody_files.graphql";

export type ValueSelectorKey = [documentId: string, valueIdentifier: string];

export const getDocumentValueByIdentifier = selectorFamily<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any,
  ValueSelectorKey
>({
  key: "documents/getDocumentValueByIdentifier",
  get:
    (valueKey) =>
    ({ get }) => {
      const id = get(idFamily(valueKey));
      return get(valueFamily(id));
    },
});

export const idFamily = atomFamily<
  any,
  [documentId: string, identifier: string]
>({
  key: "DocumentRenderer.idFamily",
  default: null,
});

export const valueFamily = atomFamily<any, [documentId: string, id: string]>({
  key: "DocumentRenderer.valueFamily",
  default: null,
});

export const documentFiles = atom<
  ExtractNode<NonNullable<DocumentViewerBody_files["files"]>>[]
>({
  key: "DocumentRenderer.files",
  default: [],
});

export const valueIds = atom({
  key: "DocumentRenderer.valueIds",
  default: [],
});
