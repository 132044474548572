import * as React from "react";
import { FragmentSupportingSwitch } from "~/core/CoreBootloader";
import { CoreEntryPointRoute } from "~/core/CoreEntryPointRoute";
import { routeConfig, routes } from "./routes";

export function AdminRoute(): JSX.Element {
  return (
    <FragmentSupportingSwitch>
      <CoreEntryPointRoute
        path={routes["users.index"].path}
        entrypoint={routeConfig["users.index"]}
      />
    </FragmentSupportingSwitch>
  );
}
