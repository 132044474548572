import { newId } from "@workflows/runtime-web";
import {
  Button,
  Callout,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  ConfirmDialog,
  DotsVerticalIcon,
  DropdownMenu,
  DropdownMenuItem,
  DuplicateIcon,
  NonIdealState,
  TrashIcon,
  useDialog,
  useForm,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { ConnectionHandler, graphql, useMutation } from "react-relay/hooks";
import { DocumentInspectorColumnDeleteDocumentMutation as DeleteDocumentMutation } from "~/__graphql__/DocumentInspectorColumnDeleteDocumentMutation.graphql";
import { DocumentInspectorColumnDuplicateDocumentMutation as DuplicateDocumentMutation } from "~/__graphql__/DocumentInspectorColumnDuplicateDocumentMutation.graphql";
import { DocumentInspectorColumnMutation as UpdateDocumentMutation } from "~/__graphql__/DocumentInspectorColumnMutation.graphql";
import { DocumentInspector } from "./DocumentInspector";

export interface DocumentInspectorColumnProps {
  documentId: string;
  initRerender: () => void;
}

export function DocumentInspectorColumn({
  documentId,
  initRerender,
}: DocumentInspectorColumnProps): JSX.Element {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const tenantId = useTenantId();
  const form = useForm("DocumentInspectorForm");
  const [error, setError] = React.useState();

  const [updateDocument, isPending] = useMutation<UpdateDocumentMutation>(
    updateDocumentMutation
  );
  const [deleteDocument] = useMutation<DeleteDocumentMutation>(
    deleteDocumentMutation
  );
  const [duplicateDocument] = useMutation<DuplicateDocumentMutation>(
    duplicateDocumentMutation
  );

  const handleUpdateDocument = () => {
    const { title, description, status } = (form as any).getValues();

    updateDocument({
      variables: {
        input: {
          clientMutationId: newId(),
          description,
          documentId,
          statusIdentifier: status,
          tenantId,
          title,
        },
      },
      onError(error) {
        console.error(error);
        setError(t("DocumentInspector.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.updateDocument?.errors) {
          console.error(data.updateDocument?.errors);
          setError(t("DocumentInspector.errors.unspecified"));
        }
      },
    });
  };

  const handleConfirmDeleteDocument = (isConfirmed: boolean) => {
    isConfirmed ? handleDeleteDocument() : closeDialog();
  };

  const handleDeleteDocument = () => {
    deleteDocument({
      variables: {
        input: {
          tenantId,
          clientMutationId: newId(),
          documentId,
        },
      },
      onError(error) {
        console.error(error);
        setError(t("DocumentInspector.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.deleteDocument?.errors) {
          console.error(data.deleteDocument?.errors);
          setError(t("DocumentInspector.errors.unspecified"));
        } else {
          initRerender();
        }
      },
    });
  };

  const handleDuplicateDocument = () => {
    const { title } = (form as any).getValues();
    const input = {
      tenantId,
      clientMutationId: newId(),
      documentId,
      title: `${title} ${t("DocumentInspectorColumn.copy")}`,
    };

    const connectionID = ConnectionHandler.getConnectionID(
      "root",
      "DocumentsList_documents",
      { tenantId, types: ["ai.workflows.documents.document"] }
    );

    duplicateDocument({
      variables: { input, connections: [connectionID] },
      onError(error) {
        console.error(error);
        setError(t("DocumentInspector.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.duplicateDocument?.errors) {
          console.error(data.duplicateDocument?.errors);
          setError(t("DocumentInspector.errors.unspecified"));
        }
      },
    });
  };

  const items: DropdownMenuItem[] = [
    {
      key: "duplicateDocument",
      label: t("DocumentInspectorColumn.duplicateDocument"),
      onClick: handleDuplicateDocument,
      icon: <DuplicateIcon />,
    },
    {
      key: "deleteDocument",
      label: t("DocumentInspectorColumn.deleteDocument"),
      onClick: openDialog,
      icon: <TrashIcon />,
    },
  ];

  if (!documentId) {
    return <DocumentInspectorColumnSkeleton />;
  }

  return (
    <>
      <Column id="DocumentInspectorColumn" role="aside" background="muted">
        <ColumnHeader
          title={t("DocumentInspector.title")}
          end={
            <DropdownMenu items={items} placement="bottom-end">
              <Button icon={<DotsVerticalIcon />} />
            </DropdownMenu>
          }
        />
        {error && <Callout intent="critical">{error}</Callout>}
        <ColumnBody inset>
          <DocumentInspector documentId={documentId} form={form} />
        </ColumnBody>
        <ColumnFooter
          end={
            <Button
              intent="primary"
              fill
              onClick={handleUpdateDocument}
              isLoading={isPending}
            >
              {t("DocumentInspectorColumn.updateDocument")}
            </Button>
          }
        />
      </Column>
      <ConfirmDialog
        title={t("DocumentInspectorColumn.confirmDialog.title")}
        intent="critical"
        confirmText={t("DocumentInspectorColumn.confirmDialog.confirmText")}
        cancelText={t("DocumentInspectorColumn.confirmDialog.cancelText")}
        isOpen={isDialogOpen}
        onConfirm={handleConfirmDeleteDocument}
      >
        {t("DocumentInspectorColumn.confirmDialog.message")}
      </ConfirmDialog>
    </>
  );
}

export function DocumentInspectorColumnSkeleton(): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  return (
    <Column role="aside" background="muted">
      <ColumnHeader title={t("DocumentInspector.title")} />
      <ColumnBody inset>
        <NonIdealState
          description={t("DocumentInspectorColumnSkeleton.description")}
        />
      </ColumnBody>
    </Column>
  );
}

const deleteDocumentMutation = graphql`
  mutation DocumentInspectorColumnDeleteDocumentMutation(
    $input: DeleteDocumentInput!
  ) {
    deleteDocument(input: $input) {
      errors {
        code
        path
        message
      }
    }
  }
`;

const updateDocumentMutation = graphql`
  mutation DocumentInspectorColumnMutation($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
        title
        description
        status {
          id
          name
        }
      }
    }
  }
`;

const duplicateDocumentMutation = graphql`
  mutation DocumentInspectorColumnDuplicateDocumentMutation(
    $input: DuplicateDocumentInput!
    $connections: [ID!]!
  ) {
    duplicateDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document
        @appendNode(connections: $connections, edgeTypeName: "DocumentEdge") {
        id
        title
        insertedAt
        color
        props
        ...Status_status
      }
    }
  }
`;
