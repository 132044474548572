import { Column, ColumnBody, ColumnHeader } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay/hooks";
import { UsersColumnQuery } from "~/__graphql__/UsersColumnQuery.graphql";
import { UsersTableView } from "./UsersTableView";
export interface UsersColumnProps {
  queryRef: PreloadedQuery<UsersColumnQuery>;
}

export function UsersColumn({ queryRef }: UsersColumnProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.admin");

  const [innerRef] = useQueryLoader(UsersColumnQueryRequest, queryRef);

  const data = usePreloadedQuery(UsersColumnQueryRequest, innerRef!);

  return (
    <Column id="UsersRoute" role="main">
      <ColumnHeader title={t("UsersColumn.title")} />
      <ColumnBody>
        <React.Suspense
          fallback={<UsersTableView isPending={true} queryFragment={data} />}
        >
          <UsersTableView queryFragment={data} />
        </React.Suspense>
      </ColumnBody>
    </Column>
  );
}

export const UsersColumnQueryRequest = graphql`
  query UsersColumnQuery($tenantId: ID!) {
    ...UsersTableView_users @arguments(tenantId: $tenantId)
  }
`;
