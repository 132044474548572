import { newId, useMutation } from "@workflows/runtime-web";
import { graphql } from "react-relay/hooks";
import {
  useAdminUpdateUserMutation,
  useAdminUpdateUserMutationResponse,
} from "~/__graphql__/useAdminUpdateUserMutation.graphql";
import { useTenantId } from "@workflows/ui";

interface useAdminUpdateUserProps {
  onError?: (error: Error) => void;
  onCompleted?: (data: useAdminUpdateUserMutationResponse) => void;
  input: {
    subtenantId: string | undefined;
    tenantRoleIds: string[];
    userId: string;
  };
}

interface useAdminUpdateUserValue {
  adminUpdateUser: (props: useAdminUpdateUserProps) => void;
  isPending: boolean;
}

export const useAdminUpdateTenant = (): useAdminUpdateUserValue => {
  const tenantId = useTenantId();
  const [mutation, isPending] = useMutation<useAdminUpdateUserMutation>(
    adminUpdateUserMutation
  );

  const adminUpdateUser = (props: useAdminUpdateUserProps) => {
    const {
      onError,
      onCompleted,
      input: { subtenantId, tenantRoleIds, userId },
    } = props;

    const input = {
      clientMutationId: newId(),
      tenantId: subtenantId || tenantId,
      tenantRoleIds,
      userId,
    };

    mutation({
      variables: {
        tenantId: subtenantId || tenantId,
        input,
      },
      onError,
      onCompleted,
    });
  };

  return { adminUpdateUser, isPending };
};

const adminUpdateUserMutation = graphql`
  mutation useAdminUpdateUserMutation(
    $input: AdminUpdateUserInput!
    $tenantId: ID!
  ) {
    adminUpdateUser(input: $input) {
      errors {
        code
        path
        message
        type
      }
      user {
        ...useTenantUsers_user @relay(mask: false)
      }
    }
  }
`;
