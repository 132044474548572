/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useTenantQueryVariables = {
    tenantId: string;
};
export type useTenantQueryResponse = {
    readonly tenant: {
        readonly id: string;
        readonly name?: string;
        readonly host?: {
            readonly baseUrl: string;
        };
        readonly admins?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                } | null;
            } | null> | null;
        } | null;
        readonly organization?: {
            readonly name?: string | null;
            readonly email?: string | null;
            readonly postalCode?: string | null;
            readonly postalCountry?: string | null;
            readonly streetAddress?: string | null;
            readonly locality?: string | null;
            readonly administrativeArea?: string | null;
        } | null;
        readonly contact?: {
            readonly name?: string | null;
            readonly email?: string | null;
            readonly locality?: string | null;
            readonly position?: string | null;
            readonly postalCode?: string | null;
            readonly postalCountry?: string | null;
            readonly administrativeArea?: string | null;
            readonly streetAddress?: string | null;
        } | null;
    } | null;
};
export type useTenantQuery = {
    readonly response: useTenantQueryResponse;
    readonly variables: useTenantQueryVariables;
};



/*
query useTenantQuery(
  $tenantId: ID!
) {
  tenant: node(id: $tenantId) {
    __typename
    id
    ... on Tenant {
      name
      host {
        baseUrl
        id
      }
      admins: users(roleIdentifiers: ["tenant.admin"]) {
        edges {
          node {
            id
            name
          }
        }
      }
      organization: entity(identifier: "de.agad.dpm.entities.tenants.address") {
        __typename
        ... on EntityOrganization {
          name
          email
          postalCode
          postalCountry
          streetAddress
          locality
          administrativeArea
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      contact: entity(identifier: "de.agad.dpm.entities.tenants.contact") {
        __typename
        ... on EntityPerson {
          name
          email
          locality
          position
          postalCode
          postalCountry
          administrativeArea
          streetAddress
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "tenantId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseUrl",
        "storageKey": null
    } as any, v5 = {
        "alias": "admins",
        "args": [
            {
                "kind": "Literal",
                "name": "roleIdentifiers",
                "value": [
                    "tenant.admin"
                ]
            }
        ],
        "concreteType": "UserConnection",
        "kind": "LinkedField",
        "name": "users",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "UserEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                            (v2 /*: any*/),
                            (v3 /*: any*/)
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "storageKey": "users(roleIdentifiers:[\"tenant.admin\"])"
    } as any, v6 = [
        {
            "kind": "Literal",
            "name": "identifier",
            "value": "de.agad.dpm.entities.tenants.address"
        } as any
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCountry",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "streetAddress",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "locality",
        "storageKey": null
    } as any, v12 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "administrativeArea",
        "storageKey": null
    } as any, v13 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/),
            (v7 /*: any*/),
            (v8 /*: any*/),
            (v9 /*: any*/),
            (v10 /*: any*/),
            (v11 /*: any*/),
            (v12 /*: any*/)
        ],
        "type": "EntityOrganization",
        "abstractKey": null
    } as any, v14 = [
        {
            "kind": "Literal",
            "name": "identifier",
            "value": "de.agad.dpm.entities.tenants.contact"
        } as any
    ], v15 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/),
            (v7 /*: any*/),
            (v11 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
            },
            (v8 /*: any*/),
            (v9 /*: any*/),
            (v12 /*: any*/),
            (v10 /*: any*/)
        ],
        "type": "EntityPerson",
        "abstractKey": null
    } as any, v16 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v17 = {
        "kind": "InlineFragment",
        "selections": [
            (v2 /*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useTenantQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Host",
                                    "kind": "LinkedField",
                                    "name": "host",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/),
                                {
                                    "alias": "organization",
                                    "args": (v6 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v13 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.address\")"
                                },
                                {
                                    "alias": "contact",
                                    "args": (v14 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v15 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.contact\")"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useTenantQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v16 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Host",
                                    "kind": "LinkedField",
                                    "name": "host",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v5 /*: any*/),
                                {
                                    "alias": "organization",
                                    "args": (v6 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v16 /*: any*/),
                                        (v13 /*: any*/),
                                        (v17 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.address\")"
                                },
                                {
                                    "alias": "contact",
                                    "args": (v14 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v16 /*: any*/),
                                        (v15 /*: any*/),
                                        (v17 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.contact\")"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "3ded3bd6920350060b538282bd317450",
            "id": null,
            "metadata": {},
            "name": "useTenantQuery",
            "operationKind": "query",
            "text": "query useTenantQuery(\n  $tenantId: ID!\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    id\n    ... on Tenant {\n      name\n      host {\n        baseUrl\n        id\n      }\n      admins: users(roleIdentifiers: [\"tenant.admin\"]) {\n        edges {\n          node {\n            id\n            name\n          }\n        }\n      }\n      organization: entity(identifier: \"de.agad.dpm.entities.tenants.address\") {\n        __typename\n        ... on EntityOrganization {\n          name\n          email\n          postalCode\n          postalCountry\n          streetAddress\n          locality\n          administrativeArea\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      contact: entity(identifier: \"de.agad.dpm.entities.tenants.contact\") {\n        __typename\n        ... on EntityPerson {\n          name\n          email\n          locality\n          position\n          postalCode\n          postalCountry\n          administrativeArea\n          streetAddress\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '5f7190075ec869c3684fadeaed45e6ba';
export default node;
