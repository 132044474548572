import {
  getEdgeNodes,
  graphql,
  newId,
  useHistory,
  useLazyLoadQuery,
  useMutation,
} from "@workflows/runtime-web";
import {
  Button,
  Callout,
  ChoiceList,
  ChoiceListProps,
  Column,
  ColumnBody,
  ColumnFooter,
  FormContext,
  FormControl,
  FormInput,
  NonIdealState,
  Section,
  SectionBody,
  Select,
  SelectProps,
  Text,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  useForm,
  useFormWatch,
  useViewerTenant,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Error500 } from "~/core/Error500";
import { VertragsgeneratorRoles } from "~/core/roles";
import { Statuses } from "~/core/statuses";
import { routes } from "~/de.smartconex.vertragsgenerator/routes";
import { FlowComponent } from "~/flows/types";
import { AppDokgeneratorWorkflowsEmploymentRefv1Mutation } from "~/__graphql__/AppDokgeneratorWorkflowsEmploymentRefv1Mutation.graphql";
import { AppDokgeneratorWorkflowsEmploymentRefv1Query } from "~/__graphql__/AppDokgeneratorWorkflowsEmploymentRefv1Query.graphql";

type TemplateProps = { gender?: string; key: string };

export type AppDokgeneratorWorkflowsEmploymentRefv1Props = FlowComponent<{
  collectionId: string;
}>;

export function AppDokgeneratorWorkflowsEmploymentRefv1({
  workflow,
}: AppDokgeneratorWorkflowsEmploymentRefv1Props): JSX.Element {
  const { tenantId, userId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const history = useHistory();
  const form = useForm("AppDokgeneratorWorkflowsEmploymentRefv1");
  const { templateKey } = useFormWatch(
    "AppDokgeneratorWorkflowsEmploymentRefv1",
    ["templateKey"]
  );

  const data = useLazyLoadQuery<AppDokgeneratorWorkflowsEmploymentRefv1Query>(
    $AppDokgeneratorWorkflowsEmploymentRefv1Query,
    { collectionId: workflow.config.collectionId }
  );

  const [error, setError] = React.useState("");
  const [createDocument, isPending] =
    useMutation<AppDokgeneratorWorkflowsEmploymentRefv1Mutation>(
      $AppDokgeneratorWorkflowsEmploymentRefv1Mutation
    );

  const handleSubmit = async () => {
    const { isValid } = await form.validate();
    if (!isValid) {
      return;
    }

    const { templateKey, title, description, gender, ...values } =
      form.getValues();

    let template;
    if (templateKey === "simple") {
      template = templates.find(
        (tmpl) => tmpl.props && (tmpl.props as TemplateProps).key == "simple"
      );
    } else if (["final", "interim"].includes(templateKey)) {
      template = templates.find(
        (tmpl) =>
          tmpl.props &&
          (tmpl.props as TemplateProps).key == templateKey &&
          (tmpl.props as TemplateProps).gender == gender
      );
    }

    if (!template) {
      setError(
        t("AppDokgeneratorWorkflowsEmploymentRefv1.errors.noTemplateMatch")
      );
      return;
    }

    const input = {
      assignees: [
        {
          assigneeId: userId,
          roleIdentifier: VertragsgeneratorRoles.Assignee,
        },
      ],
      statusIdentifier: Statuses.Open,
      clientMutationId: newId(),
      templateId: template.id,
      tenantId,
      title,
      description,
      defaultValues: Object.entries(values)
        .map(([key, value]) => ({
          identifier: key,
          value: JSON.stringify(value),
        }))
        .filter((val) => !!val.value),
    };

    createDocument({
      variables: { input },
      onError(error) {
        console.error(error);
        setError(
          t("AppDokgeneratorWorkflowsEmploymentRefv1.errors.unspecified")
        );
      },
      onCompleted(data) {
        if (data.createDocument?.errors) {
          console.error(data.createDocument?.errors);
          setError(
            t("AppDokgeneratorWorkflowsEmploymentRefv1.errors.unspecified")
          );
        } else if (data.createDocument && data.createDocument.document) {
          const documentId = data.createDocument.document.id;
          history.replace(routes["wizard.index"].build({ documentId }));
        } else {
          console.error(data.createDocument?.errors);
          setError(
            t("AppDokgeneratorWorkflowsEmploymentRefv1.errors.unspecified")
          );
        }
      },
    });
  };

  const collection = data.node;
  const templates = getEdgeNodes(
    collection && collection.items ? collection.items : null
  ).filter((tmpl) => tmpl.__typename === "DocumentTemplate");

  if (!workflow.config.collectionId) {
    return <Error500 />;
  }

  if (templates.length === 0) {
    return (
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <NonIdealState
            title="Keine Vertragstypen verfügbar"
            description="Ihr Benutzerkonto hat keine Vorlagen zugeordnet. Bitte kontaktieren Sie den Support um das Problem zu lösen."
          />
        </ColumnBody>
      </Column>
    );
  }

  return (
    <FormContext name="AppDokgeneratorWorkflowsEmploymentRefv1">
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <Section>
            <SectionBody>
              <Text variant="headingLg">Konfiguration</Text>
              {error && <Callout intent="critical">{error}</Callout>}
            </SectionBody>
          </Section>
          <Section>
            <SectionBody>
              <FormControl
                name="title"
                htmlFor="title"
                label={t(
                  "AppDokgeneratorWorkflowsEmploymentRefv1.inputs.title"
                )}
                helper={t(
                  "AppDokgeneratorWorkflowsEmploymentRefv1.helpers.title"
                )}
              >
                <FormInput<TextFieldProps>
                  autoFocus
                  id="title"
                  component={TextField}
                  name="title"
                  required="Bitte füllen Sie dieses Feld aus."
                  max={[250, "Bitte geben Sie max. 250 Zeichen ein"]}
                />
              </FormControl>
              <FormControl
                name="description"
                htmlFor="description"
                label={t(
                  "AppDokgeneratorWorkflowsEmploymentRefv1.inputs.description"
                )}
                helper={t(
                  "AppDokgeneratorWorkflowsEmploymentRefv1.helpers.description"
                )}
              >
                <FormInput<TextAreaProps>
                  id="description"
                  component={TextArea}
                  name="description"
                  max={[500, "Bitte geben Sie max. 500 Zeichen ein"]}
                />
              </FormControl>
              <FormControl
                htmlFor="templateKey"
                name="templateKey"
                label="Zeugnistyp"
                description="Bitte wählen Sie einen passenden Zeugnistyp."
              >
                <FormInput<ChoiceListProps>
                  component={ChoiceList}
                  id="templateKey"
                  name="templateKey"
                  type="radio"
                  required="Bitte wählen Sie einen passenden Zeugnistyp aus."
                  options={[
                    {
                      label: "Abschlusszeugnis",
                      value: "final",
                    },
                    {
                      label: "Zwischenzeugnis",
                      value: "interim",
                    },
                    {
                      label: "Einfaches Zeugnis",
                      value: "simple",
                    },
                  ]}
                />
              </FormControl>
              {["final", "interim"].includes(templateKey) && (
                <>
                  <FormControl
                    htmlFor="gender"
                    name="gender"
                    label="Geschlecht"
                    description="Das Geschlecht ist notwendig, um die Ansprache und Personalpronomen entsprechend anzupassen."
                  >
                    <FormInput<SelectProps>
                      component={Select}
                      id="gender"
                      name="gender"
                      validationMode="onChange"
                      required="Bitte füllen Sie dieses Feld aus."
                      options={[
                        {
                          value: "",
                          label: "Bitte wählen Sie eine Option",
                          disabled: true,
                        },
                        {
                          label: "männlich",
                          value: "male",
                        },
                        {
                          label: "weiblich",
                          value: "female",
                        },
                        {
                          label: "divers",
                          value: "other",
                        },
                      ]}
                    />
                  </FormControl>
                  <Callout intent="default">
                    Das Geschlecht kann bei einem Abschluss- oder
                    Zwischenzeugnis nachträglich nicht mehr geändert werden.
                  </Callout>
                </>
              )}
            </SectionBody>
          </Section>
        </ColumnBody>
        <ColumnFooter
          end={
            <Button
              intent="primary"
              onClick={handleSubmit}
              isLoading={isPending}
            >
              {t("AppDokgeneratorWorkflowsEmploymentRefv1.actions.create")}
            </Button>
          }
        />
      </Column>
      <Column background="base" style={{ border: "none" }}>
        <ColumnBody inset scrollable={false}>
          <div style={{ maxWidth: "30rem" }}>
            Auf der{" "}
            <a href="/" target="_blank" className="link--underline">
              Startseite können
            </a>{" "}
            Sie einen Bewertungsbogen als Formular herunterladen. Diesen
            Bewertungsbogen können Sie einfach intern verschicken und damit die
            Erstellung des Arbeitszeugnisses vereinfachen.
          </div>
        </ColumnBody>
      </Column>
    </FormContext>
  );
}

const $AppDokgeneratorWorkflowsEmploymentRefv1Query = graphql`
  query AppDokgeneratorWorkflowsEmploymentRefv1Query($collectionId: ID!) {
    node(id: $collectionId) {
      ... on Collection {
        name
        items(first: 100) {
          edges {
            cursor
            node {
              id
              ... on DocumentTemplate {
                __typename
                id
                title
                description
                color
                props
              }
            }
          }
        }
      }
    }
  }
`;

const $AppDokgeneratorWorkflowsEmploymentRefv1Mutation = graphql`
  mutation AppDokgeneratorWorkflowsEmploymentRefv1Mutation(
    $input: CreateDocumentInput!
  ) {
    createDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
      }
    }
  }
`;
