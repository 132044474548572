import {
  useUpdateTenantMutation,
  useUpdateTenantMutationResponse,
} from "~/__graphql__/useUpdateTenantMutation.graphql";
import { newId, useMutation } from "@workflows/runtime-web";
import { graphql } from "react-relay/hooks";

interface useUpdateTenantProps {
  onError: (error: Error) => void;
  onCompleted: (data: useUpdateTenantMutationResponse) => void;
  input: {
    name: string;
    companyName: string;
    companyAddress: string;
    companyPostalCode: string;
    companyCity: string;
    contactName: string;
    position: string;
    email: string;
    tenantId: string;
  };
}

interface useUpdateTenantValue {
  updateTenant: (props: useUpdateTenantProps) => void;
  isPending: boolean;
}

export const useUpdateTenant = (): useUpdateTenantValue => {
  const [mutation, isPending] =
    useMutation<useUpdateTenantMutation>(updateTenantMutation);

  const updateTenant = (props: useUpdateTenantProps) => {
    const {
      onError,
      onCompleted,
      input: {
        name,
        companyName,
        companyAddress,
        companyPostalCode,
        companyCity,
        contactName,
        position,
        email,
        tenantId,
      },
    } = props;

    const input = {
      clientMutationId: newId(),
      name,
      tenantId,
      entities: [
        {
          identifier: "de.agad.dpm.entities.tenants.address",
          type: "ai.workflows.entity.organization",
          name: companyName,
          streetAddress: companyAddress,
          postalCode: companyPostalCode,
          locality: companyCity,
        },
        {
          identifier: "de.agad.dpm.entities.tenants.contact",
          type: "ai.workflows.entity.person",
          name: contactName,
          position,
          email,
        },
      ],
    };

    mutation({
      variables: {
        input,
      },
      onError,
      onCompleted,
    });
  };

  return { updateTenant, isPending };
};

const updateTenantMutation = graphql`
  mutation useUpdateTenantMutation($input: UpdateTenantInput!) {
    updateTenant(input: $input) {
      errors {
        code
        path
        message
        type
      }
      tenant {
        id
        name
        organization: entity(
          identifier: "de.agad.dpm.entities.tenants.address"
        ) {
          ... on EntityOrganization {
            locality
            name
            postalCode
            streetAddress
          }
        }
        contact: entity(identifier: "de.agad.dpm.entities.tenants.contact") {
          ... on EntityPerson {
            name
            position
            email
          }
        }
      }
    }
  }
`;
