import * as React from "react";
import { useTranslation } from "react-i18next";
import { newId } from "@workflows/runtime-web";
import {
  Button,
  ConfirmDialog,
  Form2,
  FormInput,
  Select,
  SelectProps,
  Stack,
  StackItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  TextField,
  TextFieldProps,
  useForm,
  useToast,
  XIcon,
} from "@workflows/ui";
import { useInviteUsers } from "~/core/Dialogs/InviteUsersDialog/hooks/useInviteUsers";

const MAX_USERS = 5;
const ROLES = [
  {
    label: "User",
    value: "tenant.user",
  },
  {
    label: "Admin",
    value: "tenant.admin",
  },
];

export type InviteUser = {
  id: string;
  email: string;
  roleIdentifier: string;
  firstName?: string;
  lastName?: string;
};

interface InviteUserDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onInvite?: () => void;
  tenantId?: string;
}

export const InviteUsersDialog: React.FC<InviteUserDialogProps> = ({
  isOpen,
  onClose,
  onInvite,
  tenantId,
}) => {
  const { t } = useTranslation("ai.workflows.core");
  const inviteUsersForm = useForm("InviteUsersForm");
  const [addToast] = useToast();
  const [users, setUsers] = React.useState<InviteUser[]>([
    {
      id: newId(),
      email: "",
      firstName: "",
      lastName: "",
      roleIdentifier: ROLES[0].value,
    },
  ]);
  const { inviteUsers, isPending } = useInviteUsers();

  const addUser = () => {
    setUsers((users) => [
      ...users,
      { id: newId(), email: "", roleIdentifier: ROLES[0].value },
    ]);
  };

  const removeUser = (id: string) => {
    setUsers((users) => users.filter((user) => user.id !== id));
  };

  const handleError = () => {
    addToast({
      id: newId(),
      message: t("InviteUsersDialog.messages.error"),
      intent: "critical",
    });
    onClose();
  };

  const handleSuccess = () => {
    addToast({
      id: newId(),
      message: t("InviteUsersDialog.messages.success"),
      intent: "success",
    });
    onInvite?.();
    onClose();
  };

  const handleConfirm = (userClickedConfirm: boolean) => {
    if (!userClickedConfirm) {
      onClose();
      return;
    }

    const invitees = normalizeFormValues(inviteUsersForm.getValues());

    inviteUsers({
      input: {
        subtenantId: tenantId,
        invitees,
      },
      onError(error) {
        console.error(error);
        handleError();
      },
      onCompleted(data) {
        if (data.inviteUsers?.errors) {
          handleError();
        } else {
          handleSuccess();
        }
      },
    });
  };

  return (
    <ConfirmDialog
      cancelText={t("InviteUsersDialog.actions.cancel")}
      confirmText={t("InviteUsersDialog.actions.submit")}
      isOpen={isOpen}
      isPending={isPending}
      showIcon={false}
      onConfirm={handleConfirm}
      title={t("InviteUsersDialog.title")}
    >
      <Form2 name="InviteUsersForm">
        <Stack direction="vertical">
          <Text>{t("InviteUsersDialog.description")}</Text>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>
                  {t("InviteUsersDialog.inputs.email")}
                </TableHeadCell>
                <TableHeadCell>
                  {t("InviteUsersDialog.inputs.firstName")}
                </TableHeadCell>
                <TableHeadCell>
                  {t("InviteUsersDialog.inputs.lastName")}
                </TableHeadCell>
                <TableHeadCell>
                  {t("InviteUsersDialog.inputs.role")}
                </TableHeadCell>
                <TableHeadCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user: InviteUser) => (
                <TableRow key={user.id} interactive={false} selected={false}>
                  <TableCell className="p-sm">
                    <FormInput<TextFieldProps>
                      autoFocus
                      component={TextField}
                      id={`${user.id}.email`}
                      name={`${user.id}.email`}
                      type="email"
                      placeholder={"example@email.com"}
                      style={{ minWidth: "150px" }}
                    />
                  </TableCell>
                  <TableCell className="p-xs">
                    <FormInput<TextFieldProps>
                      component={TextField}
                      id={`${user.id}.firstName`}
                      name={`${user.id}.firstName`}
                      type="text"
                      placeholder={"John"}
                    />
                  </TableCell>
                  <TableCell className="p-xs">
                    <FormInput<TextFieldProps>
                      component={TextField}
                      id={`${user.id}.lastName`}
                      name={`${user.id}.lastName`}
                      type="text"
                      placeholder={"Doe"}
                    />
                  </TableCell>
                  <TableCell className="p-xs">
                    <FormInput<SelectProps>
                      component={Select}
                      id={`${user.id}.roleIdentifier`}
                      name={`${user.id}.roleIdentifier`}
                      options={ROLES}
                      defaultValue={ROLES[0].value}
                      style={{ minWidth: "90px" }}
                    />
                  </TableCell>
                  <TableCell className="p-xs">
                    <Button
                      disabled={users.length === 1}
                      icon={<XIcon />}
                      minimal
                      onClick={() => removeUser(user.id)}
                      size="sm"
                      title={t("InviteUsersDialog.actions.remove")}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Stack>

        {users.length < MAX_USERS && (
          <Stack direction="vertical">
            <StackItem width="1/4">
              <Button fill type="button" onClick={addUser}>
                {t("InviteUsersDialog.actions.add")}
              </Button>
            </StackItem>
          </Stack>
        )}
      </Form2>
    </ConfirmDialog>
  );
};

const normalizeFormValues = (formValues: Record<string, string>) => {
  const result = Object.entries(formValues).reduce((users, [key, value]) => {
    const [id, propName] = key.split(".");

    users.set(id, {
      ...users.get(id),
      [propName]: value,
    });

    return users;
  }, new Map());

  return Array.from(result)
    .map(([_, user]) => user)
    .filter((user) => user.email !== undefined);
};
