import {
  ChevronLeftIcon,
  Column,
  ColumnBody,
  ColumnHeader,
  LinkButton,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { UsersEditorColumnQuery } from "~/__graphql__/UsersEditorColumnQuery.graphql";
import { routes } from "../routes";
import { UserForm } from "./UserForm";

export const UsersEditorColumn: React.FC<{
  userId: string;
}> = ({ userId }) => {
  const tenantId = useTenantId();

  const data = useLazyLoadQuery<UsersEditorColumnQuery>(query, {
    userId,
    tenantId,
    objectId: tenantId,
    typeIdentifier: "ai.workflows.tenants.tenant",
    count: 100,
  });

  return (
    <Column
      id="ProjectEditorColumn"
      role="aside"
      width={1 / 4}
      header={
        <ColumnHeader
          title={data?.user?.name}
          start={
            <LinkButton to={routes["users.index"].build()} minimal size="sm">
              <ChevronLeftIcon />
            </LinkButton>
          }
        />
      }
    >
      <ColumnBody inset>
        <UserForm data={data} />
      </ColumnBody>
    </Column>
  );
};

const query = graphql`
  query UsersEditorColumnQuery(
    $userId: ID!
    $objectId: ID!
    $tenantId: ID!
    $typeIdentifier: String!
    $count: Int
    $cursor: String
  ) {
    tenant: node(id: $tenantId) {
      id
      ...UserFormQuery_assignableRoles
        @arguments(
          tenantId: $tenantId
          count: $count
          cursor: $cursor
          typeIdentifier: $typeIdentifier
        )
    }
    user: node(id: $userId) {
      id
      ... on User {
        name
        email
        assignedRoles(objectId: $objectId) {
          edges {
            node {
              id
              identifier
              name
            }
          }
        }
      }
    }
  }
`;
