import { Route, Switch } from "@workflows/runtime-web";
import * as React from "react";
import { PreloadedQuery } from "react-relay";
import { TenantsColumnQuery } from "~/__graphql__/TenantsColumnQuery.graphql";
import { routes } from "../routes";
import { TenantsColumn, TenantsColumnQueryRequest } from "./TenantsColumn";
import { TenantEditorColumn } from "~/de.agad.dpm/tenants/TenantEditorColumn";
import { TenantCreatorDrawer } from "~/de.agad.dpm/tenants/TenantCreatorDrawer";

export const TenantsRouteQuery = TenantsColumnQueryRequest;

export interface TenantsRouteEntryPointQueries {
  tenants: PreloadedQuery<TenantsColumnQuery>;
}

interface TenantsRouteProps {
  queries: TenantsRouteEntryPointQueries;
}

export function TenantsRoute({ queries }: TenantsRouteProps): JSX.Element {
  return (
    <>
      <TenantsColumn queryRef={queries.tenants} />
      <Switch>
        <Route
          path={[routes["tenant.new"].path]}
          component={TenantCreatorDrawer}
        />

        <Route
          path={[routes["tenant.index"].path, routes["tenant.people"].path]}
          component={TenantEditorColumn}
        />
      </Switch>
    </>
  );
}
