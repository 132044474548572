import {
  useInviteUsersMutation,
  useInviteUsersMutationResponse,
} from "~/__graphql__/useInviteUsersMutation.graphql";
import { newId, useMutation } from "@workflows/runtime-web";
import { graphql } from "react-relay/hooks";
import { useTenantId } from "@workflows/ui";
import { ConnectionHandler } from "relay-runtime";
import { InviteUser } from "~/core/Dialogs/InviteUsersDialog";

interface useInviteUsersProps {
  onError: (error: Error) => void;
  onCompleted: (data: useInviteUsersMutationResponse) => void;
  input: {
    invitees: Omit<InviteUser, "id">[];
    subtenantId: string | undefined;
  };
}

interface useInviteUsersValue {
  inviteUsers: (props: useInviteUsersProps) => void;
  isPending: boolean;
}

export const useInviteUsers = (): useInviteUsersValue => {
  const tenantId = useTenantId();
  const [mutation, isPending] =
    useMutation<useInviteUsersMutation>(inviteUsersMutation);

  const inviteUsers = (props: useInviteUsersProps) => {
    const {
      onError,
      onCompleted,
      input: { invitees, subtenantId },
    } = props;

    mutation({
      variables: {
        tenantId: subtenantId || tenantId,
        input: {
          tenantId: subtenantId || tenantId,
          clientMutationId: newId(),
          invitees,
        },
      },
      updater: (store) => {
        const connectionRecord = ConnectionHandler.getConnection(
          store.getRoot(),
          "useTenantUsers_users",
          { tenantId: subtenantId || tenantId }
        );

        if (!connectionRecord) {
          return;
        }

        const payload = store.getRootField("inviteUsers");
        const invitedUsers = payload.getLinkedRecords("users");

        invitedUsers?.forEach((user) => {
          const newUser = ConnectionHandler.createEdge(
            store,
            connectionRecord,
            // This is a bug in the Relay library.
            // @ts-expect-error: "user" is a RecordProxy that can contain a "user" or "null"
            user,
            "UserEdge"
          );

          const assignedRoles = user.getLinkedRecord("assignedRoles", {
            objectId: subtenantId,
            first: 100,
          });
          const assignedRolesEdges = assignedRoles?.getLinkedRecords("edges");

          assignedRolesEdges?.forEach((edge) => {
            const role = edge.getLinkedRecord("node");
            const newRole = ConnectionHandler.createEdge(
              store,
              edge,
              // @ts-expect-error: "role" is a RecordProxy that can contain a "role" or "null"
              role,
              "RoleEdge"
            );

            ConnectionHandler.insertEdgeAfter(edge, newRole);
          });

          ConnectionHandler.insertEdgeAfter(connectionRecord, newUser);
        });
      },
      onError,
      onCompleted,
    });
  };

  return { inviteUsers, isPending };
};

const inviteUsersMutation = graphql`
  mutation useInviteUsersMutation($input: InviteUsersInput!, $tenantId: ID!) {
    inviteUsers(input: $input) {
      users {
        ...useTenantUsers_user @relay(mask: false)
      }
      errors {
        code
        path
        message
      }
    }
  }
`;
