/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserFormQuery_assignableRole = {
    readonly id: string;
    readonly name: string | null;
    readonly identifier: string | null;
    readonly " $refType": "UserFormQuery_assignableRole";
};
export type UserFormQuery_assignableRole$data = UserFormQuery_assignableRole;
export type UserFormQuery_assignableRole$key = {
    readonly " $data"?: UserFormQuery_assignableRole$data;
    readonly " $fragmentRefs": FragmentRefs<"UserFormQuery_assignableRole">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserFormQuery_assignableRole",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "identifier",
            "storageKey": null
        }
    ],
    "type": "Role",
    "abstractKey": null
} as any;
(node as any).hash = 'd9fcd1e63f6f0bf3bde09f59930aa7b7';
export default node;
