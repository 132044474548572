/* eslint-disable @typescript-eslint/no-explicit-any */
import { getEdgeNodes, Link } from "@workflows/runtime-web";
import {
  AssigneesPreview,
  ErrorNotFoundIllustration,
  NonIdealState,
  Skeleton,
  Status,
  TableView,
  TableViewColumn,
  Text,
} from "@workflows/ui";
import { format, parseISO, formatDistanceToNow } from "date-fns";
import de from "date-fns/locale/de";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { MattersTableViewPaginationQuery } from "~/__graphql__/MattersTableViewPaginationQuery.graphql";
import { MattersTableView_matters$key } from "~/__graphql__/MattersTableView_matters.graphql";
import { routes } from "../routes";

export interface MattersTableViewProps {
  isPending?: boolean;
  queryFragment?: any;
}

export function MattersTableView({
  isPending = false,
  queryFragment,
}: MattersTableViewProps): JSX.Element {
  const { data } = usePaginationFragment<
    MattersTableViewPaginationQuery,
    MattersTableView_matters$key
  >(MattersTableView_matters, queryFragment);

  const cols = useColumns();
  const matters = React.useMemo(
    () => getEdgeNodes(data.matters),
    [data.matters]
  );

  return (
    <TableView
      isPending={isPending}
      columns={cols}
      data={matters}
      emptyState={<MattersTableViewEmptyState />}
    />
  );
}

/* const MattersTableView_matter = */ graphql`
  fragment MattersTableView_matter on Matter {
    id
    title
    number
    dueDate
    insertedAt
    creator {
      id
      name
    }
    ...AssigneesPreview_node
    ...Status_status
  }
`;

const MattersTableView_matters = graphql`
  fragment MattersTableView_matters on RootQueryType
  @refetchable(queryName: "MattersTableViewPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
    tenantId: { type: "ID!" }
  ) {
    matters(tenantId: $tenantId, first: $count, after: $cursor)
      @connection(key: "MattersTableView_matters") {
      edges {
        cursor
        node {
          ...MattersTableView_matter @relay(mask: false)
        }
      }
    }
  }
`;

export function useColumns(): TableViewColumn[] {
  const { t } = useTranslation("ai.workflows.matters");

  return React.useMemo(
    () => [
      {
        id: "primary",
        header: t("MattersTableView.cols.title"),
        accessor: (matter: any) => matter,
        width: "40%",
        render: ({ value: matter }: any) => {
          return matter ? (
            <div>
              <Link to={routes["matter.index"].build({ matterId: matter.id })}>
                {matter.title}
              </Link>
              <Text color="muted">
                {t("MattersTableView.subline", {
                  matterNumber: matter.number ? `#${matter.number}` : "DRAFT",
                  insertedAt: formatDistanceToNow(parseISO(matter.insertedAt), {
                    locale: de,
                    addSuffix: true,
                  }),
                  name: matter.creator.name,
                })}
              </Text>
            </div>
          ) : null;
        },
      },
      {
        id: "status",
        header: t("MattersTableView.cols.status"),
        accessor: (matter: any) => matter,
        width: "20%",
        render: ({ value: matter }: any) => {
          return matter ? <Status status={matter} /> : null;
        },
        skeleton: () => (
          <Skeleton>
            <Status name="…" />
          </Skeleton>
        ),
      },
      {
        id: "assignees",
        header: t("MattersTableView.cols.assignees"),
        accessor: (matter: any) => matter,
        width: "20%",
        render: ({ value: matter }: any) => {
          return <AssigneesPreview size="sm" node={matter} />;
        },
      },
      {
        id: "dueDate",
        header: t("MattersTableView.cols.dueDate"),
        accessor: "dueDate",
        width: "20%",
        render: ({ value }: any) => {
          return value ? format(parseISO(value), "PPPP", { locale: de }) : "";
        },
      },
    ],
    []
  );
}

function MattersTableViewEmptyState() {
  const { t } = useTranslation("ai.workflows.matters");

  return (
    <NonIdealState
      header={<ErrorNotFoundIllustration />}
      title={t("MattersTableViewEmptyState.title")}
      description={t("MattersTableViewEmptyState.description")}
    />
  );
}
