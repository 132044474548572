import { getEdgeNodes } from "@workflows/runtime-web";
import {
  Avatar,
  Column,
  ColumnBody,
  ColumnHeader,
  Section,
  SectionBody,
  Stack,
  Status,
  Tag,
  Text,
} from "@workflows/ui";
import { formatDistance } from "date-fns";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Error404 } from "~/core/Error404";
import { locales } from "~/core/i18n";
import { MatterRouteQueryResponse } from "~/__graphql__/MatterRouteQuery.graphql";

export interface MatterInspectorColumnProps {
  data: MatterRouteQueryResponse;
}

export function MatterInspectorColumn({
  data,
}: MatterInspectorColumnProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");
  const { matter } = data;

  if (data === null || matter === null) {
    return <Error404 />;
  }

  const assignees = getEdgeNodes(matter.assignees!);

  const formattedDueDate = formatDistance(
    new Date(matter.dueDate!),
    new Date(),
    {
      locale: locales["en"],
      addSuffix: true,
    }
  );

  return (
    <Column role="aside" background="muted">
      <ColumnHeader title="Details" />
      <ColumnBody>
        {!!matter.status && (
          <Section title={t("MatterInspectorColumn.sections.status")}>
            <SectionBody>
              <div>
                <Status {...matter.status} />
              </div>
            </SectionBody>
          </Section>
        )}
        <Section title={t("MatterInspectorColumn.sections.assignee")}>
          <SectionBody>
            {assignees.map((assignee) => (
              <Stack
                key={assignee.actor.id}
                direction="horizontal"
                align="center"
                gap="xs"
              >
                <Avatar label={assignee.actor.name!} size="sm" />
                <Text>{assignee.actor.name}</Text>
              </Stack>
            ))}
          </SectionBody>
        </Section>
        {matter.dueDate && (
          <Section title={t("MatterInspectorColumn.sections.overdue")}>
            <SectionBody>
              <Text>{formattedDueDate}</Text>
            </SectionBody>
          </Section>
        )}
      </ColumnBody>
    </Column>
  );
}
