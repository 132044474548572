import { newId, useMutation } from "@workflows/runtime-web";
import { ConnectionHandler, graphql } from "react-relay/hooks";
import {
  useDeleteTenantMutation,
  useDeleteTenantMutationResponse,
} from "~/__graphql__/useDeleteTenantMutation.graphql";

interface useDeleteTenantProps {
  onError: (error: Error) => void;
  onCompleted: (data: useDeleteTenantMutationResponse) => void;
  input: {
    tenantId: string;
  };
}

interface useDeleteTenantValue {
  deleteTenant: (props: useDeleteTenantProps) => void;
  isPending: boolean;
}

export const useDeleteTenant = (): useDeleteTenantValue => {
  const [mutation, isPending] =
    useMutation<useDeleteTenantMutation>(deleteTenantMutation);

  const deleteTenant = (props: useDeleteTenantProps) => {
    const {
      onError,
      onCompleted,
      input: { tenantId },
    } = props;

    const input = {
      clientMutationId: newId(),
      tenantId,
    };

    mutation({
      variables: {
        input,
      },
      onError,
      onCompleted,
      updater: (store) => {
        const tenantRecord = store.get(tenantId);

        if (!tenantRecord) {
          return;
        }

        const tenants = ConnectionHandler.getConnection(
          tenantRecord,
          "useTenants_tenants"
        );

        if (!tenants) {
          return;
        }

        ConnectionHandler.deleteNode(tenants, tenantId);
      },
    });
  };

  return { deleteTenant, isPending };
};

const deleteTenantMutation = graphql`
  mutation useDeleteTenantMutation($input: DeleteTenantInput!) {
    deleteTenant(input: $input) {
      errors {
        code
        path
        message
        type
      }
    }
  }
`;
