/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorkflowChooserListItem_item = {
    readonly id: string;
    readonly name: string;
    readonly descriptionText: string | null;
    readonly color: string | null;
    readonly icon: string | null;
    readonly " $refType": "WorkflowChooserListItem_item";
};
export type WorkflowChooserListItem_item$data = WorkflowChooserListItem_item;
export type WorkflowChooserListItem_item$key = {
    readonly " $data"?: WorkflowChooserListItem_item$data;
    readonly " $fragmentRefs": FragmentRefs<"WorkflowChooserListItem_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkflowChooserListItem_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionText",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
        }
    ],
    "type": "Workflow",
    "abstractKey": null
} as any;
(node as any).hash = '2badc2649977e1f33094ea9d3234be3f';
export default node;
