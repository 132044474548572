import { useTenantId } from "@workflows/ui";
import { graphql, newId, useMutation } from "@workflows/runtime-web";
import {
  CreateFeedbackInput,
  useCreateFeedbackMutation,
  useCreateFeedbackMutationResponse,
} from "~/__graphql__/useCreateFeedbackMutation.graphql";

interface useCreateFeedbackProps {
  onError: () => void;
  onCompleted: (data: useCreateFeedbackMutationResponse) => void;
  feedbackChannelId: string;
  text: string;
  email: string;
}

interface useCreateFeedbackValue {
  createFeedback: (props: useCreateFeedbackProps) => void;
  isPending: boolean;
}

export const useCreateFeedback = (): useCreateFeedbackValue => {
  const tenantId = useTenantId();
  const [mutation, isPending] = useMutation<useCreateFeedbackMutation>(
    createFeedbackMutation
  );

  const createFeedback = (props: useCreateFeedbackProps) => {
    const { feedbackChannelId, text, email, onError, onCompleted } = props;

    const input: CreateFeedbackInput = {
      clientMutationId: newId(),
      email,
      feedbackChannelId,
      tenantId,
      text,
    };

    mutation({
      variables: { input },
      onError,
      onCompleted,
    });
  };

  return { createFeedback, isPending };
};

const createFeedbackMutation = graphql`
  mutation useCreateFeedbackMutation($input: CreateFeedbackInput!) {
    createFeedback(input: $input) {
      errors {
        code
        path
        message
      }
    }
  }
`;
