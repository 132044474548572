import { Value } from "./types";

export const type = "array";

export function load(val: Value<string>): Value<unknown[]> {
  return { ...val, value: JSON.parse(val.value ?? "[]") };
}

export function dump(val: Value<string>): string | null {
  return JSON.stringify(val.value);
}
