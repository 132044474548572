import { getEdgeNodes } from "@workflows/runtime-web";
import React from "react";
import { usePaginationFragment } from "react-relay";
import { graphql } from "relay-runtime";
import { useTenantsPaginationQuery } from "~/__graphql__/useTenantsPaginationQuery.graphql";
import { useTenants_tenants$key } from "~/__graphql__/useTenants_tenants.graphql";
import { UseTenants_tenants } from "../TenantsTableView";

export function useTenants(
  queryFragment: useTenants_tenants$key | null
): UseTenants_tenants[] {
  const { data } = usePaginationFragment<
    useTenantsPaginationQuery,
    useTenants_tenants$key
  >(useTenants_tenants, queryFragment || null);

  return React.useMemo(
    () => getEdgeNodes(data?.node?.tenants || null),
    [data?.node?.tenants]
  );
}

const useTenants_tenants = graphql`
  fragment useTenants_tenants on RootQueryType
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
    tenantId: { type: "ID!" }
  )
  @refetchable(queryName: "useTenantsPaginationQuery") {
    node(id: $tenantId) {
      ... on Tenant {
        tenants(after: $cursor, first: $count)
          @connection(key: "useTenants_tenants") {
          edges {
            cursor
            node {
              id
              name
              insertedAt
            }
          }
        }
      }
    }
  }
`;
