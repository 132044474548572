import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import * as React from "react";
import * as ReactDOM from "react-dom";
import "regenerator-runtime/runtime";
import { CoreBootloader } from "~/core/CoreBootloader";
import { CoreKernel } from "~/core/CoreKernel";
import "./index.scss";
import { environment } from "./relay";

try {
  Sentry.init({
    dsn: window.__workflows.config.SENTRY_DSN,
    environment: window.__workflows.config.SENTRY_ENV,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
} catch (error) {
  console.error(error);
}

async function startMockServer() {
  if (window.__workflows.config.MSW_MOCKING === "true") {
    // eslint-disable-next-line node/no-unsupported-features/es-syntax
    const { startWorker } = await import("../__mocks__/browser");
    await startWorker();
  }
  return Promise.resolve();
}

const enableConcurrentMode = false;

const root = document.getElementById("root");
const app = (
  <React.StrictMode>
    <CoreKernel relay={environment}>
      <CoreBootloader />
    </CoreKernel>
  </React.StrictMode>
);

if (!root) {
  throw new Error("Failed to initialize application.");
}

startMockServer().then(() => {
  if (enableConcurrentMode) {
    ReactDOM.createRoot(root).render(app);
  } else {
    ReactDOM.render(app, root);
  }
});
