import {
  atomFamily,
  ExtractNode,
  getEdgeNodes,
  sortElements,
  useSetRecoilState,
} from "@workflows/runtime-web";
import { Columns, Toaster, useForm } from "@workflows/ui";
import * as React from "react";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { Error404 } from "~/core/Error404";
import { ErrorBoundary } from "~/core/ErrorBoundary";
import { wizardStepIndexState } from "~/de.smartconex.vertragsgenerator/wizard/state";
import { WizardStep } from "~/de.smartconex.vertragsgenerator/wizard/types";
import { DocumentViewerContext } from "~/documents/DocumentViewerContext";
import {
  WizardQuery,
  WizardQueryResponse,
} from "~/__graphql__/WizardQuery.graphql";
import { useInitializeOutline } from "./hooks/useInitializeOutline";
import { useSnippets } from "./hooks/useSnippets";
import { hasStringProp } from "./utils";
import { WizardHeader } from "./WizardHeader";
import { WizardSkeleton } from "./WizardSkeleton";

const { useState, useEffect, useMemo, useCallback } = React;

export interface WizardProps {
  documentId: string;
  slug?: string;
}

export type DocumentElement = ExtractNode<
  NonNullable<NonNullable<WizardQueryResponse["document"]>["elements"]>
>;

export const outlineFamily = atomFamily({
  key: "Wizard.outlineFamily",
  default: {},
});

export function Wizard({ documentId, slug }: WizardProps): JSX.Element | null {
  const { document } = useLazyLoadQuery<WizardQuery>($WizardQuery, {
    documentId,
  });
  const [steps, setSteps] = useState<WizardStep[] | null>(null);
  const [isLoadingSteps, setIsLoadingSteps] = useState(true);

  const initializeOutline = useInitializeOutline();

  const sections = useMemo(
    () =>
      getEdgeNodes(document?.elements || null)
        .filter(isSection)
        .sort(sortElements),
    [document?.elements]
  );

  const workflowIdentifier = getWorkflowName(sections);
  if (!workflowIdentifier) {
    console.error("Missing workflow name in document");
  }

  React.useEffect(() => {
    if (!workflowIdentifier) {
      return;
    }

    async function loadBundle() {
      // eslint-disable-next-line node/no-unsupported-features/es-syntax
      const { steps } = await import(
        /* webpackMode: "lazy" */
        `../wizards/${workflowIdentifier}/index.ts`
      );

      setSteps(steps);
      setIsLoadingSteps(false);
    }

    loadBundle();
  });

  const snippets = useSnippets(workflowIdentifier || "");
  const stepIndex = getCurrentStepIndex(steps, slug);
  const sectionIdentifier = steps ? steps[stepIndex].section : slug;
  const section = sectionIdentifier
    ? getSectionFromIdentifier(sections, sectionIdentifier)
    : getSectionFromWorkflowName(sections, workflowIdentifier || "");

  const setCurrentStep = useSetRecoilState(wizardStepIndexState);
  useEffect(() => setCurrentStep(stepIndex), [setCurrentStep, stepIndex]);

  const [initialized, setInitialized] = useState(false);
  const form = useForm(documentId);

  React.useEffect(() => {
    if (document && document.elements && document.values) {
      initializeOutline(document.elements, document.values);
    }
  }, [document, initialized, initializeOutline]);

  const handleInitializeEnd = useCallback(
    ({ elements, values }: any) => {
      if (initialized) {
        return;
      }

      initializeOutline(elements, values);

      if (form) {
        setInitialized(true);
        form.reset(
          values.reduce((acc: any, val: any) => {
            acc[val.identifier] = val.value;
            return acc;
          }, {})
        );
      }
    },
    [form, initialized, initializeOutline]
  );

  if (isLoadingSteps) {
    return (
      <Columns>
        <WizardSkeleton />
      </Columns>
    );
  }

  if (!document || !steps || stepIndex === -1) {
    return <Error404 />;
  }

  const Step = steps[stepIndex].component;
  return (
    <DocumentViewerContext.Provider value={{ documentId }}>
      <Toaster maxDisplayAmount={3} />
      <Columns
        header={
          <WizardHeader
            document={document}
            steps={steps}
            stepIndex={stepIndex}
          />
        }
      >
        <ErrorBoundary>
          <React.Suspense fallback={<WizardSkeleton />}>
            {Step ? (
              <Step
                document={document}
                sections={sections}
                section={section}
                onInitializeEnd={handleInitializeEnd}
                snippets={snippets}
              />
            ) : (
              <Error404 />
            )}
          </React.Suspense>
        </ErrorBoundary>
      </Columns>
    </DocumentViewerContext.Provider>
  );
}

const isSection = (section: DocumentElement) => {
  return (
    section.type.identifier == "ai.workflows.documents.elements.section" &&
    section.path.length === 0
  );
};

function getCurrentStepIndex(steps: WizardStep[] | null, slug?: string) {
  const stepSlug = slug || "index";
  return steps?.findIndex((s) => s.slug === stepSlug) || 0;
}

function getWorkflowName(sections: DocumentElement[]) {
  const workflowSection = sections.find(
    ({ props }: DocumentElement) =>
      hasStringProp(props, "workflow") && props?.workflow
  );
  return (
    hasStringProp(workflowSection?.props, "workflow") &&
    workflowSection?.props?.workflow
  );
}

function getSectionFromIdentifier(
  sections: DocumentElement[],
  sectionIdentifier: string
) {
  return sections.find(
    ({ props }: DocumentElement) =>
      hasStringProp(props, "identifier") &&
      props.identifier == sectionIdentifier
  );
}

function getSectionFromWorkflowName(
  sections: DocumentElement[],
  workflowName: string
) {
  return sections.find(
    ({ props }: DocumentElement) =>
      hasStringProp(props, "workflow") && props.workflow == workflowName
  );
}

const $WizardQuery = graphql`
  query WizardQuery($documentId: ID!) {
    document: node(id: $documentId) {
      id
      ... on Document {
        title
        props
        values {
          edges {
            node {
              id
              name
              type
              value
              identifier
              type
              props
            }
          }
        }
        elements {
          edges {
            node {
              id
              path
              props
              sort
              visible {
                expression
                state
                valueRefs
              }
              type {
                identifier
              }
            }
          }
        }
      }
    }
  }
`;
