/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DocumentsList_documents = {
    readonly documents: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly id: string;
                readonly files?: {
                    readonly edges: ReadonlyArray<{
                        readonly cursor: string | null;
                    } | null> | null;
                } | null;
                readonly title?: string;
                readonly insertedAt?: string;
                readonly color?: string | null;
                readonly props?: unknown | null;
                readonly template?: {
                    readonly id: string;
                    readonly title: string;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"Status_status">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "DocumentsList_documents";
};
export type DocumentsList_documents$data = DocumentsList_documents;
export type DocumentsList_documents$key = {
    readonly " $data"?: DocumentsList_documents$data;
    readonly " $fragmentRefs": FragmentRefs<"DocumentsList_documents">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "documents"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 9999,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "tenantId"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": require('./DocumentsListPaginationQuery.graphql.ts')
            }
        },
        "name": "DocumentsList_documents",
        "selections": [
            {
                "alias": "documents",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "tenantId"
                    },
                    {
                        "kind": "Literal",
                        "name": "types",
                        "value": [
                            "ai.workflows.documents.document"
                        ]
                    }
                ],
                "concreteType": "NodeConnection",
                "kind": "LinkedField",
                "name": "__DocumentsList_documents_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "NodeEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            (v1 /*: any*/),
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "FileConnection",
                                                "kind": "LinkedField",
                                                "name": "files",
                                                "plural": false,
                                                "selections": [
                                                    {
                                                        "alias": null,
                                                        "args": null,
                                                        "concreteType": "FileEdge",
                                                        "kind": "LinkedField",
                                                        "name": "edges",
                                                        "plural": true,
                                                        "selections": [
                                                            (v1 /*: any*/)
                                                        ],
                                                        "storageKey": null
                                                    }
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "type": "HasFiles",
                                        "abstractKey": "__isHasFiles"
                                    },
                                    {
                                        "kind": "InlineFragment",
                                        "selections": [
                                            (v3 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "insertedAt",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "color",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "props",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "DocumentTemplate",
                                                "kind": "LinkedField",
                                                "name": "template",
                                                "plural": false,
                                                "selections": [
                                                    (v2 /*: any*/),
                                                    (v3 /*: any*/)
                                                ],
                                                "storageKey": null
                                            },
                                            {
                                                "args": null,
                                                "kind": "FragmentSpread",
                                                "name": "Status_status"
                                            }
                                        ],
                                        "type": "Document",
                                        "abstractKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "RootQueryType",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7ea9110454f84159606ccbc1c523a639';
export default node;
