import { newId } from "@workflows/runtime-web";
import { graphql, useMutation } from "react-relay/hooks";
import {
  PatchDocumentInput,
  usePatchDocumentMutation,
  usePatchDocumentMutationResponse,
} from "~/__graphql__/usePatchDocumentMutation.graphql";
import { useTenantId } from "@workflows/ui";

interface usePatchDocumentProps {
  onError?: (error: Error) => void;
  onCompleted?: (data: usePatchDocumentMutationResponse) => void;
  input: Pick<PatchDocumentInput, "documentId" | "patch">;
}

interface usePatchDocumentValue {
  patchDocument: (props: usePatchDocumentProps) => void;
  isPending: boolean;
}

export const usePatchDocument = (): usePatchDocumentValue => {
  const tenantId = useTenantId();
  const [mutation, isPending] = useMutation<usePatchDocumentMutation>(
    updateBillingPeriodMutation
  );

  const patchDocument = ({
    input: { documentId, patch },
    onError,
    onCompleted,
  }: usePatchDocumentProps) => {
    const input = {
      clientMutationId: newId(),
      documentId,
      tenantId,
      patch,
    };

    mutation({
      variables: { input },
      onError(error) {
        onError?.(error);
      },
      onCompleted(data) {
        onCompleted?.(data);
      },
    });
  };

  return { patchDocument, isPending };
};

const updateBillingPeriodMutation = graphql`
  mutation usePatchDocumentMutation($input: PatchDocumentInput!) {
    patchDocument(input: $input) {
      errors {
        code
        path
        message
        type
      }
      document {
        props
      }
    }
  }
`;
