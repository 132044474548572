import * as React from "react";
import { AppDokgeneratorWorkflowsEmploymentContractv1 } from "~/de.smartconex.vertragsgenerator/workflows/app.dokgenerator.workflows.employmentContract.v1/AppDokgeneratorWorkflowsEmploymentContractv1";
import { AppDokgeneratorWorkflowsEmploymentRefv1 } from "~/de.smartconex.vertragsgenerator/workflows/app.dokgenerator.workflows.employmentRef.v1/AppDokgeneratorWorkflowsEmploymentRefv1";
import { DeSmartconexVertragsgeneratorWorkflowsCreatorv1 } from "~/de.smartconex.vertragsgenerator/workflows/de.smartconex.vertragsgenerator.workflows.documentCreator.v1/DeSmartconexVertragsgeneratorWorkflowsCreatorv1";

// prettier-ignore
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mapping: Record<string, React.ElementType<any>> = {
  "app.dokgenerator.workflows.employmentContract.v1": AppDokgeneratorWorkflowsEmploymentContractv1,
  "app.dokgenerator.workflows.employmentRef.v1": AppDokgeneratorWorkflowsEmploymentRefv1,
  "de.smartconex.vertragsgenerator.workflows.documentCreator.v1": DeSmartconexVertragsgeneratorWorkflowsCreatorv1,
};
