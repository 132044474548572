import { useHistory } from "@workflows/runtime-web";
import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  Text,
  Truncate,
} from "@workflows/ui";
import * as React from "react";
import { graphql, useFragment } from "react-relay/hooks";
import { routes } from "~/flows/routes";
import { CollectionItemChooserListWorkflowItem_item$key } from "~/__graphql__/CollectionItemChooserListWorkflowItem_item.graphql";

export interface CollectionItemChooserListWorkflowItemProps {
  fragment: CollectionItemChooserListWorkflowItem_item$key;
}

export function CollectionItemChooserListWorkflowItem({
  fragment,
}: CollectionItemChooserListWorkflowItemProps): JSX.Element {
  const history = useHistory();
  const item = useFragment(
    $CollectionItemChooserListWorkflowItem_item,
    fragment
  );

  return (
    <Card
      interactive
      tint={item.color || undefined}
      onClick={() => {
        history.push(routes["flows.new"].build({ workflowId: item.id }));
      }}
    >
      <CardHeader
        iconBackgroundColor={item.color || undefined}
        iconTint={item.color || undefined}
        icon={<Icon identifier={item.icon || "ai.workflows.icons:PencilAlt"} />}
      >
        <Truncate lines={2}>
          <Text variant="headingMd">{item.name}</Text>
        </Truncate>
      </CardHeader>
      <CardBody>
        <Text color="muted">
          <Truncate lines={10}>{item.descriptionText}</Truncate>
        </Text>
      </CardBody>
    </Card>
  );
}

const $CollectionItemChooserListWorkflowItem_item = graphql`
  fragment CollectionItemChooserListWorkflowItem_item on Workflow {
    id
    name
    descriptionText
    icon
    color
  }
`;
