import {
  Button,
  NonIdealState,
  Stack,
  TextGenericIllustration,
} from "@workflows/ui";
import { useTranslation } from "react-i18next";
import * as React from "react";

export function CoreResetRoute(): JSX.Element {
  const { t } = useTranslation("ai.workflows.core");

  const resetData = function () {
    document.cookie.split(";").forEach(function (c) {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });

    localStorage.clear();
    sessionStorage.clear();
  };

  return (
    <Stack direction="vertical" fill>
      <NonIdealState
        header={<TextGenericIllustration />}
        description={t("CoreResetRoute.description")}
        footer={
          <Button intent="primary" onClick={resetData}>
            {t("CoreResetRoute.actions.submit")}
          </Button>
        }
      />
    </Stack>
  );
}
