/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type TenantsColumnQueryVariables = {
    tenantId: string;
};
export type TenantsColumnQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"useTenants_tenants">;
};
export type TenantsColumnQuery = {
    readonly response: TenantsColumnQueryResponse;
    readonly variables: TenantsColumnQueryVariables;
};



/*
query TenantsColumnQuery(
  $tenantId: ID!
) {
  ...useTenants_tenants_1GYzvY
}

fragment useTenants_tenants_1GYzvY on RootQueryType {
  node(id: $tenantId) {
    __typename
    ... on Tenant {
      tenants(first: 100) {
        edges {
          cursor
          node {
            id
            name
            insertedAt
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "TenantsColumnQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "tenantId",
                            "variableName": "tenantId"
                        }
                    ],
                    "kind": "FragmentSpread",
                    "name": "useTenants_tenants"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "TenantsColumnQuery",
            "selections": [
                {
                    "alias": null,
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "id",
                            "variableName": "tenantId"
                        }
                    ],
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "TenantConnection",
                                    "kind": "LinkedField",
                                    "name": "tenants",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TenantEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Tenant",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "insertedAt",
                                                            "storageKey": null
                                                        },
                                                        (v1 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "tenants(first:100)"
                                },
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "useTenants_tenants",
                                    "kind": "LinkedHandle",
                                    "name": "tenants"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "72710cb0bdfcfde3c91175faabbe66ee",
            "id": null,
            "metadata": {},
            "name": "TenantsColumnQuery",
            "operationKind": "query",
            "text": "query TenantsColumnQuery(\n  $tenantId: ID!\n) {\n  ...useTenants_tenants_1GYzvY\n}\n\nfragment useTenants_tenants_1GYzvY on RootQueryType {\n  node(id: $tenantId) {\n    __typename\n    ... on Tenant {\n      tenants(first: 100) {\n        edges {\n          cursor\n          node {\n            id\n            name\n            insertedAt\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '10770a1e358ae47db20e4c0631daaf7d';
export default node;
