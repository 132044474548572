/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MatterTimelineItemActivityFragment = {
    readonly id: string;
    readonly identifier: string;
    readonly insertedAt: string;
    readonly actor: {
        readonly name: string;
    } | null;
    readonly toObject: {
        readonly id: string;
        readonly __typename: string;
        readonly name?: string;
        readonly color?: string;
        readonly textColor?: string;
    } | null;
    readonly " $refType": "MatterTimelineItemActivityFragment";
};
export type MatterTimelineItemActivityFragment$data = MatterTimelineItemActivityFragment;
export type MatterTimelineItemActivityFragment$key = {
    readonly " $data"?: MatterTimelineItemActivityFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MatterTimelineItemActivityFragment">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v2 = [
        (v1 /*: any*/)
    ], v3 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "textColor",
            "storageKey": null
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MatterTimelineItemActivityFragment",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identifier",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "actor",
                "plural": false,
                "selections": (v2 /*: any*/),
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "toObject",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v2 /*: any*/),
                        "type": "User",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v3 /*: any*/),
                        "type": "Status",
                        "abstractKey": null
                    },
                    {
                        "kind": "InlineFragment",
                        "selections": (v3 /*: any*/),
                        "type": "Tag",
                        "abstractKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Activity",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'b07d2dd9bc1fc5fa9180cffca02de426';
export default node;
