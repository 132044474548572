import { Route, RouteComponentProps, Switch } from "@workflows/runtime-web";
import {
  FormContext,
  TableView,
  useDocumentTitle,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PreloadedQuery } from "react-relay";
import { usePreloadedQuery, useQueryLoader } from "react-relay/hooks";
import { DocumentsColumnQuery } from "~/__graphql__/DocumentsColumnQuery.graphql";
import { routes } from "../routes";
import {
  DocumentInspectorColumn,
  DocumentInspectorColumnSkeleton,
} from "./DocumentInspectorColumn";
import { DocumentsColumn, documentsColumnQuery } from "./DocumentsColumn";

export interface DocumentColumnEntryPointQueries {
  documents: PreloadedQuery<DocumentsColumnQuery>;
}

export interface DocumentRouteProps extends RouteComponentProps {
  queries: DocumentColumnEntryPointQueries;
}

export function DocumentsRoute({ queries }: DocumentRouteProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const [selection, setSelection] = React.useState<string[]>([]);
  const tenantId = useTenantId();

  useDocumentTitle(t("DocumentsRoute.pageTitle"));

  const [innerRef, loadQuery] = useQueryLoader(
    documentsColumnQuery,
    queries.documents
  );
  const documentsRef = usePreloadedQuery(documentsColumnQuery, innerRef!);

  const initRerender = () => {
    setSelection([]);
    loadQuery({ tenantId }, { fetchPolicy: "network-only" });
  };

  return (
    <>
      <React.Suspense
        fallback={<TableView isPending={true} columns={[]} data={[]} />}
      >
        {queries.documents != null && (
          <FormContext name="DocumentForm">
            <DocumentsColumn
              documentsRef={documentsRef}
              onSelect={(selection: string[]) => setSelection(selection)}
              initRerender={initRerender}
              selection={selection || []}
            />
          </FormContext>
        )}
      </React.Suspense>

      <React.Suspense fallback={<DocumentInspectorColumnSkeleton />}>
        <Route path={routes["documents.index"].path}>
          {selection.length ? (
            <DocumentInspectorColumn
              documentId={selection[0]}
              initRerender={initRerender}
            />
          ) : (
            <DocumentInspectorColumnSkeleton />
          )}
        </Route>
      </React.Suspense>
    </>
  );
}
