import { ExtractNode, useParams } from "@workflows/runtime-web";
import { Link, Stack, TableView, TableViewColumn } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  useTenants_tenants as useTenants_tenantsType,
  useTenants_tenants$key,
} from "~/__graphql__/useTenants_tenants.graphql";
import { routes } from "../routes";
import { useTenants } from "./hooks/useTenants";
import { TenantsTableViewEmptyState } from "./TenantsTableViewEmptyState";

export type UseTenants_tenants = ExtractNode<
  NonNullable<NonNullable<useTenants_tenantsType["node"]>["tenants"]>
>;

interface TenantTableViewRow {
  value: UseTenants_tenants;
}

interface TenantsTableViewProps {
  isPending?: boolean;
  queryFragment?: useTenants_tenants$key;
}

export function TenantsTableView({
  isPending = false,
  queryFragment,
}: TenantsTableViewProps): JSX.Element {
  const tenants = useTenants(queryFragment || null);
  const columns = useColumns();
  const { tenantId } = useParams<{ tenantId: string }>();

  return isPending || tenants.length > 0 ? (
    <TableView
      isPending={isPending}
      columns={columns}
      data={tenants}
      selection={[tenantId]}
    />
  ) : (
    <TenantsTableViewEmptyState />
  );
}

export function useColumns(): TableViewColumn[] {
  const { t } = useTranslation("de.agad.dpm");

  return [
    {
      id: "tenant",
      header: t("TenantsTableView.cols.name"),
      width: "40%",
      render: ({ value: tenant }: TenantTableViewRow) => {
        return (
          <Stack direction="vertical" gap="xxs">
            <Link to={routes["tenant.index"].build({ tenantId: tenant.id })}>
              {tenant.name}
            </Link>
          </Stack>
        );
      },
    },
    {
      id: "insertedAt",
      header: t("TenantsTableView.cols.insertedAt"),
      width: "20%",
      render: ({ value: tenant }: TenantTableViewRow) => {
        return tenant.insertedAt
          ? t("TenantsTableView.date", { value: tenant.insertedAt })
          : "-";
      },
    },
  ];
}
