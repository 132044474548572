import * as React from "react";
import { PreloadedQuery } from "react-relay";
import { MatterRouteEntryPointParams } from "~/matters/Matter/MatterRoute";
import { FlowRendererRouteQuery } from "~/__graphql__/FlowRendererRouteQuery.graphql";
import {
  graphql,
  RouteComponentProps,
  usePreloadedQuery,
} from "@workflows/runtime-web";
import { mapping } from "./mapping";
import { Error404 } from "~/core/Error404";
import { useDocumentTitle } from "@workflows/ui";
import { CoreRouterContext } from "~/core/CoreRouterContext";
import { FlowComponent } from "./types";

export interface MatterRouteEntryPointQueries {
  workflow: PreloadedQuery<FlowRendererRouteQuery>;
}

export interface MatterRouteProps {
  queries: MatterRouteEntryPointQueries;
  workflowId?: string;
  props: RouteComponentProps<MatterRouteEntryPointParams>;
}

export function FlowRendererRoute({
  queries,
  props,
}: MatterRouteProps): JSX.Element {
  const data = usePreloadedQuery<FlowRendererRouteQuery>(
    FlowRendererRouteQueryRequest,
    queries.workflow
  );

  // TODO: Modify `useDocumentTitle` to handle undefined titles
  useDocumentTitle((data.node && data.node ? data.node.name : "")!);

  if (!data.node || !data.node.identifier) {
    return <Error404 />;
  }

  // TODO: Add this point we would lazy-load the JavaScript bundle that contains
  // the code for the workflow and render it into a sandboxed iframe. Until we have
  // all the moving pieces in place we'll use this simple mapping.
  const Component = mapping[data.node.identifier];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const workflow: FlowComponent["workflow"] = {
    ...(data.node as any),
    config: {},
  };

  const preloadConfig = (data.node.config || []).find(
    (config) => config && config.key === "ai.workflows.workflows.config.preload"
  );
  if (preloadConfig) {
    workflow.config = preloadConfig.value as Record<string, unknown>;
  }

  if (!Component) {
    return <Error404 />;
  }

  // TODO: This route should minimize the main navigation
  return (
    <CoreRouterContext baseUrl={props.match.url}>
      <Component {...props} workflow={workflow} />
    </CoreRouterContext>
  );
}

export const FlowRendererRouteQueryRequest = graphql`
  query FlowRendererRouteQuery($workflowId: ID!) {
    node(id: $workflowId) {
      id
      ... on Workflow {
        identifier
        name
        config(keys: ["ai.workflows.workflows.config.preload"]) {
          key
          value
        }
      }
    }
  }
`;
