/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_tenant = {
    readonly id: string;
    readonly name: string;
    readonly parentId: string | null;
    readonly roles: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string | null;
            } | null;
        } | null> | null;
    } | null;
    readonly viewerPermissions: ReadonlyArray<string>;
    readonly config: ReadonlyArray<{
        readonly key: string;
        readonly value: unknown;
    } | null> | null;
    readonly " $refType": "App_tenant";
};
export type App_tenant$data = App_tenant;
export type App_tenant$key = {
    readonly " $data"?: App_tenant$data;
    readonly " $fragmentRefs": FragmentRefs<"App_tenant">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "configValueKeys"
            }
        ],
        "kind": "Fragment",
        "metadata": null,
        "name": "App_tenant",
        "selections": [
            (v0 /*: any*/),
            (v1 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "parentId",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v0 /*: any*/),
                                    (v1 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerPermissions",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "keys",
                        "variableName": "configValueKeys"
                    }
                ],
                "concreteType": "ConfigValue",
                "kind": "LinkedField",
                "name": "config",
                "plural": true,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Tenant",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '06766aefa48b1cee4bcf69c2f1ccef45';
export default node;
