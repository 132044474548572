/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeleteUserInput = {
    clientMutationId: string;
    tenantId: string;
    userId: string;
};
export type useDeleteUserMutationVariables = {
    connections: Array<string>;
    input: DeleteUserInput;
};
export type useDeleteUserMutationResponse = {
    readonly deleteUser: {
        readonly user: {
            readonly id: string;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
    } | null;
};
export type useDeleteUserMutation = {
    readonly response: useDeleteUserMutationResponse;
    readonly variables: useDeleteUserMutationVariables;
};



/*
mutation useDeleteUserMutation(
  $input: DeleteUserInput!
) {
  deleteUser(input: $input) {
    user {
      id
    }
    errors {
      code
      path
      message
      type
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connections"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useDeleteUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteUserPayload",
                    "kind": "LinkedField",
                    "name": "deleteUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useDeleteUserMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeleteUserPayload",
                    "kind": "LinkedField",
                    "name": "deleteUser",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "filters": null,
                                    "handle": "deleteEdge",
                                    "key": "",
                                    "kind": "ScalarHandle",
                                    "name": "id",
                                    "handleArgs": [
                                        {
                                            "kind": "Variable",
                                            "name": "connections",
                                            "variableName": "connections"
                                        }
                                    ]
                                }
                            ],
                            "storageKey": null
                        },
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "44452a822be043c93048d7e81dcd8510",
            "id": null,
            "metadata": {},
            "name": "useDeleteUserMutation",
            "operationKind": "mutation",
            "text": "mutation useDeleteUserMutation(\n  $input: DeleteUserInput!\n) {\n  deleteUser(input: $input) {\n    user {\n      id\n    }\n    errors {\n      code\n      path\n      message\n      type\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1f65562b25d18266b8718b052ec495b5';
export default node;
