import { getMappedElements, sortElements } from "@workflows/runtime-web";
import { TFunction } from "react-i18next";
import { evaluate } from "~/documents/utils";
import { Value } from "~/documents/values/types";
import { DocumentElement } from "./Wizard";

export const booleanTransform = (): {
  get: (value: boolean | null) => string | null;
  set: (value: string | null) => boolean | null;
} => ({
  get(value) {
    return typeof value === "boolean" ? `${value}` : value;
  },

  set(value) {
    if (typeof value === "string") {
      return value === "true" ? true : false;
    }
    return value;
  },
});

export function hasStringProp(
  object: unknown,
  key: string
): object is Record<string, string> {
  return (
    !!object && typeof (object as Record<string, string>)[key] === "string"
  );
}

const hasOwnProperty = Object.prototype.hasOwnProperty;
export type CounterFunction = "set" | "reset" | "increment";
export type Counter = Record<CounterFunction, string>;

export interface TreeOutputNode extends DocumentElement {
  isVisible?: boolean;
  props: any;
}

export function toTrees<I extends DocumentElement>(
  elements: I[],
  values: Value[],
  t: TFunction<"ai.workflows.documents">
): TreeOutputNode[] {
  const list = elements.sort(sortElements);
  const roots = list.filter((el) => el.path.length === 0);

  if (roots.length === 0) {
    return [];
  }

  const mapping = getMappedElements<TreeOutputNode>(list);

  list.forEach((el) => {
    const key = el.path.concat(el.id).join(".");
    const parentKey = el.path.join(".");

    if (hasOwnProperty.call(mapping, parentKey)) {
      if (!hasOwnProperty.call(mapping[parentKey], "props")) {
        mapping[parentKey].props = { children: [] };
      }

      if (
        hasOwnProperty.call(mapping[parentKey], "isVisible") &&
        mapping[parentKey].isVisible === false
      ) {
        mapping[key].isVisible = false;
      } else {
        if (
          hasOwnProperty.call(mapping[parentKey], "visible") &&
          hasOwnProperty.call(mapping[parentKey].visible, "expression")
        ) {
          const valueRefs = mapping[parentKey].visible?.valueRefs || [];
          const evaluator = new Function(
            "runtime",
            "values",
            `with(runtime) { return (${mapping[parentKey].visible?.expression}); }`
          );

          const vals = valueRefs.map((id: string) =>
            values?.find((value: Value) => value?.id === id)
          );

          try {
            const result = evaluate(evaluator, valueRefs, vals, t);
            mapping[parentKey].isVisible = result;
            mapping[key].isVisible = result;
          } catch (err) {
            console.error(
              `Failed to evaluate visibility expression of ${el.id}:`,
              err
            );
          }
        }
      }

      if (!hasOwnProperty.call(mapping[parentKey].props, "children")) {
        mapping[parentKey].props.children = [];
      }
      mapping[parentKey].props.children.push(mapping[key]);
    }
  });

  return roots.map((el) => mapping[el.id]);
}

export function flattenTrees(trees: TreeOutputNode[]): TreeOutputNode[] {
  const flat: TreeOutputNode[] = [];

  Object.keys(trees).forEach((key, i) => {
    flat.push(trees[i], ...trees[i].props.children.reduce(flattenElements, []));
  });

  return flat;
}

function flattenElements(
  accumulator: TreeOutputNode[],
  currentElement: TreeOutputNode
) {
  accumulator.push(currentElement);
  if (Array.isArray(currentElement.props.children)) {
    return currentElement.props.children.reduce(flattenElements, accumulator);
  }
  return accumulator;
}

export type Outline = Record<string, Record<string, number>>;

export function toOutline(
  trees: TreeOutputNode[]
): Record<string, Record<string, number>> {
  const elements = flattenTrees(trees);
  const counter: Record<string, number> = {};
  const outline: Outline = {};

  const createOrUpdateCounters = (counters: Counter) => {
    if (!counter) return;

    Object.keys(counters).forEach((key) => {
      const [counterKey, counterValue] =
        counters[key as CounterFunction].split(" ");
      switch (key) {
        case "set":
          counter[counterKey] = parseInt(counterValue, 10);
          break;
        case "reset":
          counter[counterKey] = counterValue ? parseInt(counterValue, 10) : 0;
          break;
        case "increment":
          if (!counter[counterKey]) {
            counter[counterKey] = 1;
          } else {
            counter[counterKey] += 1;
          }
          break;
      }
    });
    return { ...counter };
  };

  elements.forEach((el) => {
    if (el?.props?.counter && el?.isVisible !== false) {
      createOrUpdateCounters(el?.props?.counter);
    }
    if (el.path.length === 0) {
      outline[el.id] = { ...counter };
    }
  });

  return outline;
}
