import * as React from "react";
import { Route, Switch } from "@workflows/runtime-web";
import { useViewerTenant } from "@workflows/ui";
import { CoreEntryPointRoute } from "~/core/CoreEntryPointRoute";
import { ProcessingActivitiesRoute } from "./dashboard/ProcessingActivitiesDashboardRoute";
import { routeConfig, routes } from "./routes";
import { router as deSmartconexVertragsgeneratorRouter } from "~/de.smartconex.vertragsgenerator/routes";

export const DeAgadDpmRoute = (): JSX.Element => {
  const { parentId: isSubtenant } = useViewerTenant(true);

  return isSubtenant ? (
    <Switch>
      <Route
        path={routes["tenants.dashboard"].path}
        component={ProcessingActivitiesRoute}
        exact
      />
      {deSmartconexVertragsgeneratorRouter}
    </Switch>
  ) : (
    <CoreEntryPointRoute
      path={routes["tenants.index"].path}
      entrypoint={routeConfig["tenants.index"]}
    />
  );
};
