import * as React from "react";
import {
  Button,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  Drawer,
  Form2,
  FormControl,
  FormInput,
  Label,
  Skeleton,
  TableView,
  TableViewColumn,
  Tag,
  Text,
  TextField,
  TextFieldProps,
  useForm,
} from "@workflows/ui";
import { routes } from "~/de.agad.dpm/routes";
import { useHistory, useParams } from "@workflows/runtime-web";
import { useDocumentTemplates } from "~/de.agad.dpm/tenants/hooks/useDocumentTemplates";
import { useDeAgadDpmCreateDocuments } from "~/de.agad.dpm/tenants/hooks/useDeAgadDpmCreateDocuments";
import { useTranslation } from "react-i18next";

export const AddDocumentsDrawer = (): JSX.Element => {
  const { tenantId: subtenantId } = useParams<{ tenantId: string }>();
  const { t } = useTranslation("de.agad.dpm");

  const history = useHistory();

  const handleClose = () => {
    history.push(routes["tenant.documents"].build({ tenantId: subtenantId }));
  };

  const { deAgadDpmCreateDocuments, isPending } = useDeAgadDpmCreateDocuments();
  const form = useForm("documentTemplatesForm");

  function handleAddDocuments() {
    const formValues = form.getValues();

    const input = {
      subtenantId,
      documents: getDocumentsArrayFromFormValues(formValues),
    };

    deAgadDpmCreateDocuments({
      input,
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.deAgadDpmCreateDocuments?.errors) {
          console.error(data.deAgadDpmCreateDocuments.errors);
        }
        handleClose();
      },
    });
  }

  const columns = useColumns();
  const documentTemplates = useDocumentTemplates();

  return (
    <Drawer isOpen width="auto" closeOnOutsideClick onClose={handleClose}>
      <Column>
        <ColumnHeader
          borderless
          start={
            <>
              <Label>{t("AddDocumentsDrawer.title")}</Label>
              <Text>{t("AddDocumentsDrawer.description")}</Text>
            </>
          }
        />
        <ColumnBody scrollable>
          <Form2 name="documentTemplatesForm" onSubmit={handleAddDocuments}>
            <TableView
              columns={columns}
              data={documentTemplates}
              className={"mt-md"}
            />
          </Form2>
        </ColumnBody>
        <ColumnFooter>
          <Button
            onClick={handleAddDocuments}
            intent={"primary"}
            isLoading={isPending}
          >
            {t("AddDocumentsDrawer.save")}
          </Button>
        </ColumnFooter>
      </Column>
    </Drawer>
  );
};

function getDocumentsArrayFromFormValues(formValues: any) {
  return Object.keys(formValues)
    .map((formValue) => ({
      documentTemplateId: formValue,
      count: formValues[formValue],
    }))
    .filter((template) => template.count);
}

function useColumns(): TableViewColumn[] {
  const { t } = useTranslation("de.agad.dpm");
  return React.useMemo(
    () => [
      {
        id: "title",
        header: t("AddDocumentsDrawer.column.name"),
        accessor: "title",
        width: "80%",
        render: ({ value }: { value: string }) => {
          return <>{value}</>;
        },
      },
      {
        id: "count",
        header: t("AddDocumentsDrawer.column.count"),
        width: "10%",
        render: ({ value: documentTemplate }: { value: { id: string } }) => {
          return (
            <FormControl htmlFor={documentTemplate.id}>
              <FormInput<TextFieldProps>
                id={documentTemplate.id}
                component={TextField}
                type="number"
                name={documentTemplate.id}
                placeholder={"0"}
              />
            </FormControl>
          );
        },
      },
      {
        id: "status",
        header: t("AddDocumentsDrawer.column.status"),
        accessor: "status",
        width: "10%",
        render: ({
          value: status,
        }: {
          value: { color: string; textColor: string; name: string };
        }) => {
          return status ? (
            <Tag color={status.color} textColor={status.textColor}>
              {status.name}
            </Tag>
          ) : null;
        },
        skeleton: () => (
          <Skeleton>
            <Tag>…</Tag>
          </Skeleton>
        ),
      },
    ],
    [t]
  );
}
