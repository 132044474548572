import {
  BrandLogo,
  Button,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  Drawer,
  Link,
  LogoutIcon,
  MenuIcon,
  MenuSize,
  Nav,
  Stack,
  SupportIcon,
  Toaster,
  useBreakpointMatch,
  useDialog,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { CoreNav } from "~/core/CoreNav";
import { SupportDialog } from "~/core/Dialogs/SupportDialog";
import { CoreNav_nav$key } from "~/__graphql__/CoreNav_nav.graphql";
import { ErrorBoundary } from "./ErrorBoundary";
import { signOut } from "~/core/auth";

export interface SidebarProps {
  nav: CoreNav_nav$key;
}

export function CoreSidebar(props: SidebarProps): JSX.Element {
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { t } = useTranslation("ai.workflows.core");

  const [isOpen, setIsOpen] = React.useState(false);

  const handleSupportDialogOpen = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleClick();
    openDialog();
  };

  const handleSignOut = (event: React.SyntheticEvent) => {
    event.preventDefault();
    handleClick();
    signOut();
  };

  const { menuSize } = useBreakpointMatch({
    menuSize: { xs: "md", lg: "xs" },
  }) as { menuSize: MenuSize | undefined };

  const handleClick = React.useCallback(() => {
    menuSize === "md" && setIsOpen(!isOpen);
  }, [isOpen, menuSize]);

  const nav = (
    <>
      <ColumnBody inset>
        <ErrorBoundary>
          <CoreNav nav={props.nav} size={menuSize} onClick={handleClick} />
        </ErrorBoundary>
      </ColumnBody>
      <ColumnFooter borderless {...(menuSize === "md" && { align: "start" })}>
        <Nav size={menuSize}>
          <Nav.Item
            key="support"
            to={"/support"}
            label={t("CoreSidebar.support")}
            icon={<SupportIcon />}
            onClick={handleSupportDialogOpen}
            exact
          />
          <Nav.Item
            key="logout"
            to={"/sign-out"}
            label={t("CoreSidebar.logout")}
            icon={<LogoutIcon />}
            onClick={handleSignOut}
            exact
          />
        </Nav>
      </ColumnFooter>
    </>
  );

  const end = <Button icon={<MenuIcon />} onClick={handleClick} />;
  const brandLink = (
    <Link to="/">
      <BrandLogo size="xs" framed />
    </Link>
  );

  return (
    <>
      <Column background="base">
        <ColumnHeader
          inset="sm"
          {...(menuSize === "md"
            ? {
                start: brandLink,
                end,
              }
            : {
                children: (
                  <Stack direction="horizontal" justify="center">
                    {brandLink}
                  </Stack>
                ),
              })}
        />
        {menuSize === "md" ? (
          <Drawer
            isOpen={isOpen}
            position="start"
            onClose={handleClick}
            width="auto"
          >
            {nav}
          </Drawer>
        ) : (
          nav
        )}
      </Column>

      {isDialogOpen && <SupportDialog onClose={closeDialog} />}
      {/*TODO: Add a Global Toaster */}
      {<Toaster />}
    </>
  );
}
