import {
  ErrorNotFoundIllustration,
  Link,
  NonIdealState,
  Section,
  SectionBody,
  Stack,
  StackItem,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AdminType, UseTenantQueryResponseType } from "./hooks/useTenant";
import { getEdgeNodes } from "@workflows/runtime-web";

export interface TenantOverviewTabProps {
  tenant: UseTenantQueryResponseType;
}

export function TenantOverviewTab({
  tenant,
}: TenantOverviewTabProps): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");

  if (!tenant) {
    return (
      <NonIdealState
        title={t("TenantOverviewTab.tenantNotFound.title")}
        description={t("TenantOverviewTab.tenantNotFound.description")}
        header={<ErrorNotFoundIllustration />}
      />
    );
  }

  function createStackItems(values: (string | null | undefined)[]) {
    const definedValues = values.filter(Boolean);
    if (definedValues.length === 0) {
      return <StackItem>-</StackItem>;
    }

    return definedValues.map((info, index) => (
      <StackItem key={index}>{info}</StackItem>
    ));
  }

  const { host, organization, contact, admins: adminEdges } = tenant;
  const admins = getEdgeNodes(adminEdges || null);

  return (
    <>
      <Section title={t("TenantOverviewTab.link")}>
        <SectionBody>
          <Link href={host?.baseUrl} target="_blank">
            {host?.baseUrl}
          </Link>
        </SectionBody>
      </Section>

      <Section title={t("TenantOverviewTab.address")}>
        <SectionBody>
          <Stack direction="vertical" gap="xs">
            {createStackItems([
              organization?.name,
              organization?.streetAddress,
              organization?.postalCode,
              organization?.administrativeArea,
              organization?.postalCountry,
            ])}
          </Stack>
        </SectionBody>
      </Section>

      <Section title={t("TenantOverviewTab.contact")}>
        <SectionBody>
          <Stack direction="vertical" gap="xs">
            {createStackItems([contact?.name, contact?.position])}
            <StackItem>
              <Link href={`mailto:${contact?.email}`}>{contact?.email}</Link>
            </StackItem>
          </Stack>
        </SectionBody>
      </Section>

      <Section title={t("TenantOverviewTab.manager")}>
        <SectionBody>
          <Stack direction="vertical" gap="xs">
            <AdminsList admins={admins} />
          </Stack>
        </SectionBody>
      </Section>
    </>
  );

  function AdminsList({ admins }: { admins: AdminType[] }) {
    if (admins.length === 0) {
      return <StackItem>-</StackItem>;
    }
    return (
      <>
        {admins.map((admin) => (
          <StackItem key={admin.id}>{admin.name}</StackItem>
        ))}
      </>
    );
  }
}
