import { Tab, Tabs } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { UseTenantQueryResponseType } from "./hooks/useTenant";
import { TenantOverviewTab } from "./TenantOverviewTab";
import { useHistory, useLocation, useParams } from "@workflows/runtime-web";
import { TenantEditor } from "~/de.agad.dpm/tenants/TenantEditor";
import { TenantDocumentsTab } from "~/de.agad.dpm/tenants/TenantDocumentsTab";
import { TenantPeopleTab } from "~/de.agad.dpm/tenants/TenantPeopleTab";
import { routes } from "~/de.agad.dpm/routes";
import { TabSkeleton } from "~/core/Skeleton/TabSkeleton";

interface TenantColumnTabsProps {
  tenant?: UseTenantQueryResponseType;
}

export function TenantColumnTabs({
  tenant,
}: TenantColumnTabsProps): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");
  const location = useLocation();
  const currentTab = getCurrentTab(location);
  const history = useHistory();
  const { tenantId: subTenantId } = useParams<{ tenantId: string }>();

  const handleNavigate = (tabId: string) => {
    history.push(routesMap[tabId]);
  };

  const routesMap: Record<string, string> = {
    overview: routes["tenant.index"].build({ tenantId: subTenantId }),
    details: routes["tenant.details"].build({ tenantId: subTenantId }),
    documents: routes["tenant.documents"].build({ tenantId: subTenantId }),
    people: routes["tenant.people"].build({ tenantId: subTenantId }),
  };

  return (
    <Tabs
      selectedTabId={currentTab}
      onChange={(_, tabId) => handleNavigate(tabId)}
      fixed
      indent="md"
    >
      <Tab id="overview" title={t("TenantColumnTabs.overview")}>
        <TenantOverviewTab tenant={tenant || null} />
      </Tab>

      <Tab id="details" title={t("TenantColumnTabs.details")}>
        <TenantEditor />
      </Tab>
      <Tab id="documents" title={t("TenantColumnTabs.documents")}>
        <React.Suspense fallback={<TabSkeleton />}>
          <TenantDocumentsTab />
        </React.Suspense>
      </Tab>
      <Tab id="people" title={t("TenantColumnTabs.people")}>
        <React.Suspense fallback={<TabSkeleton />}>
          <TenantPeopleTab />
        </React.Suspense>
      </Tab>
    </Tabs>
  );
}

function getCurrentTab(location: any) {
  if (location.pathname.includes("documents")) return "documents";
  if (location.pathname.endsWith("people")) return "people";
  if (location.pathname.endsWith("details")) return "details";
  else return "overview";
}
