import { Spinner, Stack } from "@workflows/ui";
import * as React from "react";

export const SuspenseFallback: React.FC = () => {
  return (
    <Stack fill justify="center" align="center" direction="vertical">
      <Spinner />
    </Stack>
  );
};
