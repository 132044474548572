import { ExtractNode, getEdgeNodes, getEdges } from "@workflows/runtime-web";
import {
  Badge,
  Icon,
  Link,
  NonIdealState,
  PaperClipIcon,
  Stack,
  StackItem,
  Status,
  TableView,
  TemplatesEmptyIllustration,
  Text,
} from "@workflows/ui";
import { format, parseISO } from "date-fns";
import de from "date-fns/locale/de";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import {
  DocumentsList_documents,
  DocumentsList_documents$key,
} from "~/__graphql__/DocumentsList_documents.graphql";
import { Status_status$key } from "~/__graphql__/Status_status.graphql";
import { routes } from "../routes";

export interface DocumentsListProps {
  documents: DocumentsList_documents$key;
  onSelect: (select: string[]) => void;
  isPending?: boolean;
  selection: string[];
}

export type DocumentType = ExtractNode<
  NonNullable<DocumentsList_documents["documents"]>
>;

export interface ExternalDocumentPropType {
  type: string;
  external: {
    url: string;
  };
}

export function DocumentsList({
  documents,
  isPending,
  selection,
  onSelect,
}: DocumentsListProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const { data } = usePaginationFragment($DocumentsList_documents, documents);

  const rows = React.useMemo(() => getEdgeNodes(data.documents), [data]);

  const columns = React.useMemo(
    () => [
      {
        id: "title",
        header: t("DocumentsList.columns.title"),
        width: "60%",
        openable: true,
        render: ({ value: document }: { value: DocumentType }) => {
          return (
            <Stack direction="horizontal" justify="between" fill>
              <Stack direction="vertical" gap="xxs">
                <StackItem>
                  <Link
                    to={routes["wizard.index"].build({
                      documentId: document.id,
                    })}
                  >
                    {document.title}
                  </Link>
                </StackItem>
                {document.template && document.template.title && (
                  <StackItem>
                    <Text color="muted">{document.template.title}</Text>
                  </StackItem>
                )}
              </Stack>
              <Stack direction="horizontal">
                {document.color && (
                  <StackItem align="center">
                    <Badge circle color={document.color} />
                  </StackItem>
                )}
                {isExternalDocument(document) && (
                  <Icon identifier="ai.workflows.icons:Link" />
                )}
              </Stack>
            </Stack>
          );
        },
      },
      {
        id: "insertedAt",
        header: t("DocumentsList.columns.insertedAt"),
        accessor: "insertedAt",
        width: "15%",
        render: ({
          value: insertedAt,
        }: {
          value: DocumentType["insertedAt"];
        }) => {
          return insertedAt
            ? format(parseISO(insertedAt), "Pp", {
                locale: de,
              })
            : null;
        },
      },
      {
        id: "attachments",
        header: "",
        accessor: "files",
        width: "5%",
        render: ({ value: files }: { value: DocumentType["files"] }) =>
          getEdges(files).length > 0 ? <PaperClipIcon /> : null,
      },
      {
        id: "status",
        header: t("DocumentsList.columns.status"),
        width: "10%",
        render: ({
          row: document,
          value: fragmentRef,
        }: {
          row: DocumentType;
          value: Status_status$key;
        }) => {
          if (isExternalDocument(document)) {
            return null;
          }

          return fragmentRef ? <Status status={fragmentRef} /> : null;
        },
      },
    ],
    []
  );

  return (
    <>
      {rows !== null && rows.length ? (
        <TableView
          columns={columns}
          data={rows as Record<string, unknown>[]}
          isPending={isPending}
          onSelect={onSelect}
          selection={selection}
          usePointer
        />
      ) : (
        <NonIdealState
          header={<TemplatesEmptyIllustration />}
          title={t("DocumentsList.NonIdealState.title")}
          description={t("DocumentsList.NonIdealState.description")}
        />
      )}
    </>
  );
}

export const isExternalDocument = (document: DocumentType): boolean => {
  const props = document.props as ExternalDocumentPropType;
  return props?.type === "ai.workflows.documents.externalDocument";
};

// TODO: `count` is fixed
export const $DocumentsList_documents = graphql`
  fragment DocumentsList_documents on RootQueryType
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 9999 }
    tenantId: { type: "ID!" }
  )
  @refetchable(queryName: "DocumentsListPaginationQuery") {
    documents: nodes(
      tenantId: $tenantId
      types: ["ai.workflows.documents.document"]
      after: $cursor
      first: $count
    ) @connection(key: "DocumentsList_documents") {
      edges {
        cursor
        node {
          id
          ... on HasFiles {
            files {
              edges {
                cursor
              }
            }
          }
          ... on Document {
            title
            insertedAt
            color
            props
            template {
              id
              title
            }
            ...Status_status
          }
        }
      }
    }
  }
`;
