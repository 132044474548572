import {
  graphql,
  useFragment,
  useRecoilValue,
  getEdgeNodes,
} from "@workflows/runtime-web";
import { Columns, ViewerTenantContext } from "@workflows/ui";
import * as React from "react";
import { CoreSidebar } from "~/core/CoreSidebar";
import { App_tenant$key } from "~/__graphql__/App_tenant.graphql";
import { App_viewer$key } from "~/__graphql__/App_viewer.graphql";
import { CoreNav_nav$key } from "~/__graphql__/CoreNav_nav.graphql";
import { ErrorBoundary } from "../ErrorBoundary";
import { appState } from "./state";

export interface AppProps {
  tenant: App_tenant$key | null;
  viewer: App_viewer$key | null;
  nav: CoreNav_nav$key | null;
  children?: React.ReactNode;
}

export function App({ children, ...props }: AppProps): JSX.Element {
  const viewer = useFragment(ViewerFragment, props.viewer);
  const tenant = useFragment(TenantFragment, props.tenant);
  const app = useRecoilValue(appState);

  return (
    <ViewerTenantContext
      tenantId={tenant?.id}
      userId={viewer?.user?.id}
      user={viewer?.user}
      roles={(tenant && getEdgeNodes(tenant.roles)) || []}
      permissions={tenant?.viewerPermissions || []}
      configValues={tenant?.config || []}
      parentId={tenant?.parentId ?? undefined}
    >
      <Columns>
        {viewer?.user && tenant && app.showSidebar && props.nav && (
          <CoreSidebar nav={props.nav} />
        )}
        <ErrorBoundary>{children}</ErrorBoundary>
      </Columns>
    </ViewerTenantContext>
  );
}

const ViewerFragment = graphql`
  fragment App_viewer on Viewer
  @argumentDefinitions(tenantId: { type: "ID!" }) {
    user(tenantId: $tenantId) {
      id
      name
      email
      firstName
      lastName
      displayName
    }
  }
`;

const TenantFragment = graphql`
  fragment App_tenant on Tenant
  @argumentDefinitions(configValueKeys: { type: "[String]!" }) {
    id
    name
    parentId
    roles {
      edges {
        node {
          id
          name
        }
      }
    }
    viewerPermissions
    config(keys: $configValueKeys) {
      key
      value
    }
  }
`;
