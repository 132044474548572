import { graphql } from "react-relay/hooks";
import { getEdgeNodes, useLazyLoadQuery } from "@workflows/runtime-web";
import { useDocumentTemplatesQuery } from "~/__graphql__/useDocumentTemplatesQuery.graphql";
import { useTenantId } from "@workflows/ui";

export const useDocumentTemplates = (subtenantId?: string) => {
  const tenantId = useTenantId();
  const { documentTemplates } = useLazyLoadQuery<useDocumentTemplatesQuery>(
    documentTemplatesQuery,
    {
      tenantId: subtenantId || tenantId,
    }
  );
  return getEdgeNodes(documentTemplates || null);
};

const documentTemplatesQuery = graphql`
  query useDocumentTemplatesQuery($tenantId: ID!) {
    documentTemplates(tenantId: $tenantId, first: 100)
      @connection(key: "useDocumentTemplates_documentTemplates") {
      edges {
        node {
          id
          title
          status {
            id
            name
            color
            textColor
          }
        }
      }
    }
  }
`;
