import {
  ExtractNode,
  getEdgeNodes,
  Link,
  useHistory,
  useParams,
} from "@workflows/runtime-web";
import { Stack, TableView, TableViewColumn } from "@workflows/ui";
import * as React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import {
  UsersTableView_users,
  UsersTableView_users$key,
} from "~/__graphql__/UsersTableView_users.graphql";
import { routes } from "../routes";
import { UsersTableViewEmptyState } from "./UsersTableViewEmptyState";

export interface UsersTableViewProps {
  isPending?: boolean;
  queryFragment: UsersTableView_users$key;
}
interface UsersTableViewRow {
  value: ExtractNode<NonNullable<UsersTableView_users["users"]>>;
}

export function UsersTableView({
  isPending = false,
  queryFragment,
}: UsersTableViewProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.admin");
  const history = useHistory();

  const { data } = usePaginationFragment(usersTableViewFragment, queryFragment);

  const users = React.useMemo(
    () => (data ? getEdgeNodes(data.users) : []),
    [data]
  );

  const cols = useColumns(t);

  const handleSelect = React.useCallback(
    (ids: string[]) => {
      const userId = ids[0];
      const user = users.find((t) => t.id === userId);

      if (user) {
        const url = routes["users.edit"].build({ userId });
        history.push(url);
      }
    },
    [history, users]
  );

  return (
    <>
      {users?.length ? (
        <TableView
          onSelect={handleSelect}
          isPending={isPending}
          columns={cols}
          data={users}
        />
      ) : (
        <UsersTableViewEmptyState />
      )}
    </>
  );
}

export function useColumns(
  t: TFunction<"ai.workflows.admin">
): TableViewColumn[] {
  return React.useMemo(
    () => [
      {
        id: "name",
        header: t("UsersTableView.cols.name"),
        width: "40%",
        render: ({ value }: UsersTableViewRow) => {
          return (
            <Stack direction="vertical" gap="xxs">
              <Link to={routes["users.edit"].build({ userId: value.id })}>
                {value.name || "–"}
              </Link>
            </Stack>
          );
        },
      },
      {
        id: "email",
        header: t("UsersTableView.cols.email"),
        accessor: "email",
        width: "40%",
      },
      {
        id: "insertedAt",
        header: t("UsersTableView.cols.insertedAt"),
        width: "20%",
        render: ({ value }: UsersTableViewRow) =>
          t("UsersTableView.date", { value: value.insertedAt }),
      },
    ],
    [t]
  );
}

// TODO: `count` is fixed
export const usersTableViewFragment = graphql`
  fragment UsersTableView_users on RootQueryType
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
    tenantId: { type: "ID!" }
  )
  @refetchable(queryName: "UsersTableViewPaginationQuery") {
    users(tenantId: $tenantId, after: $cursor, first: $count)
      @connection(key: "UsersTableView_users") {
      edges {
        cursor
        node {
          id
          name
          email
          insertedAt
        }
      }
    }
  }
`;
