import * as React from "react";
import { useTranslation } from "react-i18next";
import { Dialog, useForm, useToast } from "@workflows/ui";
import { newId } from "@workflows/runtime-web";
import { FeedbackForm } from "./FeedbackForm";
import { GenericDialogSkeleton } from "~/core/Dialogs/GenericDialogSkeleton";

interface SupportDialogProps {
  onClose: () => void;
}

export const SupportDialog: React.FC<SupportDialogProps> = ({ onClose }) => {
  const [addToast] = useToast();
  const feedbackForm = useForm("FeedbackForm");
  const { t } = useTranslation("ai.workflows.core");

  const handleSuccess = () => {
    addToast({
      id: newId(),
      message: t("SupportDialog.messages.success"),
      intent: "success",
    });

    feedbackForm.reset();
    onClose();
  };

  const handleError = () => {
    addToast({
      id: newId(),
      message: t("SupportDialog.messages.error"),
      intent: "critical",
    });

    onClose();
  };

  return (
    <>
      <Dialog
        size="md"
        title={t("SupportDialog.titles.title")}
        onClose={onClose}
        isOpen
      >
        <React.Suspense fallback={<GenericDialogSkeleton />}>
          <FeedbackForm
            onCancel={onClose}
            onError={handleError}
            onSuccess={handleSuccess}
          />
        </React.Suspense>
      </Dialog>
    </>
  );
};
