import {
  getEdgeNodes,
  graphql,
  newId,
  useFragment,
  useMutation,
  usePaginationFragment,
} from "@workflows/runtime-web";
import {
  Callout,
  Section,
  SectionBody,
  ThreadMessageEditor,
  ThreadMessageEditorProps,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Error404 } from "~/core/Error404";
import { MatterRouteQueryResponse } from "~/__graphql__/MatterRouteQuery.graphql";
import { MatterTimelineAddThreadMessageMutation as MessageMutation } from "~/__graphql__/MatterTimelineAddThreadMessageMutation.graphql";
import { MatterTimelinePaginationQuery } from "~/__graphql__/MatterTimelinePaginationQuery.graphql";
import {
  MatterTimeline_threads,
  MatterTimeline_threads$key,
} from "~/__graphql__/MatterTimeline_threads.graphql";
import { MatterTimeline_timeline$key } from "~/__graphql__/MatterTimeline_timeline.graphql";
import { MatterTimelineItem } from "./MatterTimelineItem";

export interface MatterTimelineProps {
  fragmentRef: MatterRouteQueryResponse;
  timelineFragmentRef: MatterRouteQueryResponse["matter"];
  refresh: () => void;
}

export function MatterTimeline(props: MatterTimelineProps): JSX.Element {
  const { fragmentRef, timelineFragmentRef, refresh } = props;
  const [error, setError] = React.useState("");
  const tenantId = useTenantId();
  const { t } = useTranslation("ai.workflows.matters");
  const threadsData = useFragment<MatterTimeline_threads$key>(
    MatterTimeline_threads,
    fragmentRef
  );
  const { data } = usePaginationFragment<
    MatterTimelinePaginationQuery,
    MatterTimeline_timeline$key
  >(MatterTimeline_timeline, timelineFragmentRef);
  const [addThreadMessage] = useMutation<MessageMutation>(
    AddThreadMessageMutation
  );

  const threads = getEdgeNodes(threadsData.threads);
  const timelineEdges = data?.timeline;
  const timelineItems = getEdgeNodes(timelineEdges || null);

  if (threads.length !== 1) {
    console.warn("Invalid thread count for this matter.");
    return <Error404 />;
  }

  const onAddThreadMessage = (data: ThreadMessageEditorProps) => {
    const { bodyText } = data;
    if (threads.length === 0 || bodyText === undefined || bodyText === "") {
      return;
    }

    addThreadMessage({
      variables: {
        input: {
          body: bodyText,
          clientMutationId: newId(),
          tenantId,
          threadId: threads[0]?.id,
        },
      },
      onError(error) {
        console.error(error);
        setError(t("MatterTimeline.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.addThreadMessage?.errors) {
          const error = "MatterTimeline.errors.unspecified";
          setError(t(error));
        }
      },
    });
    refresh();
  };

  return (
    <Section title={t("MatterTimeline.activities")}>
      <SectionBody>
        {error && <Callout intent="critical">{error}</Callout>}

        {timelineItems.map((timelineItem) => (
          <MatterTimelineItem
            timelineItem={timelineItem as any}
            key={timelineItem.id}
          />
        ))}

        <ThreadMessageEditor
          onEdit={(message) => onAddThreadMessage(message)}
          bodyText=""
        />
      </SectionBody>
    </Section>
  );
}

const MatterTimeline_threads = graphql`
  fragment MatterTimeline_threads on RootQueryType
  @argumentDefinitions(tenantId: { type: "ID!" }, matterId: { type: "ID!" }) {
    threads(refId: $matterId, tenantId: $tenantId) {
      edges {
        node {
          id
          messages {
            edges {
              node {
                creator {
                  name
                }
                id
                bodyText
                insertedAt
              }
            }
          }
        }
      }
    }
  }
`;

const MatterTimeline_timeline = graphql`
  fragment MatterTimeline_timeline on HasTimeline
  @argumentDefinitions(
    cursor: { type: "String", defaultValue: null }
    count: { type: "Int", defaultValue: 99 }
  )
  @refetchable(queryName: "MatterTimelinePaginationQuery") {
    timeline(after: $cursor, first: $count)
      @connection(key: "MatterTimeline_timeline") {
      edges {
        node {
          ... on ThreadMessage {
            creator {
              name
            }
            id
            bodyText
            insertedAt
          }
          ... on Activity {
            id
            identifier
            insertedAt
            actor {
              name
            }
            toObject {
              id
              __typename
              ... on User {
                name
              }
              ... on Status {
                color
                textColor
                name
              }
              ... on Tag {
                color
                textColor
                name
              }
            }
          }
        }
      }
    }
  }
`;

const AddThreadMessageMutation = graphql`
  mutation MatterTimelineAddThreadMessageMutation(
    $input: AddThreadMessageInput!
  ) {
    addThreadMessage(input: $input) {
      errors {
        code
        message
        type
      }
      message {
        id
        bodyText
      }
    }
  }
`;
