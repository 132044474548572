/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SearchResultsList_search = {
    readonly search: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"SearchResultsListItem_item">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "SearchResultsList_search";
};
export type SearchResultsList_search$data = SearchResultsList_search;
export type SearchResultsList_search$key = {
    readonly " $data"?: SearchResultsList_search$data;
    readonly " $fragmentRefs": FragmentRefs<"SearchResultsList_search">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "search"
    ];
    return {
        "argumentDefinitions": [
            {
                "kind": "RootArgument",
                "name": "after"
            },
            {
                "kind": "RootArgument",
                "name": "first"
            },
            {
                "kind": "RootArgument",
                "name": "queryText"
            },
            {
                "kind": "RootArgument",
                "name": "tenantId"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "first",
                    "cursor": "after",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "first",
                        "cursor": "after"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": require('./SearchResultsListQuery.graphql.ts')
            }
        },
        "name": "SearchResultsList_search",
        "selections": [
            {
                "alias": "search",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "queryText",
                        "variableName": "queryText"
                    },
                    {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "tenantId"
                    }
                ],
                "concreteType": "SearchResultConnection",
                "kind": "LinkedField",
                "name": "__SearchResultsList_search_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "SearchResultEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "SearchResult",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "SearchResultsListItem_item"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "RootQueryType",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '3cb2bf3e3d9ec5176079a0022d319614';
export default node;
