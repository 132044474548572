/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AccountLayoutQueryVariables = {
    tenantId: string;
};
export type AccountLayoutQueryResponse = {
    readonly tenant: {
        readonly id: string;
        readonly nav?: {
            readonly items: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly name: string;
                        readonly externalUrl: string | null;
                        readonly route: {
                            readonly path: string;
                        } | null;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
    } | null;
};
export type AccountLayoutQuery = {
    readonly response: AccountLayoutQueryResponse;
    readonly variables: AccountLayoutQueryVariables;
};



/*
query AccountLayoutQuery(
  $tenantId: ID!
) {
  tenant: node(id: $tenantId) {
    __typename
    id
    ... on Tenant {
      nav(identifier: "ai.workflows.nav.footer") {
        items {
          edges {
            node {
              id
              name
              externalUrl
              route {
                path
                id
              }
            }
          }
        }
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "tenantId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = [
        {
            "kind": "Literal",
            "name": "identifier",
            "value": "ai.workflows.nav.footer"
        } as any
    ], v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "externalUrl",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "path",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AccountLayoutQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "Nav",
                                    "kind": "LinkedField",
                                    "name": "nav",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "NavItemConnection",
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "NavItemEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "NavItem",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Route",
                                                                    "kind": "LinkedField",
                                                                    "name": "route",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "nav(identifier:\"ai.workflows.nav.footer\")"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AccountLayoutQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v3 /*: any*/),
                                    "concreteType": "Nav",
                                    "kind": "LinkedField",
                                    "name": "nav",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "NavItemConnection",
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "NavItemEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "NavItem",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v4 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Route",
                                                                    "kind": "LinkedField",
                                                                    "name": "route",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v6 /*: any*/),
                                                                        (v2 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": "nav(identifier:\"ai.workflows.nav.footer\")"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "15ad42b6116ec44d26dfc212fb4dee63",
            "id": null,
            "metadata": {},
            "name": "AccountLayoutQuery",
            "operationKind": "query",
            "text": "query AccountLayoutQuery(\n  $tenantId: ID!\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    id\n    ... on Tenant {\n      nav(identifier: \"ai.workflows.nav.footer\") {\n        items {\n          edges {\n            node {\n              id\n              name\n              externalUrl\n              route {\n                path\n                id\n              }\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'c53982b0a7dde5f3d92bcf400b378d77';
export default node;
