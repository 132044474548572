/* eslint-disable @typescript-eslint/no-explicit-any */
import { Route, Switch, SwitchProps } from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "relay-runtime";
import { AccountAcceptInviteRoute } from "~/accounts/AccountAcceptInviteRoute";
import { AccountConfirmEmailRoute } from "~/accounts/AccountConfirmEmailRoute";
import { AccountRecoverPasswordRoute } from "~/accounts/AccountRecoverPasswordRoute";
import { AccountSetNewPasswordRoute } from "~/accounts/AccountSetNewPasswordRoute";
import { AccountSignUpRoute } from "~/accounts/AccountSignUpRoute";
import { ActionRoute } from "~/actions/ActionRoute";
import { AdminRoute } from "~/admin/AdminRoute";
import { AccountTokenChecker } from "~/core/AccountTokenChecker";
import { App } from "~/core/App";
import {
  ConfigValueGateway,
  globalConfigValues,
  useConfigValue,
} from "~/core/configValues";
import { Error404 } from "~/core/Error404";
import { DashboardRoute } from "~/dashboard/DashboardRoute";
import { DeAgadDpmRoute } from "~/de.agad.dpm/DeAgadDpmRoute";
import { router as deSmartconexVertragsgeneratorRouter } from "~/de.smartconex.vertragsgenerator/routes";
import { router as flowsRouter } from "~/flows/routes";
import { router as matterRouter } from "~/matters/routes";
import { ChooseTenantRoute } from "~/session/choose-tenant/ChooseTenantRoute";
import { PasswordlessSignInRoute } from "~/session/passwordlessSignIn/PasswordlessSignInRoute";
import { SignInRoute } from "~/session/signIn/SignInRoute";
import { CoreBootloaderQuery } from "~/__graphql__/CoreBootloaderQuery.graphql";
import { CoreDebugRoute } from "./CoreDebugRoute";
import { CoreResetRoute } from "./CoreResetRoute";
import { flattenChildren } from "./utils";

export function CoreBootloader(): JSX.Element {
  // NOTE: The hook suspends the rendering until the translation is
  // loaded. Do not remove it.
  useTranslation();

  const tenantId = useTenantId();
  const data = useLazyLoadQuery<CoreBootloaderQuery>(query, {
    tenantId,
    configValueKeys: globalConfigValues,
  });

  return (
    <App viewer={data.viewer} tenant={data.tenant} nav={data.tenant}>
      {data.viewer ? (
        <>
          <FragmentSupportingSwitch>
            <Route path="/-/debug" component={CoreDebugRoute} />
            <Route path="/-/reset" component={CoreResetRoute} />
            <Route path="/sign-in" component={SignInRoute} />
            {/* TODO: Load routes from server and remove `currentApp` workaround */}
            {getAppRouter()}

            <Route path="/go/:actionId" component={ActionRoute} />
            {matterRouter}
            {flowsRouter}
            <Route path="/dashboard" component={DashboardRoute} />
            <Route path="/admin" component={AdminRoute} />
            <Route path="/choose-tenant" component={ChooseTenantRoute} />
            <Route component={Error404} />
          </FragmentSupportingSwitch>
          <AccountTokenChecker />
        </>
      ) : (
        <UnauthenticatedRoutes />
      )}
    </App>
  );
}

function getAppRouter(): JSX.Element | null {
  {
    switch (window.__workflows.app) {
      case "de.smartconex.vertragsgenerator":
        return deSmartconexVertragsgeneratorRouter;
      case "de.agad.dpm":
        return <DeAgadDpmRoute />;
      default:
        return null;
    }
  }
}

export const query = graphql`
  query CoreBootloaderQuery($tenantId: ID!, $configValueKeys: [String]) {
    viewer {
      id
      ...App_viewer @arguments(tenantId: $tenantId)
    }
    tenant: node(id: $tenantId) {
      id
      ...App_tenant @arguments(configValueKeys: $configValueKeys)
      ...CoreNav_nav
    }
  }
`;

function UnauthenticatedRoutes() {
  const gatewayConfigValue = useConfigValue<ConfigValueGateway>(
    "ai.workflows.tenants.config.gateway"
  );

  return (
    <Switch>
      <Route path="/-/debug" component={CoreDebugRoute} />
      <Route path="/-/reset" component={CoreResetRoute} />
      <Route path="/go/:actionId" component={ActionRoute} />
      <Route path="/sign-up" component={AccountSignUpRoute} />
      <Route path="/recover-password" component={AccountRecoverPasswordRoute} />
      <Route path="/reset-password" component={AccountSetNewPasswordRoute} />
      <Route path="/confirm-email" component={AccountConfirmEmailRoute} />
      <Route path="/accept-invite" component={AccountAcceptInviteRoute} />

      {gatewayConfigValue?.enabled ? (
        <PasswordlessSignInRoute />
      ) : (
        <SignInRoute />
      )}
    </Switch>
  );
}

export function FragmentSupportingSwitch(
  props: React.PropsWithChildren<SwitchProps>
): JSX.Element {
  const children: any = [];
  flattenChildren(children, props.children);
  return <Switch {...props} children={children} />;
}
