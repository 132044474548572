import { Stack, Text, FileList } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { getEdgeNodes } from "@workflows/runtime-web";

type Flow = {
  id: string;
  title: string;
  fields: any;
};

export interface DeNetzwerkpartnerHotlineRequestPreviewProps {
  flow: Flow;
}

export function DeNetzwerkpartnerHotlineRequestPreview({
  flow,
}: DeNetzwerkpartnerHotlineRequestPreviewProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");
  const files = getEdgeNodes(flow.fields.attachments || null);
  return (
    <Stack direction="vertical" fill gap="xs">
      <Stack direction="vertical" fill gap="xs">
        <Text variant="headingSm">
          {t("DeNetzwerkpartnerHotlineRequestPreview.messages")}
        </Text>
        <Text>{flow?.fields?.descriptionText}</Text>
      </Stack>
      <Stack direction="vertical" fill gap="xs">
        <Text variant="headingSm">
          {t("DeNetzwerkpartnerHotlineRequestPreview.attachments")}
        </Text>
        {files.length ? (
          <FileList files={files} />
        ) : (
          <>{t("DeNetzwerkpartnerHotlineRequestPreview.emptyState")}</>
        )}
      </Stack>
    </Stack>
  );
}
