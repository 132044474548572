/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UsersColumnQueryVariables = {
    tenantId: string;
};
export type UsersColumnQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"UsersTableView_users">;
};
export type UsersColumnQuery = {
    readonly response: UsersColumnQueryResponse;
    readonly variables: UsersColumnQueryVariables;
};



/*
query UsersColumnQuery(
  $tenantId: ID!
) {
  ...UsersTableView_users_1GYzvY
}

fragment UsersTableView_users_1GYzvY on RootQueryType {
  users(tenantId: $tenantId, first: 100) {
    edges {
      cursor
      node {
        id
        name
        email
        insertedAt
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UsersColumnQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "UsersTableView_users"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UsersColumnQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "email",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "insertedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "UsersTableView_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "cacheID": "49f2b839415a22cd696c8bdfdf72d41e",
            "id": null,
            "metadata": {},
            "name": "UsersColumnQuery",
            "operationKind": "query",
            "text": "query UsersColumnQuery(\n  $tenantId: ID!\n) {\n  ...UsersTableView_users_1GYzvY\n}\n\nfragment UsersTableView_users_1GYzvY on RootQueryType {\n  users(tenantId: $tenantId, first: 100) {\n    edges {\n      cursor\n      node {\n        id\n        name\n        email\n        insertedAt\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0e0a2e8be3aa36f7df0a3dea470bc802';
export default node;
