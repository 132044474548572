import * as React from "react";
import {
  Button,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  Drawer,
  Form2,
  FormControl,
  FormInput,
  Label,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  useForm,
} from "@workflows/ui";
import { routes } from "~/de.agad.dpm/routes";
import { useHistory, useParams } from "@workflows/runtime-web";
import { useDocumentTemplate } from "~/de.agad.dpm/tenants/hooks/useDocumentTemplate";
import { useUpdateDocumentTemplate } from "~/de.agad.dpm/tenants/hooks/useUpdateDocumentTemplate";
import { useTranslation } from "react-i18next";

export const EditDocumentDrawer = (): JSX.Element => {
  const { tenantId: subtenantId, documentId } =
    useParams<{ tenantId: string; documentId: string }>();

  const history = useHistory();

  function handleClose() {
    history.push(routes["tenant.documents"].build({ tenantId: subtenantId }));
  }

  const form = useForm("EditDocumentDrawer");

  const { updateDocumentTemplate, isPending } = useUpdateDocumentTemplate();

  function handleUpdateDocument() {
    const { title, description } = form.getValues();

    const input = {
      description,
      title,
      documentTemplateId: documentId,
    };

    updateDocumentTemplate({
      input,
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.updateDocumentTemplate?.errors) {
          console.error(data.updateDocumentTemplate.errors);
        }
        handleClose();
      },
    });
  }

  const document = useDocumentTemplate(documentId);
  const { t } = useTranslation("de.agad.dpm");

  return (
    <Drawer isOpen width="sm" closeOnOutsideClick onClose={handleClose}>
      <Column>
        <ColumnHeader borderless start={<Label>{document?.title}</Label>} />
        <ColumnBody scrollable inset>
          <Form2 name="EditDocumentDrawer" defaultValues={document!}>
            <FormControl
              label={t("EditDocumentDrawer.fields.title")}
              name="title"
            >
              <FormInput<TextFieldProps> component={TextField} name="title" />
            </FormControl>
            <FormControl
              label={t("EditDocumentDrawer.fields.description")}
              name="description"
            >
              <FormInput<TextAreaProps>
                component={TextArea}
                name="description"
                rows={5}
              />
            </FormControl>
          </Form2>
        </ColumnBody>
        <ColumnFooter>
          <Button
            onClick={handleUpdateDocument}
            intent={"primary"}
            isLoading={isPending}
          >
            {t("EditDocumentDrawer.update")}
          </Button>
        </ColumnFooter>
      </Column>
    </Drawer>
  );
};
