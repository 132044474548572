/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useDocumentTemplatesQueryVariables = {
    tenantId: string;
};
export type useDocumentTemplatesQueryResponse = {
    readonly documentTemplates: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly status: {
                    readonly id: string;
                    readonly name: string;
                    readonly color: string;
                    readonly textColor: string;
                };
            } | null;
        } | null> | null;
    } | null;
};
export type useDocumentTemplatesQuery = {
    readonly response: useDocumentTemplatesQueryResponse;
    readonly variables: useDocumentTemplatesQueryVariables;
};



/*
query useDocumentTemplatesQuery(
  $tenantId: ID!
) {
  documentTemplates(tenantId: $tenantId, first: 100) {
    edges {
      node {
        id
        title
        status {
          id
          name
          color
          textColor
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "DocumentTemplateEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Status",
                            "kind": "LinkedField",
                            "name": "status",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "color",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "textColor",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ], v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useDocumentTemplatesQuery",
            "selections": [
                {
                    "alias": "documentTemplates",
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "concreteType": "DocumentTemplateConnection",
                    "kind": "LinkedField",
                    "name": "__useDocumentTemplates_documentTemplates_connection",
                    "plural": false,
                    "selections": (v3 /*: any*/),
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useDocumentTemplatesQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "concreteType": "DocumentTemplateConnection",
                    "kind": "LinkedField",
                    "name": "documentTemplates",
                    "plural": false,
                    "selections": (v3 /*: any*/),
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v4 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "useDocumentTemplates_documentTemplates",
                    "kind": "LinkedHandle",
                    "name": "documentTemplates"
                }
            ]
        },
        "params": {
            "cacheID": "6ac0f4452ff634b5ce1f61618f092c39",
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "documentTemplates"
                        ]
                    }
                ]
            },
            "name": "useDocumentTemplatesQuery",
            "operationKind": "query",
            "text": "query useDocumentTemplatesQuery(\n  $tenantId: ID!\n) {\n  documentTemplates(tenantId: $tenantId, first: 100) {\n    edges {\n      node {\n        id\n        title\n        status {\n          id\n          name\n          color\n          textColor\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'bef7a54f798d58e59358a6b0c9d5206f';
export default node;
