import { useHistory } from "@workflows/runtime-web";
import {
  CheckCircleIcon,
  ChevronRightIcon,
  DocumentTextIcon,
  LibraryIcon,
  Link,
  RefreshIcon,
  Stack,
  StackItem,
  TemplateIcon,
  Text,
} from "@workflows/ui";
import * as React from "react";
import { graphql, useFragment } from "react-relay/hooks";
import { routes as matterRoutes } from "~/matters/routes";
import { routes as fileRoutes } from "~/files/routes";
import { routes as taskRoutes } from "~/tasks/routes";
import { routes as workflowRoutes } from "~/workflows/routes";
import { SearchResultsListItem_item$key } from "~/__graphql__/SearchResultsListItem_item.graphql";

export interface SearchResultsListItemProps {
  result: SearchResultsListItem_item$key | null;
  isPending?: boolean;
  onOpen?: (item: any, e: React.MouseEvent) => void;
}

export const SearchResultsListItem: React.FC<SearchResultsListItemProps> = ({
  result,
  isPending = false,
  onOpen,
}) => {
  const data = useFragment(SearchResultsListItem_item, result);
  const history = useHistory();
  const typeToRoute: Record<string, string> = {
    "ai.workflows.files.file": fileRoutes["file.index"].build({
      fileId: data?.id || "",
    }),
    "ai.workflows.matters.matter": matterRoutes["matter.index"].build({
      matterId: data?.id || "",
    }),
    "ai.workflows.tasks.task": taskRoutes["task.index"].build({
      taskId: data?.id || "",
    }),
    "ai.workflows.workflows.workflow": workflowRoutes["workflow.index"].build({
      workflowId: data?.id || "",
    }),
  };

  const handleOnClick = (e: React.MouseEvent) => {
    onOpen?.(data, e);
  };

  function openListItem() {
    if (data) {
      history.push(typeToRoute[data.type.identifier]);
    }
  }

  return (
    <Stack
      direction="horizontal"
      className="border--b py-md cursor--pointer"
      align="center"
      onClick={() => openListItem()}
    >
      {data !== null && (
        <>
          <StackItem align="center" className="p-md">
            {data.type.identifier == "ai.workflows.files.file" && (
              <DocumentTextIcon size="lg" />
            )}
            {data.type.identifier == "ai.workflows.wiki.page" && (
              <LibraryIcon size="lg" />
            )}
            {data.type.identifier == "ai.workflows.matters.matter" && (
              <LibraryIcon size="lg" />
            )}
            {data.type.identifier == "ai.workflows.tasks.task" && (
              <CheckCircleIcon size="lg" />
            )}
            {data.type.identifier == "ai.workflows.workflows.workflow" && (
              <TemplateIcon size="lg" />
            )}
          </StackItem>
          <StackItem grow={1}>
            <Link to={`/wiki/${data.id}/${data.id}`} onClick={handleOnClick}>
              <Text type="title2">{data.name}</Text>
            </Link>
            <Text type="bodyMuted" containerType="div">
              {data.previewText}
            </Text>
          </StackItem>
        </>
      )}
      {isPending && <ListItemFallBack />}
      <StackItem align="center" className="p-md">
        <ChevronRightIcon />
      </StackItem>
    </Stack>
  );
};

const ListItemFallBack = () => {
  return (
    <>
      <StackItem align="center" className="p-md">
        <RefreshIcon size="lg" className="skeleton" />
      </StackItem>
      <StackItem grow={1}>
        <Stack gap="xs" direction="vertical">
          <Text type="title2" className="skeleton" containerType="span">
            Loading
          </Text>
          <Text type="bodyMuted" containerType="span" className="skeleton">
            Loading
          </Text>
        </Stack>
      </StackItem>
    </>
  );
};

export const SearchResultsListItem_item = graphql`
  fragment SearchResultsListItem_item on SearchResult {
    id
    name
    body
    description
    previewText
    type {
      identifier
      name
    }
  }
`;
