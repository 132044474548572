import { newId } from "@workflows/runtime-web";
import {
  Callout,
  Stack,
  StackItem,
  Text,
  useDocumentTitle,
  useTenantId,
} from "@workflows/ui";
import { FormValues } from "@workflows/ui/lib/Form/types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useMutation } from "react-relay/hooks";
import { AccountRecoverPasswordRouteMutation } from "~/__graphql__/AccountRecoverPasswordRouteMutation.graphql";
import { AccountLayout } from "./AccountLayout";
import { AccountRecoverPasswordForm } from "./AccountRecoverPasswordForm";

export function AccountRecoverPasswordRoute(): JSX.Element {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [completed, setCompleted] = React.useState(false);

  const { t } = useTranslation("ai.workflows.accounts");
  const tenantId = useTenantId();

  useDocumentTitle(t("AccountRecoverPasswordRoute.title"));

  const [resetUserPassword] = useMutation<AccountRecoverPasswordRouteMutation>(
    AccountRecoverPasswordRouteMutation
  );

  const onSubmitEmail = async (values: FormValues) => {
    try {
      resetUserPassword({
        variables: {
          input: {
            clientMutationId: newId(),
            email: values.email as string,
            tenantId,
          },
        },
        onError(error) {
          console.error(error);
          showErrorMessage("unspecified");
        },
        onCompleted() {
          setCompleted(true);
        },
      });
    } catch (err) {
      console.error(err);
      showErrorMessage("unspecified");
    }
  };

  const showErrorMessage = (code: string) => {
    setError(code);
    setLoading(false);
  };

  return (
    <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{ maxWidth: "30rem" }}>
          <Text type="title1" className="mb-md">
            {t("AccountRecoverPasswordRoute.title")}
          </Text>
          {completed ? (
            <Text className="mb-md">
              {t("AccountRecoverPasswordRoute.message.completed")}
            </Text>
          ) : (
            <>
              {error && (
                <Callout intent="critical" className="mb-md">
                  {t([
                    `AccountRecoverPasswordRoute.errors.${error}`,
                    "AccountRecoverPasswordRoute.errors.unspecified",
                  ])}
                </Callout>
              )}
              <AccountRecoverPasswordForm
                onSubmit={onSubmitEmail}
                loading={loading}
              />
            </>
          )}
        </StackItem>
      </Stack>
    </AccountLayout>
  );
}

const AccountRecoverPasswordRouteMutation = graphql`
  mutation AccountRecoverPasswordRouteMutation(
    $input: ResetUserPasswordInput!
  ) {
    resetUserPassword(input: $input) {
      clientMutationId
    }
  }
`;
