/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type PatchDocumentInput = {
    clientMutationId: string;
    documentId: string;
    patch?: Array<Patch | null> | null;
    tenantId: string;
};
export type Patch = {
    op: string;
    path?: string | null;
    value?: unknown | null;
};
export type usePatchDocumentMutationVariables = {
    input: PatchDocumentInput;
};
export type usePatchDocumentMutationResponse = {
    readonly patchDocument: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly document: {
            readonly props: unknown | null;
        } | null;
    } | null;
};
export type usePatchDocumentMutation = {
    readonly response: usePatchDocumentMutationResponse;
    readonly variables: usePatchDocumentMutationVariables;
};



/*
mutation usePatchDocumentMutation(
  $input: PatchDocumentInput!
) {
  patchDocument(input: $input) {
    errors {
      code
      path
      message
      type
    }
    document {
      props
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "props",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "usePatchDocumentMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PatchDocumentPayload",
                    "kind": "LinkedField",
                    "name": "patchDocument",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "document",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/)
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "usePatchDocumentMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PatchDocumentPayload",
                    "kind": "LinkedField",
                    "name": "patchDocument",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "document",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a43e9fc4585af30e34dd4c85eb03522e",
            "id": null,
            "metadata": {},
            "name": "usePatchDocumentMutation",
            "operationKind": "mutation",
            "text": "mutation usePatchDocumentMutation(\n  $input: PatchDocumentInput!\n) {\n  patchDocument(input: $input) {\n    errors {\n      code\n      path\n      message\n      type\n    }\n    document {\n      props\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1de8b369ebc9cc23f5932ec83c020cd0';
export default node;
