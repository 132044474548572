/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionItemChooserListFileItem_item = {
    readonly id: string;
    readonly name: string;
    readonly description: string | null;
    readonly downloadUrl: string | null;
    readonly " $refType": "CollectionItemChooserListFileItem_item";
};
export type CollectionItemChooserListFileItem_item$data = CollectionItemChooserListFileItem_item;
export type CollectionItemChooserListFileItem_item$key = {
    readonly " $data"?: CollectionItemChooserListFileItem_item$data;
    readonly " $fragmentRefs": FragmentRefs<"CollectionItemChooserListFileItem_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionItemChooserListFileItem_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "description",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "downloadUrl",
            "storageKey": null
        }
    ],
    "type": "File",
    "abstractKey": null
} as any;
(node as any).hash = 'b4866b7112032c9607afcfde9a67bee1';
export default node;
