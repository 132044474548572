/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type App_viewer = {
    readonly user: {
        readonly id: string;
        readonly name: string;
        readonly email: string;
        readonly firstName: string | null;
        readonly lastName: string | null;
        readonly displayName: string;
    } | null;
    readonly " $refType": "App_viewer";
};
export type App_viewer$data = App_viewer;
export type App_viewer$key = {
    readonly " $data"?: App_viewer$data;
    readonly " $fragmentRefs": FragmentRefs<"App_viewer">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        }
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "App_viewer",
    "selections": [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "tenantId",
                    "variableName": "tenantId"
                }
            ],
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "email",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "firstName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "lastName",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "displayName",
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "Viewer",
    "abstractKey": null
} as any;
(node as any).hash = 'baf14719dc9a4954d281036902aacf97';
export default node;
