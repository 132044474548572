import {
  Button,
  Column,
  ColumnBody,
  ColumnHeader,
  DownloadIcon,
  Grid,
  NonIdealState,
  Section,
  SectionBody,
  TemplatesEmptyIllustration,
  useDocumentTitle,
} from "@workflows/ui";
import { format } from "date-fns";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UseDashboardData, useDashboardData } from "./hooks/useDashboardData";
import { ProcessingActivitiesDashboardItem } from "./ProcessingActivitiesDashboardItem";

const highlightDocuments = ["#EA580C"];

export function ProcessingActivitiesDashboardColumn(): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");
  const [isDownloading, setIsDownloading] = useState(false);
  const { documents, statuses } = useDashboardData();

  const [highlightedDocuments, otherDocuments] = React.useMemo(() => {
    const list1: UseDashboardData["documents"] = [];
    const list2: UseDashboardData["documents"] = [];

    documents.forEach((document) => {
      if (document.color && highlightDocuments.includes(document.color)) {
        list1.push(document);
      } else {
        list2.push(document);
      }
    });

    return [list1, list2];
  }, [documents]);

  useDocumentTitle(t("ProcessingActivitiesDashboardColumn.title"));

  const handleDownload = async () => {
    const documentIds: string[] = [];
    const downloadURL = "/endpoints/01FTXEK63F7R6DXE09J0FMB3M8";

    if (documents) {
      setIsDownloading(true);
      documents.forEach((document) => documentIds.push(document.id));

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(documentIds),
      };

      fetch(downloadURL, requestOptions)
        .then((res) => {
          return res.blob();
        })
        .then((data) => {
          const a = document.createElement("a");
          a.href = window.URL.createObjectURL(data);
          a.download = `VVT ${format(new Date(), "yyyy-MM-dd")}.zip`;
          a.click();
          setIsDownloading(false);
        });
    }
  };

  return (
    <Column role="main" background="default">
      <ColumnHeader
        title={t("ProcessingActivitiesDashboardColumn.title")}
        end={
          <Button
            disabled={documents?.length === 0}
            icon={<DownloadIcon />}
            isLoading={isDownloading}
            onClick={handleDownload}
          >
            {t("ProcessingActivitiesDashboardColumn.export")}
          </Button>
        }
      />
      <ColumnBody>
        {documents?.length > 0 ? (
          <>
            <Section>
              <SectionBody>
                <Grid cols={{ xs: 1, md: 2, lg: 2, "2xl": 3, "3xl": 4 }}>
                  {highlightedDocuments.map((document) => (
                    <ProcessingActivitiesDashboardItem
                      document={document}
                      statuses={statuses}
                      key={document.id}
                    />
                  ))}
                </Grid>
              </SectionBody>
            </Section>
            <Section>
              <SectionBody>
                <Grid cols={{ xs: 1, md: 2, lg: 2, "2xl": 3, "3xl": 4 }}>
                  {otherDocuments.map((document) => (
                    <ProcessingActivitiesDashboardItem
                      document={document}
                      statuses={statuses}
                      key={document.id}
                    />
                  ))}
                </Grid>
              </SectionBody>
            </Section>
          </>
        ) : (
          <NonIdealState
            header={<TemplatesEmptyIllustration />}
            title={t("ProcessingActivitiesDashboardColumn.NonIdealState.title")}
            description={t(
              "ProcessingActivitiesDashboardColumn.NonIdealState.description"
            )}
          />
        )}
      </ColumnBody>
    </Column>
  );
}
