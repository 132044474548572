import { ListAddIllustration, NonIdealState, Stack } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";

export function UsersTableViewEmptyState(): JSX.Element {
  const { t } = useTranslation("ai.workflows.admin");

  return (
    <Stack direction="vertical" fill>
      <NonIdealState
        header={<ListAddIllustration />}
        title={t("UsersTableView.emptyState.title")}
        description={t("UsersTableView.emptyState.description")}
      />
    </Stack>
  );
}
