/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DeAgadDpmCreateDocumentsInput = {
    clientMutationId: string;
    documents?: Array<DeAgadDpmDocumentInput> | null;
    subtenantId: string;
    tenantId: string;
};
export type DeAgadDpmDocumentInput = {
    count: number;
    documentTemplateId: string;
};
export type useDeAgadDpmCreateDocumentsMutationVariables = {
    input: DeAgadDpmCreateDocumentsInput;
};
export type useDeAgadDpmCreateDocumentsMutationResponse = {
    readonly deAgadDpmCreateDocuments: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly replicaDocuments: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly title: string;
                    readonly status: {
                        readonly id: string;
                        readonly name: string;
                        readonly color: string;
                        readonly textColor: string;
                    };
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type useDeAgadDpmCreateDocumentsMutation = {
    readonly response: useDeAgadDpmCreateDocumentsMutationResponse;
    readonly variables: useDeAgadDpmCreateDocumentsMutationVariables;
};



/*
mutation useDeAgadDpmCreateDocumentsMutation(
  $input: DeAgadDpmCreateDocumentsInput!
) {
  deAgadDpmCreateDocuments(input: $input) {
    errors {
      code
      path
      message
      type
    }
    replicaDocuments {
      edges {
        node {
          id
          title
          status {
            id
            name
            color
            textColor
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "DeAgadDpmCreateDocumentsPayload",
            "kind": "LinkedField",
            "name": "deAgadDpmCreateDocuments",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentTemplateConnection",
                    "kind": "LinkedField",
                    "name": "replicaDocuments",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentTemplateEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "DocumentTemplate",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v1 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Status",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "color",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "textColor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useDeAgadDpmCreateDocumentsMutation",
            "selections": (v2 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useDeAgadDpmCreateDocumentsMutation",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "805b18fd564371bf8d437503468e5623",
            "id": null,
            "metadata": {},
            "name": "useDeAgadDpmCreateDocumentsMutation",
            "operationKind": "mutation",
            "text": "mutation useDeAgadDpmCreateDocumentsMutation(\n  $input: DeAgadDpmCreateDocumentsInput!\n) {\n  deAgadDpmCreateDocuments(input: $input) {\n    errors {\n      code\n      path\n      message\n      type\n    }\n    replicaDocuments {\n      edges {\n        node {\n          id\n          title\n          status {\n            id\n            name\n            color\n            textColor\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a4cd8c97846966b65b811c2d3563ea97';
export default node;
