import {
  DeAgadDpmDocumentInput,
  useDeAgadDpmCreateDocumentsMutation,
  useDeAgadDpmCreateDocumentsMutationResponse,
} from "~/__graphql__/useDeAgadDpmCreateDocumentsMutation.graphql";
import { newId, useMutation } from "@workflows/runtime-web";
import { graphql } from "react-relay/hooks";
import { useTenantId } from "@workflows/ui";
import { ConnectionHandler } from "relay-runtime";

interface useDeAgadDpmCreateDocumentsProps {
  onError: (error: Error) => void;
  onCompleted: (data: useDeAgadDpmCreateDocumentsMutationResponse) => void;
  input: {
    subtenantId: string;
    documents: DeAgadDpmDocumentInput[];
  };
}

interface useDeAgadDpmCreateDocumentsValue {
  deAgadDpmCreateDocuments: (props: useDeAgadDpmCreateDocumentsProps) => void;
  isPending: boolean;
}

export const useDeAgadDpmCreateDocuments =
  (): useDeAgadDpmCreateDocumentsValue => {
    const tenantId = useTenantId();
    const [mutation, isPending] =
      useMutation<useDeAgadDpmCreateDocumentsMutation>(
        deAgadDpmCreateDocumentsMutation
      );

    const deAgadDpmCreateDocuments = (
      props: useDeAgadDpmCreateDocumentsProps
    ) => {
      const {
        onError,
        onCompleted,
        input: { subtenantId, documents },
      } = props;

      mutation({
        variables: {
          input: {
            clientMutationId: newId(),
            documents,
            subtenantId,
            tenantId,
          },
        },
        updater: (store) => {
          const connectionRecord = ConnectionHandler.getConnection(
            store.getRoot(),
            "useDocumentTemplates_documentTemplates",
            { tenantId: subtenantId }
          );

          if (!connectionRecord) {
            return;
          }

          const payload = store.getRootField("deAgadDpmCreateDocuments");

          const replicaDocuments = payload?.getLinkedRecord("replicaDocuments");
          const replicaDocumentEdges =
            replicaDocuments?.getLinkedRecords("edges");

          replicaDocumentEdges?.forEach((replicaDocumentEdge) => {
            const replicaDocument = replicaDocumentEdge.getLinkedRecord("node");
            const newEdge = ConnectionHandler.createEdge(
              store,
              connectionRecord,
              // @ts-expect-error: "replicaDocument" is a RecordProxy that can contain a "replicaDocument" or "null"
              replicaDocument,
              "DocumentTemplateEdge"
            );

            if (newEdge) {
              ConnectionHandler.insertEdgeAfter(connectionRecord, newEdge);
            }
          });
        },
        onError,
        onCompleted,
      });
    };

    return { deAgadDpmCreateDocuments, isPending };
  };

const deAgadDpmCreateDocumentsMutation = graphql`
  mutation useDeAgadDpmCreateDocumentsMutation(
    $input: DeAgadDpmCreateDocumentsInput!
  ) {
    deAgadDpmCreateDocuments(input: $input) {
      errors {
        code
        path
        message
        type
      }
      replicaDocuments {
        edges {
          node {
            id
            title
            status {
              id
              name
              color
              textColor
            }
          }
        }
      }
    }
  }
`;
