import { compileRoutes } from "@workflows/runtime-web";
import * as React from "react";
import { CoreEntryPointRoute } from "~/core/CoreEntryPointRoute";
import {
  createStaticResource,
  EntryPointPreloadGlobals,
} from "~/core/entryPoints";
import {
  MatterRoute,
  MatterRouteEntryPointParams,
  MatterRouteQueryRequest,
} from "./Matter/MatterRoute";
import { MattersRoute, MattersRouteQuery } from "./Matters/MattersRoute";

export const routes = compileRoutes({
  "matters.index": (_?: unknown) => "/matters",
  "matter.index": (_: { matterId: string }) => "/matter/:matterId",
  "matter.new": (_?: unknown) => "/matters/new",
});

const routeConfig = {
  "matters.index": {
    root: createStaticResource("MattersRoute", MattersRoute),
    getPreloadProps(config: {
      params: Record<string, never>;
      globals: EntryPointPreloadGlobals;
    }) {
      const variables = {
        tenantId: config.globals.tenantId,
      };

      return {
        queries: {
          matters: {
            parameters: MattersRouteQuery,
            variables,
          },
        },
      };
    },
  },
  "matter.index": {
    root: createStaticResource("MatterRoute", MatterRoute),
    getPreloadProps(config: {
      params: MatterRouteEntryPointParams;
      globals: EntryPointPreloadGlobals;
    }) {
      const variables = {
        matterId: config.params.matterId,
        tenantId: config.globals.tenantId,
      };

      return {
        queries: {
          matter: {
            parameters: MatterRouteQueryRequest,
            variables,
          },
        },
      };
    },
  },
};

export const router = (
  <>
    <CoreEntryPointRoute
      path={routes["matters.index"].path}
      entrypoint={routeConfig["matters.index"]}
    />
    <CoreEntryPointRoute
      path={routes["matter.index"].path}
      entrypoint={routeConfig["matter.index"]}
    />
  </>
);
