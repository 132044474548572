import { Value } from "./types";

export const type = "enum";

export interface EnumValueProps {
  values: Record<string, string> | string[];
}

export function load(val: Value<string>): Value<string> {
  return val;
}

export function dump(val: Value<string, EnumValueProps>): string | null {
  const notPropertyInEnumObject =
    typeof val.value === "string" &&
    !Object.prototype.hasOwnProperty.call(val.props.values, val.value);

  const notElementInEnumArray =
    typeof val.value === "string" &&
    Array.isArray(val.props.values) &&
    !val.props.values.includes(val.value);

  if (notPropertyInEnumObject && notElementInEnumArray) {
    throw new Error("Invalid enum value");
  }
  return val.value;
}
