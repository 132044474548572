import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  LinkButton,
  ListAddIllustration,
  NonIdealState,
  Stack,
} from "@workflows/ui";
import { routes } from "../routes";

export const TenantsTableViewEmptyState: React.FC = () => {
  const { t } = useTranslation("de.agad.dpm");

  return (
    <Stack direction="vertical" fill>
      <NonIdealState
        header={<ListAddIllustration />}
        title={t("tenants.emptyState.heading")}
        description={t("tenants.emptyState.body")}
        footer={
          <LinkButton intent="primary" to={routes["tenant.new"].build()}>
            {t("tenants.emptyState.button")}
          </LinkButton>
        }
      />
    </Stack>
  );
};
