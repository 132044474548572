import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  Skeleton,
  Stack,
} from "@workflows/ui";
import * as React from "react";

export function CollectionItemChooserListItemSkeleton(): JSX.Element {
  return (
    <Card>
      <CardHeader icon={<Icon />}>
        <Skeleton width={50} />
      </CardHeader>
      <CardBody>
        <Stack gap="xs" direction="vertical">
          <Skeleton width={95} />
          <Skeleton width={92} />
          <Skeleton width={87} />
        </Stack>
      </CardBody>
    </Card>
  );
}
