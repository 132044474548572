import * as React from "react";
import { Skeleton } from "@workflows/ui";

export const TabSkeleton = (): JSX.Element => {
  return (
    <>
      <Skeleton width={54} className={"mb--md"} />
      <Skeleton width={24} className={"mb--md"} />
      <Skeleton width={44} className={"mb--md"} />
      <Skeleton width={14} className={"mb--md"} />
    </>
  );
};
