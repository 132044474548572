import * as React from "react";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { CollectionItemChooserQuery } from "~/__graphql__/CollectionItemChooserQuery.graphql";
import { CollectionItemChooserList as CollectionItemChooserList } from "./CollectionItemChooserList";

export interface CollectionItemChooserProps {
  isPending?: boolean;
  collectionId: string;
  start?: React.ReactNodeArray;
}

export function CollectionItemChooser({
  isPending = false,
  collectionId,
  start,
}: CollectionItemChooserProps): JSX.Element | null {
  const data = useLazyLoadQuery<CollectionItemChooserQuery>(Query, {
    collectionId,
  });

  if (!data.node) {
    return null;
  }

  return (
    <CollectionItemChooserList
      collection={data.node}
      isPending={isPending}
      start={start}
    />
  );
}

const Query = graphql`
  query CollectionItemChooserQuery($collectionId: ID!) {
    node(id: $collectionId) {
      ...CollectionItemChooserList_collection
    }
  }
`;
