import { Text } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { SearchResultsList_search$key } from "~/__graphql__/SearchResultsList_search.graphql";
import { SearchFallback } from "./Search";
import { SearchResultsListItem } from "./SearchResultsListItem";

export interface SearchResultsListProps {
  query: string;
  queryRef: SearchResultsList_search$key;
  onOpen?: (item: any, e: any) => void;
}

export const SearchResultsList: React.FC<SearchResultsListProps> = ({
  query,
  queryRef,
  onOpen,
}) => {
  const { t } = useTranslation("ai.workflows.search");

  const { data, loadNext, refetch } = usePaginationFragment(
    SearchResultsList_search,
    queryRef
  );

  React.useEffect(() => {
    refetch({ first: 10, query }, { fetchPolicy: "store-or-network" });
  }, [query]);

  return (
    <div>
      {data?.search?.edges ? (
        <>
          <Text type="bodyMuted" className="py-md">
            {data.search.edges.length} {t("SearchResultsList.results")}
          </Text>
          {data?.search?.edges.map((edge) => {
            if (!edge || !edge.node) {
              return null;
            }
            return (
              <SearchResultsListItem
                key={edge?.cursor}
                result={edge.node}
                onOpen={onOpen}
              />
            );
          })}
        </>
      ) : (
        <SearchFallback />
      )}
    </div>
  );
};

export const SearchResultsList_search = graphql`
  fragment SearchResultsList_search on RootQueryType
  @refetchable(queryName: "SearchResultsListQuery") {
    search(
      queryText: $queryText
      tenantId: $tenantId
      after: $after
      first: $first
    ) @connection(key: "SearchResultsList_search") {
      edges {
        cursor
        node {
          ...SearchResultsListItem_item
        }
      }
    }
  }
`;
