import { formatISO, isValid, parseISO } from "date-fns";
import { Value } from "./types";

export const type = "date";

export function load(val: Value<string>): Value<Date> {
  return { ...val, value: val.value !== null ? parseISO(val.value) : null };
}

export function dump(val: Value<Date>): string | null {
  return val.value && isValid(val.value)
    ? formatISO(val.value, { representation: "date" })
    : null;
}
