/* eslint-disable @typescript-eslint/no-explicit-any */
import { useHistory, useParams } from "@workflows/runtime-web";
import {
  ColumnFooter,
  LinkButton,
  Stack,
  StackItem,
  useForm,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "../routes";
import { useWizardSync } from "./WizardSync";
import { usePatchDocument } from "~/de.smartconex.vertragsgenerator/wizard/usePatchDocument";
import { WizardQueryResponse } from "~/__graphql__/WizardQuery.graphql";

export interface WizardNavProps {
  nextSlug?: string | null;
  prevSlug?: string | null;
  end?: React.ReactNode;
  document?: NonNullable<WizardQueryResponse["document"]>;
}

export function WizardNav({
  nextSlug,
  prevSlug,
  end,
  document,
}: WizardNavProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const tenantId = useTenantId();
  const history = useHistory();

  const completedSections = (document?.props as any)?.completedSections;

  const { documentId, slug } =
    useParams<{ documentId: string; slug: string }>();
  const { isPending, sync } = useWizardSync(tenantId, documentId);

  const form = useForm(documentId);

  const { patchDocument } = usePatchDocument();
  const handleSubmit = React.useCallback(
    async (e: React.SyntheticEvent) => {
      e.preventDefault();

      const { isValid } = await form.validate();
      if (!isValid) {
        setTimeout(() => {
          const errorNode = window.document.querySelector(".form-error");
          if (errorNode && errorNode.scrollIntoView) {
            errorNode.scrollIntoView({ behavior: "smooth" });
          }
        }, 50);
        return;
      }

      sync({
        onCompleted() {
          updateCompletedSections();
          if (nextSlug) {
            history.push(
              routes["wizard.index"].build({
                documentId,
                slug: nextSlug,
              })
            );
          }
        },
      });
    },
    [sync, documentId, nextSlug, history, form]
  );

  function updateCompletedSections() {
    const newCompletedSection = getCompletedSections(
      completedSections,
      slug,
      nextSlug
    );

    const updateCompletedSectionsPatch = {
      op: completedSections ? "replace" : "add",
      path: "/props/completedSections",
      value: JSON.stringify(newCompletedSection),
    };

    patchDocument({
      input: {
        documentId,
        patch: [updateCompletedSectionsPatch],
      },
    });
  }

  return (
    <ColumnFooter
      start={
        typeof prevSlug == "string" && (
          <LinkButton
            to={routes["wizard.index"].build({
              documentId,
              slug: prevSlug,
            })}
          >
            {t("WizardNav.button.previous")}
          </LinkButton>
        )
      }
      end={
        <Stack direction="horizontal" gap="md">
          {typeof nextSlug == "string" && (
            <StackItem>
              <LinkButton
                intent="primary"
                isLoading={isPending}
                onClick={handleSubmit}
                to={routes["wizard.index"].build({
                  documentId,
                  slug: nextSlug,
                })}
              >
                {t("WizardNav.button.next")}
              </LinkButton>
            </StackItem>
          )}
          {end && <StackItem>{end}</StackItem>}
        </Stack>
      }
    />
  );
}

function getCompletedSections(
  completedSections: string[],
  slug = "index",
  nextSlug?: string | null
) {
  if (!completedSections) {
    return [slug];
  }
  if (completedSections.includes(slug)) {
    return completedSections;
  }
  if (nextSlug === "finish") {
    return [...completedSections, slug, "finish"];
  }
  return [...completedSections, slug];
}
