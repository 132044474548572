import { Value } from "./types";

export const type = "duration";

export function load(val: Value<Duration>): Value<Duration> {
  return val;
}

export function dump(val: Value<Duration>): Duration | null {
  return val.value;
}
