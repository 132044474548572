import * as React from "react";
import {
  FormContext,
  FormControl,
  FormInput,
  Section,
  SectionBody,
  Stack,
  TextField,
  TextFieldProps,
  useForm,
  useToast,
} from "@workflows/ui";
import { newId, useHistory } from "@workflows/runtime-web";
import { routes } from "~/de.agad.dpm/routes";
import { useTranslation } from "react-i18next";
import { useDeAgadDpmCreateSubtenant } from "~/de.agad.dpm/tenants/hooks/useDeAgadDpmCreateSubtenant";

export const TenantCreator = (): JSX.Element => {
  const form = useForm("TenantCreator");
  const { t } = useTranslation("de.agad.dpm");
  const { deAgadDpmCreateSubtenant } = useDeAgadDpmCreateSubtenant();
  const history = useHistory();
  const [addToast] = useToast();

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [form]);

  const handleSubmit = async () => {
    const { isValid } = await form.validate();
    const values = form.getValues();

    if (!isValid) {
      return;
    }

    deAgadDpmCreateSubtenant({
      input: values,
      onError(error) {
        console.error(error);
        handleError();
      },
      onCompleted(data) {
        if (data.deAgadDpmCreateSubtenant?.errors) {
          const errors = data.deAgadDpmCreateSubtenant.errors;
          if (errors.some((e) => e && e.code === "hostTooLong")) {
            form.addError("host", t("TenantCreator.errors.hostTooLong"));
          }

          handleError();
        } else {
          const tenant = data.deAgadDpmCreateSubtenant?.tenant;
          if (!tenant) return;

          history.push(
            routes["tenant.documents"].build({ tenantId: tenant.id })
          );
        }
      },
    });
  };

  const handleError = () => {
    addToast({
      id: newId(),
      message: t("TenantEditor.error"),
      intent: "warning",
    });
  };

  return (
    <FormContext
      name="TenantCreator"
      onSubmit={handleSubmit}
      defaultValues={defaultValues}
    >
      <BasicInformation />
      <CompanySection />
      <ContactSection />
    </FormContext>
  );
};

export const BasicInformation = (): JSX.Element => {
  const { t } = useTranslation("de.agad.dpm");
  const form = useForm("TenantCreator");

  const onUpdateName = (value: string) => {
    const host = value
      .toLowerCase()
      .replace(/(.*)(gmbh & co\. kg|e\.g\.|e\. v\.)$/g, "$1")
      .replace(/(.*)(gmbh|ag|ohg|gbr|ug|kg|se)$/g, "$1")
      .replace(/ä/g, "ae")
      .replace(/ö/g, "oe")
      .replace(/ü/g, "ue")
      .trim()
      .replace(/\s+/g, "-")
      .replace(/-{2}/g, "-")
      .replace(/[^\d\w-]/g, "")
      .replace(/^-(.*)/, "$1")
      .replace(/(.*)-$/, "$1");

    form.setValues({ host });
  };

  return (
    <Section title={t("TenantEditor.sections.basic")}>
      <SectionBody className="p-0">
        <FormControl
          htmlFor="name"
          name="name"
          label={t("TenantEditor.name.label")}
          helper={t("TenantEditor.name.helper")}
        >
          <FormInput<TextFieldProps>
            id="name"
            component={TextField}
            type="text"
            name="name"
            required={t("TenantEditor.required.name") as string}
            onChangeAfter={onUpdateName}
          />
        </FormControl>
        <FormControl
          htmlFor="host"
          name="host"
          label={t("TenantEditor.host.label")}
          helper={t("TenantEditor.host.helper")}
        >
          <FormInput<TextFieldProps>
            id="host"
            component={TextField}
            type="text"
            name="host"
            required={t("TenantEditor.required.host") as string}
          />
        </FormControl>
      </SectionBody>
    </Section>
  );
};

export const CompanySection = (): JSX.Element => {
  const { t } = useTranslation("de.agad.dpm");

  return (
    <Section title={t("TenantEditor.sections.company")}>
      <SectionBody>
        <FormControl
          htmlFor="companyName"
          label={t("TenantEditor.companyName")}
        >
          <FormInput<TextFieldProps>
            id="companyName"
            component={TextField}
            type="text"
            name="companyName"
          />
        </FormControl>
        <FormControl
          htmlFor="companyAddress"
          label={t("TenantEditor.companyAddress")}
        >
          <FormInput<TextFieldProps>
            id="companyAddress"
            component={TextField}
            type="text"
            name="companyAddress"
          />
        </FormControl>
        <Stack direction="horizontal">
          <FormControl
            htmlFor="companyPostalCode"
            label={t("TenantEditor.companyPostalCode")}
          >
            <FormInput<TextFieldProps>
              id="companyPostalCode"
              component={TextField}
              type="text"
              name="companyPostalCode"
            />
          </FormControl>
          <FormControl
            htmlFor="companyCity"
            label={t("TenantEditor.companyCity")}
          >
            <FormInput<TextFieldProps>
              id="companyCity"
              component={TextField}
              type="text"
              name="companyCity"
            />
          </FormControl>
        </Stack>
      </SectionBody>
    </Section>
  );
};

export const ContactSection = (): JSX.Element => {
  const { t } = useTranslation("de.agad.dpm");

  return (
    <Section title={t("TenantEditor.sections.contact")}>
      <SectionBody>
        <FormControl
          htmlFor="contactName"
          label={t("TenantEditor.contactName")}
        >
          <FormInput<TextFieldProps>
            id="contactName"
            component={TextField}
            type="text"
            name="contactName"
          />
        </FormControl>
        <FormControl htmlFor="position" label={"Position"}>
          <FormInput<TextFieldProps>
            id="position"
            component={TextField}
            type="text"
            name="position"
          />
        </FormControl>
        <FormControl htmlFor="email" label={"Email"}>
          <FormInput<TextFieldProps>
            id="email"
            component={TextField}
            type="text"
            name="email"
          />
        </FormControl>
      </SectionBody>
    </Section>
  );
};

const defaultValues = {
  name: "",
  host: "",
  companyName: "",
  companyAddress: "",
  companyPostalCode: "",
  companyCity: "",
  contactName: "",
  position: "",
  email: "",
};
