/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { add, sub, isValid } from "date-fns";
import { TFunction } from "i18next";

interface CreateRuntimeOptions {
  t?: TFunction;
}

export function createRuntime(options?: CreateRuntimeOptions) {
  return {
    isEmpty(value: any) {
      return (
        value == undefined ||
        value == null ||
        (Array.isArray(value) && value.length === 0) ||
        (typeof value === "string" && value.length === 0)
      );
    },
    date: {
      subtract(date: Date, duration: Duration): Date | null {
        if (date && isValid(date) && duration) {
          return sub(date, duration);
        }
        return null;
      },
      add(date: Date, duration: Duration): Date | null {
        if (date && isValid(date) && duration && duration.years) {
          return add(date, duration);
        }
        return null;
      },
    },
    format(value: any, type: "date" | "money" | undefined) {
      switch (type) {
        case "date":
          return value && options?.t
            ? options.t("Variable.date", { value })
            : null;
        case "money":
          return value && options?.t
            ? options.t("Variable.money", { value })
            : null;
        default:
          return String(value);
      }
    },
    math: {
      sum(...args: number[]) {
        return args.reduce(
          (acc, next) => (Number.isFinite(next) ? acc + next : acc),
          0
        );
      },
      div(dividend: number, divisor: number) {
        return Number.isFinite(dividend) && Number.isFinite(divisor)
          ? dividend / divisor
          : 0;
      },
      round(number: number, decimals = 2): number | null {
        if (!Number.isFinite(number)) {
          return null;
        }
        return +(
          Math.round((number + "e+" + decimals) as unknown as number) +
          "e-" +
          decimals
        );
      },
    },
  };
}
