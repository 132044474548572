/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DashboardRouteQueryVariables = {
    tenantId: string;
    keys: Array<string>;
};
export type DashboardRouteQueryResponse = {
    readonly config: ReadonlyArray<{
        readonly value: unknown;
        readonly key: string;
    } | null> | null;
};
export type DashboardRouteQuery = {
    readonly response: DashboardRouteQueryResponse;
    readonly variables: DashboardRouteQueryVariables;
};



/*
query DashboardRouteQuery(
  $tenantId: ID!
  $keys: [String!]!
) {
  config(tenantId: $tenantId, nodeId: $tenantId, keys: $keys) {
    value
    key
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "keys"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tenantId"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "keys",
            "variableName": "keys"
        } as any,
        {
            "kind": "Variable",
            "name": "nodeId",
            "variableName": "tenantId"
        } as any,
        {
            "kind": "Variable",
            "name": "tenantId",
            "variableName": "tenantId"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "DashboardRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ConfigValue",
                    "kind": "LinkedField",
                    "name": "config",
                    "plural": true,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "DashboardRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "ConfigValue",
                    "kind": "LinkedField",
                    "name": "config",
                    "plural": true,
                    "selections": [
                        (v3 /*: any*/),
                        (v4 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "11038167797a2e6c6621c22bc75bda48",
            "id": null,
            "metadata": {},
            "name": "DashboardRouteQuery",
            "operationKind": "query",
            "text": "query DashboardRouteQuery(\n  $tenantId: ID!\n  $keys: [String!]!\n) {\n  config(tenantId: $tenantId, nodeId: $tenantId, keys: $keys) {\n    value\n    key\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '95217187191d5414af8b6ab605a8ca2e';
export default node;
