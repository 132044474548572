import { compileRoutes, GraphQLTaggedNode } from "@workflows/runtime-web";
import {
  createStaticResource,
  EntryPointPreloadGlobals,
} from "~/core/entryPoints";
import { TenantsRoute, TenantsRouteQuery } from "./tenants/TenantsRoute";

export const routes = compileRoutes({
  "tenants.dashboard": () => "/",
  "tenants.index": (_: { tenantId?: string }) => "/:tenantId?",
  "tenant.new": () => "/new",
  "tenant.index": (_: { tenantId: string }) => "/:tenantId",
  "tenant.details": (_: { tenantId: string }) => "/:tenantId/details",
  "tenant.documents": (_: { tenantId: string }) => "/:tenantId/documents",
  "tenant.documents.add": (_: { tenantId: string }) =>
    "/:tenantId/documents/add",
  "tenant.people": (_: { tenantId: string }) => "/:tenantId/people",
  "tenant.document.edit": (_: { tenantId: string; documentId: string }) =>
    "/:tenantId/documents/:documentId/edit",
});

export const routeConfig = {
  "tenants.index": {
    root: createStaticResource("TenantsRoute", TenantsRoute),
    getPreloadProps(config: { globals: EntryPointPreloadGlobals }): Record<
      "queries",
      Record<
        "tenants",
        {
          parameters: GraphQLTaggedNode;
          variables: Record<"tenantId", string | undefined>;
        }
      >
    > {
      const variables = {
        tenantId: config.globals.tenantId,
      };

      return {
        queries: {
          tenants: {
            parameters: TenantsRouteQuery,
            variables,
          },
        },
      };
    },
  },
};
