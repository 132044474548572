/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateTenantInput = {
    clientMutationId: string;
    entities?: Array<EntityInput | null> | null;
    name?: string | null;
    tenantId: string;
};
export type EntityInput = {
    administrativeArea?: string | null;
    email?: string | null;
    identifier?: string | null;
    locality?: string | null;
    name?: string | null;
    position?: string | null;
    postalCode?: string | null;
    postalCountry?: string | null;
    streetAddress?: string | null;
    type?: string | null;
};
export type useUpdateTenantMutationVariables = {
    input: UpdateTenantInput;
};
export type useUpdateTenantMutationResponse = {
    readonly updateTenant: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly tenant: {
            readonly id: string;
            readonly name: string;
            readonly organization: {
                readonly locality?: string | null;
                readonly name?: string | null;
                readonly postalCode?: string | null;
                readonly streetAddress?: string | null;
            } | null;
            readonly contact: {
                readonly name?: string | null;
                readonly position?: string | null;
                readonly email?: string | null;
            } | null;
        } | null;
    } | null;
};
export type useUpdateTenantMutation = {
    readonly response: useUpdateTenantMutationResponse;
    readonly variables: useUpdateTenantMutationVariables;
};



/*
mutation useUpdateTenantMutation(
  $input: UpdateTenantInput!
) {
  updateTenant(input: $input) {
    errors {
      code
      path
      message
      type
    }
    tenant {
      id
      name
      organization: entity(identifier: "de.agad.dpm.entities.tenants.address") {
        __typename
        ... on EntityOrganization {
          locality
          name
          postalCode
          streetAddress
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      contact: entity(identifier: "de.agad.dpm.entities.tenants.contact") {
        __typename
        ... on EntityPerson {
          name
          position
          email
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = [
        {
            "kind": "Literal",
            "name": "identifier",
            "value": "de.agad.dpm.entities.tenants.address"
        } as any
    ], v6 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "locality",
                "storageKey": null
            },
            (v4 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "postalCode",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "streetAddress",
                "storageKey": null
            }
        ],
        "type": "EntityOrganization",
        "abstractKey": null
    } as any, v7 = [
        {
            "kind": "Literal",
            "name": "identifier",
            "value": "de.agad.dpm.entities.tenants.contact"
        } as any
    ], v8 = {
        "kind": "InlineFragment",
        "selections": [
            (v4 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "position",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
            }
        ],
        "type": "EntityPerson",
        "abstractKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v10 = {
        "kind": "InlineFragment",
        "selections": [
            (v3 /*: any*/)
        ],
        "type": "Node",
        "abstractKey": "__isNode"
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useUpdateTenantMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateTenantPayload",
                    "kind": "LinkedField",
                    "name": "updateTenant",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tenant",
                            "kind": "LinkedField",
                            "name": "tenant",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": "organization",
                                    "args": (v5 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.address\")"
                                },
                                {
                                    "alias": "contact",
                                    "args": (v7 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.contact\")"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useUpdateTenantMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "UpdateTenantPayload",
                    "kind": "LinkedField",
                    "name": "updateTenant",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Tenant",
                            "kind": "LinkedField",
                            "name": "tenant",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": "organization",
                                    "args": (v5 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        (v6 /*: any*/),
                                        (v10 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.address\")"
                                },
                                {
                                    "alias": "contact",
                                    "args": (v7 /*: any*/),
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "entity",
                                    "plural": false,
                                    "selections": [
                                        (v9 /*: any*/),
                                        (v8 /*: any*/),
                                        (v10 /*: any*/)
                                    ],
                                    "storageKey": "entity(identifier:\"de.agad.dpm.entities.tenants.contact\")"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5f167a024340214c8e96d0a327e22c9b",
            "id": null,
            "metadata": {},
            "name": "useUpdateTenantMutation",
            "operationKind": "mutation",
            "text": "mutation useUpdateTenantMutation(\n  $input: UpdateTenantInput!\n) {\n  updateTenant(input: $input) {\n    errors {\n      code\n      path\n      message\n      type\n    }\n    tenant {\n      id\n      name\n      organization: entity(identifier: \"de.agad.dpm.entities.tenants.address\") {\n        __typename\n        ... on EntityOrganization {\n          locality\n          name\n          postalCode\n          streetAddress\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      contact: entity(identifier: \"de.agad.dpm.entities.tenants.contact\") {\n        __typename\n        ... on EntityPerson {\n          name\n          position\n          email\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '8059d055e0b28746f5ea51a4eb0604f1';
export default node;
