import {
  Button,
  Form2,
  SearchIcon,
  Stack,
  StackItem,
  TextField,
} from "@workflows/ui";
import * as React from "react";

export interface SearchFieldProps {
  value?: string;
  onChange: (query: string) => void;
  onSearch: () => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  value,
  onChange,
  onSearch,
}) => {
  const handleChange = (e: string | number) => {
    onChange(e as string);
  };

  return (
    <Form2 name={"SearchForm"} onSubmit={onSearch}>
      <Stack direction="horizontal" gap="xs">
        <StackItem grow={1}>
          <TextField
            placeholder="Suche…"
            value={value}
            onChange={handleChange}
          />
        </StackItem>
        <Button type="submit" onClick={onSearch}>
          <SearchIcon />
        </Button>
      </Stack>
    </Form2>
  );
};
