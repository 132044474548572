/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CoreBootloaderQueryVariables = {
    tenantId: string;
    configValueKeys?: Array<string | null> | null;
};
export type CoreBootloaderQueryResponse = {
    readonly viewer: {
        readonly id: string | null;
        readonly " $fragmentRefs": FragmentRefs<"App_viewer">;
    } | null;
    readonly tenant: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"App_tenant" | "CoreNav_nav">;
    } | null;
};
export type CoreBootloaderQuery = {
    readonly response: CoreBootloaderQueryResponse;
    readonly variables: CoreBootloaderQueryVariables;
};



/*
query CoreBootloaderQuery(
  $tenantId: ID!
  $configValueKeys: [String]
) {
  viewer {
    id
    ...App_viewer_1GYzvY
  }
  tenant: node(id: $tenantId) {
    __typename
    id
    ...App_tenant_1NeYjL
    ...CoreNav_nav
  }
}

fragment App_tenant_1NeYjL on Tenant {
  id
  name
  parentId
  roles {
    edges {
      node {
        id
        name
      }
    }
  }
  viewerPermissions
  config(keys: $configValueKeys) {
    key
    value
    id
  }
}

fragment App_viewer_1GYzvY on Viewer {
  user(tenantId: $tenantId) {
    id
    name
    email
    firstName
    lastName
    displayName
  }
}

fragment CoreNav_nav on Tenant {
  viewerPermissions
  nav(identifier: "ai.workflows.nav.main") {
    items {
      edges {
        node {
          id
          name
          sort
          path
          icon
          identifier
          externalUrl
          route {
            path
            id
          }
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "configValueKeys"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tenantId"
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = [
        {
            "kind": "Variable",
            "name": "tenantId",
            "variableName": "tenantId"
        } as any
    ], v4 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "tenantId"
        } as any
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "path",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "CoreBootloaderQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "args": (v3 /*: any*/),
                            "kind": "FragmentSpread",
                            "name": "App_viewer"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "tenant",
                    "args": (v4 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "configValueKeys",
                                    "variableName": "configValueKeys"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "App_tenant"
                        },
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CoreNav_nav"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "CoreBootloaderQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": (v3 /*: any*/),
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "email",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "firstName",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "lastName",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "displayName",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "tenant",
                    "args": (v4 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "parentId",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RoleConnection",
                                    "kind": "LinkedField",
                                    "name": "roles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "viewerPermissions",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Variable",
                                            "name": "keys",
                                            "variableName": "configValueKeys"
                                        }
                                    ],
                                    "concreteType": "ConfigValue",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "key",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "value",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": [
                                        {
                                            "kind": "Literal",
                                            "name": "identifier",
                                            "value": "ai.workflows.nav.main"
                                        }
                                    ],
                                    "concreteType": "Nav",
                                    "kind": "LinkedField",
                                    "name": "nav",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "NavItemConnection",
                                            "kind": "LinkedField",
                                            "name": "items",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "NavItemEdge",
                                                    "kind": "LinkedField",
                                                    "name": "edges",
                                                    "plural": true,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "NavItem",
                                                            "kind": "LinkedField",
                                                            "name": "node",
                                                            "plural": false,
                                                            "selections": [
                                                                (v2 /*: any*/),
                                                                (v5 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "sort",
                                                                    "storageKey": null
                                                                },
                                                                (v6 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "icon",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "identifier",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "externalUrl",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Route",
                                                                    "kind": "LinkedField",
                                                                    "name": "route",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v6 /*: any*/),
                                                                        (v2 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": "nav(identifier:\"ai.workflows.nav.main\")"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b9e1bc7557592a6e6a5422fe06ae5068",
            "id": null,
            "metadata": {},
            "name": "CoreBootloaderQuery",
            "operationKind": "query",
            "text": "query CoreBootloaderQuery(\n  $tenantId: ID!\n  $configValueKeys: [String]\n) {\n  viewer {\n    id\n    ...App_viewer_1GYzvY\n  }\n  tenant: node(id: $tenantId) {\n    __typename\n    id\n    ...App_tenant_1NeYjL\n    ...CoreNav_nav\n  }\n}\n\nfragment App_tenant_1NeYjL on Tenant {\n  id\n  name\n  parentId\n  roles {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n  viewerPermissions\n  config(keys: $configValueKeys) {\n    key\n    value\n    id\n  }\n}\n\nfragment App_viewer_1GYzvY on Viewer {\n  user(tenantId: $tenantId) {\n    id\n    name\n    email\n    firstName\n    lastName\n    displayName\n  }\n}\n\nfragment CoreNav_nav on Tenant {\n  viewerPermissions\n  nav(identifier: \"ai.workflows.nav.main\") {\n    items {\n      edges {\n        node {\n          id\n          name\n          sort\n          path\n          icon\n          identifier\n          externalUrl\n          route {\n            path\n            id\n          }\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a4deb4c43c5295106b0d89b784d8c3ce';
export default node;
