/* eslint-disable @typescript-eslint/no-explicit-any */
import { ColumnHeader, Progress, useDocumentTitle } from "@workflows/ui";
import { WizardQueryResponse } from "~/__graphql__/WizardQuery.graphql";
import { WizardStep } from "./types";
import { WizardQuickNavigation } from "./WizardQuickNavigation";
import * as React from "react";

export interface WizardHeaderProps {
  document: NonNullable<WizardQueryResponse["document"]>;
  steps: WizardStep[];
  stepIndex: number;
}

export function WizardHeader({
  document,
  steps,
  stepIndex,
}: WizardHeaderProps): JSX.Element {
  const completedSections =
    document && document.props && (document.props as any).completedSections
      ? ((document.props as any).completedSections as string[])
      : [];

  const step = steps[stepIndex];
  const stepProgress = completedSections.length / steps.length;

  useDocumentTitle(document.title || "");

  return (
    <ColumnHeader
      title={document.title}
      end={<Progress max={100} value={stepProgress * 100} />}
    >
      <WizardQuickNavigation
        steps={steps}
        currentStepSlug={step?.slug}
        completedSections={completedSections}
      />
    </ColumnHeader>
  );
}
