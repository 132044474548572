import {
  LinkButton,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  DocumentIcon,
  Icon,
  Text,
  Truncate,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useFragment } from "react-relay/hooks";
import { routes } from "~/flows/routes";
import {
  WorkflowChooserListItem_item$data,
  WorkflowChooserListItem_item$key,
} from "~/__graphql__/WorkflowChooserListItem_item.graphql";
export interface WorkflowChooserListItemProps {
  fragment: WorkflowChooserListItem_item$key;
  onClick: (
    e: React.MouseEvent,
    item: WorkflowChooserListItem_item$data
  ) => void;
}

export function WorkflowChooserListItem({
  fragment,
  onClick,
}: WorkflowChooserListItemProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.workflows");
  const item = useFragment(WorkflowChooserListItem_item, fragment);

  return (
    <Card interactive={false} onClick={(e) => onClick(e, item)}>
      <CardHeader
        title={item.name}
        icon={<Icon identifier={item.icon} fallback={DocumentIcon} />}
        iconTint={item.color}
      />
      <CardBody>
        <Text color="muted">
          <Truncate lines={3}>{item.descriptionText}</Truncate>
        </Text>
      </CardBody>
      <CardFooter>
        <LinkButton
          to={routes["flows.new"].build({ workflowId: item.id })}
          fill
        >
          {t("WorkflowChooserListItem.actions.choose")}
        </LinkButton>
      </CardFooter>
    </Card>
  );
}

const WorkflowChooserListItem_item = graphql`
  fragment WorkflowChooserListItem_item on Workflow {
    id
    name
    descriptionText
    color
    icon
  }
`;
