declare global {
  interface Window {
    __workflows: Workflows;
  }
}

export interface Theme {
  logo: {
    lg: string;
    xs: string;
  };
  favicon: string;
}

export interface Workflows {
  app: string;
  tenantId: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
  theme: Theme;
  languages: Languages | null;
  config: {
    SENTRY_DSN: string;
    SENTRY_ENV: string;
    MSW_MOCKING: string;
  };
  endpoints: {
    api: string;
    apiGraphQL: string;
    apiWebsocket: string;
  };
}

export interface Languages {
  default: string;
  available: string[];
}

const wf = window.__workflows;

if (!wf) {
  throw new Error("Failed to load bootstrapping config.");
}

// Endpoints
if (
  !wf.endpoints ||
  !wf.endpoints.apiWebsocket ||
  !wf.endpoints.apiGraphQL ||
  !wf.endpoints.api
) {
  throw new Error(
    "Failed to set up API connection due to missing endpoint URLs. " +
      "Make sure you set API_PUBLIC_ENDPOINT, API_PUBLIC_GRAPHQL_ENDPOINT and API_PUBLIC_WEBSOCKET_ENDPOINT in your environment."
  );
}

wf.endpoints = Object.entries(wf.endpoints).reduce<Record<string, string>>(
  (acc, [k, v]) => {
    acc[k] = v.endsWith("/") ? v : `${v}/`;
    return acc;
  },
  {}
) as Workflows["endpoints"];

export const data = wf.data;

export const endpoints: Workflows["endpoints"] = wf.endpoints;

export const theme: Workflows["theme"] = wf.theme;

export const tenantId = wf.tenantId;

export const languages: Languages = wf.languages || {
  default: "en",
  available: ["en", "de"],
};
