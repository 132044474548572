import { RouteComponentProps } from "@workflows/runtime-web";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeadCell,
  TableRow,
  Text,
  TextContainer,
} from "@workflows/ui";
import * as React from "react";
import { Error404 } from "~/core/Error404";

export type GlossaryPageRouteProps = RouteComponentProps<{ slug?: string }>;

export function GlossaryPageRoute({
  match: {
    params: { slug },
  },
}: GlossaryPageRouteProps): JSX.Element {
  const slugs = [
    "abtretung",
    "arbeitsvertrag",
    "befristung-ohne-sachgrund",
    "kuendigungsfristen",
    "rente",
    "urlaub",
    "abtretung",
  ];

  return (
    <>
      {slug === "abtretung" && (
        <TextContainer style={{ maxWidth: "50rem" }}>
          <Text variant="headingLg">Abtretung</Text>
          <Text as="p">
            Der Vorbehalt, dass Gehaltsabtretungen nur mit Zustimmung der
            Geschäftsleitung zulässig sind, ermöglicht die Ablehnung von
            Forderungen, welche ein Gläubiger des Arbeitnehmers auf Grund einer
            Abtretung geltend macht. Bei gerichtlichen Pfändungs- und
            Überweisungsbeschlüssen wirkt das vertragliche Abtretungsverbot
            allerdings nicht.
          </Text>
          <Text as="p">
            Die mit der Bearbeitung von Entgeltpfändungen verbundenen Kosten des
            Arbeitgebers fallen diesem selbst zur Last. Ein solcher Anspruch
            kann nach der Entscheidung des BAG auch nicht durch eine
            Betriebsvereinbarung begründet werden. Es spricht daher viel dafür,
            dass eine arbeitsvertraglich vereinbarte Kostenerstattungspflicht
            des Arbeitnehmers von der Rechtsprechung gemäß § 307 BGB als
            unwirksam angesehen würde.
          </Text>
        </TextContainer>
      )}
      {slug === "arbeitsvertrag" && (
        <TextContainer style={{ maxWidth: "50rem" }}>
          <Text variant="headingLg">Arbeitsvertrag</Text>
          <Text as="p">
            Klare Vereinbarungen über Arbeitsbedingungen sind wichtig. Der
            Arbeitgeber ist gesetzlich verpflichtet, neu eingestellten
            Arbeitnehmern innerhalb eines Monats schriftlich die wesentlichen
            für sie geltenden Arbeitsbedingungen zu bestätigen.
          </Text>
          <Text as="p">
            Im Allgemeinen ist es nicht zweckmäßig, den Vertrag erst später
            auszufertigen und dem Arbeitnehmer zur Unterzeichnung zuzusenden.
            Viele Arbeitnehmer meinen, eine mündliche Vereinbarung sei erst dann
            wirksam, wenn diese schriftlich niedergelegt und unterzeichnet
            worden ist.
          </Text>
          <Text as="p">
            In Betrieben mit Betriebsrat ist vor einer Einstellung das
            Mitbestimmungsrecht des Betriebsrats zu beachten.
          </Text>
          <Text as="p">
            Bei Minderjährigen (unter 18 Jahren) müssen die Eltern (eventuell
            Vormund) dem Vertragsabschluss zustimmen. Es empfiehlt sich deshalb,
            den Vertrag von diesen ebenfalls unterzeichnen zu lassen.
          </Text>
        </TextContainer>
      )}
      {slug === "befristung-ohne-sachgrund" && (
        <TextContainer style={{ maxWidth: "50rem" }}>
          <Text variant="headingLg">Befristung ohne Sachgrund</Text>
          <Text as="p">
            Die Vereinbarung einer Befristung ohne Vorliegen eines sachlichen
            Grundes ist höchstens bis zu einer Dauer von zwei Jahren zulässig.
          </Text>
          <Text as="p">
            Eine Befristung ohne Sachgrund ist unzulässig, wenn zwischen
            Arbeitgeber und Arbeitnehmer jemals zuvor schonmal ein befristetes
            oder unbefristetes Arbeitsverhältnis bestanden hat. Ein
            Ausbildungsverhältnis gilt insoweit nicht als vorheriges
            Arbeitsverhältnis.
          </Text>
        </TextContainer>
      )}
      {slug === "kuendigungsfristen" && (
        <TextContainer style={{ maxWidth: "50rem" }}>
          <Text variant="headingLg">Kündigungsfristen</Text>
          <Text as="p">
            Bei längerer Betriebszugehörigkeit sind gemäß § 622 Abs. 2 BGB für
            eine Kündigung durch den Arbeitgeber folgende Kündigungsfristen
            einzuhalten:
          </Text>
          <Table>
            <TableHead>
              <TableRow>
                <TableHeadCell>Betriebszugehörigkeit</TableHeadCell>
                <TableHeadCell>Kündigungsfrist</TableHeadCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>nach 2 Jahren</TableCell>
                <TableCell>1 Monat zum Monatsschluss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nach 5 Jahren</TableCell>
                <TableCell>2 Monaten zum Monatsschluss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nach 8 Jahren</TableCell>
                <TableCell>3 Monaten zum Monatsschluss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nach 10 Jahren</TableCell>
                <TableCell>4 Monaten zum Monatsschluss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nach 12 Jahren</TableCell>
                <TableCell>5 Monaten zum Monatsschluss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nach 15 Jahren</TableCell>
                <TableCell>6 Monaten zum Monatsschluss</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>nach 20 Jahren</TableCell>
                <TableCell>7 Monaten zum Monatsschluss</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TextContainer>
      )}
      {slug === "rente" && (
        <TextContainer style={{ maxWidth: "50rem" }}>
          <Text variant="headingLg">Befristung ohne Sachgrund</Text>
          <Text as="p">
            Das Erreichen des Rentenalters beendet weder automatisch das
            Arbeitsverhältnis noch ist damit ohne Weiteres ein Kündigungsgrund
            gegeben. Es empfiehlt sich deshalb die im Vertrag vorgesehene
            Befristung.
          </Text>
          <Text as="p">
            Bei Verträgen mit Tarifbindung ergibt sich die Befristung aus dem
            Manteltarifvertrag.
          </Text>
        </TextContainer>
      )}
      {slug === "urlaub" && (
        <TextContainer style={{ maxWidth: "50rem" }}>
          <Text variant="headingLg">Urlaub</Text>
          <Text as="p">
            Durch eine Grundsatzentscheidung hat das Bundesarbeitsgericht
            entschieden, dass der Urlaubsanspruch, der wegen längerer
            Arbeitsunfähigkeit nicht vom Arbeitnehmer genommen werden kann,
            spätestens 15 Monate nach Ende des jeweiligen Urlaubsjahres
            verfällt.
          </Text>
          <Text as="p">
            Dies gilt auch für den tariflichen Urlaub, da der Tarifvertrag keine
            Unterscheidung vornimmt.
          </Text>
          <Text variant="headingSm">Beispiel</Text>
          <Text as="p">
            Der Urlaubsanspruch aus dem Kalenderjahr 2019 verfällt vollständig
            mit Ablauf des 31. März 2021, sofern der Arbeitnehmer ihn bis dahin
            krankheitsbedingt nicht nehmen konnte.
          </Text>
        </TextContainer>
      )}
      {slug && !slugs.includes(slug) && <Error404 />}
    </>
  );
}
