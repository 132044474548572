import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ChangedTitleActivityProps {
  activity: any;
}

export function ChangedTitleActivity({
  activity,
}: ChangedTitleActivityProps): JSX.Element {
  const { actor, insertedAt } = activity;
  const { t } = useTranslation("ai.workflows.activities");

  return (
    <div>
      {t("ChangedTitleActivity", {
        actorName: actor.name,
        insertedAt,
      })}
    </div>
  );
}
