import {
  Button,
  ButtonGroup,
  ColumnHeader,
  DotsVerticalIcon,
  Link,
  Stack,
  Text,
  EditableText,
  useTenantId,
} from "@workflows/ui";
import { formatDistance } from "date-fns";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useMutation } from "react-relay/hooks";
import { locales } from "~/core/i18n";
import { MatterOverviewColumnHeader_UpdateMatterMutation } from "~/__graphql__/MatterOverviewColumnHeader_UpdateMatterMutation.graphql";
import { newId } from "@workflows/runtime-web";
import { MatterRouteQueryResponse } from "~/__graphql__/MatterRouteQuery.graphql";

export function MatterOverviewColumnHeader({
  matter,
}: {
  matter: MatterRouteQueryResponse["matter"];
}): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");
  const tenantId = useTenantId();
  const formattedInsertedAt = formatDistance(
    new Date(matter?.insertedAt ? matter.insertedAt : ""),
    new Date(),
    {
      locale: locales["en"],
      addSuffix: true,
    }
  );
  const [updateMatter] =
    useMutation<MatterOverviewColumnHeader_UpdateMatterMutation>(
      updateMatterMutation
    );

  const updateMatterTitle = (newTitle: string) => {
    updateMatter({
      variables: {
        input: {
          clientMutationId: newId(),
          tenantId,
          matterId: matter?.id || "",
          title: newTitle,
        },
      },
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.updateMatter?.errors) {
          console.error(data.updateMatter?.errors);
        }
      },
    });
  };

  return (
    <ColumnHeader
      title={t("MatterOverviewColumn.header")}
      end={
        <ButtonGroup>
          <Button minimal>
            <DotsVerticalIcon />
          </Button>
        </ButtonGroup>
      }
    >
      <Stack direction="vertical" gap="xxs">
        <EditableText
          placeholderText={t("MatterOverviewColumn.title")}
          onSave={(text) => updateMatterTitle(text)}
        >
          <Text variant="headingLg">{matter?.title}</Text>
        </EditableText>
        <Text color="muted">
          {t("MatterOverviewColumn.createdAt", {
            matterNumber: matter?.number,
            insertedAt: formattedInsertedAt,
          })}{" "}
          <Link>{matter?.creator?.name}</Link>
        </Text>
      </Stack>
    </ColumnHeader>
  );
}

const updateMatterMutation = graphql`
  mutation MatterOverviewColumnHeader_UpdateMatterMutation(
    $input: UpdateMatterInput!
  ) {
    updateMatter(input: $input) {
      errors {
        code
        message
        type
      }
      clientMutationId
      matter {
        id
        title
      }
    }
  }
`;
