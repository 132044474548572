/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";
import { parseCookies } from "~/core/auth";
import * as config from "./config";

const sendRequest: FetchFunction = async (
  params,
  variables,
  _cacheConfig,
  uploadables?
) => {
  const cookies = parseCookies<{ "wf.token": string }>(document.cookie);
  const requestVariables: any = {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
  };

  if (cookies["wf.token"]) {
    requestVariables.headers["Authorization"] = `Bearer ${cookies["wf.token"]}`;
  }

  let body;
  if (uploadables && Object.keys(uploadables).length > 0) {
    if (!window.FormData) {
      throw new Error("Uploading files without `FormData` not supported.");
    }

    const formData = new FormData();
    formData.append("query", params.text as string);
    formData.append("variables", JSON.stringify(variables));

    Object.keys(uploadables).forEach((key) => {
      if (Object.prototype.hasOwnProperty.call(uploadables, key)) {
        formData.append(key, uploadables[key]);
      }
    });

    body = formData;
  } else {
    requestVariables.headers["Content-Type"] = "application/json";

    body = JSON.stringify({
      query: params.text,
      variables,
    });
  }

  console.debug(
    `Fetching query ${params.name} with ${JSON.stringify(variables)}`
  );

  const response = await fetch(config.endpoints.apiGraphQL, {
    credentials: "include",
    ...requestVariables,
    body,
  });

  return await response.json();
};

export const environment = new Environment({
  network: Network.create(sendRequest),
  store: new Store(new RecordSource(config.data)),
});
