import { graphql, useLazyLoadQuery } from "@workflows/runtime-web";
import {
  BrandLogo,
  Callout,
  Column,
  ColumnBody,
  Hero,
  NonIdealState,
  Section,
  SectionBody,
  TemplatesEmptyIllustration,
  useDocumentTitle,
  useTenantId,
} from "@workflows/ui";
import { useTranslation } from "react-i18next";
import { DashboardCollectionWidget } from "~/de.smartconex.vertragsgenerator/dashboard/DashboardCollectionWidget";
import { DashboardRouteQuery } from "~/__graphql__/DashboardRouteQuery.graphql";
import { CollectionItemChooserListQuickstartItem } from "../collections/CollectionItemChooserListQuickstartItem";
import * as React from "react";

const DASHBOARD_KEY = "de.smartconex.vertragsgenerator.dashboard";
const NOTICE_KEY = "de.smartconex.vertragsgenerator.notice";

interface ConfigValueDashboard {
  collections: string[];
  backgroundUrl?: string;
  quickStart?: ConfigValueQuickStart;
}

export function DashboardRoute(): JSX.Element {
  const tenantId = useTenantId();
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  useDocumentTitle(t("DashboardRoute.pageTitle"));

  const { config } = useLazyLoadQuery<DashboardRouteQuery>(
    $DashboardRouteQuery,
    {
      tenantId,
      keys: [DASHBOARD_KEY, NOTICE_KEY],
    }
  );

  const dashboardConfig = (config || []).find(
    (value) => value?.key === DASHBOARD_KEY
  ) as {
    value: ConfigValueDashboard;
  };

  const noticeConfig = (config || []).find((v) => v?.key === NOTICE_KEY) as {
    value: ConfigValueNotice;
  };

  const backgroundUrl =
    dashboardConfig &&
    dashboardConfig.value &&
    dashboardConfig.value.backgroundUrl
      ? dashboardConfig.value.backgroundUrl
      : undefined;

  return (
    <Column role="main" background="default">
      <ColumnBody>
        <Hero backgroundUrl={backgroundUrl} aspectRatio="12/2" inset fill>
          {!backgroundUrl && <BrandLogo size="lg" centered />}
        </Hero>
        {noticeConfig && noticeConfig.value && (
          <Section title="Hinweis">
            <SectionBody>
              <Callout intent="warning">
                {noticeConfig.value.description}
                {noticeConfig.value && noticeConfig.value.linkUrl && (
                  <>
                    {" "}
                    <a
                      href={noticeConfig.value.linkUrl}
                      className="link--underline"
                      target="_blank"
                    >
                      {noticeConfig.value.linkText}
                    </a>
                  </>
                )}
              </Callout>
            </SectionBody>
          </Section>
        )}
        {dashboardConfig && dashboardConfig.value.collections.length > 0 ? (
          dashboardConfig.value.collections.map((id: string, idx) =>
            idx === 0 && dashboardConfig?.value?.quickStart ? (
              <DashboardCollectionWidget
                key={id}
                collectionId={id}
                start={[
                  <CollectionItemChooserListQuickstartItem
                    key="quickstart"
                    quickstartData={dashboardConfig.value.quickStart}
                  />,
                ]}
              />
            ) : (
              <DashboardCollectionWidget key={id} collectionId={id} />
            )
          )
        ) : (
          <NonIdealState
            header={<TemplatesEmptyIllustration />}
            title={t("DashboardRoute.NonIdealState.title")}
            description={t("DashboardRoute.NonIdealState.description")}
          />
        )}
      </ColumnBody>
    </Column>
  );
}

export interface ConfigValueQuickStart {
  [key: string]: {
    icon?: string;
    title: string;
    url: string;
    buttonLabel: string;
    description: string;
  };
}

export interface ConfigValueNotice {
  description: string;
  linkUrl: string;
  linkText: string;
}

const $DashboardRouteQuery = graphql`
  query DashboardRouteQuery($tenantId: ID!, $keys: [String!]!) {
    config(tenantId: $tenantId, nodeId: $tenantId, keys: $keys) {
      value
      key
    }
  }
`;
