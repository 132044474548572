/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useDocumentTemplateQueryVariables = {
    id: string;
};
export type useDocumentTemplateQueryResponse = {
    readonly documentTemplate: {
        readonly id: string;
        readonly title?: string;
        readonly description?: string | null;
    } | null;
};
export type useDocumentTemplateQuery = {
    readonly response: useDocumentTemplateQueryResponse;
    readonly variables: useDocumentTemplateQueryVariables;
};



/*
query useDocumentTemplateQuery(
  $id: ID!
) {
  documentTemplate: node(id: $id) {
    __typename
    id
    ... on DocumentTemplate {
      title
      description
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            }
        ],
        "type": "DocumentTemplate",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useDocumentTemplateQuery",
            "selections": [
                {
                    "alias": "documentTemplate",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useDocumentTemplateQuery",
            "selections": [
                {
                    "alias": "documentTemplate",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "3b08ce5c82446c94acaec895b62b1020",
            "id": null,
            "metadata": {},
            "name": "useDocumentTemplateQuery",
            "operationKind": "query",
            "text": "query useDocumentTemplateQuery(\n  $id: ID!\n) {\n  documentTemplate: node(id: $id) {\n    __typename\n    id\n    ... on DocumentTemplate {\n      title\n      description\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd4939b879a22cdbb8601382e2614d563';
export default node;
