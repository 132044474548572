import { Button, Card, CardBody, ListView, Stack, Text } from "@workflows/ui";
import * as React from "react";
import { useLazyLoadQuery } from "react-relay";
import { graphql } from "relay-runtime";
import { TenantListQuery } from "~/__graphql__/TenantListQuery.graphql";
import { TenantList_Tenant$data } from "~/__graphql__/TenantList_Tenant.graphql";
import { useSetRecoilState } from "@workflows/runtime-web";
import { appSidebar } from "~/core/App/state";
import { useTranslation } from "react-i18next";

export function TenantList(): JSX.Element {
  const data = useLazyLoadQuery<TenantListQuery>(ViewerQuery, {
    count: 50,
  });
  return (
    <ListView<TenantList_Tenant$data>
      fragment={ViewerQuery_tenants}
      fragmentRef={data.viewer}
      itemFragment={ViewerQuery_tenant}
      component={ListItem}
      edgeKey="tenants"
    />
  );
}

interface ListItemProps {
  item: TenantList_Tenant$data;
}

const ListItem = ({ item }: ListItemProps) => {
  const { t } = useTranslation("ai.workflows.session");
  const showAppSidebar = useSetRecoilState(appSidebar);

  const handleClick = React.useCallback(() => {
    showAppSidebar(true);

    window.location.href = item.host.baseUrl;
  }, [item.host.baseUrl, showAppSidebar]);

  return (
    <Card className="w--full">
      <CardBody>
        <Stack
          direction="horizontal"
          gap="sm"
          align="center"
          justify="between"
          fill
        >
          <div>
            <Text variant="headingLg">{item.name}</Text>
            <Text variant="bodyMd">{item.host.baseUrl}</Text>
          </div>
          <Button onClick={handleClick} intent="default">
            {t("TenantList.launch")}
          </Button>
        </Stack>
      </CardBody>
    </Card>
  );
};

const ViewerQuery = graphql`
  query TenantListQuery($count: Int, $cursor: String) {
    viewer {
      ...TenantList_Tenants @arguments(count: $count, cursor: $cursor)
    }
  }
`;

const ViewerQuery_tenants = graphql`
  fragment TenantList_Tenants on Viewer
  @refetchable(queryName: "TenantListPaginationQuery")
  @argumentDefinitions(
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
  ) {
    tenants(first: $count, after: $cursor)
      @connection(key: "TenantList_tenants") {
      edges {
        node {
          ...TenantList_Tenant
        }
      }
    }
  }
`;

const ViewerQuery_tenant = graphql`
  fragment TenantList_Tenant on Tenant {
    id
    name
    host {
      id
      baseUrl
    }
  }
`;
