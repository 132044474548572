import * as React from "react";
import {
  Callout,
  Link,
  Stack,
  StackItem,
  Text,
  useTenantId,
} from "@workflows/ui";
import { newId } from "@workflows/runtime-web";
import { AccountLayout } from "~/accounts/AccountLayout";
import { useTranslation } from "react-i18next";
import {
  PasswordlessSignInForm,
  PasswordlessSignInFormValues,
} from "~/session/passwordlessSignIn/PasswordlessSignInForm";

export const PasswordlessSignInRoute: React.FC = () => {
  const { t } = useTranslation("ai.workflows.session");
  const tenantId = useTenantId();
  const [isFinished, setIsFinished] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [error, setError] = React.useState(null);

  const handleSubmit = async ({ email }: PasswordlessSignInFormValues) => {
    const result = await fetch("/auth/gateway", {
      credentials: "include",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        clientMutationId: newId(),
        email,
        tenantId,
      }),
    }).then((res) => res.json());

    if (result.success) {
      setEmail(email as string);
      setIsFinished(true);
    }

    if (result.error) {
      setError(result.error);
    }
  };

  const handleReset = () => {
    setIsFinished(false);
    setEmail("");
    setError(null);
  };

  return (
    <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem width="1/3">
          <Stack direction="vertical">
            <Text type="title1">
              {isFinished
                ? t("PasswordlessSignInRoute.checkEmail")
                : t("signIn.label")}
            </Text>

            {isFinished ? (
              <>
                <Text>{t("PasswordlessSignInRoute.emailSent", { email })}</Text>
                <Link
                  to="/"
                  onClick={handleReset}
                  style={{ textDecoration: "underline" }}
                >
                  {t("PasswordlessSignInRoute.differentAccount")}
                </Link>
              </>
            ) : (
              <Text>{t("PasswordlessSignInRoute.enterEmail")}</Text>
            )}

            {error && (
              <Callout intent="critical" className="mb-md">
                {t("form.errors.unspecified")}
              </Callout>
            )}

            {!isFinished && <PasswordlessSignInForm onSubmit={handleSubmit} />}
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>
  );
};
