/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type DocumentElementVisibilityState = "AUTO" | "HIDDEN" | "VISIBLE" | "%future added value";
export type WizardQueryVariables = {
    documentId: string;
};
export type WizardQueryResponse = {
    readonly document: {
        readonly id: string;
        readonly title?: string;
        readonly props?: unknown | null;
        readonly values?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                    readonly type: string;
                    readonly value: unknown | null;
                    readonly identifier: string;
                    readonly props: unknown | null;
                } | null;
            } | null> | null;
        } | null;
        readonly elements?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly path: ReadonlyArray<string>;
                    readonly props: unknown | null;
                    readonly sort: string;
                    readonly visible: {
                        readonly expression: string | null;
                        readonly state: DocumentElementVisibilityState;
                        readonly valueRefs: ReadonlyArray<string>;
                    };
                    readonly type: {
                        readonly identifier: string;
                    };
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type WizardQuery = {
    readonly response: WizardQueryResponse;
    readonly variables: WizardQueryVariables;
};



/*
query WizardQuery(
  $documentId: ID!
) {
  document: node(id: $documentId) {
    __typename
    id
    ... on Document {
      title
      props
      values {
        edges {
          node {
            id
            name
            type
            value
            identifier
            props
          }
        }
      }
      elements {
        edges {
          node {
            id
            path
            props
            sort
            visible {
              expression
              state
              valueRefs
            }
            type {
              identifier
            }
          }
        }
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "documentId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "documentId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "props",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
    } as any, v5 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            (v3 /*: any*/),
            {
                "alias": null,
                "args": null,
                "concreteType": "DocumentValueConnection",
                "kind": "LinkedField",
                "name": "values",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentValueEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "DocumentValue",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "name",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "type",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "value",
                                        "storageKey": null
                                    },
                                    (v4 /*: any*/),
                                    (v3 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "DocumentElementConnection",
                "kind": "LinkedField",
                "name": "elements",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "DocumentElementEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "DocumentElement",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "path",
                                        "storageKey": null
                                    },
                                    (v3 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "sort",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "DocumentElementVisibility",
                                        "kind": "LinkedField",
                                        "name": "visible",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "expression",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "state",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "valueRefs",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "ObjectType",
                                        "kind": "LinkedField",
                                        "name": "type",
                                        "plural": false,
                                        "selections": [
                                            (v4 /*: any*/)
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Document",
        "abstractKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "WizardQuery",
            "selections": [
                {
                    "alias": "document",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "WizardQuery",
            "selections": [
                {
                    "alias": "document",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "191d58d27ca151f0bed1f2416d45b8b5",
            "id": null,
            "metadata": {},
            "name": "WizardQuery",
            "operationKind": "query",
            "text": "query WizardQuery(\n  $documentId: ID!\n) {\n  document: node(id: $documentId) {\n    __typename\n    id\n    ... on Document {\n      title\n      props\n      values {\n        edges {\n          node {\n            id\n            name\n            type\n            value\n            identifier\n            props\n          }\n        }\n      }\n      elements {\n        edges {\n          node {\n            id\n            path\n            props\n            sort\n            visible {\n              expression\n              state\n              valueRefs\n            }\n            type {\n              identifier\n            }\n          }\n        }\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd19f2c4e4938e51eb70d74b6da6f115e';
export default node;
