/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionItemChooserList_collection = {
    readonly name: string;
    readonly items: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly __typename: string;
                readonly " $fragmentRefs": FragmentRefs<"CollectionItemChooserListFileItem_item" | "CollectionItemChooserListWorkflowItem_item">;
            } | null;
        } | null> | null;
    } | null;
    readonly id: string;
    readonly " $refType": "CollectionItemChooserList_collection";
};
export type CollectionItemChooserList_collection$data = CollectionItemChooserList_collection;
export type CollectionItemChooserList_collection$key = {
    readonly " $data"?: CollectionItemChooserList_collection$data;
    readonly " $fragmentRefs": FragmentRefs<"CollectionItemChooserList_collection">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "items"
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 100,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./CollectionItemChooserListPaginationQuery.graphql.ts'),
                "identifierField": "id"
            }
        },
        "name": "CollectionItemChooserList_collection",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": "items",
                "args": null,
                "concreteType": "CollectionItemConnection",
                "kind": "LinkedField",
                "name": "__CollectionItemChooserList_items_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "CollectionItemEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": null,
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CollectionItemChooserListFileItem_item"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "CollectionItemChooserListWorkflowItem_item"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            }
        ],
        "type": "Collection",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '8659024a2797d13bf0a1ee0c57dce8d7';
export default node;
