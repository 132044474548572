import { Route, Switch, useHistory, useParams } from "@workflows/runtime-web";
import {
  Button,
  DotsVerticalIcon,
  DropdownMenu,
  ListAddIllustration,
  NonIdealState,
  Section,
  SectionBody,
  SectionHeader,
  Skeleton,
  Stack,
  TableView,
  TableViewColumn,
  Tag,
  Text,
  useVisibilityToggle,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { AddDocumentsDrawer } from "~/de.agad.dpm/tenants/AddDocumentsDrawer";
import { EditDocumentDrawer } from "~/de.agad.dpm/tenants/EditDocumentDrawer";
import { useDocumentTemplates } from "~/de.agad.dpm/tenants/hooks/useDocumentTemplates";
import { routes } from "../routes";
import { DeleteDocumentTemplateDialog } from "~/de.agad.dpm/tenants/DeleteDocumentTemplateDialog";

export const TenantDocumentsTab = (): JSX.Element => {
  const { tenantId: subtenantId } = useParams<{ tenantId: string }>();

  const documentTemplates = useDocumentTemplates(subtenantId);

  const history = useHistory();

  const handleAddDocument = () => {
    history.push(
      routes["tenant.documents.add"].build({ tenantId: subtenantId })
    );
  };

  const handleEditDocument = (documentTemplateId: string) => {
    history.push(
      routes["tenant.document.edit"].build({
        tenantId: subtenantId,
        documentId: documentTemplateId,
      })
    );
  };

  const [selectedDocumentTemplate, setSelectedDocumentTemplate] =
    React.useState("");
  const [isVisible, show, hide] = useVisibilityToggle();

  const handleDeleteDocument = (documentTemplateId: string) => {
    setSelectedDocumentTemplate(documentTemplateId);
    show();
  };

  const columns = useColumns(handleEditDocument, handleDeleteDocument);
  const { t } = useTranslation("de.agad.dpm");

  return (
    <>
      <Section>
        <SectionHeader title={t("TenantDocumentsTab.title")} />
        <SectionBody>
          <Stack direction={"horizontal"} align="center" justify="between">
            <Text>{t("TenantDocumentsTab.description")}</Text>
            <Button intent="primary" onClick={handleAddDocument} size="sm">
              {t("TenantDocumentsTab.add")}
            </Button>
          </Stack>
        </SectionBody>
      </Section>

      {documentTemplates?.length ? (
        <TableView
          columns={columns}
          data={documentTemplates}
          className={"mt-md"}
        />
      ) : (
        <Stack direction="vertical" fill>
          <NonIdealState
            header={<ListAddIllustration />}
            title={t("TenantDocumentsTab.NonIdealState.title")}
            description={t("TenantDocumentsTab.NonIdealState.description")}
          />
        </Stack>
      )}

      <DeleteDocumentTemplateDialog
        isOpen={isVisible}
        onClose={hide}
        documentTemplateId={selectedDocumentTemplate}
        subtenantId={subtenantId}
      />

      <Switch>
        <Route
          path={routes["tenant.documents.add"].path}
          component={AddDocumentsDrawer}
        />
        <Route
          path={routes["tenant.document.edit"].path}
          component={EditDocumentDrawer}
        />
      </Switch>
    </>
  );
};

function useColumns(
  handleEditDocument: (documentTemplateId: string) => void,
  handleDeleteDocument: (documentTemplateId: string) => void
): TableViewColumn[] {
  const { t } = useTranslation("de.agad.dpm");
  return React.useMemo(
    () => [
      {
        id: "title",
        header: t("TenantDocumentsTab.column.name"),
        accessor: "title",
        width: "50%",
        render: ({ value }: { value: string }) => {
          return <>{value}</>;
        },
      },
      {
        id: "status",
        header: t("TenantDocumentsTab.column.status"),
        accessor: "status",
        width: "15%",
        render: ({
          value: status,
        }: {
          value: { color: string; textColor: string; name: string };
        }) => {
          return status ? (
            <Tag color={status.color} textColor={status.textColor}>
              {status.name}
            </Tag>
          ) : null;
        },
        skeleton: () => (
          <Skeleton>
            <Tag>…</Tag>
          </Skeleton>
        ),
      },
      {
        id: "menu",
        width: "5%",
        render: ({ value }: { value: { id: string } }) => {
          return (
            // TODO: Change DropdownMenu Implementation
            <DropdownMenu
              items={[
                {
                  key: "editDocument",
                  label: t("TenantDocumentsTab.menu.edit"),
                  onClick: () => handleEditDocument(value.id),
                },
                {
                  key: "deleteTimelog",
                  label: t("TenantDocumentsTab.menu.delete"),
                  onClick: () => handleDeleteDocument(value.id),
                },
              ]}
              placement="bottom-end"
            >
              <div>
                <DotsVerticalIcon />
              </div>
            </DropdownMenu>
          );
        },
      },
    ],
    [handleEditDocument, handleDeleteDocument, t]
  );
}
