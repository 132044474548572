import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form2,
  FormControl,
  FormInput,
  Link,
  Stack,
  StackItem,
  TextField,
  TextFieldProps,
  useForm,
} from "@workflows/ui";
import { FormValues } from "@workflows/ui/lib/Form/types";

export interface Props {
  onSubmit: ({ email, password }: FormValues) => void;
  loading: boolean;
}

export const SignInForm: React.FC<Props> = ({ onSubmit, loading }) => {
  const form = useForm("SignInForm");
  const { t } = useTranslation("ai.workflows.session");

  const handleSubmit = () => {
    const values = form.getValues();
    onSubmit?.(values);
  };

  return (
    <Form2
      name={"SignInForm"}
      onSubmit={handleSubmit}
      defaultValues={{ email: "", password: "" }}
    >
      <Stack direction="vertical" gap="lg">
        <FormControl
          htmlFor="email"
          name={"email"}
          label={t("form.fields.email.label")}
        >
          <FormInput<TextFieldProps>
            id="email"
            component={TextField}
            type="text"
            name="email"
            placeholder={t("form.fields.email.placeholder")}
          />
        </FormControl>
        <FormControl
          htmlFor="password"
          name={"password"}
          label={t("form.fields.password.label")}
        >
          <FormInput<TextFieldProps>
            id="password"
            component={TextField}
            type="password"
            name="password"
            placeholder={t("form.fields.password.placeholder")}
          />
        </FormControl>
        <Button
          intent="primary"
          onClick={handleSubmit}
          fill
          isLoading={loading}
          type="submit"
        >
          {t("signIn.label")}
        </Button>
        <StackItem align="center">
          <Link to="/recover-password">{t("SignInForm.link.password")}</Link>
        </StackItem>
      </Stack>
    </Form2>
  );
};
