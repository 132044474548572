import {
  format as formatDate,
  formatDistance,
  isValid,
  parseISO,
} from "date-fns";
import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import i18next, { FormatFunction } from "i18next";
import React from "react";

export type Noun = Record<"plural" | "singular", string>;

export const locales: Record<string, Locale> = {
  de: de,
  en: en,
};

export const format: FormatFunction = (value, rawFormat = "", lng = "en") => {
  const [format, ...additionalValues] = rawFormat
    .split(", ")
    .map((v) => v.trim());

  switch (format) {
    case "money":
      return Intl.NumberFormat(lng, {
        style: "currency",
        currency: additionalValues[0],
      }).format(value);
    case "date": {
      const val = typeof value === "string" ? parseISO(value) : value;
      return isValid(val) ? formatDate(val, additionalValues[0]) : null;
    }
    case "enum":
      return Array.isArray(value.values) ? value.val : value.values[value.val];
    case "float":
      return String(value).replace(".", additionalValues[0]);
    case "distance":
      return formatDistance(new Date(value), new Date(), {
        locale: locales[lng],
        addSuffix: true,
      });
    case "floatOrIntWithNumerus":
      if (value.val && value.noun) {
        return toNumerus({
          count: value.val,
          noun: value.noun,
          ...(additionalValues[0] && {
            formatedCount: String(value.val).replace(".", additionalValues[0]),
          }),
        });
      }
      return additionalValues[0]
        ? String(value).replace(".", additionalValues[0])
        : String(value);
    case "string":
    default:
      return String(value);
  }
};

export function getNumerus(count: number): keyof Noun {
  switch (i18next.services.pluralResolver.getSuffix(i18next.language, count)) {
    case "_plural":
      return "plural";
    default:
      return "singular";
  }
}

export function toNumerus({
  count,
  noun,
  formattedCount,
}: {
  count: number;
  noun: Noun;
  formattedCount?: string;
}): string {
  const numerus = noun?.[getNumerus(count)];

  return numerus.indexOf("{count}") !== -1
    ? numerus.replace("{count}", formattedCount ?? String(count))
    : `${formattedCount ?? count} ${numerus}`;
}

export function flattenChildren(
  target: React.ReactNode[],
  children: React.ReactNode
): void {
  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === React.Fragment) {
        flattenChildren(target, child.props.children);
      } else {
        target.push(child);
      }
    }
  });
}
