import * as React from "react";
import { hasValidSessionCookie, signOut } from "~/core/auth";

export function AccountTokenChecker(): null {
  React.useEffect(() => {
    const sessionControlInterval = setInterval(async () => {
      const isSessionValid = await hasValidSessionCookie();

      if (!isSessionValid) {
        signOut();
      }
    }, 60000);

    return () => clearInterval(sessionControlInterval);
  }, []);

  return null;
}
