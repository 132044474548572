import {
  Callout,
  CalloutProps,
  Link,
  Stack,
  StackItem,
  Text,
  useDocumentTitle,
} from "@workflows/ui";
import { FormValues } from "@workflows/ui/lib/Form/types";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { AccountLayout } from "~/accounts/AccountLayout";
import { SignInForm } from "./SignInForm";
import { ConfigValueSignup, useConfigValue } from "~/core/configValues";

type ConfigValueCallout = { intent: CalloutProps["intent"]; text: string };

export const SignInRoute: React.FC = () => {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { t } = useTranslation("ai.workflows.session");

  useDocumentTitle(t("signIn.pageTitle"));

  const onSubmit = async (values: FormValues) => {
    try {
      setLoading(true);
      const response = await fetch("/auth/local", {
        credentials: "include",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
        }),
      });

      const result = await response.json();

      if (["InvalidCredentials", "InvalidAuthProvider"].includes(result.code)) {
        showErrorMessage(result.code);
      }
      if (result.success) {
        window.location.href = result.redirectUri || "/";
      }
    } catch (err) {
      showErrorMessage("InternalError");
    }
  };

  const showErrorMessage = (code: string) => {
    setError(code);
    setLoading(false);
  };

  const calloutConfig = useConfigValue<ConfigValueCallout>(
    "ai.workflows.tenants.config.signupCallout"
  );
  const signupConfig = useConfigValue<ConfigValueSignup>(
    "ai.workflows.tenants.config.signup"
  );

  return (
    <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{ maxWidth: "30rem" }}>
          <Stack direction="vertical">
            <Text type="title1">{t("signIn.label")}</Text>

            {signupConfig?.enabled ? (
              <Text>
                <Trans i18nKey="SignInRoute.sign-up" t={t}>
                  Or{" "}
                  <Link to="/sign-up" style={{ textDecoration: "underline" }}>
                    register a new account.
                  </Link>{" "}
                </Trans>
              </Text>
            ) : null}

            {calloutConfig && (
              <Callout intent={calloutConfig.intent}>
                {calloutConfig.text}
              </Callout>
            )}

            {error && (
              <Callout intent="critical" className="mb-md">
                {t([`form.errors.${error}`, "form.errors.unspecified"])}
              </Callout>
            )}
            <SignInForm onSubmit={onSubmit} loading={loading} />
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>
  );
};
