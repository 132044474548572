/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateFeedbackInput = {
    clientMutationId: string;
    email?: string | null;
    feedbackChannelId: string;
    tenantId: string;
    text: string;
};
export type useCreateFeedbackMutationVariables = {
    input: CreateFeedbackInput;
};
export type useCreateFeedbackMutationResponse = {
    readonly createFeedback: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type useCreateFeedbackMutation = {
    readonly response: useCreateFeedbackMutationResponse;
    readonly variables: useCreateFeedbackMutationVariables;
};



/*
mutation useCreateFeedbackMutation(
  $input: CreateFeedbackInput!
) {
  createFeedback(input: $input) {
    errors {
      code
      path
      message
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateFeedbackPayload",
            "kind": "LinkedField",
            "name": "createFeedback",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useCreateFeedbackMutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useCreateFeedbackMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "9fd327f6604a64f4aeefc854045da084",
            "id": null,
            "metadata": {},
            "name": "useCreateFeedbackMutation",
            "operationKind": "mutation",
            "text": "mutation useCreateFeedbackMutation(\n  $input: CreateFeedbackInput!\n) {\n  createFeedback(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '8dc64eaaa3969a0f75f9733dc032967f';
export default node;
