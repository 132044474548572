import { Column, ColumnBody, ColumnHeader, Skeleton } from "@workflows/ui";
import * as React from "react";

export function MatterColumnSkeleton(): JSX.Element {
  return (
    <Column role="main">
      <ColumnHeader title={<Skeleton />} borderless />
      <ColumnBody>
        <Skeleton width={56} />
      </ColumnBody>
    </Column>
  );
}
