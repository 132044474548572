import { ExtractNode, getEdgeNodes, toTrees } from "@workflows/runtime-web";
import { icons, MenuSize, Nav as BaseNav } from "@workflows/ui";
import * as React from "react";
import { graphql, useFragment } from "react-relay/hooks";
import {
  CoreNav_nav,
  CoreNav_nav$key,
} from "~/__graphql__/CoreNav_nav.graphql";

export interface CoreNavProps {
  nav: CoreNav_nav$key;
  size?: MenuSize;
  onClick: () => void;
}

export function CoreNav(props: CoreNavProps): JSX.Element {
  const { nav, viewerPermissions } = useFragment(CoreNav_nav, props.nav);
  const edges = getEdgeNodes(nav?.items || null);
  const trees = toTrees(edges);

  // TODO: This is a hotfix for filtering the nav items based on the permissions
  // of the tenant.
  const mapping: Record<string, string[]> = {
    "de.perconex.timeTracking.navs.item:approvals": [
      "de.perconex.timeTracking.approvals:list",
    ],
    "de.perconex.timeTracking.navs.item:projects": [
      "de.perconex.timeTracking.projects:list",
    ],
    "ai.workflows.navs.item.admin:users": ["ai.workflows.users:list"],
  };

  return (
    <BaseNav size={props.size || "xs"}>
      {trees.map((nav) => {
        if (
          nav.identifier &&
          Object.prototype.hasOwnProperty.call(mapping, nav.identifier) &&
          !mapping[nav.identifier].some((p) => viewerPermissions.includes(p))
        ) {
          return;
        }
        if (nav.route || nav.externalUrl) {
          return (
            <CoreNavItem item={nav} key={nav.id} onClick={props.onClick} />
          );
        }

        return (
          <BaseNav.Section key={nav.id} label={nav.name}>
            {(nav.props?.children || []).map((item) => (
              <CoreNavItem item={item} key={item.id} onClick={props.onClick} />
            ))}
          </BaseNav.Section>
        );
      })}
    </BaseNav>
  );
}

export interface CoreNavItemProps {
  item: ExtractNode<NonNullable<NonNullable<CoreNav_nav["nav"]>["items"]>>;
  onClick: () => void;
}

const CoreNavItem = ({ item, onClick }: CoreNavItemProps) => {
  const icon =
    item.icon && icons[item.icon]
      ? React.createElement(icons[item.icon])
      : null;

  if (item.externalUrl) {
    return (
      <BaseNav.Item
        key={item.id}
        href={item.externalUrl}
        label={item.name}
        icon={icon}
        onClick={onClick}
      />
    );
  }

  return (
    <BaseNav.Item
      key={item.id}
      to={item.route ? item.route.path : ""}
      label={item.name}
      icon={icon}
      exact={!item.route || item.route.path.length < 2}
      onClick={onClick}
    />
  );
};

// TODO: Handle pagination
const CoreNav_nav = graphql`
  fragment CoreNav_nav on Tenant {
    viewerPermissions
    nav(identifier: "ai.workflows.nav.main") {
      items {
        edges {
          node {
            id
            name
            sort
            path
            icon
            identifier
            externalUrl
            route {
              path
            }
          }
        }
      }
    }
  }
`;
