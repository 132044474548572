import { newId, useHistory } from "@workflows/runtime-web";
import {
  Card,
  CardBody,
  CardHeader,
  Stack,
  StackItem,
  StatusPicker,
  Text,
  Truncate,
  useTenantId,
  useToast,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "~/de.smartconex.vertragsgenerator/routes";
import {
  UseDashboardData,
  UseDashboardDataQueryResponseDocuments,
} from "./hooks/useDashboardData";
import { useDashboardUpdateDocument } from "./hooks/useDashboardUpdateDocument";

interface ProcessingActivitiesDashboardItemProps {
  document: UseDashboardDataQueryResponseDocuments;
  statuses: UseDashboardData["statuses"];
}

export function ProcessingActivitiesDashboardItem({
  document,
  statuses,
}: ProcessingActivitiesDashboardItemProps): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");
  const tenantId = useTenantId();
  const { updateDocument } = useDashboardUpdateDocument();
  const [addToast] = useToast();

  const history = useHistory();
  const handleOpen = () => {
    history.push(routes["wizard.index"].build({ documentId: document.id }));
  };

  const handleSelect = (statusIdentifier: string) => {
    updateDocument({
      input: { statusIdentifier, tenantId, documentId: document.id },
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.updateDocument?.errors) {
          addToast({
            id: newId(),
            message: t("ProcessingActivitiesDashboardItem.error"),
            intent: "warning",
          });
        }
      },
    });
  };

  return (
    <Card interactive onClick={handleOpen} tint={document.color || undefined}>
      <CardHeader>
        <Stack direction="horizontal" justify="between">
          <StackItem className="md:w-4/6">
            <Truncate lines={2}>
              <Text variant="headingMd" className="break-all">
                {document.title}
              </Text>
            </Truncate>
          </StackItem>
          <StackItem className="whitespace--no-wrap">
            <StatusPicker
              currentStatus={document.status}
              statuses={statuses}
              title={t("ProcessingActivitiesDashboardItem.statusPicker.title")}
              onSelect={handleSelect}
            />
          </StackItem>
        </Stack>
      </CardHeader>
      {!!document.description && (
        <CardBody>
          <Text color="muted">
            <Truncate lines={6}>{document.description}</Truncate>
          </Text>
        </CardBody>
      )}
    </Card>
  );
}
