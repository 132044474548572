/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DocumentsColumnQueryVariables = {
    tenantId: string;
};
export type DocumentsColumnQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"DocumentsList_documents">;
};
export type DocumentsColumnQuery = {
    readonly response: DocumentsColumnQueryResponse;
    readonly variables: DocumentsColumnQueryVariables;
};



/*
query DocumentsColumnQuery(
  $tenantId: ID!
) {
  ...DocumentsList_documents_1GYzvY
}

fragment DocumentsList_documents_1GYzvY on RootQueryType {
  documents: nodes(tenantId: $tenantId, types: ["ai.workflows.documents.document"], first: 9999) {
    edges {
      cursor
      node {
        __typename
        id
        ... on HasFiles {
          __isHasFiles: __typename
          files {
            edges {
              cursor
            }
          }
        }
        ... on Document {
          title
          insertedAt
          color
          props
          template {
            id
            title
          }
          ...Status_status
        }
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment Status_status on HasStatus {
  __isHasStatus: __typename
  status {
    name
    textColor
    color
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 9999
        } as any,
        (v1 /*: any*/),
        {
            "kind": "Literal",
            "name": "types",
            "value": [
                "ai.workflows.documents.document"
            ]
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentsColumnQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "DocumentsList_documents"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "DocumentsColumnQuery",
            "selections": [
                {
                    "alias": "documents",
                    "args": (v2 /*: any*/),
                    "concreteType": "NodeConnection",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "NodeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "FileConnection",
                                                    "kind": "LinkedField",
                                                    "name": "files",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "FileEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "HasFiles",
                                            "abstractKey": "__isHasFiles"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                (v5 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "insertedAt",
                                                    "storageKey": null
                                                },
                                                (v6 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "props",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "DocumentTemplate",
                                                    "kind": "LinkedField",
                                                    "name": "template",
                                                    "plural": false,
                                                    "selections": [
                                                        (v4 /*: any*/),
                                                        (v5 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "kind": "InlineFragment",
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "Status",
                                                            "kind": "LinkedField",
                                                            "name": "status",
                                                            "plural": false,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "name",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "textColor",
                                                                    "storageKey": null
                                                                },
                                                                (v6 /*: any*/),
                                                                (v4 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "type": "HasStatus",
                                                    "abstractKey": "__isHasStatus"
                                                }
                                            ],
                                            "type": "Document",
                                            "abstractKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "documents",
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId",
                        "types"
                    ],
                    "handle": "connection",
                    "key": "DocumentsList_documents",
                    "kind": "LinkedHandle",
                    "name": "nodes"
                }
            ]
        },
        "params": {
            "cacheID": "0db83d018724c02bed59e33c836a636e",
            "id": null,
            "metadata": {},
            "name": "DocumentsColumnQuery",
            "operationKind": "query",
            "text": "query DocumentsColumnQuery(\n  $tenantId: ID!\n) {\n  ...DocumentsList_documents_1GYzvY\n}\n\nfragment DocumentsList_documents_1GYzvY on RootQueryType {\n  documents: nodes(tenantId: $tenantId, types: [\"ai.workflows.documents.document\"], first: 9999) {\n    edges {\n      cursor\n      node {\n        __typename\n        id\n        ... on HasFiles {\n          __isHasFiles: __typename\n          files {\n            edges {\n              cursor\n            }\n          }\n        }\n        ... on Document {\n          title\n          insertedAt\n          color\n          props\n          template {\n            id\n            title\n          }\n          ...Status_status\n        }\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Status_status on HasStatus {\n  __isHasStatus: __typename\n  status {\n    name\n    textColor\n    color\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd5cb53f7829356e0907f538ca5c6613f';
export default node;
