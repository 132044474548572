import { getEdgeNodes, newId, useHistory } from "@workflows/runtime-web";
import {
  Button,
  Callout,
  Form2,
  FormControl,
  FormInput,
  ListPicker2,
  ListPicker2Props,
  Stack,
  TextField,
  TextFieldProps,
  useForm,
  useToast,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useMutation } from "react-relay/hooks";
import { tenantId } from "~/client/config";
import { Error404 } from "~/core/Error404";
import { UserFormQuery_assignableRole } from "~/__graphql__/UserFormQuery_assignableRole.graphql";
import {
  AdminUpdateUserInput,
  UserFormUpdateUserMutation,
} from "~/__graphql__/UserFormUpdateUserMutation.graphql";
import { UsersEditorColumnQueryResponse } from "~/__graphql__/UsersEditorColumnQuery.graphql";
import { routes } from "../routes";

export type Values = Pick<AdminUpdateUserInput, "name" | "tenantRoleIds">;

export interface UserFormProps {
  data: UsersEditorColumnQueryResponse;
}

export const UserForm: React.FC<UserFormProps> = ({ data }) => {
  const { t } = useTranslation("ai.workflows.admin");
  const form = useForm("UserForm");

  const [error, setError] = React.useState<string | undefined>();

  const [addToast] = useToast();
  const history = useHistory();

  const [updateUser, isPending] =
    useMutation<UserFormUpdateUserMutation>(updateUserMutation);

  const defaultValues = React.useMemo(
    () => ({
      name: data.user?.name,
      email: data.user?.email,
      tenantRoleIds: getEdgeNodes(data.user?.assignedRoles || null).map(
        (role) => role.id
      ),
    }),
    [data.user]
  );

  const handleSubmit = React.useCallback(
    (form) => {
      const values: Values = form.getValues();

      if (!data?.user?.id) return;

      const input = {
        userId: data.user?.id,
        clientMutationId: newId(),
        name: values?.name,
        tenantRoleIds: values?.tenantRoleIds,
        tenantId,
      };

      updateUser({
        variables: { input, objectId: tenantId },
        onError(error) {
          console.error(error);
          setError(t("UserForm.errors.unspecified"));
        },
        onCompleted(data) {
          if (data.adminUpdateUser?.errors) {
            console.error(data.adminUpdateUser?.errors);
            setError(t("UserForm.errors.unspecified"));
          } else {
            history.replace(routes["users.index"].build());
            addToast({
              id: newId(),
              message: t("UserForm.messages.updated"),
              intent: "success",
            });
          }
        },
      });
    },
    [addToast, data.user, history, t, updateUser]
  );

  React.useEffect(() => {
    form.reset(defaultValues);
  }, [defaultValues, form]);

  if (!data) {
    return <Error404 />;
  }

  const render: ListPicker2Props<UserFormQuery_assignableRole>["renderItem"] =
    ({ item }) => {
      return <div>{item.name && t(`UserForm.roles.${item.name}`)}</div>;
    };

  return (
    <>
      {error && <Callout intent="critical">{error}</Callout>}

      <Form2
        name="UserForm"
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      >
        <FormControl label={t("UserForm.form.name")} name="name">
          <FormInput<TextFieldProps>
            autoFocus
            component={TextField}
            name="name"
            required
          />
        </FormControl>

        <FormControl label={t("UserForm.form.roles")} name="tenantRoleIds">
          <FormInput<ListPicker2Props<UserFormQuery_assignableRole>>
            component={ListPicker2}
            edgeKey="assignableRoles"
            fragment={assignableRolesFragment}
            fragmentRef={data.tenant}
            itemFragment={assignableRoleFragment}
            renderItem={render}
            name="tenantRoleIds"
          />
        </FormControl>

        <Stack direction="horizontal">
          <Button
            onClick={form.submit}
            intent="primary"
            type="submit"
            fill
            isLoading={isPending}
          >
            {t("UserForm.update")}
          </Button>
        </Stack>
      </Form2>
    </>
  );
};

const assignableRolesFragment = graphql`
  fragment UserFormQuery_assignableRoles on Tenant
  @refetchable(queryName: "UserFormPaginationQuery")
  @argumentDefinitions(
    tenantId: { type: "ID!" }
    count: { type: "Int", defaultValue: 100 }
    cursor: { type: "String" }
    typeIdentifier: { type: "String!" }
  ) {
    assignableRoles(
      typeIdentifier: $typeIdentifier
      first: $count
      after: $cursor
    ) @connection(key: "UserFormQuery_assignableRoles") {
      edges {
        cursor
        node {
          ...UserFormQuery_assignableRole
        }
      }
    }
  }
`;

const assignableRoleFragment = graphql`
  fragment UserFormQuery_assignableRole on Role {
    id
    name
    identifier
  }
`;

const updateUserMutation = graphql`
  mutation UserFormUpdateUserMutation(
    $input: AdminUpdateUserInput!
    $objectId: ID!
  ) {
    adminUpdateUser(input: $input) {
      clientMutationId
      user {
        id
        name
        email
        insertedAt
        assignedRoles(objectId: $objectId) {
          edges {
            node {
              id
              identifier
              name
            }
          }
        }
      }
      errors {
        code
        path
        message
        type
      }
    }
  }
`;
