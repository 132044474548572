import { ThemeContext } from "@workflows/ui";
import * as React from "react";
import { Theme, theme } from "~/client/config";

export interface CoreThemeProps {
  children?: React.ReactNode;
}

export function CoreTheme({ children }: CoreThemeProps): JSX.Element {
  const currentTheme = React.useMemo<Theme>(
    () =>
      theme || {
        logo: {
          lg: "/logo.svg",
          xs: "/logo.svg",
        },
        favicon: "/favicon.ico",
      },
    []
  );

  return <ThemeContext theme={currentTheme}>{children}</ThemeContext>;
}
