/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AdminUpdateUserInput = {
    clientMutationId: string;
    name?: string | null;
    tenantId: string;
    tenantRoleIds: Array<string | null>;
    userId: string;
};
export type UserFormUpdateUserMutationVariables = {
    input: AdminUpdateUserInput;
    objectId: string;
};
export type UserFormUpdateUserMutationResponse = {
    readonly adminUpdateUser: {
        readonly clientMutationId: string;
        readonly user: {
            readonly id: string;
            readonly name: string;
            readonly email: string;
            readonly insertedAt: string;
            readonly assignedRoles: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly id: string;
                        readonly identifier: string | null;
                        readonly name: string | null;
                    } | null;
                } | null> | null;
            } | null;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
    } | null;
};
export type UserFormUpdateUserMutation = {
    readonly response: UserFormUpdateUserMutationResponse;
    readonly variables: UserFormUpdateUserMutationVariables;
};



/*
mutation UserFormUpdateUserMutation(
  $input: AdminUpdateUserInput!
  $objectId: ID!
) {
  adminUpdateUser(input: $input) {
    clientMutationId
    user {
      id
      name
      email
      insertedAt
      assignedRoles(objectId: $objectId) {
        edges {
          node {
            id
            identifier
            name
          }
        }
      }
    }
    errors {
      code
      path
      message
      type
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "objectId"
        } as any
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v3 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "AdminUpdateUserPayload",
            "kind": "LinkedField",
            "name": "adminUpdateUser",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                        (v1 /*: any*/),
                        (v2 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "insertedAt",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "objectId",
                                    "variableName": "objectId"
                                }
                            ],
                            "concreteType": "RoleConnection",
                            "kind": "LinkedField",
                            "name": "assignedRoles",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "RoleEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Role",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                (v1 /*: any*/),
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "identifier",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "UserFormUpdateUserMutation",
            "selections": (v3 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "UserFormUpdateUserMutation",
            "selections": (v3 /*: any*/)
        },
        "params": {
            "cacheID": "b14425abd917c2c2a5416a82ec29f9f0",
            "id": null,
            "metadata": {},
            "name": "UserFormUpdateUserMutation",
            "operationKind": "mutation",
            "text": "mutation UserFormUpdateUserMutation(\n  $input: AdminUpdateUserInput!\n  $objectId: ID!\n) {\n  adminUpdateUser(input: $input) {\n    clientMutationId\n    user {\n      id\n      name\n      email\n      insertedAt\n      assignedRoles(objectId: $objectId) {\n        edges {\n          node {\n            id\n            identifier\n            name\n          }\n        }\n      }\n    }\n    errors {\n      code\n      path\n      message\n      type\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'dbc27d8ee43fb864de50f439c7e7a4a2';
export default node;
