import { ErrorNotFoundIllustration, NonIdealState } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const Error404: React.FC = () => {
  const { t } = useTranslation("ai.workflows.core");

  return (
    <NonIdealState
      title={t("error.404.title")}
      description={t("error.404.description")}
      header={<ErrorNotFoundIllustration />}
    />
  );
};
