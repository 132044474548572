import {
  RouteComponentProps,
  useEntryPointLoader,
  useRelayEnvironment,
} from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import * as React from "react";
import { EntryPointContainer } from "react-relay/hooks";

// export interface CoreEntryPointRouteContainerProps<T extends EntryPoint> extends UseEntryPointLoaderHookType<T> {
//   entryPointRef: PreloadedEntryPoint<GetEntryPointComponentFromEntryPoint<T>> | null | undefined;
//   loadEntryPoint: (GetEntryPointParamsFromEntryPoint<T>) => void;
//   disposeEntryPoint: DisposeFn;
//   routeProps: RouteComponentProps;
// }

// TODO: There are some problems with the type def of EntryPointContainer
const EntryPointContainerTypeless = EntryPointContainer as any;

export interface CoreEntryPointRouteContainerProps {
  entrypoint: any;
  routeProps: RouteComponentProps;
}

export function CoreEntryPointRouteContainer({
  entrypoint,
  routeProps,
}: CoreEntryPointRouteContainerProps): JSX.Element | null {
  const tenantId = useTenantId();
  const relay = useRelayEnvironment();

  const environmentProvider = {
    getEnvironment() {
      return relay;
    },
  };

  const [entryPointRef, loadEntryPoint, disposeEntryPoint] =
    useEntryPointLoader(environmentProvider, entrypoint);

  React.useEffect(() => {
    // TODO: Add more info to the globals key
    loadEntryPoint({ params: routeProps.match.params, globals: { tenantId } });
    return () => disposeEntryPoint();
  }, [entrypoint.root.getModuleId()]);

  return entryPointRef ? (
    <EntryPointContainerTypeless
      entryPointReference={entryPointRef}
      props={routeProps}
    />
  ) : null;
}
