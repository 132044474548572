import { ErrorGenericIllustration, NonIdealState } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";

export const Error500: React.FC = () => {
  const { t } = useTranslation("ai.workflows.core");

  // TODO: Replace with correct illustration
  return (
    <NonIdealState
      title={t("error.500.title")}
      description={t("error.500.description")}
      header={<ErrorGenericIllustration />}
    />
  );
};
