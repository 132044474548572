import { Route, Switch } from "@workflows/runtime-web";
import {
  Column,
  ColumnBody,
  ColumnHeader,
  Columns,
  FormContext,
} from "@workflows/ui";
import * as React from "react";
import { Error500 } from "~/core/Error500";
import { useRouter } from "~/core/useRouter";
import { FlowComponent } from "~/flows/types";
import { AppDokgeneratorWorkflowsEmploymentContractv1DetailsStep } from "./AppDokgeneratorWorkflowsEmploymentContractv1DetailsStep";
import { AppDokgeneratorWorkflowsEmploymentContractv1TypeStep } from "./AppDokgeneratorWorkflowsEmploymentContractv1TypeStep";

export type AppDokgeneratorWorkflowsEmploymentContractv1Props = FlowComponent<{
  collectionId: string;
}>;

export function AppDokgeneratorWorkflowsEmploymentContractv1({
  workflow,
}: AppDokgeneratorWorkflowsEmploymentContractv1Props): JSX.Element {
  const { baseUrl } = useRouter();

  if (!workflow.config.collectionId) {
    return <Error500 />;
  }

  return (
    <FormContext name="AppDokgeneratorWorkflowsEmploymentContractv1">
      <Column role="main" focus="primary">
        <ColumnHeader>Neuen Arbeitsvertrag erstellen</ColumnHeader>
        <ColumnBody scrollable={false}>
          <Columns>
            <Switch>
              <Route
                path={`${baseUrl}/type`}
                render={() => (
                  <AppDokgeneratorWorkflowsEmploymentContractv1TypeStep
                    collectionId={workflow.config.collectionId}
                  />
                )}
              />
              <Route
                component={
                  AppDokgeneratorWorkflowsEmploymentContractv1DetailsStep
                }
              />
            </Switch>
          </Columns>
        </ColumnBody>
      </Column>
    </FormContext>
  );
}
