import { InboxIcon, PlusIcon, Stack, StackItem } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "~/matters/routes";
import { DashboardShortcut } from "./DashboardShortcut";

export const DashboardShortcuts: React.FC = () => {
  const { t } = useTranslation("ai.workflows.dashboard");
  return (
    <Stack direction="horizontal" gap="xs" className="py-md">
      <StackItem className="w-2/4">
        <DashboardShortcut
          tint="#ffa400"
          icon={PlusIcon}
          to={routes["matter.new"].path}
        >
          {t("DashboardShortcuts.newMatter")}
        </DashboardShortcut>
      </StackItem>
      <StackItem className="w-2/4">
        <DashboardShortcut
          tint="#00c4ff"
          icon={InboxIcon}
          to={routes["matters.index"].path}
        >
          {t("DashboardShortcuts.mattersIndex")}
        </DashboardShortcut>
      </StackItem>
    </Stack>
  );
};
