import * as React from "react";
import { useTranslation } from "react-i18next";
import {
  Button,
  Form2,
  FormControl,
  FormInput,
  TextField,
  TextFieldProps,
  useForm,
} from "@workflows/ui";

export interface PasswordlessSignInFormValues {
  email: string;
}

interface Props {
  onSubmit: ({ email }: PasswordlessSignInFormValues) => void;
}

export const PasswordlessSignInForm: React.FC<Props> = ({ onSubmit }) => {
  const { t } = useTranslation("ai.workflows.session");
  const form = useForm("PasswordlessSignInForm");

  const handleSubmit = () => {
    const values = form.getValues() as PasswordlessSignInFormValues;
    onSubmit(values);
  };

  return (
    <Form2 name="PasswordlessSignInForm" onSubmit={handleSubmit}>
      <FormControl htmlFor="email" label={t("form.fields.email.label")}>
        <FormInput<TextFieldProps>
          autoFocus
          component={TextField}
          id="email"
          name="email"
          placeholder={t("form.fields.email.placeholder")}
          required
          type="email"
        />
      </FormControl>

      <Button onClick={form.submit} fill intent="primary" type="submit">
        {t("PasswordlessSignInRoute.continue")}
      </Button>
    </Form2>
  );
};
