/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MatterTimelineItemThreadMessageFragment = {
    readonly creator: {
        readonly name: string;
    } | null;
    readonly id: string;
    readonly bodyText: string;
    readonly insertedAt: string;
    readonly " $refType": "MatterTimelineItemThreadMessageFragment";
};
export type MatterTimelineItemThreadMessageFragment$data = MatterTimelineItemThreadMessageFragment;
export type MatterTimelineItemThreadMessageFragment$key = {
    readonly " $data"?: MatterTimelineItemThreadMessageFragment$data;
    readonly " $fragmentRefs": FragmentRefs<"MatterTimelineItemThreadMessageFragment">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "MatterTimelineItemThreadMessageFragment",
    "selections": [
        {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "creator",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                }
            ],
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bodyText",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "insertedAt",
            "storageKey": null
        }
    ],
    "type": "ThreadMessage",
    "abstractKey": null
} as any;
(node as any).hash = 'd32a83e2de871f5503efefef055f488e';
export default node;
