import { useTenantId } from "@workflows/ui";
import {
  getEdgeNodes,
  graphql,
  useLazyLoadQuery,
} from "@workflows/runtime-web";
import { useFeedbackChannelsQuery } from "~/__graphql__/useFeedbackChannelsQuery.graphql";

interface FeedbackChannel {
  id: string;
  title: string;
  description: string | null;
}

export const useFeedbackChannels = (): FeedbackChannel[] => {
  const tenantId = useTenantId();
  const data = useLazyLoadQuery<useFeedbackChannelsQuery>(
    useFeedbackChannelQuery,
    {
      tenantId,
    }
  );

  return getEdgeNodes(data.feedbackChannels);
};

const useFeedbackChannelQuery = graphql`
  query useFeedbackChannelsQuery($tenantId: ID!) {
    feedbackChannels(tenantId: $tenantId) {
      edges {
        node {
          id
          title
          description
        }
      }
    }
  }
`;
