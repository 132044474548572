/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type TenantStateEnum = "ACTIVE" | "DELETED" | "%future added value";
export type DeAgadDpmCreateSubtenantInput = {
    clientMutationId: string;
    entities?: Array<EntityInput | null> | null;
    host: string;
    name: string;
    parentId: string;
    preferences?: TenantPreferences | null;
    state?: TenantStateEnum | null;
};
export type EntityInput = {
    administrativeArea?: string | null;
    email?: string | null;
    identifier?: string | null;
    locality?: string | null;
    name?: string | null;
    position?: string | null;
    postalCode?: string | null;
    postalCountry?: string | null;
    streetAddress?: string | null;
    type?: string | null;
};
export type TenantPreferences = {
    languages?: LanguagesPreference | null;
};
export type LanguagesPreference = {
    available?: Array<string> | null;
    default: string;
};
export type useDeAgadDpmCreateSubtenantMutationVariables = {
    connections: Array<string>;
    input: DeAgadDpmCreateSubtenantInput;
};
export type useDeAgadDpmCreateSubtenantMutationResponse = {
    readonly deAgadDpmCreateSubtenant: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly tenant: {
            readonly id: string;
            readonly name: string;
            readonly insertedAt: string;
        } | null;
    } | null;
};
export type useDeAgadDpmCreateSubtenantMutation = {
    readonly response: useDeAgadDpmCreateSubtenantMutationResponse;
    readonly variables: useDeAgadDpmCreateSubtenantMutationVariables;
};



/*
mutation useDeAgadDpmCreateSubtenantMutation(
  $input: DeAgadDpmCreateSubtenantInput!
) {
  deAgadDpmCreateSubtenant(input: $input) {
    errors {
      code
      path
      message
      type
    }
    tenant {
      id
      name
      insertedAt
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "connections"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "type",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useDeAgadDpmCreateSubtenantMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeAgadDpmCreateSubtenantPayload",
                    "kind": "LinkedField",
                    "name": "deAgadDpmCreateSubtenant",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useDeAgadDpmCreateSubtenantMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "DeAgadDpmCreateSubtenantPayload",
                    "kind": "LinkedField",
                    "name": "deAgadDpmCreateSubtenant",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "prependNode",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "tenant",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connections"
                                },
                                {
                                    "kind": "Literal",
                                    "name": "edgeTypeName",
                                    "value": "TenantEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "3400839e2e1dcf1b6c67cd0ec8cbd718",
            "id": null,
            "metadata": {},
            "name": "useDeAgadDpmCreateSubtenantMutation",
            "operationKind": "mutation",
            "text": "mutation useDeAgadDpmCreateSubtenantMutation(\n  $input: DeAgadDpmCreateSubtenantInput!\n) {\n  deAgadDpmCreateSubtenant(input: $input) {\n    errors {\n      code\n      path\n      message\n      type\n    }\n    tenant {\n      id\n      name\n      insertedAt\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'd76c00d69e564b39186bc60de20fa3c6';
export default node;
