import {
  useDeleteDocumentTemplateMutation,
  useDeleteDocumentTemplateMutationResponse,
} from "~/__graphql__/useDeleteDocumentTemplateMutation.graphql";
import { newId, useMutation } from "@workflows/runtime-web";
import { graphql } from "react-relay/hooks";
import { useTenantId } from "@workflows/ui";
import { ConnectionHandler } from "relay-runtime";

interface useDeleteDocumentTemplateProps {
  onError: (error: Error) => void;
  onCompleted: (data: useDeleteDocumentTemplateMutationResponse) => void;
  input: {
    documentTemplateId: string;
    subtenantId: string;
  };
}

interface useDeleteDocumentTemplateValue {
  deleteDocumentTemplate: (props: useDeleteDocumentTemplateProps) => void;
  isPending: boolean;
}

export const useDeleteDocumentTemplate = (): useDeleteDocumentTemplateValue => {
  const tenantId = useTenantId();
  const [mutation, isPending] = useMutation<useDeleteDocumentTemplateMutation>(
    deleteDocumentTemplateMutation
  );

  const deleteDocumentTemplate = (props: useDeleteDocumentTemplateProps) => {
    const {
      onError,
      onCompleted,
      input: { documentTemplateId, subtenantId },
    } = props;

    mutation({
      variables: {
        input: {
          tenantId,
          documentTemplateId,
          clientMutationId: newId(),
        },
      },
      updater: (store) => {
        const documentTemplates = ConnectionHandler.getConnection(
          store.getRoot(),
          "useDocumentTemplates_documentTemplates",
          { tenantId: subtenantId }
        );

        if (!documentTemplates) {
          return;
        }

        ConnectionHandler.deleteNode(documentTemplates, documentTemplateId);
      },
      onError,
      onCompleted,
    });
  };

  return { deleteDocumentTemplate, isPending };
};

const deleteDocumentTemplateMutation = graphql`
  mutation useDeleteDocumentTemplateMutation(
    $input: DeleteDocumentTemplateInput!
  ) {
    deleteDocumentTemplate(input: $input) {
      errors {
        code
        path
        message
        type
      }
    }
  }
`;
