/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SearchResultsListQueryVariables = {
    after?: string | null;
    first?: number | null;
    queryText?: string | null;
    tenantId: string;
};
export type SearchResultsListQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"SearchResultsList_search">;
};
export type SearchResultsListQuery = {
    readonly response: SearchResultsListQueryResponse;
    readonly variables: SearchResultsListQueryVariables;
};



/*
query SearchResultsListQuery(
  $after: String
  $first: Int
  $queryText: String
  $tenantId: ID!
) {
  ...SearchResultsList_search
}

fragment SearchResultsListItem_item on SearchResult {
  id
  name
  body
  description
  previewText
  type {
    identifier
    name
  }
}

fragment SearchResultsList_search on RootQueryType {
  search(queryText: $queryText, tenantId: $tenantId, after: $after, first: $first) {
    edges {
      cursor
      node {
        ...SearchResultsListItem_item
        id
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "after"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "first"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "queryText"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "after"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "first"
        } as any,
        {
            "kind": "Variable",
            "name": "queryText",
            "variableName": "queryText"
        } as any,
        {
            "kind": "Variable",
            "name": "tenantId",
            "variableName": "tenantId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "SearchResultsListQuery",
            "selections": [
                {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SearchResultsList_search"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "SearchResultsListQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "SearchResultConnection",
                    "kind": "LinkedField",
                    "name": "search",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "SearchResultEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "SearchResult",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        (v2 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "body",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "description",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "previewText",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "ObjectType",
                                            "kind": "LinkedField",
                                            "name": "type",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "identifier",
                                                    "storageKey": null
                                                },
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "filters": [
                        "queryText",
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "SearchResultsList_search",
                    "kind": "LinkedHandle",
                    "name": "search"
                }
            ]
        },
        "params": {
            "cacheID": "db73cdd715a8888bcadcc2b3c1476805",
            "id": null,
            "metadata": {},
            "name": "SearchResultsListQuery",
            "operationKind": "query",
            "text": "query SearchResultsListQuery(\n  $after: String\n  $first: Int\n  $queryText: String\n  $tenantId: ID!\n) {\n  ...SearchResultsList_search\n}\n\nfragment SearchResultsListItem_item on SearchResult {\n  id\n  name\n  body\n  description\n  previewText\n  type {\n    identifier\n    name\n  }\n}\n\nfragment SearchResultsList_search on RootQueryType {\n  search(queryText: $queryText, tenantId: $tenantId, after: $after, first: $first) {\n    edges {\n      cursor\n      node {\n        ...SearchResultsListItem_item\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '3cb2bf3e3d9ec5176079a0022d319614';
export default node;
