import { Stack } from "@workflows/ui";
import * as React from "react";
import { DocumentPage } from "./DocumentPage";

export function DocumentViewerSkeleton(): JSX.Element {
  return (
    <Stack direction="vertical" align="center" grow>
      <DocumentPage isPending={true} />
    </Stack>
  );
}
