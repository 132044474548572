import { compileRoutes, GraphQLTaggedNode } from "@workflows/runtime-web";
import {
  createStaticResource,
  EntryPointPreloadGlobals,
} from "~/core/entryPoints";
import { UsersRoute, UsersRouteQuery } from "./users/UsersRoute";

export const routes = compileRoutes({
  "users.index": () => "/admin/users",

  "users.edit": (_: { userId: string }) => "/admin/users/:userId",
});

// https://relay.dev/docs/api-reference/use-entrypoint-loader/
// https://github.com/facebook/relay/issues/3300
// https://github.com/relay-tools/typescript-relayjs-examples/issues/1
// TODO: This more powerful route definition will likely replace the simpler `routes` def
export const routeConfig = {
  "users.index": {
    root: createStaticResource("UsersRoute", UsersRoute),
    getPreloadProps(config: { globals: EntryPointPreloadGlobals }): Record<
      "queries",
      Record<
        "users",
        {
          parameters: GraphQLTaggedNode;
          variables: Record<"tenantId", string | undefined>;
        }
      >
    > {
      // TODO: Use locale from globals
      const variables = {
        tenantId: config.globals.tenantId,
      };

      return {
        queries: {
          users: {
            parameters: UsersRouteQuery,
            variables,
          },
        },
      };
    },
  },
};
