import {
  Connection,
  getEdgeNodes,
  useRecoilCallback,
} from "@workflows/runtime-web";
import { useTranslation } from "react-i18next";
import { toOutline, toTrees } from "../utils";
import { DocumentElement, outlineFamily } from "../Wizard";

export function useInitializeOutline(): (
  elements: Connection | null,
  values: Connection | null
) => void {
  const { t } = useTranslation("ai.workflows.documents");

  const initializeOutline = useRecoilCallback(
    ({ set }) =>
      (elements: Connection | null, values: Connection | null) => {
        const els = getEdgeNodes(elements || null);
        const vals = getEdgeNodes(values || null);
        const trees = toTrees<DocumentElement>(els, vals, t);
        const outline = trees && trees.length > 0 ? toOutline(trees || []) : {};

        Object.keys(outline).forEach((key: string) => {
          set(outlineFamily(key), outline[key]);
        });
      },
    []
  );

  return initializeOutline;
}
