import {
  Skeleton,
  Stack,
  TextContainer,
  useBreakpointMatch,
} from "@workflows/ui";
import * as React from "react";

export interface DocumentPageProps {
  children?: React.ReactNode;
  isPending?: boolean;
}

export function DocumentPage({
  children,
  isPending,
}: DocumentPageProps): JSX.Element {
  const { direction } = useBreakpointMatch({
    direction: { xs: "column", lg: "row" },
  }) as { direction: "column" | "row" | undefined };

  const isColumn = direction === "column";
  const defaultPadding = isColumn ? "p--xl" : "p--3xl";

  return (
    <Stack
      className={`bg--default ${isColumn ? "elevation--3" : "elevation--2"}`}
      direction="vertical"
      gap={false}
      grow
      style={{ maxWidth: "50rem", width: "90%" }}
    >
      <TextContainer
        as="article"
        className={`${defaultPadding} block w--full leading--loose flex-grow`}
      >
        {isPending ? (
          <>
            <Skeleton width={64} className="mb--md" />
            <Skeleton width={68} className="mb--md" />
            <Skeleton width={96} className="mb--md" />
            <Skeleton width={68} className="mb--md" />
            <Skeleton width={96} className="mb--md" />
            <Skeleton width={64} className="mb--md" />
            <Skeleton width={80} />
          </>
        ) : (
          children
        )}
      </TextContainer>
    </Stack>
  );
}
