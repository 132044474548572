/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type FlowRendererRouteQueryVariables = {
    workflowId: string;
};
export type FlowRendererRouteQueryResponse = {
    readonly node: {
        readonly id: string;
        readonly identifier?: string;
        readonly name?: string;
        readonly config?: ReadonlyArray<{
            readonly key: string;
            readonly value: unknown;
        } | null> | null;
    } | null;
};
export type FlowRendererRouteQuery = {
    readonly response: FlowRendererRouteQueryResponse;
    readonly variables: FlowRendererRouteQueryVariables;
};



/*
query FlowRendererRouteQuery(
  $workflowId: ID!
) {
  node(id: $workflowId) {
    __typename
    id
    ... on Workflow {
      identifier
      name
      config(keys: ["ai.workflows.workflows.config.preload"]) {
        key
        value
        id
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "workflowId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "workflowId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = [
        {
            "kind": "Literal",
            "name": "keys",
            "value": [
                "ai.workflows.workflows.config.preload"
            ]
        } as any
    ], v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "FlowRendererRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "ConfigValue",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/)
                                    ],
                                    "storageKey": "config(keys:[\"ai.workflows.workflows.config.preload\"])"
                                }
                            ],
                            "type": "Workflow",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "FlowRendererRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "ConfigValue",
                                    "kind": "LinkedField",
                                    "name": "config",
                                    "plural": true,
                                    "selections": [
                                        (v6 /*: any*/),
                                        (v7 /*: any*/),
                                        (v2 /*: any*/)
                                    ],
                                    "storageKey": "config(keys:[\"ai.workflows.workflows.config.preload\"])"
                                }
                            ],
                            "type": "Workflow",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "4642c1cb7df8a7488d33ae6266e70394",
            "id": null,
            "metadata": {},
            "name": "FlowRendererRouteQuery",
            "operationKind": "query",
            "text": "query FlowRendererRouteQuery(\n  $workflowId: ID!\n) {\n  node(id: $workflowId) {\n    __typename\n    id\n    ... on Workflow {\n      identifier\n      name\n      config(keys: [\"ai.workflows.workflows.config.preload\"]) {\n        key\n        value\n        id\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'b962f823cddbe2ee030179d55252131e';
export default node;
