/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ChooseTenantRouteQueryVariables = {};
export type ChooseTenantRouteQueryResponse = {
    readonly viewer: {
        readonly tenants: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly host: {
                        readonly baseUrl: string;
                    };
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type ChooseTenantRouteQuery = {
    readonly response: ChooseTenantRouteQueryResponse;
    readonly variables: ChooseTenantRouteQueryVariables;
};



/*
query ChooseTenantRouteQuery {
  viewer {
    tenants {
      edges {
        node {
          host {
            baseUrl
            id
          }
          id
        }
      }
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "baseUrl",
        "storageKey": null
    } as any, v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [],
            "kind": "Fragment",
            "metadata": null,
            "name": "ChooseTenantRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantConnection",
                            "kind": "LinkedField",
                            "name": "tenants",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TenantEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Tenant",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Host",
                                                    "kind": "LinkedField",
                                                    "name": "host",
                                                    "plural": false,
                                                    "selections": [
                                                        (v0 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [],
            "kind": "Operation",
            "name": "ChooseTenantRouteQuery",
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Viewer",
                    "kind": "LinkedField",
                    "name": "viewer",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "TenantConnection",
                            "kind": "LinkedField",
                            "name": "tenants",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "TenantEdge",
                                    "kind": "LinkedField",
                                    "name": "edges",
                                    "plural": true,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Tenant",
                                            "kind": "LinkedField",
                                            "name": "node",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Host",
                                                    "kind": "LinkedField",
                                                    "name": "host",
                                                    "plural": false,
                                                    "selections": [
                                                        (v0 /*: any*/),
                                                        (v1 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                },
                                                (v1 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v1 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "5e2e8a1f10a77e07720bcaad83becf4b",
            "id": null,
            "metadata": {},
            "name": "ChooseTenantRouteQuery",
            "operationKind": "query",
            "text": "query ChooseTenantRouteQuery {\n  viewer {\n    tenants {\n      edges {\n        node {\n          host {\n            baseUrl\n            id\n          }\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '852d9e6c3f9d27905d8a1fc5f569c307';
export default node;
