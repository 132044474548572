import {
  atomFamily,
  SetterOrUpdater,
  useRecoilState,
  useSetRecoilState,
  RecoilState,
} from "@workflows/runtime-web";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const connectionVariables = atomFamily<Record<string, any>, string>({
  key: "ConnectionVariables",
  default: {},
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSetConnectionVariables<T = Record<string, any>>(
  connectionKey: string
): SetterOrUpdater<T> {
  return useSetRecoilState<T>(
    connectionVariables(connectionKey) as RecoilState<T>
  );
}

export function useConnectionVariables<T = Record<string, any>>(
  connectionKey: string
): [T, SetterOrUpdater<T>] {
  return useRecoilState<T>(
    connectionVariables(connectionKey) as RecoilState<T>
  );
}
