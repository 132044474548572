import * as React from "react";
import {
  AcademicCapIcon,
  Card,
  CardBody,
  CardHeader,
  Icon,
  Text,
  Truncate,
} from "@workflows/ui";

export interface CollectionItemChooserListQuickstartItemConfigValue {
  [key: string]: {
    icon?: string;
    title: string;
    url: string;
    buttonLabel: string;
    description: string;
  };
}

export interface CollectionItemChooserListQuickstartItemProps {
  quickstartData: CollectionItemChooserListQuickstartItemConfigValue;
}

export function CollectionItemChooserListQuickstartItem({
  quickstartData,
}: CollectionItemChooserListQuickstartItemProps): JSX.Element {
  return (
    <Card
      interactive
      onClick={() => window.open(`${quickstartData.url}`, "_blank")}
    >
      <CardHeader
        title={quickstartData.title}
        icon={
          quickstartData.icon ? (
            <Icon
              identifier={quickstartData.icon as unknown as string}
              fallback={AcademicCapIcon}
            />
          ) : (
            <AcademicCapIcon />
          )
        }
      />
      <CardBody>
        <Text color="muted">
          <Truncate lines={3}>{quickstartData.description}</Truncate>
        </Text>
      </CardBody>
    </Card>
  );
}
