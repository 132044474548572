export enum TenantRoles {
  User = "tenant.user",
  Guest = "tenant.guest",
  Admin = "tenant.admin",
}

export enum Roles {
  Owner = "ai.workflows.roles.role:owner",
}

export enum VertragsgeneratorRoles {
  Assignee = "de.smartconex.vertragsgenerator.assignee",
}
