import * as React from "react";

export interface DocumentViewerContextShape {
  documentId: string;
}

export const DocumentViewerContext =
  React.createContext<DocumentViewerContextShape>({
    documentId: "",
  });
