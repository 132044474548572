/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionItemChooserQueryVariables = {
    collectionId: string;
};
export type CollectionItemChooserQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"CollectionItemChooserList_collection">;
    } | null;
};
export type CollectionItemChooserQuery = {
    readonly response: CollectionItemChooserQueryResponse;
    readonly variables: CollectionItemChooserQueryVariables;
};



/*
query CollectionItemChooserQuery(
  $collectionId: ID!
) {
  node(id: $collectionId) {
    __typename
    ...CollectionItemChooserList_collection
    id
  }
}

fragment CollectionItemChooserListFileItem_item on File {
  id
  name
  description
  downloadUrl
}

fragment CollectionItemChooserListWorkflowItem_item on Workflow {
  id
  name
  descriptionText
  icon
  color
}

fragment CollectionItemChooserList_collection on Collection {
  name
  items(first: 100) {
    edges {
      cursor
      node {
        __typename
        ...CollectionItemChooserListFileItem_item
        ...CollectionItemChooserListWorkflowItem_item
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "collectionId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "collectionId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CollectionItemChooserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": null,
                            "kind": "FragmentSpread",
                            "name": "CollectionItemChooserList_collection"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CollectionItemChooserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "CollectionItemConnection",
                                    "kind": "LinkedField",
                                    "name": "items",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CollectionItemEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/),
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "description",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "downloadUrl",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "File",
                                                            "abstractKey": null
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "descriptionText",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "icon",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "color",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "Workflow",
                                                            "abstractKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "items(first:100)"
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "CollectionItemChooserList_items",
                                    "kind": "LinkedHandle",
                                    "name": "items"
                                }
                            ],
                            "type": "Collection",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "83e7a01114b2c4b7547c8038f4b0f377",
            "id": null,
            "metadata": {},
            "name": "CollectionItemChooserQuery",
            "operationKind": "query",
            "text": "query CollectionItemChooserQuery(\n  $collectionId: ID!\n) {\n  node(id: $collectionId) {\n    __typename\n    ...CollectionItemChooserList_collection\n    id\n  }\n}\n\nfragment CollectionItemChooserListFileItem_item on File {\n  id\n  name\n  description\n  downloadUrl\n}\n\nfragment CollectionItemChooserListWorkflowItem_item on Workflow {\n  id\n  name\n  descriptionText\n  icon\n  color\n}\n\nfragment CollectionItemChooserList_collection on Collection {\n  name\n  items(first: 100) {\n    edges {\n      cursor\n      node {\n        __typename\n        ...CollectionItemChooserListFileItem_item\n        ...CollectionItemChooserListWorkflowItem_item\n        id\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
        }
    } as any;
})();
(node as any).hash = '5d5dc2f0decbdf54e01e4d189ba5e17b';
export default node;
