import { newId, Redirect } from "@workflows/runtime-web";
import {
  Callout,
  CheckCircleIcon,
  Link,
  NonIdealState,
  Stack,
  StackItem,
  Text,
  useDocumentTitle,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import { graphql, useMutation } from "react-relay/hooks";
import { ConfigValueSignup, useConfigValue } from "~/core/configValues";
import { AccountSignUpRouteMutation } from "~/__graphql__/AccountSignUpRouteMutation.graphql";
import { AccountLayout } from "./AccountLayout";
import { AccountSignUpForm } from "./AccountSignUpForm";

export function AccountSignUpRoute(): JSX.Element {
  const tenantId = useTenantId();
  const [error, setError] = React.useState("");
  const { t } = useTranslation("ai.workflows.accounts");
  const [state, setState] = React.useState<"default" | "success">("default");

  useDocumentTitle(t("AccountSignUpRoute.pageTitle"));

  const [createUser, isPending] = useMutation<AccountSignUpRouteMutation>(
    AccountSignUpRouteMutation
  );

  const onSubmit = React.useCallback(
    ({ firstName, lastName, email, password, companyName }) => {
      createUser({
        variables: {
          input: {
            clientMutationId: newId(),
            tenantId,
            entity: companyName,
            firstName,
            lastName,
            email,
            password,
          },
        },
        onError(error) {
          console.error(error);
          setError(t("AccountSignUpRoute.errors.unspecified"));
        },
        onCompleted(data) {
          if (data.createUser?.errors) {
            const errors = data.createUser.errors;
            let error = "AccountSignUpRoute.errors.unspecified";

            // TODO: This can be simplified. We should think about a general
            // handling of errors in mutations
            // TODO: Increment counter to catch validation problems
            if (errors.find((e) => e && e.code === "UserAlreadyExists")) {
              error = "AccountSignUpRoute.errors.userAlreadyExists";
            } else {
              console.error(errors);
            }

            setError(t(error));
          } else {
            setState("success");
          }
        },
      });
    },
    [createUser, t, tenantId]
  );

  const signupConfig = useConfigValue<ConfigValueSignup>(
    "ai.workflows.tenants.config.signup"
  );

  if (!signupConfig?.enabled) {
    return <Redirect to={"/sign-in"} />;
  }

  return (
    <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{ maxWidth: "30rem" }}>
          <Stack direction="vertical">
            {state === "default" && (
              <>
                <Text type="title1">{t("AccountSignUpRoute.title")}</Text>

                <Text>
                  <Trans i18nKey="AccountSignUpRoute.login" t={t}>
                    Or
                    <Link to="/sign-in" style={{ textDecoration: "underline" }}>
                      log in to an existing account.
                    </Link>{" "}
                  </Trans>
                </Text>

                {error && (
                  <Callout intent="critical" className="mb-md">
                    {error}
                  </Callout>
                )}

                <AccountSignUpForm onSubmit={onSubmit} isPending={isPending} />
              </>
            )}
            {state === "success" && (
              <NonIdealState
                header={<CheckCircleIcon size="xl" />}
                title={t("AccountSignUpRoute.success.title")}
                description={t("AccountSignUpRoute.success.description")}
              />
            )}
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>
  );
}

const AccountSignUpRouteMutation = graphql`
  mutation AccountSignUpRouteMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      errors {
        code
        path
        message
      }
      user {
        id
      }
    }
  }
`;
