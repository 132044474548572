/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type DuplicateDocumentInput = {
    clientMutationId: string;
    documentId: string;
    tenantId: string;
    title: string;
};
export type DocumentInspectorColumnDuplicateDocumentMutationVariables = {
    input: DuplicateDocumentInput;
    connections: Array<string>;
};
export type DocumentInspectorColumnDuplicateDocumentMutationResponse = {
    readonly duplicateDocument: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
        readonly document: {
            readonly id: string;
            readonly title: string;
            readonly insertedAt: string;
            readonly color: string | null;
            readonly props: unknown | null;
            readonly " $fragmentRefs": FragmentRefs<"Status_status">;
        } | null;
    } | null;
};
export type DocumentInspectorColumnDuplicateDocumentMutation = {
    readonly response: DocumentInspectorColumnDuplicateDocumentMutationResponse;
    readonly variables: DocumentInspectorColumnDuplicateDocumentMutationVariables;
};



/*
mutation DocumentInspectorColumnDuplicateDocumentMutation(
  $input: DuplicateDocumentInput!
) {
  duplicateDocument(input: $input) {
    errors {
      code
      path
      message
    }
    document {
      id
      title
      insertedAt
      color
      props
      ...Status_status
    }
  }
}

fragment Status_status on HasStatus {
  __isHasStatus: __typename
  status {
    name
    textColor
    color
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "connections"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "input"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "props",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "DocumentInspectorColumnDuplicateDocumentMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "DuplicateDocumentPayload",
                    "kind": "LinkedField",
                    "name": "duplicateDocument",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "document",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "Status_status"
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "DocumentInspectorColumnDuplicateDocumentMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "DuplicateDocumentPayload",
                    "kind": "LinkedField",
                    "name": "duplicateDocument",
                    "plural": false,
                    "selections": [
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "document",
                            "plural": false,
                            "selections": [
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                (v6 /*: any*/),
                                (v7 /*: any*/),
                                (v8 /*: any*/),
                                {
                                    "kind": "InlineFragment",
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Status",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "name",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "textColor",
                                                    "storageKey": null
                                                },
                                                (v7 /*: any*/),
                                                (v4 /*: any*/)
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "type": "HasStatus",
                                    "abstractKey": "__isHasStatus"
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "filters": null,
                            "handle": "appendNode",
                            "key": "",
                            "kind": "LinkedHandle",
                            "name": "document",
                            "handleArgs": [
                                {
                                    "kind": "Variable",
                                    "name": "connections",
                                    "variableName": "connections"
                                },
                                {
                                    "kind": "Literal",
                                    "name": "edgeTypeName",
                                    "value": "DocumentEdge"
                                }
                            ]
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7b2197c3b1436f1563efbfac348f4e33",
            "id": null,
            "metadata": {},
            "name": "DocumentInspectorColumnDuplicateDocumentMutation",
            "operationKind": "mutation",
            "text": "mutation DocumentInspectorColumnDuplicateDocumentMutation(\n  $input: DuplicateDocumentInput!\n) {\n  duplicateDocument(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n    document {\n      id\n      title\n      insertedAt\n      color\n      props\n      ...Status_status\n    }\n  }\n}\n\nfragment Status_status on HasStatus {\n  __isHasStatus: __typename\n  status {\n    name\n    textColor\n    color\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'ad38b70e8f40d151bcac5d1782c2cf8e';
export default node;
