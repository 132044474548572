import de from "date-fns/locale/de";
import en from "date-fns/locale/en-GB";
import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XhrBackend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { format } from "./utils";
import { languages } from "~/client/config";

export const locales: Record<string, Locale> = {
  de: de,
  en: en,
};

export const i18n = i18next
  .use(XhrBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: languages.default,
    ns: ["ai.workflows.core"],
    defaultNS: "ai.workflows.core",
    debug: process.env.I18N_DEBUG === "true",
    supportedLngs: languages.available,

    backend: {
      loadPath: "/locales/{{ns}}/{{lng}}.json",
    },

    interpolation: {
      format,
    },
  });

i18next.on("languageChanged", (lang) => {
  if (languages.available.includes(lang) && typeof document !== "undefined") {
    document.documentElement.setAttribute("lang", lang);
  } else {
    document.documentElement.setAttribute("lang", languages.default);
  }
});
