import { useTenantId } from "@workflows/ui";
import * as React from "react";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { WorkflowChooserQuery } from "~/__graphql__/WorkflowChooserQuery.graphql";
import { WorkflowChooserList } from "./WorkflowChooserList";

export interface WorkflowChooserProps {
  isPending?: boolean;
  onClick: (e: React.MouseEvent, item: { id: string; name: string }) => void;
}

export function WorkflowChooser({
  isPending = false,
  onClick,
}: WorkflowChooserProps): JSX.Element {
  const tenantId = useTenantId();
  const data = useLazyLoadQuery<WorkflowChooserQuery>(WorkflowChooserQuery, {
    tenantId,
  });

  return (
    <WorkflowChooserList
      onClick={onClick}
      workflows={data}
      isPending={isPending}
    />
  );
}

const WorkflowChooserQuery = graphql`
  query WorkflowChooserQuery($tenantId: ID!) {
    ...WorkflowChooserList_workflows @arguments(tenantId: $tenantId)
  }
`;
