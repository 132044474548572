import { Value } from "./types";

export const type = "enumList";

export interface EnumValueProps {
  values: Record<string, string>;
}

export function load(val: Value<string[]>): Value<string[]> {
  return val;
}

export function dump(val: Value<string[], EnumValueProps>): string[] | null {
  if (val.value === null) {
    return val.value;
  }

  const possibleValues = val.props.values ? Object.keys(val.props.values) : [];
  const invalidValues = val.value.filter((v) => !possibleValues.includes(v));

  if (invalidValues.length > 0) {
    throw new Error("Invalid enum values");
  }

  return val.value;
}
