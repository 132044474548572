/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MattersTableView_matters = {
    readonly matters: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly number: number;
                readonly dueDate: string | null;
                readonly insertedAt: string;
                readonly creator: {
                    readonly id: string;
                    readonly name: string;
                } | null;
                readonly " $fragmentRefs": FragmentRefs<"AssigneesPreview_node" | "Status_status">;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "MattersTableView_matters";
};
export type MattersTableView_matters$data = MattersTableView_matters;
export type MattersTableView_matters$key = {
    readonly " $data"?: MattersTableView_matters$data;
    readonly " $fragmentRefs": FragmentRefs<"MattersTableView_matters">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "matters"
    ], v1 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 100,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "tenantId"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [],
                "operation": require('./MattersTableViewPaginationQuery.graphql.ts')
            }
        },
        "name": "MattersTableView_matters",
        "selections": [
            {
                "alias": "matters",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "tenantId",
                        "variableName": "tenantId"
                    }
                ],
                "concreteType": "MatterConnection",
                "kind": "LinkedField",
                "name": "__MattersTableView_matters_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "MatterEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Matter",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v1 /*: any*/),
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "title",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "number",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "dueDate",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "insertedAt",
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "User",
                                        "kind": "LinkedField",
                                        "name": "creator",
                                        "plural": false,
                                        "selections": [
                                            (v1 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    },
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "AssigneesPreview_node"
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "Status_status"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "RootQueryType",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'ab0b0085d88dd2f24313a807325ebfc5';
export default node;
