import { RouteComponentProps } from "@workflows/runtime-web";
import * as React from "react";
import { SuspenseFallback } from "~/core/SuspenseFallback";
import { UsersEditorColumn } from "./UsersEditorColumn";

export function UsersEditorRoute({
  match,
}: RouteComponentProps<{ userId: string }>): JSX.Element {
  return (
    <React.Suspense fallback={<SuspenseFallback />}>
      <UsersEditorColumn userId={match.params.userId} />
    </React.Suspense>
  );
}
