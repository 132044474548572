/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type SearchResultsListItem_item = {
    readonly id: string;
    readonly name: string | null;
    readonly body: string | null;
    readonly description: string | null;
    readonly previewText: string;
    readonly type: {
        readonly identifier: string;
        readonly name: string;
    };
    readonly " $refType": "SearchResultsListItem_item";
};
export type SearchResultsListItem_item$data = SearchResultsListItem_item;
export type SearchResultsListItem_item$key = {
    readonly " $data"?: SearchResultsListItem_item$data;
    readonly " $fragmentRefs": FragmentRefs<"SearchResultsListItem_item">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "SearchResultsListItem_item",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            },
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "body",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "previewText",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "ObjectType",
                "kind": "LinkedField",
                "name": "type",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "identifier",
                        "storageKey": null
                    },
                    (v0 /*: any*/)
                ],
                "storageKey": null
            }
        ],
        "type": "SearchResult",
        "abstractKey": null
    } as any;
})();
(node as any).hash = 'f4a09e4f25c1f0fc74ec49596ef8d44f';
export default node;
