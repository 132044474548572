import { newId, useHistory, useLocation } from "@workflows/runtime-web";
import {
  Callout,
  Stack,
  StackItem,
  Text,
  useDocumentTitle,
} from "@workflows/ui";
import { FormValues } from "@workflows/ui/lib/Form/types";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, useMutation } from "react-relay/hooks";
import { AccountSetNewPasswordRouteMutation } from "~/__graphql__/AccountSetNewPasswordRouteMutation.graphql";
import { AccountLayout } from "./AccountLayout";
import { AccountSetNewPasswordForm } from "./AccountSetNewPasswordForm";

function useSearchParam() {
  return new URLSearchParams(useLocation().search);
}

export function AccountSetNewPasswordRoute(): JSX.Element {
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const { t } = useTranslation("ai.workflows.accounts");
  const history = useHistory();
  const secret = useSearchParam().get("s") ?? "";

  useDocumentTitle(t("AccountSetNewPasswordRoute.title"));

  const [setNewPassword] = useMutation<AccountSetNewPasswordRouteMutation>(
    AccountSetNewPasswordRouteMutation
  );

  const onSubmit = async (values: FormValues) => {
    try {
      setNewPassword({
        variables: {
          input: {
            clientMutationId: newId(),
            password: values.password as string,
            secret,
          },
        },
        onError(error) {
          console.error(error);
          showErrorMessage("unspecified");
        },
        onCompleted(data) {
          data.updateUserPassword?.errors
            ? showErrorMessage(
                data.updateUserPassword?.errors[0]?.code as string
              )
            : history.push("/sign-in");
        },
      });
    } catch (err) {
      console.error(err);
      showErrorMessage("unspecified");
    }
  };

  const showErrorMessage = (code: string) => {
    setError(code);
    setLoading(false);
  };

  return (
    <AccountLayout>
      <Stack direction="vertical" fill justify="center" align="center">
        <StackItem className="w--full" style={{ maxWidth: "30rem" }}>
          <Stack direction="vertical">
            <Text type="title1" className="mb-md">
              {t("AccountSetNewPasswordRoute.title")}
            </Text>
            {error && (
              <Callout intent="critical" className="mb-md">
                {t([
                  `AccountSetNewPasswordRoute.errors.${error}`,
                  "AccountSetNewPasswordRoute.errors.unspecified",
                ])}
              </Callout>
            )}
            <AccountSetNewPasswordForm onSubmit={onSubmit} loading={loading} />
          </Stack>
        </StackItem>
      </Stack>
    </AccountLayout>
  );
}

const AccountSetNewPasswordRouteMutation = graphql`
  mutation AccountSetNewPasswordRouteMutation(
    $input: UpdateUserPasswordInput!
  ) {
    updateUserPassword(input: $input) {
      clientMutationId
      errors {
        code
        path
        message
        type
      }
    }
  }
`;
