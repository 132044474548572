import {
  Button,
  Column,
  ColumnBody,
  ColumnHeader,
  PlusCircleIcon,
  Stack,
  StackItem,
  TextField,
  useVisibilityToggle,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, PreloadedQuery, usePreloadedQuery } from "react-relay/hooks";
import { Route, Switch, useHistory, useParams } from "@workflows/runtime-web";
import { useSetConnectionVariables } from "~/core/connections";
import { MattersColumnQuery } from "~/__graphql__/MattersColumnQuery.graphql";
import { MatterCreatorDrawer } from "../Matter/MatterCreatorDrawer";
import { MattersTableView } from "./MattersTableView";
import { routes } from "../routes";

export interface MattersColumnProps {
  queryRef: PreloadedQuery<MattersColumnQuery>;
}

export function MattersColumn({ queryRef }: MattersColumnProps): JSX.Element {
  const setConnectionVariables = useSetConnectionVariables(
    "MattersTableView_matters"
  );

  const data = usePreloadedQuery(MattersColumnQueryRequest, queryRef!);

  React.useEffect(() => {
    setConnectionVariables(queryRef.variables);
  }, []);

  return (
    <Column role="main">
      <ColumnHeader borderless>
        <MattersColumnHeader />
      </ColumnHeader>
      <ColumnBody scrollable={false}>
        <MattersTableView queryFragment={data} />
      </ColumnBody>
    </Column>
  );
}

function MattersColumnHeader(): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");
  const history = useHistory();

  const openCreatorDrawer = () => {
    history.push(routes["matter.new"].path);
  };

  const closeCreatorDrawer = () => {
    history.push(routes["matters.index"].path);
  };

  return (
    <>
      <Stack direction="horizontal" grow>
        <StackItem grow>
          <TextField
            placeholder={t("MattersColumnHeader.placeholders.filterMatters")}
            disabled
          />
        </StackItem>
        <StackItem>
          <Button
            intent="primary"
            icon={<PlusCircleIcon />}
            onClick={openCreatorDrawer}
          >
            {t("MattersColumnHeader.actions.createMatter")}
          </Button>
        </StackItem>
      </Stack>
      <Switch>
        <Route path="/matters/new">
          <MatterCreatorDrawer isOpen onClose={closeCreatorDrawer} />
        </Route>
      </Switch>
    </>
  );
}

export const MattersColumnQueryRequest = graphql`
  query MattersColumnQuery($tenantId: ID!) {
    ...MattersTableView_matters @arguments(tenantId: $tenantId)
  }
`;
