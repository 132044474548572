import {
  Button,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  Drawer,
  useForm,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { routes } from "~/de.agad.dpm/routes";
import { useHistory } from "@workflows/runtime-web";
import { TenantCreatorColumnTabs } from "~/de.agad.dpm/tenants/TenantCreatorColumnTabs";

export function TenantCreatorDrawer(): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");
  const form = useForm("TenantCreator");
  const history = useHistory();

  const handleClose = () => {
    history.push(routes["tenants.index"].build({}));
  };

  return (
    <Drawer isOpen width="sm" closeOnOutsideClick onClose={handleClose}>
      <Column id="TenantCreatorColumn" width={1}>
        <ColumnHeader title={t("TenantCreatorDrawer.title")} />
        <ColumnBody>
          <TenantCreatorColumnTabs />
        </ColumnBody>
        <ColumnFooter
          end={
            <Button onClick={form.submit} intent="primary" type="submit">
              {t("TenantEditor.createTenant")}
            </Button>
          }
        />
      </Column>
    </Drawer>
  );
}
