import { useLazyLoadQuery } from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import { graphql } from "react-relay/hooks";
import { useSnippetsQuery } from "~/__graphql__/useSnippetsQuery.graphql";

export type Snippet = Record<string, any>;

export const useSnippets = (identifier: string): Snippet | undefined => {
  const tenantId = useTenantId();

  const { workflow } = useLazyLoadQuery<useSnippetsQuery>(Query, {
    identifier,
    tenantId,
  });

  const configValues = workflow?.config;
  const snippetConfig = configValues?.find(
    (c) => c?.key === "ai.workflows.workflows.config.preload"
  );

  if (!snippetConfig) {
    return undefined;
  }
  if (!Object.prototype.hasOwnProperty.call(snippetConfig.value, "snippets")) {
    return undefined;
  }

  return (snippetConfig.value as { snippets: Snippet }).snippets;
};

const Query = graphql`
  query useSnippetsQuery($identifier: String!, $tenantId: ID!) {
    workflow(identifier: $identifier, tenantId: $tenantId) {
      config(keys: ["ai.workflows.workflows.config.preload"]) {
        key
        value
      }
    }
  }
`;
