import { ExtractNode, graphql, useLazyLoadQuery } from "@workflows/runtime-web";
import {
  useTenantQuery,
  useTenantQueryResponse,
} from "~/__graphql__/useTenantQuery.graphql";

export type UseTenantQueryResponseType = useTenantQueryResponse["tenant"];
export type AdminType = ExtractNode<
  NonNullable<NonNullable<useTenantQueryResponse["tenant"]>["admins"]>
>;

export function useTenant(tenantId: string): UseTenantQueryResponseType {
  const { tenant } = useLazyLoadQuery<useTenantQuery>(useTenantQueryRequest, {
    tenantId,
  });

  return tenant;
}

const useTenantQueryRequest = graphql`
  query useTenantQuery($tenantId: ID!) {
    tenant: node(id: $tenantId) {
      id
      ... on Tenant {
        name
        host {
          baseUrl
        }
        admins: users(roleIdentifiers: ["tenant.admin"]) {
          edges {
            node {
              id
              name
            }
          }
        }
        organization: entity(
          identifier: "de.agad.dpm.entities.tenants.address"
        ) {
          ... on EntityOrganization {
            name
            email
            postalCode
            postalCountry
            streetAddress
            locality
            administrativeArea
          }
        }
        contact: entity(identifier: "de.agad.dpm.entities.tenants.contact") {
          ... on EntityPerson {
            name
            email
            locality
            position
            postalCode
            postalCountry
            administrativeArea
            streetAddress
            position
          }
        }
      }
    }
  }
`;
