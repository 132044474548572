import {
  Button,
  ChoiceList,
  ChoiceListProps,
  Form2,
  FormControl,
  FormInput,
  Link,
  TextField,
  TextFieldProps,
  useForm,
} from "@workflows/ui";
import i18next from "i18next";
import * as React from "react";
import { Trans, useTranslation } from "react-i18next";
import {
  ConfigValueLegalAgreements,
  ConfigValueSignup,
  useConfigValue,
} from "~/core/configValues";

export interface AccountSignUpFormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  companyName: string;
  tosPrivacy: boolean;
}

export interface AccountSignUpFormProps {
  isPending: boolean;
  onSubmit: (values: AccountSignUpFormValues) => void;
  hiddenFields?: (keyof AccountSignUpFormValues)[];
}

type LegalAgreementsLinks = ConfigValueLegalAgreements["en"];

export function AccountSignUpForm({
  onSubmit,
  isPending,
  hiddenFields = [],
}: AccountSignUpFormProps): JSX.Element {
  const form = useForm("AccountSignUpForm");
  const { t } = useTranslation("ai.workflows.accounts");
  const legalAgreementConfig = useConfigValue<ConfigValueLegalAgreements>(
    "ai.workflows.tenants.config.legalAgreements"
  );
  let currentLocale = i18next.language;

  const isLanguageSupported =
    legalAgreementConfig &&
    Object.keys(legalAgreementConfig).includes(currentLocale);

  if (!isLanguageSupported) {
    currentLocale = "en";
  }

  const legalAgreements = legalAgreementConfig?.[currentLocale];

  const handleSubmit = React.useCallback(async () => {
    const { isValid } = await form.validate();
    if (isValid && onSubmit) {
      onSubmit(form.getValues());
    }
  }, [form, onSubmit]);

  const validatePassword = (password: string) => {
    password = String(password).trim();
    if (!password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/g)) {
      return t("AccountSignUpForm.fields.password.required");
    }
    return true;
  };

  const signupConfig = useConfigValue<ConfigValueSignup>(
    "ai.workflows.tenants.config.signup"
  );

  const getTranslationComponent = (legalAgreements: LegalAgreementsLinks) => {
    if (legalAgreements?.dataPrivacy && legalAgreements?.termsOfService) {
      return <DisclaimerTOSandPrivacy legalAgreements={legalAgreements} />;
    }
    if (legalAgreements.dataPrivacy) {
      return <DisclaimerPrivacy legalAgreements={legalAgreements} />;
    }
    if (legalAgreements.termsOfService) {
      return <DisclaimerTOS legalAgreements={legalAgreements} />;
    } else {
      return null;
    }
  };

  return (
    <Form2 name="AccountSignUpForm" onSubmit={handleSubmit}>
      <FormControl
        label={t("AccountSignUpForm.fields.firstName.label")}
        name="firstName"
        htmlFor="firstName"
      >
        <FormInput<TextFieldProps>
          autoFocus
          id="firstName"
          component={TextField}
          name="firstName"
          required={t("AccountSignUpForm.fields.firstName.required") as string}
        />
      </FormControl>

      <FormControl
        label={t("AccountSignUpForm.fields.lastName.label")}
        name="lastName"
        htmlFor="lastName"
      >
        <FormInput<TextFieldProps>
          id="lastName"
          component={TextField}
          name="lastName"
          required={t("AccountSignUpForm.fields.lastName.required") as string}
        />
      </FormControl>

      {signupConfig?.hasFieldCompany && !hiddenFields.includes("companyName") && (
        <FormControl
          label={t("AccountSignUpForm.fields.company.label")}
          name="companyName"
          htmlFor="companyName"
        >
          <FormInput<TextFieldProps>
            component={TextField}
            name="companyName"
            placeholder={t("AccountSignUpForm.fields.company.placeholder")}
            required={t("AccountSignUpForm.fields.company.required") as string}
          />
        </FormControl>
      )}

      {!hiddenFields.includes("email") && (
        <FormControl
          label={t("AccountSignUpForm.fields.email.label")}
          name="email"
          htmlFor="email"
        >
          <FormInput<TextFieldProps>
            id="email"
            component={TextField}
            name="email"
            required={t("AccountSignUpForm.fields.email.required") as string}
          />
        </FormControl>
      )}

      <FormControl
        label={t("AccountSignUpForm.fields.password.label")}
        name="password"
        htmlFor="password"
      >
        <FormInput<TextFieldProps>
          id="password"
          component={TextField}
          name="password"
          type="password"
          validate={validatePassword}
        />
      </FormControl>

      {legalAgreements && (
        <FormControl name="tosPrivacy" htmlFor="tosPrivacy">
          <FormInput<ChoiceListProps>
            id="tosPrivacy"
            component={ChoiceList}
            name="tosPrivacy"
            type="checkbox"
            required={
              t("AccountSignUpForm.fields.tosPrivacy.required") as string
            }
            validationMode="onChange"
            options={[
              {
                label: getTranslationComponent(legalAgreements),
                value: "true",
              },
            ]}
          />
        </FormControl>
      )}

      <Button
        type="submit"
        intent="primary"
        onClick={handleSubmit}
        isLoading={isPending}
        fill
      >
        {t("AccountSignUpForm.actions.submit")}
      </Button>
    </Form2>
  );
}

type DisclaimerProps = {
  legalAgreements: LegalAgreementsLinks;
};

const DisclaimerTOS = ({ legalAgreements }: DisclaimerProps) => {
  const { t } = useTranslation("ai.workflows.accounts");
  return (
    <Trans i18nKey="AccountSignUpForm.disclaimer.tos" t={t}>
      I accept the{" "}
      <Link href={legalAgreements.termsOfService} target="_blank" underline>
        Terms of Service.
      </Link>{" "}
    </Trans>
  );
};

const DisclaimerPrivacy = ({ legalAgreements }: DisclaimerProps) => {
  const { t } = useTranslation("ai.workflows.accounts");
  return (
    <Trans i18nKey="AccountSignUpForm.disclaimer.privacy" t={t}>
      I accept the{" "}
      <Link href={legalAgreements.dataPrivacy} target="_blank" underline>
        Privacy Policy.
      </Link>{" "}
    </Trans>
  );
};

const DisclaimerTOSandPrivacy = ({ legalAgreements }: DisclaimerProps) => {
  const { t } = useTranslation("ai.workflows.accounts");
  return (
    <Trans i18nKey="AccountSignUpForm.disclaimer.both" t={t}>
      I accept the{" "}
      <Link href={legalAgreements.termsOfService} target="_blank" underline>
        Terms of Service
      </Link>{" "}
      and the{" "}
      <Link href={legalAgreements.dataPrivacy} target="_blank" underline>
        Privacy Policy.
      </Link>
    </Trans>
  );
};
