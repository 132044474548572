/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useTenantUsersQueryVariables = {
    tenantId: string;
};
export type useTenantUsersQueryResponse = {
    readonly users: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly name: string;
                readonly displayName: string;
                readonly email: string;
                readonly assignedRoles: {
                    readonly edges: ReadonlyArray<{
                        readonly cursor: string | null;
                        readonly node: {
                            readonly id: string;
                            readonly name: string | null;
                            readonly identifier: string | null;
                        } | null;
                    } | null> | null;
                } | null;
            } | null;
        } | null> | null;
    } | null;
};
export type useTenantUsersQuery = {
    readonly response: useTenantUsersQueryResponse;
    readonly variables: useTenantUsersQueryVariables;
};



/*
query useTenantUsersQuery(
  $tenantId: ID!
) {
  users(tenantId: $tenantId, first: 100) {
    edges {
      node {
        id
        name
        displayName
        email
        assignedRoles(objectId: $tenantId, first: 100) {
          edges {
            cursor
            node {
              id
              name
              identifier
              __typename
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v6 = {
        "kind": "Variable",
        "name": "objectId",
        "variableName": "tenantId"
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v9 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v10 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "RoleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                (v7 /*: any*/),
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "identifier",
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any,
        (v9 /*: any*/)
    ], v11 = {
        "kind": "Literal",
        "name": "first",
        "value": 100
    } as any, v12 = [
        (v11 /*: any*/),
        (v1 /*: any*/)
    ], v13 = [
        (v11 /*: any*/),
        (v6 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useTenantUsersQuery",
            "selections": [
                {
                    "alias": "users",
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "__useTenantUsers_users_connection",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": "assignedRoles",
                                            "args": [
                                                (v6 /*: any*/)
                                            ],
                                            "concreteType": "RoleConnection",
                                            "kind": "LinkedField",
                                            "name": "__user_assignedRoles_connection",
                                            "plural": false,
                                            "selections": (v10 /*: any*/),
                                            "storageKey": null
                                        },
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useTenantUsersQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "concreteType": "UserConnection",
                    "kind": "LinkedField",
                    "name": "users",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "UserEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v3 /*: any*/),
                                        (v4 /*: any*/),
                                        (v5 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": (v13 /*: any*/),
                                            "concreteType": "RoleConnection",
                                            "kind": "LinkedField",
                                            "name": "assignedRoles",
                                            "plural": false,
                                            "selections": (v10 /*: any*/),
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": (v13 /*: any*/),
                                            "filters": [
                                                "objectId"
                                            ],
                                            "handle": "connection",
                                            "key": "user_assignedRoles",
                                            "kind": "LinkedHandle",
                                            "name": "assignedRoles"
                                        },
                                        (v8 /*: any*/)
                                    ],
                                    "storageKey": null
                                },
                                (v7 /*: any*/)
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v12 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "useTenantUsers_users",
                    "kind": "LinkedHandle",
                    "name": "users"
                }
            ]
        },
        "params": {
            "cacheID": "8e1cf308c35f4c92159b2c3fd598020e",
            "id": null,
            "metadata": {
                "connection": [
                    {
                        "count": null,
                        "cursor": null,
                        "direction": "forward",
                        "path": [
                            "users"
                        ]
                    }
                ]
            },
            "name": "useTenantUsersQuery",
            "operationKind": "query",
            "text": "query useTenantUsersQuery(\n  $tenantId: ID!\n) {\n  users(tenantId: $tenantId, first: 100) {\n    edges {\n      node {\n        id\n        name\n        displayName\n        email\n        assignedRoles(objectId: $tenantId, first: 100) {\n          edges {\n            cursor\n            node {\n              id\n              name\n              identifier\n              __typename\n            }\n          }\n          pageInfo {\n            endCursor\n            hasNextPage\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0ce09ae59480105aa5f2d4b1372377ce';
export default node;
