/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AcceptUserInviteInput = {
    clientMutationId: string;
    firstName: string;
    lastName: string;
    password: string;
    secret: string;
    tenantId: string;
    title?: string | null;
};
export type AccountAcceptInviteRouteMutationVariables = {
    input: AcceptUserInviteInput;
};
export type AccountAcceptInviteRouteMutationResponse = {
    readonly acceptUserInvite: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type AccountAcceptInviteRouteMutation = {
    readonly response: AccountAcceptInviteRouteMutationResponse;
    readonly variables: AccountAcceptInviteRouteMutationVariables;
};



/*
mutation AccountAcceptInviteRouteMutation(
  $input: AcceptUserInviteInput!
) {
  acceptUserInvite(input: $input) {
    errors {
      code
      path
      message
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "AcceptUserInvitePayload",
            "kind": "LinkedField",
            "name": "acceptUserInvite",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AccountAcceptInviteRouteMutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AccountAcceptInviteRouteMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "e57cea03ebd7217ab43e9cdfba374207",
            "id": null,
            "metadata": {},
            "name": "AccountAcceptInviteRouteMutation",
            "operationKind": "mutation",
            "text": "mutation AccountAcceptInviteRouteMutation(\n  $input: AcceptUserInviteInput!\n) {\n  acceptUserInvite(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a2c414a61f9cc55117f39da746dddfa9';
export default node;
