import { captureException } from "@sentry/react";
import * as React from "react";
import { Error500 } from "~/core/Error500";

export type ErrorBoundaryHandler = (err: Error) => React.ReactNode;

export interface ErrorBoundaryProps {
  fallback?: React.ElementType<{ error?: Error }> | ErrorBoundaryHandler;
}

interface State {
  error: Error | undefined;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, State> {
  public state: State = {
    error: undefined,
  };

  public static getDerivedStateFromError(error: Error): State {
    return { error };
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
    captureException(error);
    console.error("Uncaught error:", error, errorInfo);
  }

  public render(): React.ReactNode {
    if (this.state.error) {
      if (!this.props.fallback) {
        return <Error500 />;
      }

      return React.isValidElement(this.props.fallback)
        ? React.createElement(this.props.fallback as React.ElementType, {
            error: this.state.error,
          })
        : (this.props.fallback as ErrorBoundaryHandler)(this.state.error);
    }

    return this.props.children;
  }
}
