import { getEdges } from "@workflows/runtime-web";
import { ErrorNotFoundIllustration, Grid, NonIdealState } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { WorkflowChooserList_workflows$key } from "~/__graphql__/WorkflowChooserList_workflows.graphql";
import {
  WorkflowChooserListItem,
  WorkflowChooserListItemProps,
} from "./WorkflowChooserListItem";
import { WorkflowChooserListItemSkeleton } from "./WorkflowChooserListItemSkeleton";
import { ConfigValueQuickStart } from "~/de.smartconex.vertragsgenerator/dashboard/DashboardRoute";

export interface WorkflowChooserListProps {
  isPending?: boolean;
  prependItem?: ConfigValueQuickStart;
  onClick?: WorkflowChooserListItemProps["onClick"];
  workflows?: WorkflowChooserList_workflows$key | null;
}

export function WorkflowChooserList({
  workflows,
  isPending = false,
  onClick,
}: WorkflowChooserListProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.workflows");
  const { data } = usePaginationFragment(
    WorkflowChooserList_workflows,
    workflows || null
  );

  const edges = getEdges(data?.workflows);

  return isPending || edges.length > 0 ? (
    <Grid cols={{ xs: 1, sm: 2, md: 4 }}>
      {edges
        .filter((edge) => edge.node !== null)
        .map((edge) => {
          return (
            <WorkflowChooserListItem
              onClick={onClick!}
              fragment={edge.node!}
              key={edge.cursor}
            />
          );
        })}
      {isPending && (
        <>
          <WorkflowChooserListItemSkeleton />
          <WorkflowChooserListItemSkeleton />
          <WorkflowChooserListItemSkeleton />
          <WorkflowChooserListItemSkeleton />
        </>
      )}
    </Grid>
  ) : (
    <NonIdealState
      header={<ErrorNotFoundIllustration />}
      title={t("WorkflowChooserList.emptyState.title")}
      description={t("WorkflowChooserList.emptyState.description")}
    />
  );
}

const WorkflowChooserList_workflows = graphql`
  fragment WorkflowChooserList_workflows on RootQueryType
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
    tenantId: { type: "ID!" }
  )
  @refetchable(queryName: "WorkflowChooserListPaginationQuery") {
    workflows(tenantId: $tenantId, after: $cursor, first: $count)
      @connection(key: "WorkflowChooserList_workflows") {
      edges {
        cursor
        node {
          ...WorkflowChooserListItem_item
        }
      }
    }
  }
`;
