/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MatterTimelinePaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    id: string;
};
export type MatterTimelinePaginationQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"MatterTimeline_timeline">;
    } | null;
};
export type MatterTimelinePaginationQuery = {
    readonly response: MatterTimelinePaginationQueryResponse;
    readonly variables: MatterTimelinePaginationQueryVariables;
};



/*
query MatterTimelinePaginationQuery(
  $count: Int = 99
  $cursor: String
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...MatterTimeline_timeline_1G22uz
    id
  }
}

fragment MatterTimeline_timeline_1G22uz on HasTimeline {
  __isHasTimeline: __typename
  timeline(after: $cursor, first: $count) {
    edges {
      node {
        __typename
        ... on ThreadMessage {
          creator {
            name
            id
          }
          id
          bodyText
          insertedAt
        }
        ... on Activity {
          id
          identifier
          insertedAt
          actor {
            name
            id
          }
          toObject {
            id
            __typename
            ... on User {
              name
            }
            ... on Status {
              color
              textColor
              name
            }
            ... on Tag {
              color
              textColor
              name
            }
          }
        }
        ... on Node {
          __isNode: __typename
          id
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ... on Node {
    __isNode: __typename
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": 99,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any
    ], v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v6 = [
        (v5 /*: any*/),
        (v3 /*: any*/)
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "insertedAt",
        "storageKey": null
    } as any, v8 = [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "textColor",
            "storageKey": null
        } as any,
        (v5 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MatterTimelinePaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "MatterTimeline_timeline"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MatterTimelinePaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "TimelineItemConnection",
                                    "kind": "LinkedField",
                                    "name": "timeline",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "TimelineItemEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "kind": "LinkedField",
                                                                    "name": "creator",
                                                                    "plural": false,
                                                                    "selections": (v6 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "bodyText",
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/)
                                                            ],
                                                            "type": "ThreadMessage",
                                                            "abstractKey": null
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v3 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "identifier",
                                                                    "storageKey": null
                                                                },
                                                                (v7 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "User",
                                                                    "kind": "LinkedField",
                                                                    "name": "actor",
                                                                    "plural": false,
                                                                    "selections": (v6 /*: any*/),
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": null,
                                                                    "kind": "LinkedField",
                                                                    "name": "toObject",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v3 /*: any*/),
                                                                        (v2 /*: any*/),
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": [
                                                                                (v5 /*: any*/)
                                                                            ],
                                                                            "type": "User",
                                                                            "abstractKey": null
                                                                        },
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": (v8 /*: any*/),
                                                                            "type": "Status",
                                                                            "abstractKey": null
                                                                        },
                                                                        {
                                                                            "kind": "InlineFragment",
                                                                            "selections": (v8 /*: any*/),
                                                                            "type": "Tag",
                                                                            "abstractKey": null
                                                                        }
                                                                    ],
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "Activity",
                                                            "abstractKey": null
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v3 /*: any*/)
                                                            ],
                                                            "type": "Node",
                                                            "abstractKey": "__isNode"
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "MatterTimeline_timeline",
                                    "kind": "LinkedHandle",
                                    "name": "timeline"
                                },
                                {
                                    "kind": "TypeDiscriminator",
                                    "abstractKey": "__isNode"
                                }
                            ],
                            "type": "HasTimeline",
                            "abstractKey": "__isHasTimeline"
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "c9d19fcc10ce38e0f5d5ff6d4015ea89",
            "id": null,
            "metadata": {},
            "name": "MatterTimelinePaginationQuery",
            "operationKind": "query",
            "text": "query MatterTimelinePaginationQuery(\n  $count: Int = 99\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MatterTimeline_timeline_1G22uz\n    id\n  }\n}\n\nfragment MatterTimeline_timeline_1G22uz on HasTimeline {\n  __isHasTimeline: __typename\n  timeline(after: $cursor, first: $count) {\n    edges {\n      node {\n        __typename\n        ... on ThreadMessage {\n          creator {\n            name\n            id\n          }\n          id\n          bodyText\n          insertedAt\n        }\n        ... on Activity {\n          id\n          identifier\n          insertedAt\n          actor {\n            name\n            id\n          }\n          toObject {\n            id\n            __typename\n            ... on User {\n              name\n            }\n            ... on Status {\n              color\n              textColor\n              name\n            }\n            ... on Tag {\n              color\n              textColor\n              name\n            }\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '43a6dc9f689691a9045432c982d2ad67';
export default node;
