import {
  useDeAgadDpmCreateSubtenantMutation,
  useDeAgadDpmCreateSubtenantMutationResponse,
} from "~/__graphql__/useDeAgadDpmCreateSubtenantMutation.graphql";
import { newId, useMutation } from "@workflows/runtime-web";
import { ConnectionHandler, graphql } from "react-relay/hooks";
import { useTenantId } from "@workflows/ui";

interface useDeAgadDpmCreateSubtenantProps {
  onError: (error: Error) => void;
  onCompleted: (data: useDeAgadDpmCreateSubtenantMutationResponse) => void;
  input: {
    name: string;
    host: string;
    companyName: string;
    companyAddress: string;
    companyPostalCode: string;
    companyCity: string;
    contactName: string;
    position: string;
    email: string;
  };
}

interface useDeAgadDpmCreateSubtenantValue {
  deAgadDpmCreateSubtenant: (props: useDeAgadDpmCreateSubtenantProps) => void;
  isPending: boolean;
}

export const useDeAgadDpmCreateSubtenant =
  (): useDeAgadDpmCreateSubtenantValue => {
    const tenantId = useTenantId();
    const [mutation, isPending] =
      useMutation<useDeAgadDpmCreateSubtenantMutation>(
        deAgadDpmCreateSubtenantMutation
      );

    const connectionId = ConnectionHandler.getConnectionID(
      tenantId,
      "useTenants_tenants"
    );

    const deAgadDpmCreateSubtenant = (
      props: useDeAgadDpmCreateSubtenantProps
    ) => {
      const {
        onError,
        onCompleted,
        input: {
          name,
          host,
          companyName,
          companyAddress,
          companyPostalCode,
          companyCity,
          contactName,
          position,
          email,
        },
      } = props;

      const input = {
        clientMutationId: newId(),
        name,
        host,
        parentId: tenantId,
        entities: [
          {
            identifier: "de.agad.dpm.entities.tenants.address",
            type: "ai.workflows.entity.organization",
            name: companyName,
            streetAddress: companyAddress,
            postalCode: companyPostalCode,
            locality: companyCity,
          },
          {
            identifier: "de.agad.dpm.entities.tenants.contact",
            type: "ai.workflows.entity.person",
            name: contactName,
            position,
            email,
          },
        ],
      };

      mutation({
        variables: {
          input,
          connections: [connectionId],
        },
        onError,
        onCompleted,
      });
    };

    return { deAgadDpmCreateSubtenant, isPending };
  };

const deAgadDpmCreateSubtenantMutation = graphql`
  mutation useDeAgadDpmCreateSubtenantMutation(
    $connections: [ID!]!
    $input: DeAgadDpmCreateSubtenantInput!
  ) {
    deAgadDpmCreateSubtenant(input: $input) {
      errors {
        code
        path
        message
        type
      }
      tenant
        @prependNode(connections: $connections, edgeTypeName: "TenantEdge") {
        id
        name
        insertedAt
      }
    }
  }
`;
