import * as React from "react";
import { SearchField } from "./SearchField";
import { SearchResults } from "./SearchResults";
import { SearchResultsListItem } from "./SearchResultsListItem";

export interface SearchProps {
  emptyState: React.ReactNode;
}

export const Search: React.FC<SearchProps> = ({ emptyState }) => {
  const [query, setQuery] = React.useState("");
  const [input, setInput] = React.useState("");

  const handleChange = (query: string) => {
    setInput(query);
  };

  const handleSearch = () => {
    setQuery(input);
  };

  return (
    <>
      <SearchField
        value={input}
        onChange={handleChange}
        onSearch={handleSearch}
      />
      <React.Suspense fallback={<SearchFallback />}>
        {query.length !== 0 ? <SearchResults query={query} /> : emptyState}
      </React.Suspense>
    </>
  );
};

export const SearchFallback = (): JSX.Element => {
  return (
    <>
      <SearchResultsListItem result={null} isPending />
      <SearchResultsListItem result={null} isPending />
    </>
  );
};
