/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type useTenantUsers_assignedRoles = {
    readonly assignedRoles: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly id: string;
                readonly name: string | null;
                readonly identifier: string | null;
            } | null;
        } | null> | null;
    } | null;
    readonly " $refType": "useTenantUsers_assignedRoles";
};
export type useTenantUsers_assignedRoles$data = useTenantUsers_assignedRoles;
export type useTenantUsers_assignedRoles$key = {
    readonly " $data"?: useTenantUsers_assignedRoles$data;
    readonly " $fragmentRefs": FragmentRefs<"useTenantUsers_assignedRoles">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [
        {
            "kind": "RootArgument",
            "name": "tenantId"
        }
    ],
    "kind": "Fragment",
    "metadata": {
        "connection": [
            {
                "count": null,
                "cursor": null,
                "direction": "forward",
                "path": [
                    "assignedRoles"
                ]
            }
        ]
    },
    "name": "useTenantUsers_assignedRoles",
    "selections": [
        {
            "alias": "assignedRoles",
            "args": [
                {
                    "kind": "Variable",
                    "name": "objectId",
                    "variableName": "tenantId"
                }
            ],
            "concreteType": "RoleConnection",
            "kind": "LinkedField",
            "name": "__user_assignedRoles_connection",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "identifier",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "__typename",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        }
    ],
    "type": "User",
    "abstractKey": null
} as any;
(node as any).hash = '05bf49177a4ec3bdc94df553f6f542f9';
export default node;
