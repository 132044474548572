import { getEdges } from "@workflows/runtime-web";
import {
  Grid,
  NonIdealState,
  Section,
  SectionBody,
  TemplatesEmptyIllustration,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql, usePaginationFragment } from "react-relay/hooks";
import { CollectionItemChooserList_collection$key } from "~/__graphql__/CollectionItemChooserList_collection.graphql";
import { CollectionItemChooserListFileItem } from "./CollectionItemChooserListFileItem";
import { CollectionItemChooserListWorkflowItem } from "./CollectionItemChooserListWorkflowItem";
import { CollectionItemChooserListItemSkeleton } from "./CollectionItemChooserListItemSkeleton";

export interface CollectionItemChooserListProps {
  collection?: CollectionItemChooserList_collection$key | null;
  isPending?: boolean;
  start?: React.ReactNodeArray;
}

export function CollectionItemChooserList({
  collection,
  isPending = false,
  start,
}: CollectionItemChooserListProps): JSX.Element | null {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const { data } = usePaginationFragment(
    CollectionItemChooserList_collection,
    collection || null
  );
  const edges = getEdges(data?.items);

  if (!isPending && edges.length === 0) {
    return null;
  }

  return (
    <Section title={data?.name}>
      <SectionBody>
        {isPending || edges.length > 0 ? (
          <Grid cols={{ sm: 1, lg: 3 }}>
            {Array.isArray(start) ? <>{start.map((node) => node)}</> : null}
            {edges.map((edge) => {
              if (!edge.node) {
                return null;
              }
              if (edge.node.__typename === "Workflow") {
                return (
                  <CollectionItemChooserListWorkflowItem
                    fragment={edge.node}
                    key={edge.cursor}
                  />
                );
              } else if (edge.node.__typename === "File") {
                return (
                  <CollectionItemChooserListFileItem
                    fragment={edge.node}
                    key={edge.cursor}
                  />
                );
              } else {
                console.warn(
                  "Unknown type of collection item: %s",
                  edge.node.__typename
                );
                return null;
              }
            })}
            {isPending && (
              <>
                <CollectionItemChooserListItemSkeleton />
                <CollectionItemChooserListItemSkeleton />
                <CollectionItemChooserListItemSkeleton />
                <CollectionItemChooserListItemSkeleton />
              </>
            )}
          </Grid>
        ) : (
          <NonIdealState
            header={<TemplatesEmptyIllustration />}
            title={t("CollectionItemChooserList.NonIdealState.title")}
            description={t(
              "CollectionItemChooserList.NonIdealState.description"
            )}
            embedded
          />
        )}
      </SectionBody>
    </Section>
  );
}

const CollectionItemChooserList_collection = graphql`
  fragment CollectionItemChooserList_collection on Collection
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 100 }
  )
  @refetchable(queryName: "CollectionItemChooserListPaginationQuery") {
    name
    items(after: $cursor, first: $count)
      @connection(key: "CollectionItemChooserList_items") {
      edges {
        cursor
        node {
          __typename
          ...CollectionItemChooserListFileItem_item
          ...CollectionItemChooserListWorkflowItem_item
        }
      }
    }
  }
`;
