import {
  getEdgeNodes,
  graphql,
  useLazyLoadQuery,
} from "@workflows/runtime-web";
import {
  BrandLogo,
  Column,
  ColumnBody,
  ColumnFooter,
  Link,
  Stack,
  StackItem,
  Toaster,
  useDialog,
  useTenantId,
} from "@workflows/ui";
import * as React from "react";
import { DynamicLandingContent } from "~/core/DynamicLandingContent";
import { AccountLayoutQuery } from "~/__graphql__/AccountLayoutQuery.graphql";
import {
  ConfigValueFooter,
  ConfigValueLanding,
  useConfigValue,
} from "~/core/configValues";
import { SupportDialog } from "~/core/Dialogs/SupportDialog";

export interface AccountLayoutProps {
  children: React.ReactElement;
}

export function AccountLayout({ children }: AccountLayoutProps): JSX.Element {
  const tenantId = useTenantId();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();
  const { tenant } = useLazyLoadQuery<AccountLayoutQuery>(FooterMenuQuery, {
    tenantId,
  });
  const nav = getEdgeNodes(tenant?.nav?.items || null);
  const footerConfig = useConfigValue<ConfigValueFooter>(
    "ai.workflows.tenants.config.footer"
  );

  const landingPageConfig = useConfigValue<ConfigValueLanding>(
    "ai.workflows.tenants.config.landing"
  );

  const handleSupportDialogOpen = (event: React.SyntheticEvent) => {
    event.preventDefault();
    openDialog();
  };

  const footerStart = () => {
    const currentYear = new Date().getFullYear();
    return footerConfig
      ? footerConfig.start.replace("{year}", `${currentYear}`)
      : `© ${currentYear} smartvokat GmbH`;
  };

  return (
    <>
      <Column role="aside" focus="primary" width={2 / 3}>
        <ColumnBody inset>
          <StackItem align="start">
            <Link to="/">
              <BrandLogo size="lg" />
            </Link>
          </StackItem>
          {children}
        </ColumnBody>

        <ColumnFooter
          borderless
          start={<StackItem>{footerStart()}</StackItem>}
          end={
            <Stack direction="horizontal">
              {nav.map((navItem) => {
                if (navItem.route && navItem.route.path) {
                  return (
                    <Link to={navItem.route.path} key={navItem.id}>
                      {navItem.name}
                    </Link>
                  );
                } else if (navItem.externalUrl) {
                  return (
                    <Link
                      href={navItem.externalUrl}
                      key={navItem.id}
                      target="_blank"
                    >
                      {navItem.name}
                    </Link>
                  );
                } else {
                  return null;
                }
              })}
              <StackItem>
                <Link to={"/support"} onClick={handleSupportDialogOpen}>
                  Support
                </Link>
              </StackItem>
            </Stack>
          }
        />
      </Column>
      {landingPageConfig && (
        <Column focus="secondary" width={1 / 3}>
          <ColumnBody inset>
            <DynamicLandingContent name={landingPageConfig.name} />
          </ColumnBody>
        </Column>
      )}
      {isDialogOpen && <SupportDialog onClose={closeDialog} />}
      {/*TODO: Add a Global Toaster */}
      {<Toaster />}
    </>
  );
}

const FooterMenuQuery = graphql`
  query AccountLayoutQuery($tenantId: ID!) {
    tenant: node(id: $tenantId) {
      id
      ... on Tenant {
        nav(identifier: "ai.workflows.nav.footer") {
          items {
            edges {
              node {
                id
                name
                externalUrl
                route {
                  path
                }
              }
            }
          }
        }
      }
    }
  }
`;
