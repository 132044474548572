import { Tab, Tabs } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { TenantCreator } from "~/de.agad.dpm/tenants/TenantCreator";

export function TenantCreatorColumnTabs(): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");

  return (
    <Tabs defaultSelectedTabId="details">
      <Tab id="details" title={t("TenantColumnTabs.details")}>
        <TenantCreator />
      </Tab>
      <Tab id="documents" title={t("TenantColumnTabs.documents")} disabled>
        {t("TenantColumnTabs.documents")}
      </Tab>
      <Tab id="people" title={t("TenantColumnTabs.people")} disabled>
        {t("TenantColumnTabs.people")}
      </Tab>
    </Tabs>
  );
}
