/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type PatchDocumentInput = {
    clientMutationId: string;
    documentId: string;
    patch?: Array<Patch | null> | null;
    tenantId: string;
};
export type Patch = {
    op: string;
    path?: string | null;
    value?: unknown | null;
};
export type WizardSyncMutationVariables = {
    input: PatchDocumentInput;
};
export type WizardSyncMutationResponse = {
    readonly patchDocument: {
        readonly document: {
            readonly " $fragmentRefs": FragmentRefs<"DocumentViewerBody_values">;
        } | null;
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type WizardSyncMutation = {
    readonly response: WizardSyncMutationResponse;
    readonly variables: WizardSyncMutationVariables;
};



/*
mutation WizardSyncMutation(
  $input: PatchDocumentInput!
) {
  patchDocument(input: $input) {
    document {
      ...DocumentViewerBody_values
      id
    }
    errors {
      code
      path
      message
    }
  }
}

fragment DocumentViewerBody_values on Document {
  id
  values(first: 100) {
    edges {
      node {
        id
        name
        type
        value
        identifier
        props
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "WizardSyncMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PatchDocumentPayload",
                    "kind": "LinkedField",
                    "name": "patchDocument",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "document",
                            "plural": false,
                            "selections": [
                                {
                                    "args": null,
                                    "kind": "FragmentSpread",
                                    "name": "DocumentViewerBody_values"
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "WizardSyncMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "PatchDocumentPayload",
                    "kind": "LinkedField",
                    "name": "patchDocument",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Document",
                            "kind": "LinkedField",
                            "name": "document",
                            "plural": false,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "concreteType": "DocumentValueConnection",
                                    "kind": "LinkedField",
                                    "name": "values",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "DocumentValueEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "DocumentValue",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v3 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "type",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "value",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "identifier",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "props",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "__typename",
                                                            "storageKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": "values(first:100)"
                                },
                                {
                                    "alias": null,
                                    "args": (v4 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "DocumentViewerBody_values",
                                    "kind": "LinkedHandle",
                                    "name": "values"
                                }
                            ],
                            "storageKey": null
                        },
                        (v2 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "baad609033835374c6414d4ebf860993",
            "id": null,
            "metadata": {},
            "name": "WizardSyncMutation",
            "operationKind": "mutation",
            "text": "mutation WizardSyncMutation(\n  $input: PatchDocumentInput!\n) {\n  patchDocument(input: $input) {\n    document {\n      ...DocumentViewerBody_values\n      id\n    }\n    errors {\n      code\n      path\n      message\n    }\n  }\n}\n\nfragment DocumentViewerBody_values on Document {\n  id\n  values(first: 100) {\n    edges {\n      node {\n        id\n        name\n        type\n        value\n        identifier\n        props\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '4dee86a605e5ba753a8e73157a47190b';
export default node;
