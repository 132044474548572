import { ConfirmDialog } from "@workflows/ui";
import * as React from "react";
import { useDeleteDocumentTemplate } from "~/de.agad.dpm/tenants/hooks/useDeleteDocumentTemplate";
import { useTranslation } from "react-i18next";

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  documentTemplateId: string;
  subtenantId: string;
}

export const DeleteDocumentTemplateDialog = ({
  isOpen,
  onClose,
  documentTemplateId,
  subtenantId,
}: Props): JSX.Element => {
  const { deleteDocumentTemplate, isPending } = useDeleteDocumentTemplate();

  const handleDeleteDocument = (result: boolean) => {
    if (!result) {
      onClose();
      return;
    }
    deleteDocumentTemplate({
      input: { documentTemplateId, subtenantId },
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.deleteDocumentTemplate?.errors) {
          console.error(data.deleteDocumentTemplate.errors);
        }
        onClose();
      },
    });
  };

  const { t } = useTranslation("de.agad.dpm");

  return (
    <ConfirmDialog
      cancelText={t("DeleteDocumentTemplateDialog.cancelText")}
      confirmText={t("DeleteDocumentTemplateDialog.confirmText")}
      intent="critical"
      isOpen={isOpen}
      isPending={isPending}
      onConfirm={handleDeleteDocument}
      title={t("DeleteDocumentTemplateDialog.title")}
    >
      {t("DeleteDocumentTemplateDialog.body")}
    </ConfirmDialog>
  );
};
