import * as React from "react";

export interface CoreRouterContextShape {
  baseUrl: string;
}

export const CoreRouterContextContainer =
  React.createContext<CoreRouterContextShape>({
    baseUrl: "",
  });

export interface CoreRouterContextProps extends CoreRouterContextShape {
  children?: React.ReactNode;
  baseUrl: string;
}

export function CoreRouterContext({
  children,
  baseUrl,
}: CoreRouterContextProps): JSX.Element {
  return (
    <CoreRouterContextContainer.Provider value={{ baseUrl }}>
      {children}
    </CoreRouterContextContainer.Provider>
  );
}
