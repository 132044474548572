import { Route, RouteProps } from "@workflows/runtime-web";
import * as React from "react";
import { SuspenseFallback } from "~/core/SuspenseFallback";
import { CoreEntryPointRouteContainer } from "./CoreEntryPointRouteContainer";

export interface CoreEntryPointRoute
  extends Omit<RouteProps, "render" | "component"> {
  entrypoint: any;
  fallback?: React.ReactNode;
}

export function CoreEntryPointRoute({
  entrypoint,
  fallback = <SuspenseFallback />,
  ...props
}: CoreEntryPointRoute): JSX.Element {
  return (
    <Route
      {...props}
      render={(routeProps) => (
        <React.Suspense fallback={fallback}>
          <CoreEntryPointRouteContainer
            entrypoint={entrypoint}
            routeProps={routeProps}
          />
        </React.Suspense>
      )}
    />
  );
}
