import { RouteComponentProps } from "@workflows/runtime-web";
import * as React from "react";
import { CoreRouterContext } from "~/core/CoreRouterContext";
import { Wizard } from "./Wizard";

export type WizardRouteProps = RouteComponentProps<{
  documentId: string;
  slug?: string;
}>;

export function WizardRoute({ match }: WizardRouteProps): JSX.Element {
  const baseUrl = match.params.slug
    ? match.url.replace(`/${match.params.slug}`, "")
    : match.url;

  return (
    <CoreRouterContext baseUrl={baseUrl}>
      <Wizard documentId={match.params.documentId} slug={match.params.slug} />
    </CoreRouterContext>
  );
}
