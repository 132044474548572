import {
  Card,
  CardBody,
  CardHeader,
  Icon,
  Text,
  Truncate,
} from "@workflows/ui";
import * as React from "react";
import { graphql, useFragment } from "react-relay/hooks";
import { CollectionItemChooserListFileItem_item$key } from "~/__graphql__/CollectionItemChooserListFileItem_item.graphql";

export interface CollectionItemChooserListFileItemProps {
  fragment: CollectionItemChooserListFileItem_item$key;
}

export function CollectionItemChooserListFileItem({
  fragment,
}: CollectionItemChooserListFileItemProps): JSX.Element {
  const item = useFragment(CollectionItemChooserListFileItem_item, fragment);

  const handleDownload = (e: React.SyntheticEvent<HTMLElement>) => {
    if (!item.downloadUrl) {
      return;
    }
    e.preventDefault();
    window.location.href = item.downloadUrl;
  };

  return (
    <Card interactive onClick={handleDownload}>
      <CardHeader icon={<Icon identifier="ai.workflows.icons:Download" />}>
        <Truncate lines={2}>
          <Text variant="headingMd">{item.name}</Text>
        </Truncate>
      </CardHeader>
      <CardBody>
        <Text color="muted">
          <Truncate lines={10}>{item.description}</Truncate>
        </Text>
      </CardBody>
    </Card>
  );
}

const CollectionItemChooserListFileItem_item = graphql`
  fragment CollectionItemChooserListFileItem_item on File {
    id
    name
    description
    downloadUrl
  }
`;
