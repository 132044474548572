/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type CreateDocumentInput = {
    assignees: Array<AssigneeRename | null>;
    clientMutationId: string;
    defaultValues?: Array<CreateDocumentDefaultValue | null> | null;
    description?: string | null;
    isTemplate?: boolean | null;
    statusId?: string | null;
    statusIdentifier?: string | null;
    templateId?: string | null;
    tenantId: string;
    title: string;
};
export type AssigneeRename = {
    assigneeId: string;
    roleId?: string | null;
    roleIdentifier?: string | null;
};
export type CreateDocumentDefaultValue = {
    identifier: string;
    value?: unknown | null;
};
export type AppDokgeneratorWorkflowsEmploymentRefv1MutationVariables = {
    input: CreateDocumentInput;
};
export type AppDokgeneratorWorkflowsEmploymentRefv1MutationResponse = {
    readonly createDocument: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
        readonly document: {
            readonly id: string;
        } | null;
    } | null;
};
export type AppDokgeneratorWorkflowsEmploymentRefv1Mutation = {
    readonly response: AppDokgeneratorWorkflowsEmploymentRefv1MutationResponse;
    readonly variables: AppDokgeneratorWorkflowsEmploymentRefv1MutationVariables;
};



/*
mutation AppDokgeneratorWorkflowsEmploymentRefv1Mutation(
  $input: CreateDocumentInput!
) {
  createDocument(input: $input) {
    errors {
      code
      path
      message
    }
    document {
      id
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "CreateDocumentPayload",
            "kind": "LinkedField",
            "name": "createDocument",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Document",
                    "kind": "LinkedField",
                    "name": "document",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AppDokgeneratorWorkflowsEmploymentRefv1Mutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AppDokgeneratorWorkflowsEmploymentRefv1Mutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "d7330d18c7e4490dcf0decbe6eca0aac",
            "id": null,
            "metadata": {},
            "name": "AppDokgeneratorWorkflowsEmploymentRefv1Mutation",
            "operationKind": "mutation",
            "text": "mutation AppDokgeneratorWorkflowsEmploymentRefv1Mutation(\n  $input: CreateDocumentInput!\n) {\n  createDocument(input: $input) {\n    errors {\n      code\n      path\n      message\n    }\n    document {\n      id\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '78adf323e5b05759120e8a71d15e4996';
export default node;
