/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorkflowChooserQueryVariables = {
    tenantId: string;
};
export type WorkflowChooserQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"WorkflowChooserList_workflows">;
};
export type WorkflowChooserQuery = {
    readonly response: WorkflowChooserQueryResponse;
    readonly variables: WorkflowChooserQueryVariables;
};



/*
query WorkflowChooserQuery(
  $tenantId: ID!
) {
  ...WorkflowChooserList_workflows_1GYzvY
}

fragment WorkflowChooserListItem_item on Workflow {
  id
  name
  descriptionText
  color
  icon
}

fragment WorkflowChooserList_workflows_1GYzvY on RootQueryType {
  workflows(tenantId: $tenantId, first: 100) {
    edges {
      cursor
      node {
        ...WorkflowChooserListItem_item
        id
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "WorkflowChooserQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "WorkflowChooserList_workflows"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "WorkflowChooserQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "WorkflowConnection",
                    "kind": "LinkedField",
                    "name": "workflows",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkflowEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Workflow",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "descriptionText",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "color",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "icon",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "WorkflowChooserList_workflows",
                    "kind": "LinkedHandle",
                    "name": "workflows"
                }
            ]
        },
        "params": {
            "cacheID": "73fc81bac523c3e3d33f7905ab990250",
            "id": null,
            "metadata": {},
            "name": "WorkflowChooserQuery",
            "operationKind": "query",
            "text": "query WorkflowChooserQuery(\n  $tenantId: ID!\n) {\n  ...WorkflowChooserList_workflows_1GYzvY\n}\n\nfragment WorkflowChooserListItem_item on Workflow {\n  id\n  name\n  descriptionText\n  color\n  icon\n}\n\nfragment WorkflowChooserList_workflows_1GYzvY on RootQueryType {\n  workflows(tenantId: $tenantId, first: 100) {\n    edges {\n      cursor\n      node {\n        ...WorkflowChooserListItem_item\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '5bff7b34216f8a475a1e67ed72531e4e';
export default node;
