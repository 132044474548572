import { endpoints } from "~/client/config";

const cookieName = "wf.token";

export function setSessionCookie(
  token: string,
  maxAge: number | null = null
): void {
  const cookieMaxAge = maxAge ? maxAge : 5 * 60 * 60 * 1000; // 5 hours
  const domain = window.location.hostname.split(".").slice(-2).join(".");

  document.cookie = `${cookieName}=${token}; max-age=${cookieMaxAge}; samesite=strict; path=/; domain=${domain}`;
}

export const hasValidSessionCookie = async (): Promise<boolean> => {
  const cookies = parseCookies<{ "wf.token": string }>(document.cookie);

  if (!cookies["wf.token"] || cookies["wf.token"] === "") {
    return Promise.resolve(false);
  }

  try {
    const response = await fetch(`${endpoints.api}token_info`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token: cookies["wf.token"] }),
    });

    const data = await response.json();

    if (data.active) {
      setSessionCookie(cookies["wf.token"], data.exp);
    }

    return data.active;
  } catch (err) {
    console.error(err);
    return true;
  }
};

export function parseCookies<T = Record<string, string>>(str: string): T {
  return str
    .split(";")
    .map((v) => v.split("="))
    .filter((v) => Array.isArray(v) && v.length === 2)
    .reduce((acc, v) => {
      (acc as any)[decodeURIComponent(v[0].trim())] = decodeURIComponent(
        v[1].trim()
      );
      return acc;
    }, {} as T);
}

export function signOut(): void {
  fetch("/sign-out", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }).then(({ url }) => {
    window.location.href = url;
  });
}
