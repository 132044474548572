/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type InviteUsersInput = {
    clientMutationId: string;
    invitees: Array<InviteesUsersInput | null>;
    tenantId: string;
};
export type InviteesUsersInput = {
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    roleId?: string | null;
    roleIdentifier?: string | null;
    title?: string | null;
};
export type useInviteUsersMutationVariables = {
    input: InviteUsersInput;
    tenantId: string;
};
export type useInviteUsersMutationResponse = {
    readonly inviteUsers: {
        readonly users: ReadonlyArray<{
            readonly id: string;
            readonly name: string;
            readonly displayName: string;
            readonly email: string;
            readonly assignedRoles: {
                readonly edges: ReadonlyArray<{
                    readonly cursor: string | null;
                    readonly node: {
                        readonly id: string;
                        readonly name: string | null;
                        readonly identifier: string | null;
                    } | null;
                } | null> | null;
            } | null;
        } | null> | null;
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
        } | null> | null;
    } | null;
};
export type useInviteUsersMutation = {
    readonly response: useInviteUsersMutationResponse;
    readonly variables: useInviteUsersMutationVariables;
};



/*
mutation useInviteUsersMutation(
  $input: InviteUsersInput!
  $tenantId: ID!
) {
  inviteUsers(input: $input) {
    users {
      id
      name
      displayName
      email
      assignedRoles(objectId: $tenantId, first: 100) {
        edges {
          cursor
          node {
            id
            name
            identifier
            __typename
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
    errors {
      code
      path
      message
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "displayName",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
    } as any, v6 = {
        "kind": "Variable",
        "name": "objectId",
        "variableName": "tenantId"
    } as any, v7 = [
        {
            "alias": null,
            "args": null,
            "concreteType": "RoleEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Role",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "identifier",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any,
        {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ], v8 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "path",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "message",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v9 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any,
        (v6 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useInviteUsersMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "InviteUsersPayload",
                    "kind": "LinkedField",
                    "name": "inviteUsers",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "users",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "alias": "assignedRoles",
                                    "args": [
                                        (v6 /*: any*/)
                                    ],
                                    "concreteType": "RoleConnection",
                                    "kind": "LinkedField",
                                    "name": "__user_assignedRoles_connection",
                                    "plural": false,
                                    "selections": (v7 /*: any*/),
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useInviteUsersMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "InviteUsersPayload",
                    "kind": "LinkedField",
                    "name": "inviteUsers",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "users",
                            "plural": true,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v9 /*: any*/),
                                    "concreteType": "RoleConnection",
                                    "kind": "LinkedField",
                                    "name": "assignedRoles",
                                    "plural": false,
                                    "selections": (v7 /*: any*/),
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v9 /*: any*/),
                                    "filters": [
                                        "objectId"
                                    ],
                                    "handle": "connection",
                                    "key": "user_assignedRoles",
                                    "kind": "LinkedHandle",
                                    "name": "assignedRoles"
                                }
                            ],
                            "storageKey": null
                        },
                        (v8 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "6f9ccf1fa99832d27bda56ae89633cbb",
            "id": null,
            "metadata": {},
            "name": "useInviteUsersMutation",
            "operationKind": "mutation",
            "text": "mutation useInviteUsersMutation(\n  $input: InviteUsersInput!\n  $tenantId: ID!\n) {\n  inviteUsers(input: $input) {\n    users {\n      id\n      name\n      displayName\n      email\n      assignedRoles(objectId: $tenantId, first: 100) {\n        edges {\n          cursor\n          node {\n            id\n            name\n            identifier\n            __typename\n          }\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n    }\n    errors {\n      code\n      path\n      message\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '41ce0a03624d804c3ce1a26c20012bb2';
export default node;
