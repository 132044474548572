import { Redirect, Route, Switch } from "@workflows/runtime-web";
import {
  Column,
  ColumnBody,
  ColumnHeader,
  Columns,
  DocumentIcon,
  Nav,
} from "@workflows/ui";
import { NavItem } from "@workflows/ui/lib/Nav/item";
import * as React from "react";
import { GlossaryPageRoute } from "./GlossaryPageRoute";

export function GlossaryRoute(): JSX.Element {
  return (
    <Columns>
      <Column role="aside" background="muted">
        <ColumnHeader title="Inhaltsverzeichnis" />
        <ColumnBody inset>
          <Nav>
            <NavItem icon={<DocumentIcon />} to="/glossary/abtretung">
              Abtretung
            </NavItem>
            <NavItem icon={<DocumentIcon />} to="/glossary/arbeitsvertrag">
              Arbeitsvertrag
            </NavItem>
            <NavItem
              icon={<DocumentIcon />}
              to="/glossary/befristung-ohne-sachgrund"
            >
              Befristung ohne Sachgrund
            </NavItem>
            <NavItem icon={<DocumentIcon />} to="/glossary/kuendigungsfristen">
              Kündigungsfristen
            </NavItem>
            <NavItem icon={<DocumentIcon />} to="/glossary/rente">
              Rente
            </NavItem>
            <NavItem icon={<DocumentIcon />} to="/glossary/urlaub">
              Urlaub
            </NavItem>
          </Nav>
        </ColumnBody>
      </Column>
      <Column role="main">
        <ColumnBody inset>
          <Switch>
            <Route path="/glossary/:slug" component={GlossaryPageRoute} />
            <Redirect from="/glossary" to="/glossary/abtretung" />
          </Switch>
        </ColumnBody>
      </Column>
    </Columns>
  );
}
