/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type AddThreadMessageInput = {
    body: string;
    clientMutationId: string;
    tenantId: string;
    thread?: AddThreadMessageThread | null;
    threadId?: string | null;
};
export type AddThreadMessageThread = {
    refId: string;
};
export type MatterTimelineAddThreadMessageMutationVariables = {
    input: AddThreadMessageInput;
};
export type MatterTimelineAddThreadMessageMutationResponse = {
    readonly addThreadMessage: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly message: {
            readonly id: string;
            readonly bodyText: string;
        } | null;
    } | null;
};
export type MatterTimelineAddThreadMessageMutation = {
    readonly response: MatterTimelineAddThreadMessageMutationResponse;
    readonly variables: MatterTimelineAddThreadMessageMutationVariables;
};



/*
mutation MatterTimelineAddThreadMessageMutation(
  $input: AddThreadMessageInput!
) {
  addThreadMessage(input: $input) {
    errors {
      code
      message
      type
    }
    message {
      id
      bodyText
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "AddThreadMessagePayload",
            "kind": "LinkedField",
            "name": "addThreadMessage",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ThreadMessage",
                    "kind": "LinkedField",
                    "name": "message",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "bodyText",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MatterTimelineAddThreadMessageMutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MatterTimelineAddThreadMessageMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "366ff8d7c72fd4b87ae86117f55f5b0d",
            "id": null,
            "metadata": {},
            "name": "MatterTimelineAddThreadMessageMutation",
            "operationKind": "mutation",
            "text": "mutation MatterTimelineAddThreadMessageMutation(\n  $input: AddThreadMessageInput!\n) {\n  addThreadMessage(input: $input) {\n    errors {\n      code\n      message\n      type\n    }\n    message {\n      id\n      bodyText\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '0ebe3585750bff769dae4e28cfdd3cf1';
export default node;
