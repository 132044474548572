import {
  getEdgeNodes,
  graphql,
  useHistory,
  useLazyLoadQuery,
  useSetRecoilState,
} from "@workflows/runtime-web";
import { Stack, StackItem } from "@workflows/ui";
import * as React from "react";
import { AccountLayout } from "~/accounts/AccountLayout";
import { ChooseTenantRouteQuery } from "~/__graphql__/ChooseTenantRouteQuery.graphql";
import { TenantList } from "./TenantList";
import { appSidebar } from "~/core/App/state";
import { SuspenseFallback } from "~/core/SuspenseFallback";

export const ChooseTenantRoute = (): JSX.Element => {
  const history = useHistory();
  const data = useLazyLoadQuery<ChooseTenantRouteQuery>(ViewerQuery, {});
  const tenants = getEdgeNodes(data.viewer?.tenants || null);
  const showAppSidebar = useSetRecoilState(appSidebar);

  React.useEffect(() => {
    if (tenants.length === 1) {
      showAppSidebar(true);
      window.location.href = tenants[0].host.baseUrl;
    } else {
      showAppSidebar(false);
    }
  }, [tenants, history, showAppSidebar]);

  return (
    <AccountLayout>
      {tenants.length > 0 ? (
        <Stack direction="vertical" fill justify="center" align="center">
          <StackItem width="1/2" align="center">
            <TenantList />
          </StackItem>
        </Stack>
      ) : (
        <SuspenseFallback />
      )}
    </AccountLayout>
  );
};

const ViewerQuery = graphql`
  query ChooseTenantRouteQuery {
    viewer {
      tenants {
        edges {
          node {
            host {
              baseUrl
            }
          }
        }
      }
    }
  }
`;
