import { Value } from "./types";

export const type = "integer";

export function load(val: Value<number>): Value<number> {
  return val;
}

export function dump(val: Value<number>): number | null {
  return val.value;
}
