/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ActionRouteQueryVariables = {
    tenantId: string;
    secret: string;
};
export type ActionRouteQueryResponse = {
    readonly action: {
        readonly id: string;
        readonly type: {
            readonly identifier: string;
        };
    } | null;
};
export type ActionRouteQuery = {
    readonly response: ActionRouteQueryResponse;
    readonly variables: ActionRouteQueryVariables;
};



/*
query ActionRouteQuery(
  $tenantId: ID!
  $secret: String!
) {
  action(tenantId: $tenantId, secret: $secret) {
    id
    type {
      identifier
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "secret"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tenantId"
    } as any, v2 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "secret",
                    "variableName": "secret"
                },
                {
                    "kind": "Variable",
                    "name": "tenantId",
                    "variableName": "tenantId"
                }
            ],
            "concreteType": "Action",
            "kind": "LinkedField",
            "name": "action",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "ObjectType",
                    "kind": "LinkedField",
                    "name": "type",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "identifier",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "ActionRouteQuery",
            "selections": (v2 /*: any*/),
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v1 /*: any*/),
                (v0 /*: any*/)
            ],
            "kind": "Operation",
            "name": "ActionRouteQuery",
            "selections": (v2 /*: any*/)
        },
        "params": {
            "cacheID": "3b42533bacf081d90a3fadcf3b27a962",
            "id": null,
            "metadata": {},
            "name": "ActionRouteQuery",
            "operationKind": "query",
            "text": "query ActionRouteQuery(\n  $tenantId: ID!\n  $secret: String!\n) {\n  action(tenantId: $tenantId, secret: $secret) {\n    id\n    type {\n      identifier\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'f86efd3260856a101a3a08081720c5e5';
export default node;
