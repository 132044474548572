import {
  ExtractNode,
  getEdgeNodes,
  useLazyLoadQuery,
} from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import { graphql } from "react-relay/hooks";
import {
  useDashboardDataQuery,
  useDashboardDataQueryResponse,
} from "~/__graphql__/useDashboardDataQuery.graphql";

export type UseDashboardDataQueryResponseDocuments = ExtractNode<
  NonNullable<useDashboardDataQueryResponse["documents"]>
>;

export interface UseDashboardData {
  documents: UseDashboardDataQueryResponseDocuments[];
  statuses: ExtractNode<
    NonNullable<
      NonNullable<useDashboardDataQueryResponse["tenant"]>["statuses"]
    >
  >[];
}

export function useDashboardData(): UseDashboardData {
  const tenantId = useTenantId();
  const data = useLazyLoadQuery<useDashboardDataQuery>(
    documentsAnsStatusesQuery,
    {
      tenantId,
    }
  );
  return {
    documents: getEdgeNodes(data.documents || null),
    statuses: getEdgeNodes(data.tenant?.statuses || null),
  };
}

const documentsAnsStatusesQuery = graphql`
  query useDashboardDataQuery($tenantId: ID!) {
    tenant: node(id: $tenantId) {
      id
      ... on Tenant {
        statuses {
          edges {
            node {
              id
              name
              identifier
              textColor
              color
            }
          }
        }
      }
    }
    documents(tenantId: $tenantId, sort: { field: TITLE, order: ASC }) {
      edges {
        node {
          id
          title
          status {
            color
            textColor
            identifier
            name
          }
          description
          color
        }
      }
    }
  }
`;
