/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useSnippetsQueryVariables = {
    identifier: string;
    tenantId: string;
};
export type useSnippetsQueryResponse = {
    readonly workflow: {
        readonly config: ReadonlyArray<{
            readonly key: string;
            readonly value: unknown;
        } | null> | null;
    } | null;
};
export type useSnippetsQuery = {
    readonly response: useSnippetsQueryResponse;
    readonly variables: useSnippetsQueryVariables;
};



/*
query useSnippetsQuery(
  $identifier: String!
  $tenantId: ID!
) {
  workflow(identifier: $identifier, tenantId: $tenantId) {
    config(keys: ["ai.workflows.workflows.config.preload"]) {
      key
      value
      id
    }
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "identifier"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "identifier",
            "variableName": "identifier"
        } as any,
        {
            "kind": "Variable",
            "name": "tenantId",
            "variableName": "tenantId"
        } as any
    ], v2 = [
        {
            "kind": "Literal",
            "name": "keys",
            "value": [
                "ai.workflows.workflows.config.preload"
            ]
        } as any
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "key",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "value",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useSnippetsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Workflow",
                    "kind": "LinkedField",
                    "name": "workflow",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v2 /*: any*/),
                            "concreteType": "ConfigValue",
                            "kind": "LinkedField",
                            "name": "config",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/)
                            ],
                            "storageKey": "config(keys:[\"ai.workflows.workflows.config.preload\"])"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useSnippetsQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "Workflow",
                    "kind": "LinkedField",
                    "name": "workflow",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": (v2 /*: any*/),
                            "concreteType": "ConfigValue",
                            "kind": "LinkedField",
                            "name": "config",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                (v4 /*: any*/),
                                (v5 /*: any*/)
                            ],
                            "storageKey": "config(keys:[\"ai.workflows.workflows.config.preload\"])"
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "b1f0deb1e125abd628b12a790567829f",
            "id": null,
            "metadata": {},
            "name": "useSnippetsQuery",
            "operationKind": "query",
            "text": "query useSnippetsQuery(\n  $identifier: String!\n  $tenantId: ID!\n) {\n  workflow(identifier: $identifier, tenantId: $tenantId) {\n    config(keys: [\"ai.workflows.workflows.config.preload\"]) {\n      key\n      value\n      id\n    }\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'cd75b99fba7389aff208c483ce8a2dc2';
export default node;
