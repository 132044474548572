/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateDocumentTemplateInput = {
    clientMutationId: string;
    description?: string | null;
    documentTemplateId: string;
    props?: unknown | null;
    statusId?: string | null;
    statusIdentifier?: string | null;
    tenantId: string;
    title?: string | null;
};
export type useUpdateDocumentTemplateMutationVariables = {
    input: UpdateDocumentTemplateInput;
};
export type useUpdateDocumentTemplateMutationResponse = {
    readonly updateDocumentTemplate: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly path: ReadonlyArray<string | null> | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly documentTemplate: {
            readonly id: string;
            readonly description: string | null;
            readonly title: string;
        } | null;
    } | null;
};
export type useUpdateDocumentTemplateMutation = {
    readonly response: useUpdateDocumentTemplateMutationResponse;
    readonly variables: useUpdateDocumentTemplateMutationVariables;
};



/*
mutation useUpdateDocumentTemplateMutation(
  $input: UpdateDocumentTemplateInput!
) {
  updateDocumentTemplate(input: $input) {
    errors {
      code
      path
      message
      type
    }
    documentTemplate {
      id
      description
      title
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateDocumentTemplatePayload",
            "kind": "LinkedField",
            "name": "updateDocumentTemplate",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "path",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "DocumentTemplate",
                    "kind": "LinkedField",
                    "name": "documentTemplate",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useUpdateDocumentTemplateMutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useUpdateDocumentTemplateMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "d9a3cd575811a716a39a4e0e1c5dd75c",
            "id": null,
            "metadata": {},
            "name": "useUpdateDocumentTemplateMutation",
            "operationKind": "mutation",
            "text": "mutation useUpdateDocumentTemplateMutation(\n  $input: UpdateDocumentTemplateInput!\n) {\n  updateDocumentTemplate(input: $input) {\n    errors {\n      code\n      path\n      message\n      type\n    }\n    documentTemplate {\n      id\n      description\n      title\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '262e9fc8aa24a95fe13a60fccf065f65';
export default node;
