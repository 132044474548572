import { Icon, Stack, StackItem, ThreadMessage } from "@workflows/ui";
import * as React from "react";
import { graphql } from "relay-runtime";
import { activityMapping } from "~/activities/mapping";
import { MatterTimelineItemActivityFragment as ActivityFragment } from "~/__graphql__/MatterTimelineItemActivityFragment.graphql";
import { MatterTimelineItemThreadMessageFragment as ThreadMessageFragment } from "~/__graphql__/MatterTimelineItemThreadMessageFragment.graphql";
import { useTranslation } from "react-i18next";

export interface MatterTimelineItemProps {
  timelineItem: ActivityFragment | ThreadMessageFragment;
}

export function MatterTimelineItem({
  timelineItem,
}: MatterTimelineItemProps): JSX.Element {
  const icon = isThreadMessage(timelineItem)
    ? "ai.workflows.icons:Annotation"
    : iconMapping[timelineItem.identifier];

  return (
    <Stack direction="horizontal" gap="sm">
      <StackItem>
        <Icon identifier={icon} size="lg" />
      </StackItem>
      <TimelineDescription timelineItem={timelineItem} />
    </Stack>
  );
}

const TimelineDescription = ({ timelineItem }: MatterTimelineItemProps) => {
  const { t } = useTranslation("ai.workflows.matters");

  if (isThreadMessage(timelineItem)) {
    return (
      <ThreadMessage
        key={timelineItem?.id}
        bodyText={timelineItem?.bodyText}
        creatorName={timelineItem?.creator?.name || t("MatterTimeline.removed")}
        insertedAt={new Date(timelineItem?.insertedAt)}
      />
    );
  }

  const Component = activityMapping[timelineItem?.identifier];
  return <Component activity={timelineItem} />;
};

const isThreadMessage = (
  item: MatterTimelineItemProps["timelineItem"]
): item is ThreadMessageFragment => {
  return !Object.hasOwnProperty.call(item, "actor");
};

const iconMapping: Record<string, string> = {
  "ai.workflows.tags.activities.assignedTag": "ai.workflows.icons:Tag",
  "ai.workflows.tags.activities.unassignedTag": "ai.workflows.icons:Tag",
  "ai.workflows.statuses.activities.changedStatus":
    "ai.workflows.icons:ClipboardList",
  "ai.workflows.objects.activities.changedTitle":
    "ai.workflows.icons:PencilAlt",
  "ai.workflows.objects.activities.changedName": "ai.workflows.icons:PencilAlt",
  "ai.workflows.objects.activities.changedDescription":
    "ai.workflows.icons:PencilAlt",
  "ai.workflows.assignees.activities.assignedAssignee":
    "ai.workflows.icons:UserAdd",
  "ai.workflows.assignees.activities.unassignedAssignee":
    "ai.workflows.icons:UserRemove",
};

graphql`
  fragment MatterTimelineItemThreadMessageFragment on ThreadMessage {
    creator {
      name
    }
    id
    bodyText
    insertedAt
  }
`;

graphql`
  fragment MatterTimelineItemActivityFragment on Activity {
    id
    identifier
    insertedAt
    actor {
      name
    }
    toObject {
      id
      __typename
      ... on User {
        name
      }
      ... on Status {
        color
        textColor
        name
      }
      ... on Tag {
        color
        textColor
        name
      }
    }
  }
`;
