/* eslint-disable @typescript-eslint/no-explicit-any */
import { Column, ColumnBody, ColumnHeader } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "react-relay/hooks";
import { DocumentsList } from "./DocumentsList";

export interface DocumentsColumnProps {
  documentsRef: any;
  initRerender: () => void;
  onSelect: (selection: string[]) => void;
  selection: string[];
}

export function DocumentsColumn({
  documentsRef,
  onSelect,
  initRerender,
  selection,
}: DocumentsColumnProps): JSX.Element {
  const { t } = useTranslation("de.smartconex.vertragsgenerator");

  React.useEffect(() => {
    initRerender();
  }, []);

  return (
    <Column id="DocumentsColumn" role="main">
      <ColumnHeader title={t("DocumentsColumn.title")} />
      <ColumnBody scrollable>
        <DocumentsList
          documents={documentsRef}
          onSelect={onSelect}
          selection={selection}
        />
      </ColumnBody>
    </Column>
  );
}

export const documentsColumnQuery = graphql`
  query DocumentsColumnQuery($tenantId: ID!) {
    ...DocumentsList_documents @arguments(tenantId: $tenantId)
  }
`;
