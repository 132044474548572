import {
  ArchiveIcon,
  AtSymbolIcon,
  BookmarkIcon,
  ChatAltIcon,
  ClipboardIcon,
  Column,
  ColumnBody,
  ColumnHeader,
  InboxIcon,
  Menu,
} from "@workflows/ui";
import * as React from "react";

export function MattersFilterColumn(): JSX.Element {
  return (
    <Column role="aside" background="muted">
      <ColumnHeader title="Matters" />
      <ColumnBody inset>
        <Menu>
          <Menu.Section>
            <Menu.Item label="All" icon={<InboxIcon />} disabled />
            <Menu.Item label="Bookmarks" icon={<BookmarkIcon />} disabled />
            <Menu.Item label="Archive" icon={<ArchiveIcon />} disabled />
          </Menu.Section>
          <Menu.Section label="Filters">
            <Menu.Item
              label="Assigned to me"
              icon={<ClipboardIcon />}
              disabled
            />
            <Menu.Item label="Participating" icon={<ChatAltIcon />} disabled />
            <Menu.Item label="Mentioned" icon={<AtSymbolIcon />} disabled />
          </Menu.Section>
        </Menu>
      </ColumnBody>
    </Column>
  );
}
