import * as React from "react";
import { graphql, newId } from "@workflows/runtime-web";
import { useTenantId } from "@workflows/ui";
import { Error404 } from "~/core/Error404";
import { useMutation } from "react-relay/hooks";
import { ActionMagicLinkMutation } from "~/__graphql__/ActionMagicLinkMutation.graphql";
import { setSessionCookie } from "~/core/auth";

interface ActionMagicLinkProps {
  secret: string;
  to: string;
}

export function ActionMagicLink({
  secret,
  to,
}: ActionMagicLinkProps): JSX.Element | null {
  const tenantId = useTenantId();
  const [actionError, setActionError] = React.useState(false);
  const [executeAction] = useMutation<ActionMagicLinkMutation>(
    actionMagicLinkMutation
  );

  React.useEffect(() => {
    executeAction({
      variables: {
        input: {
          clientMutationId: newId(),
          tenantId,
          secret,
        },
      },
      onCompleted(data) {
        if (data.executeAction?.action?.payload.token) {
          setSessionCookie(data.executeAction?.action?.payload.token);
          window.location.href = to;
        } else {
          setActionError(true);
        }
      },
      onError() {
        setActionError(true);
      },
    });
  }, [executeAction, secret, tenantId, to]);

  if (actionError) {
    return <Error404 />;
  }

  return null;
}

export const actionMagicLinkMutation = graphql`
  mutation ActionMagicLinkMutation($input: ExecuteActionInput!) {
    executeAction(input: $input) {
      action {
        id
        type {
          identifier
        }
        payload {
          ... on AiWorkflowsUsersActionsMagicLinkPayload {
            token
          }
        }
      }
      errors {
        code
      }
    }
  }
`;
