import { useParams } from "@workflows/runtime-web";
import {
  Button,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  DotsVerticalIcon,
  DropdownMenu,
  useForm,
  useVisibilityToggle,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { useTenant } from "./hooks/useTenant";
import { TenantColumnTabs } from "./TenantColumnTabs";
import { DeleteTenantDialog } from "~/de.agad.dpm/tenants/DeleteTenantDialog";

export function TenantEditorColumn(): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");
  const { tenantId } = useParams<{ tenantId: string }>();

  const tenant = useTenant(tenantId || "");
  const form = useForm("tenantEditor");

  const [isVisible, show, hide] = useVisibilityToggle();

  const menuItems = [
    {
      key: "delete",
      label: t("TenantEditorColumn.menu.delete"),
      onClick: show,
    },
  ];

  return (
    <>
      <Column id="TenantEditorColumn" role="aside" width={2 / 4}>
        <ColumnHeader
          title={tenant?.name}
          end={
            <DropdownMenu items={menuItems} placement="bottom-end">
              <Button icon={<DotsVerticalIcon />} />
            </DropdownMenu>
          }
        />

        <ColumnBody>
          <TenantColumnTabs tenant={tenant} />
        </ColumnBody>
        <ColumnFooter
          end={
            <Button onClick={form.submit} intent="primary" type="submit">
              {t("TenantEditor.updateTenant")}
            </Button>
          }
        />
      </Column>
      <DeleteTenantDialog
        isOpen={isVisible}
        onClose={hide}
        tenantId={tenant?.id || ""}
      />
    </>
  );
}
