import { newId, useParams } from "@workflows/runtime-web";
import {
  Button,
  ConfirmDialog,
  DotsVerticalIcon,
  DropdownMenu,
  Role,
  RolePicker,
  Section,
  SectionBody,
  SectionHeader,
  Stack,
  TableView,
  TableViewColumn,
  Text,
  useDialog,
  useToast,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { InviteUsersDialog } from "~/core/Dialogs/InviteUsersDialog";
import { useAdminUpdateTenant } from "./hooks/useAdminUpdateUser";
import { useRoles } from "./hooks/useRoles";
import { useTenantUsers } from "./hooks/useTenantUsers";
import { useDeleteUser } from "~/de.agad.dpm/tenants/hooks/useDeleteUser";

export const TenantPeopleTab = (): JSX.Element => {
  const { t } = useTranslation("de.agad.dpm");
  const [addToast] = useToast();
  const { tenantId: subtenantId } = useParams<{ tenantId: string }>();
  const { isDialogOpen, openDialog, closeDialog } = useDialog();

  const { deleteUser } = useDeleteUser();
  const { tenantUsers } = useTenantUsers(subtenantId);
  const tenantRoles = useRoles(subtenantId);
  const { adminUpdateUser } = useAdminUpdateTenant();

  const handleUserDelete = (userId: string) => {
    deleteUser({
      input: { tenantId: subtenantId, userId },
      onError(error) {
        console.error(error);
      },
      onCompleted(data) {
        if (data.deleteUser?.errors) {
          console.error(data.deleteUser.errors);
        } else {
          addToast({
            id: newId(),
            message: t("TenantPeopleTab.confirmDialog.success"),
            intent: "success",
          });
        }
      },
    });
  };

  const handleChangeUserRole = (userId: string, tenantRoleIds: string[]) => {
    adminUpdateUser({
      onError: (error) => {
        console.error(error);
        addToast({
          id: "adminUpdateUserError",
          message: t("TenantPeopleTab.messages.error"),
          intent: "critical",
        });
      },
      input: {
        userId,
        subtenantId,
        tenantRoleIds,
      },
    });
  };

  const columns = useColumns(
    tenantRoles,
    handleUserDelete,
    handleChangeUserRole
  );

  return (
    <>
      <Section>
        <SectionHeader title={t("TenantPeopleTab.title")} />
        <SectionBody>
          <Stack direction="horizontal" align="center" justify="between">
            <Text>{t("TenantPeopleTab.description")}</Text>
            <Button intent="primary" onClick={openDialog} size="sm">
              {t("TenantPeopleTab.invite")}
            </Button>
          </Stack>
        </SectionBody>
      </Section>

      <TableView columns={columns} data={tenantUsers} className={"mt-md"} />

      <InviteUsersDialog
        isOpen={isDialogOpen}
        onClose={closeDialog}
        tenantId={subtenantId}
      />
    </>
  );
};

function useColumns(
  tenantRoles: Role[],
  handleDeleteUser: (userId: string) => void,
  handleChangeUserRole: (userId: string, roles: string[]) => void
): TableViewColumn[] {
  const { t } = useTranslation("de.agad.dpm");

  return React.useMemo(
    () => [
      {
        id: "name",
        header: "Name",
        width: "40%",
        render: ({
          value,
        }: {
          value: { email: string; displayName: string };
        }) => {
          return <>{value.displayName || value.email}</>;
        },
      },
      {
        id: "role",
        header: t("TenantPeopleTab.role"),
        width: "45%",
        render: ({ value }: any) => {
          return (
            <RolePicker
              value={value.assignedRoles}
              roles={tenantRoles}
              selectLabel={t("TenantPeopleTab.selectRole")}
              onChange={(roles) => {
                handleChangeUserRole(value.id, roles);
              }}
            />
          );
        },
      },
      {
        id: "menu",
        width: "5%",
        render: ({ value }: { value: { id: string } }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const [isOpen, setIsOpen] = React.useState(false);

          return (
            <>
              <DropdownMenu
                placement="bottom-end"
                items={[
                  {
                    key: "deleteUser",
                    label: t("TenantPeopleTab.deleteUser"),
                    onClick: () => setIsOpen(true),
                  },
                ]}
              >
                <div>
                  <DotsVerticalIcon />
                </div>
              </DropdownMenu>

              <ConfirmDialog
                title={t("TenantPeopleTab.confirmDialog.title")}
                intent="critical"
                confirmText={t("TenantPeopleTab.confirmDialog.confirmText")}
                cancelText={t("TenantPeopleTab.confirmDialog.cancelText")}
                isOpen={isOpen}
                onConfirm={(isConfirmed: boolean) =>
                  isConfirmed ? handleDeleteUser(value.id) : setIsOpen(false)
                }
              >
                {t("TenantPeopleTab.confirmDialog.message")}
              </ConfirmDialog>
            </>
          );
        },
      },
    ],
    [t, handleDeleteUser, handleChangeUserRole, tenantRoles]
  );
}
