import {
  getEdgeNodes,
  graphql,
  newId,
  useHistory,
  useLazyLoadQuery,
  useMutation,
} from "@workflows/runtime-web";
import {
  Button,
  Callout,
  ChoiceList,
  ChoiceListProps,
  Column,
  ColumnBody,
  ColumnFooter,
  FormContext,
  FormControl,
  FormInput,
  NonIdealState,
  Section,
  SectionBody,
  Text,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  useForm,
  useViewerTenant,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Error500 } from "~/core/Error500";
import { VertragsgeneratorRoles } from "~/core/roles";
import { Statuses } from "~/core/statuses";
import { routes } from "~/de.smartconex.vertragsgenerator/routes";
import { FlowComponent } from "~/flows/types";
import { DeSmartconexVertragsgeneratorWorkflowsCreatorv1Mutation } from "~/__graphql__/DeSmartconexVertragsgeneratorWorkflowsCreatorv1Mutation.graphql";
import { DeSmartconexVertragsgeneratorWorkflowsCreatorv1Query } from "~/__graphql__/DeSmartconexVertragsgeneratorWorkflowsCreatorv1Query.graphql";

export type DeSmartconexVertragsgeneratorWorkflowsCreatorv1Props =
  FlowComponent<{
    collectionId: string;
  }>;

export function DeSmartconexVertragsgeneratorWorkflowsCreatorv1({
  workflow,
}: DeSmartconexVertragsgeneratorWorkflowsCreatorv1Props): JSX.Element {
  const { tenantId, userId } = useViewerTenant(true);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const history = useHistory();
  const form = useForm("DeSmartconexVertragsgeneratorWorkflowsCreatorv1");

  const data =
    useLazyLoadQuery<DeSmartconexVertragsgeneratorWorkflowsCreatorv1Query>(
      $DeSmartconexVertragsgeneratorWorkflowsCreatorv1Query,
      { collectionId: workflow.config.collectionId }
    );

  const [error, setError] = React.useState("");
  const [createDocument, isPending] =
    useMutation<DeSmartconexVertragsgeneratorWorkflowsCreatorv1Mutation>(
      $DeSmartconexVertragsgeneratorWorkflowsCreatorv1Mutation
    );

  const handleSubmit = async () => {
    const { isValid } = await form.validate();
    if (!isValid) {
      return;
    }

    const { templateId, title, description, ...values } = form.getValues();

    const input = {
      assignees: [
        {
          assigneeId: userId,
          roleIdentifier: VertragsgeneratorRoles.Assignee,
        },
      ],
      statusIdentifier: Statuses.Open,
      clientMutationId: newId(),
      templateId,
      tenantId,
      title,
      description,
      defaultValues: Object.entries(values)
        .map(([key, value]) => ({
          identifier: key,
          value: JSON.stringify(value),
        }))
        .filter((val) => !!val.value),
    };

    createDocument({
      variables: { input },
      onError(error) {
        console.error(error);
        setError(
          t(
            "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.errors.unspecified"
          )
        );
      },
      onCompleted(data) {
        if (data.createDocument?.errors) {
          console.error(data.createDocument?.errors);
          setError(
            t(
              "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.errors.unspecified"
            )
          );
        } else if (data.createDocument && data.createDocument.document) {
          const documentId = data.createDocument.document.id;
          history.replace(routes["wizard.index"].build({ documentId }));
        } else {
          console.error(data.createDocument?.errors);
          setError(
            t(
              "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.errors.unspecified"
            )
          );
        }
      },
    });
  };

  const collection = data.node;
  const templates = getEdgeNodes(
    collection && collection.items ? collection.items : null
  )
    .filter((tmpl) => tmpl.__typename === "DocumentTemplate")
    .map((tmpl) => ({ label: tmpl.title, value: tmpl.id }));

  if (!workflow.config.collectionId) {
    return <Error500 />;
  }

  if (templates.length === 0) {
    return (
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <NonIdealState
            title="Keine Vertragstypen verfügbar"
            description="Ihr Benutzerkonto hat keine Vorlagen zugeordnet. Bitte kontaktieren Sie den Support um das Problem zu lösen."
          />
        </ColumnBody>
      </Column>
    );
  }

  return (
    <FormContext name="DeSmartconexVertragsgeneratorWorkflowsCreatorv1">
      <Column focus="primary" width={0.5} background="default">
        <ColumnBody>
          <Section>
            <SectionBody>
              <Text variant="headingLg">Konfiguration</Text>
              {error && <Callout intent="critical">{error}</Callout>}
            </SectionBody>
          </Section>
          <Section>
            <SectionBody>
              <FormControl
                name="title"
                htmlFor="title"
                label={t(
                  "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.inputs.title"
                )}
                helper={t(
                  "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.helpers.title"
                )}
              >
                <FormInput<TextFieldProps>
                  autoFocus
                  id="title"
                  component={TextField}
                  name="title"
                  required="Bitte füllen Sie dieses Feld aus."
                  max={[250, "Bitte geben Sie max. 250 Zeichen ein"]}
                />
              </FormControl>
              <FormControl
                name="description"
                htmlFor="description"
                label={t(
                  "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.inputs.description"
                )}
                helper={t(
                  "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.helpers.description"
                )}
                labelEnd="Optional"
              >
                <FormInput<TextAreaProps>
                  id="description"
                  component={TextArea}
                  name="description"
                  max={[500, "Bitte geben Sie max. 500 Zeichen ein"]}
                />
              </FormControl>
              <FormControl
                htmlFor="templateId"
                name="templateId"
                label="Vorlage"
                description="Bitte wählen Sie eine passende Vorlage für Ihr Dokument."
              >
                <FormInput<ChoiceListProps>
                  component={ChoiceList}
                  id="templateId"
                  name="templateId"
                  type="radio"
                  options={templates}
                  required="Bitte wählen Sie eine Vorlage aus."
                />
              </FormControl>
            </SectionBody>
          </Section>
        </ColumnBody>
        <ColumnFooter
          end={
            <Button
              intent="primary"
              onClick={handleSubmit}
              isLoading={isPending}
            >
              {t(
                "DeSmartconexVertragsgeneratorWorkflowsCreatorv1.actions.create"
              )}
            </Button>
          }
        />
      </Column>
    </FormContext>
  );
}

const $DeSmartconexVertragsgeneratorWorkflowsCreatorv1Query = graphql`
  query DeSmartconexVertragsgeneratorWorkflowsCreatorv1Query(
    $collectionId: ID!
  ) {
    node(id: $collectionId) {
      ... on Collection {
        name
        items(first: 100) {
          edges {
            cursor
            node {
              id
              ... on DocumentTemplate {
                __typename
                id
                title
                description
                color
              }
            }
          }
        }
      }
    }
  }
`;

const $DeSmartconexVertragsgeneratorWorkflowsCreatorv1Mutation = graphql`
  mutation DeSmartconexVertragsgeneratorWorkflowsCreatorv1Mutation(
    $input: CreateDocumentInput!
  ) {
    createDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
      }
    }
  }
`;
