import { Column, ColumnBody, Sections, FlowList } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Error404 } from "~/core/Error404";
import { MatterRouteQueryResponse } from "~/__graphql__/MatterRouteQuery.graphql";
import { MatterTimeline } from "./MatterTimeline";
import { MatterOverviewColumnHeader } from "./MatterOverviewColumnHeader";
import { DeNetzwerkpartnerHotlineRequestPreview } from "./DeNetzwerkpartnerHotlineRequestPreview";

export interface MatterOverviewColumnProps {
  data: MatterRouteQueryResponse;
  refresh: () => void;
}

export function MatterOverviewColumn({
  data,
  refresh,
}: MatterOverviewColumnProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");

  if (!data) {
    return <Error404 />;
  }
  return (
    <Column role="main">
      <MatterOverviewColumnHeader matter={data.matter} />

      <ColumnBody inset={false}>
        <Sections>
          <FlowList
            title={t("Workflows")}
            previewComponent={DeNetzwerkpartnerHotlineRequestPreview}
            fragmentRef={data?.matter || undefined}
          />

          <MatterTimeline
            fragmentRef={data}
            timelineFragmentRef={data?.matter}
            refresh={refresh}
          />
        </Sections>
      </ColumnBody>
    </Column>
  );
}
