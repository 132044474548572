import {
  Column,
  ColumnBody,
  Section,
  SectionBody,
  Skeleton,
  Text,
} from "@workflows/ui";
import * as React from "react";
import { DocumentViewerSkeleton } from "~/documents/DocumentViewerSkeleton";
import { WizardNav } from "./WizardNav";

export function WizardSkeleton(): JSX.Element {
  return (
    <>
      <Column role="aside" width={0.3}>
        <ColumnBody>
          <Section>
            <SectionBody>
              <Text variant="headingLg">
                <Skeleton width={96} />
              </Text>
              <Skeleton width={64} />
              <Skeleton width={48} />
            </SectionBody>
          </Section>
        </ColumnBody>
        <WizardNav nextSlug="contractualObligations" />
      </Column>
      <Column role="main" background="muted">
        <ColumnBody>
          <DocumentViewerSkeleton />
        </ColumnBody>
      </Column>
    </>
  );
}
