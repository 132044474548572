import { Value } from "./types";

export const type = "boolean";

export function load(val: Value<boolean>): Value<boolean> {
  return val;
}

export function dump(val: Value<boolean>): boolean | null {
  return val.value;
}
