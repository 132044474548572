import { atom, selectorFamily } from "@workflows/runtime-web";
import { idFamily, valueFamily } from "~/documents/state";
import { outlineFamily } from "./Wizard";

export const wizardStepIndexState = atom({
  key: "wizardStepIndexState",
  default: 0,
});

export type ValueSelectorKey = [documentId: string, valueIdentifier: string];

export const valueSelector = selectorFamily<any, ValueSelectorKey>({
  key: "valueSelector",
  get:
    (valueKey) =>
    ({ get }) => {
      const key = get(idFamily(valueKey));

      if (!key) {
        return "";
      }

      const val: any = get(valueFamily(key));
      return (val ? val.value : "") ?? "";
    },

  set:
    (valueKey) =>
    ({ set, get }, newValue) => {
      const key = get(idFamily(valueKey));

      set(valueFamily(key), (currentVal: any) => ({
        ...currentVal,
        value: newValue,
      }));
    },
});

export const outlineSelector = selectorFamily({
  key: "outlineSelector",
  get:
    (identifier: string) =>
    ({ get }) =>
      get(outlineFamily(identifier)),
  set:
    (identifier: string) =>
    ({ set }, newValue: any) => {
      set(outlineFamily(identifier), (currentVal: any) => ({
        ...currentVal,
        ...newValue,
      }));
    },
});
