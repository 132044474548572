import { BrowserRouter, RecoilRoot } from "@workflows/runtime-web";
import { Root, TenantContext } from "@workflows/ui";
import * as React from "react";
import { RelayEnvironmentProvider } from "react-relay/hooks";
import { Environment } from "relay-runtime";
import { ErrorBoundary } from "~/core/ErrorBoundary";
import { SuspenseFallback } from "~/core/SuspenseFallback";
import { CoreDebugger } from "./CoreDebugger";
import { CoreTheme } from "./CoreTheme";
import "./i18n";
import { tenantId } from "~/client/config";

export interface CoreKernelProps {
  children: React.ReactNode;
  relay: Environment;
}

export function CoreKernel({ relay, children }: CoreKernelProps): JSX.Element {
  return (
    <ErrorBoundary>
      <React.Suspense fallback={<SuspenseFallback />}>
        <RecoilRoot>
          <RelayEnvironmentProvider environment={relay}>
            <BrowserRouter>
              <Root>
                <CoreTheme>
                  <TenantContext id={tenantId}>{children}</TenantContext>
                </CoreTheme>
              </Root>
            </BrowserRouter>
          </RelayEnvironmentProvider>
          {process.env.NODE_ENV === "development" ? <CoreDebugger /> : null}
        </RecoilRoot>
      </React.Suspense>
    </ErrorBoundary>
  );
}
