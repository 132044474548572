import { ChangedDescriptionActiviy } from "./activityTypes/ChangedDescriptionActivity";
import { AssignedTagActivity } from "./activityTypes/AssignedTagActivity";
import { ChangedStatusActivity } from "./activityTypes/ChangedStatusActivity";
import { ChangedTitleActivity } from "./activityTypes/ChangedTitleActivity";
import { UnassignTagActivity } from "./activityTypes/UnassignedTagActivity";
import { ChangedNameActivity } from "./activityTypes/ChangedNameActivity";
import { AssignedAssigneeActivity } from "./activityTypes/AssignedAssigneeActivity";
import { UnassignedAssigneeActivity } from "./activityTypes/UnassignedAssigneeActivity";

export const activityMapping: Record<string, any> = {
  "ai.workflows.tags.activities.assignedTag": AssignedTagActivity,
  "ai.workflows.tags.activities.unassignedTag": UnassignTagActivity,
  "ai.workflows.statuses.activities.changedStatus": ChangedStatusActivity,
  "ai.workflows.objects.activities.changedTitle": ChangedTitleActivity,
  "ai.workflows.objects.activities.changedName": ChangedNameActivity,
  "ai.workflows.objects.activities.changedDescription":
    ChangedDescriptionActiviy,
  "ai.workflows.assignees.activities.assignedAssignee":
    AssignedAssigneeActivity,
  "ai.workflows.assignees.activities.unassignedAssignee":
    UnassignedAssigneeActivity,
};
