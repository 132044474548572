import { compileRoutes } from "@workflows/runtime-web";
import * as React from "react";
import { CoreEntryPointRoute } from "~/core/CoreEntryPointRoute";
import {
  createStaticResource,
  EntryPointPreloadGlobals,
} from "~/core/entryPoints";
import {
  FlowRendererRoute,
  FlowRendererRouteQueryRequest,
} from "./FlowRendererRoute";

export const routes = compileRoutes({
  "flows.new": (_: { workflowId: string }) => "/new/:workflowId",
  "flows.request": (_: { flowId: string; matterId: string }) =>
    "/flows/:flowId/request/:matterId",
  "flows.specification": (_: { flowId: string }) =>
    "/flows/:flowId/specification",
});

const routeConfig = {
  "flows.new": {
    root: createStaticResource("FlowRendererRoute", FlowRendererRoute),
    getPreloadProps(config: {
      params: { workflowId: string };
      _globals: EntryPointPreloadGlobals;
    }) {
      return {
        queries: {
          workflow: {
            parameters: FlowRendererRouteQueryRequest,
            variables: config.params,
          },
        },
      };
    },
  },
};

export const router = (
  <>
    <CoreEntryPointRoute
      path={routes["flows.new"].path}
      entrypoint={routeConfig["flows.new"]}
    />
  </>
);
