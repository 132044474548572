import { newId, useHistory, useMutation } from "@workflows/runtime-web";
import {
  Button,
  Callout,
  Column,
  ColumnBody,
  ColumnFooter,
  ColumnHeader,
  Drawer,
  Form2,
  FormInput,
  Panels,
  Stack,
  StackItem,
  TextField,
  useForm,
  useViewerTenant,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { graphql } from "relay-runtime";
import { WorkflowChooser } from "~/workflows/WorkflowChooser";
import { MatterCreatorDrawerMutation } from "~/__graphql__/MatterCreatorDrawerMutation.graphql";
import { routes } from "../routes";
import { Statuses } from "~/core/statuses";
import { TenantRoles } from "~/core/roles";

export interface MatterCreatorDrawerProps {
  isOpen: boolean;
  onClose: () => void;
}

export function MatterCreatorDrawer({
  isOpen,
  onClose,
}: MatterCreatorDrawerProps): JSX.Element {
  return (
    <Drawer
      isOpen={isOpen}
      width="md"
      closeOnOutsideClick={true}
      onClose={onClose}
    >
      <Panels initialPanel={{ id: "workflow", component: WorkflowPanel }} />
    </Drawer>
  );
}

function WorkflowPanel(
  props: any /*: PanelProps<TemplateAsideProps> */
): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");

  return (
    <Column>
      <ColumnHeader>
        <MatterCreatorDrawerHeader />
      </ColumnHeader>
      <ColumnBody inset scrollable>
        <React.Suspense fallback="loading">
          <WorkflowChooser onClick={console.log} />
        </React.Suspense>
      </ColumnBody>
      <ColumnFooter
        start={
          <Button
            onClick={() =>
              props.openPanel({
                id: "form",
                component: FormPanel,
              })
            }
          >
            {t("MatterCreatorDrawer.actions.createEmptyMatter")}
          </Button>
        }
        end={
          <Button intent="primary" disabled>
            {t("MatterCreatorDrawer.actions.continue")}
          </Button>
        }
      />
    </Column>
  );
}

function FormPanel(props: any): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");
  const history = useHistory();
  const [error, setError] = React.useState("");
  const { tenantId, userId } = useViewerTenant(true);
  const [createMatter, isPending] = useMutation<MatterCreatorDrawerMutation>(
    MatterCreatorDrawerMutationRequest
  );

  const form = useForm("MatterCreatorDrawer");

  const handleSubmit = () => {
    const { title } = (form as any).getValues();

    const input = {
      statusIdentifier: Statuses.Open,
      clientMutationId: newId(),
      tenantId,
      title,
      assignees: [{ assigneeId: userId, roleIdentifier: TenantRoles.User }],
    };

    createMatter({
      variables: { input },
      onError(error) {
        console.error(error);
        setError(t("MatterCreatorDrawer.errors.unspecified"));
      },
      onCompleted(data) {
        if (data.createMatter?.errors) {
          console.error(data.createMatter?.errors);
          setError(t("MatterCreatorDrawer.errors.unspecified"));
        } else {
          const matterId = data.createMatter?.matter?.id as string;
          history.replace(routes["matter.index"].build({ matterId }));
        }
      },
    });
  };

  return (
    <Column>
      <ColumnHeader
        back
        onBack={() => props.closePanel()}
        title={t("MatterCreatorDrawer.titles.createMatter")}
      />
      <ColumnBody inset>
        {error && <Callout intent="critical">{error}</Callout>}
        <Form2 name="MatterCreatorDrawer">
          <FormInput
            component={TextField}
            label={t("MatterCreatorDrawer.inputs.title")}
            name="title"
          />
        </Form2>
      </ColumnBody>
      <ColumnFooter
        start={
          <Button onClick={() => props.closePanel()} disabled={isPending}>
            {t("MatterCreatorDrawer.actions.cancel")}
          </Button>
        }
        end={
          <Button onClick={handleSubmit} intent="primary" isLoading={isPending}>
            {t("MatterCreatorDrawer.actions.createMatter")}
          </Button>
        }
      />
    </Column>
  );
}

function MatterCreatorDrawerHeader(): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");

  return (
    <Stack direction="horizontal" grow>
      <StackItem grow>
        <TextField
          placeholder={t("MatterCreatorDrawer.placeholders.searchWorkflows")}
          disabled
        />
      </StackItem>
    </Stack>
  );
}

const MatterCreatorDrawerMutationRequest = graphql`
  mutation MatterCreatorDrawerMutation($input: CreateMatterInput!) {
    createMatter(input: $input) {
      errors {
        code
        path
        message
      }
      matter {
        id
      }
    }
  }
`;
