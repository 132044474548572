import { RouteComponentProps } from "@workflows/runtime-web";
import { Columns } from "@workflows/ui";
import * as React from "react";
import {
  graphql,
  PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from "react-relay";
import { Error404 } from "~/core/Error404";
import { MatterRouteQuery } from "~/__graphql__/MatterRouteQuery.graphql";
import { MatterColumnSkeleton } from "./MatterColumnSkeleton";
import { MatterInspectorColumn } from "./MatterInspectorColumn";
import { MatterOverviewColumn } from "./MatterOverviewColumn";

const { useCallback, useEffect, Suspense } = React;

export interface MatterRouteEntryPointParams {
  matterId?: string;
}

export interface MatterRouteEntryPointQueries {
  matter: PreloadedQuery<MatterRouteQuery>;
}

export interface MatterRouteProps
  extends RouteComponentProps<MatterRouteEntryPointParams> {
  queries: MatterRouteEntryPointQueries;
}

export function MatterRoute({
  queries: { matter },
}: MatterRouteProps): JSX.Element {
  const [innerRef, loadQuery, disposeQuery] = useQueryLoader(
    MatterRouteQueryRequest,
    matter
  );

  if (!innerRef) {
    return <Error404 />;
  }

  const data = usePreloadedQuery(MatterRouteQueryRequest, innerRef);

  if (!data || !data.matter) {
    return <Error404 />;
  }

  useEffect(() => {
    return () => {
      disposeQuery();
    };
  }, []);

  const refresh = useCallback(() => {
    loadQuery(matter?.variables, { fetchPolicy: "network-only" });
  }, [matter]);

  return (
    <Columns>
      <Suspense fallback={<MatterColumnSkeleton />}>
        <MatterOverviewColumn data={data} refresh={refresh} />
      </Suspense>
      <Suspense fallback={<MatterColumnSkeleton />}>
        <MatterInspectorColumn data={data} />
      </Suspense>
    </Columns>
  );
}

export const MatterRouteQueryRequest = graphql`
  query MatterRouteQuery($matterId: ID!, $tenantId: ID!) {
    matter: node(id: $matterId) {
      id
      ... on Matter {
        ...FlowList_flows @arguments(count: 100)
        ...MatterTimeline_timeline @arguments(count: 100)
        creator {
          id
          name
        }
        number
        insertedAt
        title
        dueDate
        state
        ...Status_status @relay(mask: false)
        assignees {
          edges {
            node {
              id
              actor {
                id
                ... on User {
                  name
                }
                ... on Group {
                  name
                }
              }
            }
          }
        }
      }
    }
    ...MatterTimeline_threads
      @arguments(tenantId: $tenantId, matterId: $matterId)
  }
`;
