import { TFunction } from "i18next";
import { createRuntime } from "./runtime";

export type Noun = Record<"plural" | "singular", string>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/explicit-module-boundary-types
export function evaluate(
  evaluator: any,
  refs: readonly string[],
  vals: any,
  t: TFunction
): any {
  const values = refs.reduce((acc: any, id: any, idx) => {
    const val = vals[idx];
    acc[id] = val ? val.value : null;
    if (val) {
      acc[val.identifier] = acc[id];
    }
    return acc;
  }, {});

  return evaluator(createRuntime({ t }), values);
}
