/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type UpdateMatterInput = {
    assignees?: Array<AssigneeRename | null> | null;
    clientMutationId: string;
    dueDate?: string | null;
    language?: string | null;
    matterId: string;
    statusId?: string | null;
    statusIdentifier?: string | null;
    tags?: Array<string | null> | null;
    tenantId: string;
    title?: string | null;
};
export type AssigneeRename = {
    assigneeId: string;
    roleId?: string | null;
    roleIdentifier?: string | null;
};
export type MatterOverviewColumnHeader_UpdateMatterMutationVariables = {
    input: UpdateMatterInput;
};
export type MatterOverviewColumnHeader_UpdateMatterMutationResponse = {
    readonly updateMatter: {
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
            readonly message: string | null;
            readonly type: string | null;
        } | null> | null;
        readonly clientMutationId: string;
        readonly matter: {
            readonly id: string;
            readonly title: string;
        } | null;
    } | null;
};
export type MatterOverviewColumnHeader_UpdateMatterMutation = {
    readonly response: MatterOverviewColumnHeader_UpdateMatterMutationResponse;
    readonly variables: MatterOverviewColumnHeader_UpdateMatterMutationVariables;
};



/*
mutation MatterOverviewColumnHeader_UpdateMatterMutation(
  $input: UpdateMatterInput!
) {
  updateMatter(input: $input) {
    errors {
      code
      message
      type
    }
    clientMutationId
    matter {
      id
      title
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "UpdateMatterPayload",
            "kind": "LinkedField",
            "name": "updateMatter",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "InputError",
                    "kind": "LinkedField",
                    "name": "errors",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "code",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "message",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "type",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "Matter",
                    "kind": "LinkedField",
                    "name": "matter",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "id",
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MatterOverviewColumnHeader_UpdateMatterMutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MatterOverviewColumnHeader_UpdateMatterMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "02eee3f2e3381281e6ece5cfc0b5dcc2",
            "id": null,
            "metadata": {},
            "name": "MatterOverviewColumnHeader_UpdateMatterMutation",
            "operationKind": "mutation",
            "text": "mutation MatterOverviewColumnHeader_UpdateMatterMutation(\n  $input: UpdateMatterInput!\n) {\n  updateMatter(input: $input) {\n    errors {\n      code\n      message\n      type\n    }\n    clientMutationId\n    matter {\n      id\n      title\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '1715644724b58de2e2225325ba72b5d6';
export default node;
