import * as React from "react";
import {
  Button,
  ChoiceList,
  ChoiceListProps,
  Form2,
  FormControl,
  FormInput,
  Stack,
  TextArea,
  TextAreaProps,
  TextField,
  TextFieldProps,
  useDeviceInfo,
  useForm,
  useViewerTenant,
} from "@workflows/ui";
import { useTranslation } from "react-i18next";
import { useCreateFeedback } from "./hooks/useCreateFeedback";
import { useFeedbackChannels } from "./hooks/useFeedbackChannels";

interface FeedbackFormProps {
  onCancel: () => void;
  onError: () => void;
  onSuccess: () => void;
}

interface FeedbackFormState {
  email: string;
  feedbackChannelId: string;
  name?: string;
  text: string;
}

export const FeedbackForm = ({
  onCancel,
  onError,
  onSuccess,
}: FeedbackFormProps): JSX.Element => {
  const { userId, user } = useViewerTenant(true);
  const feedbackForm = useForm("FeedbackForm");
  const deviceInfo = useDeviceInfo();
  const { createFeedback, isPending } = useCreateFeedback();
  const nodes = useFeedbackChannels();
  const { t } = useTranslation(["ai.workflows.core", "ai.workflows.accounts"]);

  const feedbackChannels = nodes.map(({ id, title, description }) => ({
    value: id,
    label: (
      <>
        <div>{title}</div>
        <span className="text--muted">{description}</span>
      </>
    ),
  }));

  const handleSubmit = async () => {
    const { isValid } = await feedbackForm.validate();

    if (!isValid) {
      return;
    }

    const { feedbackChannelId, text, email, name } =
      feedbackForm.getValues() as FeedbackFormState;

    createFeedback({
      feedbackChannelId,
      email,
      text: `
        Name: ${name || user?.name}
        Device Info: ${JSON.stringify(deviceInfo)}
        Message: ${text}
      `,
      onError() {
        onError();
      },
      onCompleted(data) {
        if (data.createFeedback?.errors) {
          onError();
        } else {
          onSuccess();
        }
      },
    });
  };

  const handleCancel = () => {
    feedbackForm.reset();
    onCancel();
  };

  return (
    <Form2 name="FeedbackForm" onSubmit={handleSubmit}>
      <FormControl name="feedbackChannelId" htmlFor="feedbackChannelId">
        <FormInput<ChoiceListProps>
          component={ChoiceList}
          name="feedbackChannelId"
          type="radio"
          options={feedbackChannels}
          required={t("SupportDialog.validation.feedback") as string}
          validationMode="onChange"
        />
      </FormControl>

      {!userId ? (
        <>
          <FormControl
            name="name"
            htmlFor="name"
            label={t("SupportDialog.inputs.name")}
          >
            <FormInput<TextFieldProps>
              id="name"
              component={TextField}
              type="text"
              name="name"
              required={
                t("AccountSignUpForm.fields.firstName.required", {
                  ns: "ai.workflows.accounts",
                }) as string
              }
            />
          </FormControl>

          <FormControl
            name="email"
            htmlFor="email"
            label={t("SupportDialog.inputs.email")}
          >
            <FormInput<TextFieldProps>
              id="email"
              component={TextField}
              type="email"
              name="email"
              placeholder="you@example.com"
              required={
                t("AccountSignUpForm.fields.email.required", {
                  ns: "ai.workflows.accounts",
                }) as string
              }
            />
          </FormControl>
        </>
      ) : null}

      <FormControl
        name="text"
        htmlFor="text"
        label={t("SupportDialog.inputs.message")}
      >
        <FormInput<TextAreaProps>
          id="text"
          component={TextArea}
          rows={5}
          name="text"
          required={t("SupportDialog.validation.text") as string}
        />
      </FormControl>

      <Stack direction="horizontal" justify="end">
        <Button onClick={handleCancel} disabled={isPending}>
          {t("SupportDialog.actions.cancel")}
        </Button>
        <Button onClick={handleSubmit} intent="primary" isLoading={isPending}>
          {t("SupportDialog.actions.submit")}
        </Button>
      </Stack>
    </Form2>
  );
};
