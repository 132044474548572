/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useRolesQueryVariables = {
    tenantId: string;
};
export type useRolesQueryResponse = {
    readonly roles: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly identifier: string | null;
                readonly name: string | null;
            } | null;
        } | null> | null;
    } | null;
};
export type useRolesQuery = {
    readonly response: useRolesQueryResponse;
    readonly variables: useRolesQueryVariables;
};



/*
query useRolesQuery(
  $tenantId: ID!
) {
  roles(tenantId: $tenantId) {
    edges {
      node {
        id
        identifier
        name
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "tenantId",
                    "variableName": "tenantId"
                }
            ],
            "concreteType": "RoleConnection",
            "kind": "LinkedField",
            "name": "roles",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Role",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "id",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "identifier",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useRolesQuery",
            "selections": (v1 /*: any*/),
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useRolesQuery",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "c553dd7bbda8eef2c4880f5b7221460b",
            "id": null,
            "metadata": {},
            "name": "useRolesQuery",
            "operationKind": "query",
            "text": "query useRolesQuery(\n  $tenantId: ID!\n) {\n  roles(tenantId: $tenantId) {\n    edges {\n      node {\n        id\n        identifier\n        name\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '7938f97873279ad46148bca69b7753d9';
export default node;
