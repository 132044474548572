import * as ArrayValue from "./array";
import * as BooleanValue from "./boolean";
import * as DateValue from "./date";
import * as DateTimeValue from "./datetime";
import * as DurationValue from "./duration";
import * as EnumValue from "./enum";
import * as EnumListValue from "./enumList";
import * as FloatValue from "./float";
import * as IntegerValue from "./integer";
import * as MoneyValue from "./money";
import * as StringValue from "./string";
import { Value, ValueType } from "./types";

export const types: Record<string, ValueType> = {
  [ArrayValue.type]: ArrayValue,
  [BooleanValue.type]: BooleanValue,
  [DateTimeValue.type]: DateTimeValue,
  [DateValue.type]: DateValue,
  [DurationValue.type]: DurationValue,
  [EnumListValue.type]: EnumListValue,
  [EnumValue.type]: EnumValue,
  [FloatValue.type]: FloatValue,
  [IntegerValue.type]: IntegerValue,
  [MoneyValue.type]: MoneyValue,
  [StringValue.type]: StringValue,
};

export function load<V = Value>(value: Value): V {
  if (!Object.prototype.hasOwnProperty.call(types, value.type)) {
    throw new Error(`Cannot load value of unknown type '${value.type}'`);
  }
  return types[value.type].load(value);
}

export function dump(value: Value): unknown {
  if (!Object.prototype.hasOwnProperty.call(types, value.type)) {
    throw new Error(`Cannot dump value of unknown type '${value.type}'`);
  }
  return types[value.type].dump(value);
}
