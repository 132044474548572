import { atom, selector } from "@workflows/runtime-web";

export const appState = atom({
  key: "appState",
  default: {
    showSidebar: true,
  },
});

export const appSidebar = selector({
  key: "appSidebar",
  get: ({ get }) => get(appState).showSidebar,
  set: ({ set }, newValue) => {
    set(appState, (prev) => {
      return {
        ...prev,
        showSidebar: newValue as boolean,
      };
    });
  },
});
