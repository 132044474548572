import { Nav } from "@workflows/ui";
import { useRouter } from "~/core/useRouter";
import { WizardStep } from "./types";
import * as React from "react";

export interface WizardQuickNavigationProps {
  steps: WizardStep[];
  currentStepSlug: string;
  completedSections: string[];
}

export function WizardQuickNavigation({
  steps,
  currentStepSlug,
  completedSections = [],
}: WizardQuickNavigationProps): JSX.Element {
  const { baseUrl } = useRouter();

  return (
    <Nav direction="horizontal">
      {steps.map((step) => (
        <Nav.Item
          key={step.slug}
          label={step.title}
          to={`${baseUrl}/${step.slug}`}
          disabled={
            !completedSections.includes(step.slug) &&
            step.slug !== currentStepSlug
          }
        />
      ))}
    </Nav>
  );
}
