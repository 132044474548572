/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionItemChooserListWorkflowItem_item = {
    readonly id: string;
    readonly name: string;
    readonly descriptionText: string | null;
    readonly icon: string | null;
    readonly color: string | null;
    readonly " $refType": "CollectionItemChooserListWorkflowItem_item";
};
export type CollectionItemChooserListWorkflowItem_item$data = CollectionItemChooserListWorkflowItem_item;
export type CollectionItemChooserListWorkflowItem_item$key = {
    readonly " $data"?: CollectionItemChooserListWorkflowItem_item$data;
    readonly " $fragmentRefs": FragmentRefs<"CollectionItemChooserListWorkflowItem_item">;
};



const node: ReaderFragment = {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CollectionItemChooserListWorkflowItem_item",
    "selections": [
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "descriptionText",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "icon",
            "storageKey": null
        },
        {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "color",
            "storageKey": null
        }
    ],
    "type": "Workflow",
    "abstractKey": null
} as any;
(node as any).hash = '928a01166eede70042c976aa5c04b817';
export default node;
