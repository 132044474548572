import {
  graphql,
  usePreloadedQuery,
  useQueryLoader,
} from "@workflows/runtime-web";
import {
  Column,
  ColumnBody,
  ColumnHeader,
  LinkButton,
  PlusIcon,
} from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PreloadedQuery } from "react-relay/hooks";
import { TenantsColumnQuery } from "~/__graphql__/TenantsColumnQuery.graphql";
import { routes } from "../routes";
import { TenantsTableView } from "./TenantsTableView";

export interface TenantsColumnProps {
  queryRef: PreloadedQuery<TenantsColumnQuery>;
}

export function TenantsColumn({ queryRef }: TenantsColumnProps): JSX.Element {
  const { t } = useTranslation("de.agad.dpm");
  const [innerRef] = useQueryLoader(TenantsColumnQueryRequest, queryRef);
  const data = usePreloadedQuery(TenantsColumnQueryRequest, innerRef!);

  const end = (
    <LinkButton
      to={routes["tenant.new"].build()}
      size="sm"
      icon={<PlusIcon />}
      intent="primary"
    >
      {t("TenantsColumn.newTenant")}
    </LinkButton>
  );

  return (
    <Column id="TenantsRoute" role="main" width={1 / 4}>
      <ColumnHeader title={t("TenantsColumn.title")} borderless end={end} />
      <ColumnBody>
        <React.Suspense fallback={<TenantsTableView isPending />}>
          <TenantsTableView queryFragment={data} />
        </React.Suspense>
      </ColumnBody>
    </Column>
  );
}

export const TenantsColumnQueryRequest = graphql`
  query TenantsColumnQuery($tenantId: ID!) {
    ...useTenants_tenants @arguments(tenantId: $tenantId)
  }
`;
