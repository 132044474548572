import * as React from "react";
import { useTranslation } from "react-i18next";

export interface ChangedDescriptionActiviyProps {
  activity: any;
}

export function ChangedDescriptionActiviy({
  activity,
}: ChangedDescriptionActiviyProps): JSX.Element {
  const { actor, insertedAt } = activity;
  const { t } = useTranslation("ai.workflows.activities");

  return (
    <div>
      {t("ChangedDescriptionActivity", {
        actorName: actor.name,
        insertedAt,
      })}
    </div>
  );
}
