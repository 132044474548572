import { Switch, Route, RouteComponentProps } from "@workflows/runtime-web";
import * as React from "react";
import { PreloadedQuery } from "react-relay";
import { UsersColumnQuery } from "~/__graphql__/UsersColumnQuery.graphql";
import { routes } from "../routes";
import { UsersColumn, UsersColumnQueryRequest } from "./UsersColumn";
import { UsersEditorColumn } from "./UsersEditorColumn";
import { UsersEditorRoute } from "./UsersEditorRoute";

export const UsersRouteQuery = UsersColumnQueryRequest;

export interface UsersRouteEntryPointQueries {
  users: PreloadedQuery<UsersColumnQuery>;
}

interface UsersRouteProps extends RouteComponentProps<{ userId: string }> {
  queries: UsersRouteEntryPointQueries;
}

export function UsersRoute({ queries }: UsersRouteProps): JSX.Element {
  return (
    <>
      <UsersColumn queryRef={queries.users} />
      <Switch>
        <Route path={routes["users.edit"].path} component={UsersEditorRoute} />
      </Switch>
    </>
  );
}
