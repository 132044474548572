import { newId } from "@workflows/runtime-web";
import { useMutation } from "react-relay";
import { graphql } from "relay-runtime";
import {
  useDashboardUpdateDocumentMutation,
  useDashboardUpdateDocumentMutationResponse,
} from "~/__graphql__/useDashboardUpdateDocumentMutation.graphql";

interface useDashboardUpdateDocumentProps {
  onError: (error: Error) => void;
  onCompleted: (data: useDashboardUpdateDocumentMutationResponse) => void;
  input: { statusIdentifier: string; documentId: string; tenantId: string };
}

interface useDashboardUpdateDocumentValue {
  updateDocument: (props: useDashboardUpdateDocumentProps) => void;
  isPending: boolean;
}

export const useDashboardUpdateDocument =
  (): useDashboardUpdateDocumentValue => {
    const [mutation, isPending] =
      useMutation<useDashboardUpdateDocumentMutation>(updateDocumentMutation);

    const updateDocument = ({
      input: { documentId, tenantId, statusIdentifier },
      onError,
      onCompleted,
    }: useDashboardUpdateDocumentProps) => {
      mutation({
        variables: {
          input: {
            clientMutationId: newId(),
            documentId,
            statusIdentifier,
            tenantId,
          },
        },
        onError,
        onCompleted,
      });
    };

    return { updateDocument, isPending };
  };

const updateDocumentMutation = graphql`
  mutation useDashboardUpdateDocumentMutation($input: UpdateDocumentInput!) {
    updateDocument(input: $input) {
      errors {
        code
        path
        message
      }
      document {
        id
        status {
          id
          name
        }
      }
    }
  }
`;
