import {
  Column,
  ColumnBody,
  ColumnHeader,
  Sections,
  Stack,
  StackItem,
  useBreakpointMatch,
} from "@workflows/ui";
import * as React from "react";
import { Search } from "../search/Search";
import { DashboardShortcuts } from "./DashboardShortcuts";

export const DashboardRoute: React.FC = () => {
  const { width } = useBreakpointMatch({
    width: { xs: "full", md: "1/2" },
  }) as { width: "full" | "1/2" | undefined };
  return (
    <Column id="DashboardRoute" focus="primary" role="main">
      <ColumnHeader title="Portal" />
      <ColumnBody scrollable>
        <Stack
          direction="horizontal"
          fill
          justify="center"
          align="center"
          className="p-md"
        >
          <StackItem width={width}>
            <Search
              emptyState={
                <Sections>
                  <DashboardShortcuts />
                </Sections>
              }
            />
          </StackItem>
        </Stack>
      </ColumnBody>
    </Column>
  );
};
