/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ExecuteActionInput = {
    clientMutationId: string;
    secret: string;
    tenantId: string;
};
export type ActionMagicLinkMutationVariables = {
    input: ExecuteActionInput;
};
export type ActionMagicLinkMutationResponse = {
    readonly executeAction: {
        readonly action: {
            readonly id: string;
            readonly type: {
                readonly identifier: string;
            };
            readonly payload: {
                readonly token?: string;
            };
        } | null;
        readonly errors: ReadonlyArray<{
            readonly code: string | null;
        } | null> | null;
    } | null;
};
export type ActionMagicLinkMutation = {
    readonly response: ActionMagicLinkMutationResponse;
    readonly variables: ActionMagicLinkMutationVariables;
};



/*
mutation ActionMagicLinkMutation(
  $input: ExecuteActionInput!
) {
  executeAction(input: $input) {
    action {
      id
      type {
        identifier
      }
      payload {
        __typename
        ... on AiWorkflowsUsersActionsMagicLinkPayload {
          token
        }
      }
    }
    errors {
      code
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "input",
            "variableName": "input"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "concreteType": "ObjectType",
        "kind": "LinkedField",
        "name": "type",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "identifier",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any, v4 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "token",
                "storageKey": null
            }
        ],
        "type": "AiWorkflowsUsersActionsMagicLinkPayload",
        "abstractKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "concreteType": "InputError",
        "kind": "LinkedField",
        "name": "errors",
        "plural": true,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "ActionMagicLinkMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ExecuteActionPayload",
                    "kind": "LinkedField",
                    "name": "executeAction",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Action",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "payload",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "ActionMagicLinkMutation",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": "ExecuteActionPayload",
                    "kind": "LinkedField",
                    "name": "executeAction",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "Action",
                            "kind": "LinkedField",
                            "name": "action",
                            "plural": false,
                            "selections": [
                                (v2 /*: any*/),
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "payload",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        },
                                        (v4 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v5 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7ef39bd4d0ac5db97c2a59d12bbd50ac",
            "id": null,
            "metadata": {},
            "name": "ActionMagicLinkMutation",
            "operationKind": "mutation",
            "text": "mutation ActionMagicLinkMutation(\n  $input: ExecuteActionInput!\n) {\n  executeAction(input: $input) {\n    action {\n      id\n      type {\n        identifier\n      }\n      payload {\n        __typename\n        ... on AiWorkflowsUsersActionsMagicLinkPayload {\n          token\n        }\n      }\n    }\n    errors {\n      code\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '60bfadad8a8f2a565072994e0c10bd28';
export default node;
