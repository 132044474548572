import * as React from "react";
import { Trans, useTranslation } from "react-i18next";

export interface AssignedAssigneeActivityProps {
  activity: any;
}

export function AssignedAssigneeActivity({
  activity,
}: AssignedAssigneeActivityProps): JSX.Element {
  const { toObject, actor, insertedAt } = activity;
  const { t } = useTranslation("ai.workflows.activities");

  return (
    <div>
      <Trans i18nKey="AssignedAssigneeActivity" t={t}>
        {{ actorName: actor.name }} added {{ objectName: toObject.name }}{" "}
        {{ insertedAt }}
      </Trans>
    </div>
  );
}
