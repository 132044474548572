import { useTenantId } from "@workflows/ui";
import * as React from "react";
import { graphql, useLazyLoadQuery } from "react-relay/hooks";
import { SearchResultsList } from "./SearchResultsList";

export const SearchResultsQuery = graphql`
  query SearchResultsQuery(
    $queryText: String!
    $tenantId: ID!
    $after: String
    $first: Int
  ) {
    ...SearchResultsList_search
  }
`;

export interface SearchResultsProps {
  query: string;
  onOpen?: (item: any, e: any) => void;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  query,
  onOpen,
}) => {
  const tenantId = useTenantId();

  const data = useLazyLoadQuery(SearchResultsQuery, {
    queryText: query,
    tenantId,
  });
  return (
    <SearchResultsList query={query} queryRef={data as any} onOpen={onOpen} />
  );
};
