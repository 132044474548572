import * as React from "react";
import { ViewerTenantContextContainer } from "@workflows/ui";

const availableConfigValues = [
  "ai.workflows.tenants.config.footer",
  "ai.workflows.tenants.config.gateway",
  "ai.workflows.tenants.config.landing",
  "ai.workflows.tenants.config.legalAgreements",
  "ai.workflows.tenants.config.signup",
  "ai.workflows.tenants.config.signupCallout",
] as const;

export const globalConfigValues = availableConfigValues as unknown as string[];

export type ConfigValueKey = typeof availableConfigValues[number];

export function useConfigValue<T = unknown>(key: ConfigValueKey): T | null {
  const { configValues } = React.useContext(ViewerTenantContextContainer);
  const config = configValues?.find((c) => c?.key === key);

  if (!config) {
    return null;
  }

  return config.value as T;
}

export interface ConfigValueFooter {
  start: string;
}

export interface ConfigValueGateway {
  enabled: boolean;
}

export interface ConfigValueLanding {
  name: string;
}

export interface ConfigValueLegalAgreements {
  [key: string]: {
    termsOfService?: string;
    dataPrivacy?: string;
  };
}

export interface ConfigValueSignup {
  enabled: boolean;
  hasFieldCompany: boolean;
}
