/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UsersEditorColumnQueryVariables = {
    userId: string;
    objectId: string;
    tenantId: string;
    typeIdentifier: string;
    count?: number | null;
    cursor?: string | null;
};
export type UsersEditorColumnQueryResponse = {
    readonly tenant: {
        readonly id: string;
        readonly " $fragmentRefs": FragmentRefs<"UserFormQuery_assignableRoles">;
    } | null;
    readonly user: {
        readonly id: string;
        readonly name?: string;
        readonly email?: string;
        readonly assignedRoles?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly identifier: string | null;
                    readonly name: string | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
};
export type UsersEditorColumnQuery = {
    readonly response: UsersEditorColumnQueryResponse;
    readonly variables: UsersEditorColumnQueryVariables;
};



/*
query UsersEditorColumnQuery(
  $userId: ID!
  $objectId: ID!
  $tenantId: ID!
  $typeIdentifier: String!
  $count: Int
  $cursor: String
) {
  tenant: node(id: $tenantId) {
    __typename
    id
    ...UserFormQuery_assignableRoles_1mj6oi
  }
  user: node(id: $userId) {
    __typename
    id
    ... on User {
      name
      email
      assignedRoles(objectId: $objectId) {
        edges {
          node {
            id
            identifier
            name
          }
        }
      }
    }
  }
}

fragment UserFormQuery_assignableRole on Role {
  id
  name
  identifier
}

fragment UserFormQuery_assignableRoles_1mj6oi on Tenant {
  assignableRoles(typeIdentifier: $typeIdentifier, first: $count, after: $cursor) {
    edges {
      cursor
      node {
        ...UserFormQuery_assignableRole
        id
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "count"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "objectId"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tenantId"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "typeIdentifier"
    } as any, v5 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "userId"
    } as any, v6 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "tenantId"
        } as any
    ], v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v8 = {
        "kind": "Variable",
        "name": "typeIdentifier",
        "variableName": "typeIdentifier"
    } as any, v9 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "userId"
        } as any
    ], v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v11 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
    } as any, v12 = {
        "kind": "InlineFragment",
        "selections": [
            (v10 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "email",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Variable",
                        "name": "objectId",
                        "variableName": "objectId"
                    }
                ],
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "assignedRoles",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v7 /*: any*/),
                                    (v11 /*: any*/),
                                    (v10 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "User",
        "abstractKey": null
    } as any, v13 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v14 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any,
        (v8 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v5 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UsersEditorColumnQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v6 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "tenantId",
                                    "variableName": "tenantId"
                                },
                                (v8 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "UserFormQuery_assignableRoles"
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "user",
                    "args": (v9 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        (v12 /*: any*/)
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v5 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v0 /*: any*/),
                (v1 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UsersEditorColumnQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v6 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v13 /*: any*/),
                        (v7 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v14 /*: any*/),
                                    "concreteType": "RoleConnection",
                                    "kind": "LinkedField",
                                    "name": "assignableRoles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v7 /*: any*/),
                                                        (v10 /*: any*/),
                                                        (v11 /*: any*/),
                                                        (v13 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v14 /*: any*/),
                                    "filters": [
                                        "typeIdentifier"
                                    ],
                                    "handle": "connection",
                                    "key": "UserFormQuery_assignableRoles",
                                    "kind": "LinkedHandle",
                                    "name": "assignableRoles"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": "user",
                    "args": (v9 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v13 /*: any*/),
                        (v7 /*: any*/),
                        (v12 /*: any*/)
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "a6338f85df2f31849fb3173a26ad83d5",
            "id": null,
            "metadata": {},
            "name": "UsersEditorColumnQuery",
            "operationKind": "query",
            "text": "query UsersEditorColumnQuery(\n  $userId: ID!\n  $objectId: ID!\n  $tenantId: ID!\n  $typeIdentifier: String!\n  $count: Int\n  $cursor: String\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    id\n    ...UserFormQuery_assignableRoles_1mj6oi\n  }\n  user: node(id: $userId) {\n    __typename\n    id\n    ... on User {\n      name\n      email\n      assignedRoles(objectId: $objectId) {\n        edges {\n          node {\n            id\n            identifier\n            name\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment UserFormQuery_assignableRole on Role {\n  id\n  name\n  identifier\n}\n\nfragment UserFormQuery_assignableRoles_1mj6oi on Tenant {\n  assignableRoles(typeIdentifier: $typeIdentifier, first: $count, after: $cursor) {\n    edges {\n      cursor\n      node {\n        ...UserFormQuery_assignableRole\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
        }
    } as any;
})();
(node as any).hash = 'a90e42fe8fb2a0089b4b9ce2dc37d989';
export default node;
