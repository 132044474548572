import {
  getEdgeNodes,
  graphql,
  useLazyLoadQuery,
} from "@workflows/runtime-web";
import { useTenantUsersQuery } from "~/__graphql__/useTenantUsersQuery.graphql";
import { useTenantId } from "@workflows/ui";

export const useTenantUsers = (subtenantId?: string) => {
  const tenantId = useTenantId();

  const { users } = useLazyLoadQuery<useTenantUsersQuery>(tenantUsersQuery, {
    tenantId: subtenantId || tenantId,
  });

  const edgeNodes = getEdgeNodes(users || null) || [];
  const tenantUsers = edgeNodes.map((user) => ({
    ...user,
    assignedRoles: getEdgeNodes(user.assignedRoles || null),
  }));

  return {
    tenantUsers,
  };
};

const tenantUsersQuery = graphql`
  query useTenantUsersQuery($tenantId: ID!) {
    users(tenantId: $tenantId, first: 100)
      @connection(key: "useTenantUsers_users") {
      edges {
        node {
          ...useTenantUsers_user @relay(mask: false)
        }
      }
    }
  }
`;

graphql`
  fragment useTenantUsers_user on User {
    id
    name
    displayName
    email
    ...useTenantUsers_assignedRoles @relay(mask: false)
  }
`;

graphql`
  fragment useTenantUsers_assignedRoles on User {
    assignedRoles(objectId: $tenantId, first: 100)
      @connection(key: "user_assignedRoles") {
      edges {
        cursor
        node {
          id
          name
          identifier
        }
      }
    }
  }
`;
