import { graphql } from "react-relay/hooks";
import { useLazyLoadQuery } from "@workflows/runtime-web";
import { useDocumentTemplateQuery } from "~/__graphql__/useDocumentTemplateQuery.graphql";

export const useDocumentTemplate = (id: string) => {
  const { documentTemplate } = useLazyLoadQuery<useDocumentTemplateQuery>(
    Query,
    {
      id,
    }
  );
  return documentTemplate;
};

const Query = graphql`
  query useDocumentTemplateQuery($id: ID!) {
    documentTemplate: node(id: $id) {
      id
      ... on DocumentTemplate {
        title
        description
      }
    }
  }
`;
