/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CoreNav_nav = {
    readonly viewerPermissions: ReadonlyArray<string>;
    readonly nav: {
        readonly items: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                    readonly sort: string;
                    readonly path: ReadonlyArray<string>;
                    readonly icon: string | null;
                    readonly identifier: string | null;
                    readonly externalUrl: string | null;
                    readonly route: {
                        readonly path: string;
                    } | null;
                } | null;
            } | null> | null;
        } | null;
    } | null;
    readonly " $refType": "CoreNav_nav";
};
export type CoreNav_nav$data = CoreNav_nav;
export type CoreNav_nav$key = {
    readonly " $data"?: CoreNav_nav$data;
    readonly " $fragmentRefs": FragmentRefs<"CoreNav_nav">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "path",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "CoreNav_nav",
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "viewerPermissions",
                "storageKey": null
            },
            {
                "alias": null,
                "args": [
                    {
                        "kind": "Literal",
                        "name": "identifier",
                        "value": "ai.workflows.nav.main"
                    }
                ],
                "concreteType": "Nav",
                "kind": "LinkedField",
                "name": "nav",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "NavItemConnection",
                        "kind": "LinkedField",
                        "name": "items",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "NavItemEdge",
                                "kind": "LinkedField",
                                "name": "edges",
                                "plural": true,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "NavItem",
                                        "kind": "LinkedField",
                                        "name": "node",
                                        "plural": false,
                                        "selections": [
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "id",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "name",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "sort",
                                                "storageKey": null
                                            },
                                            (v0 /*: any*/),
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "icon",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "identifier",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "kind": "ScalarField",
                                                "name": "externalUrl",
                                                "storageKey": null
                                            },
                                            {
                                                "alias": null,
                                                "args": null,
                                                "concreteType": "Route",
                                                "kind": "LinkedField",
                                                "name": "route",
                                                "plural": false,
                                                "selections": [
                                                    (v0 /*: any*/)
                                                ],
                                                "storageKey": null
                                            }
                                        ],
                                        "storageKey": null
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": "nav(identifier:\"ai.workflows.nav.main\")"
            }
        ],
        "type": "Tenant",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '549c47022e3bbd90380af5d913950633';
export default node;
