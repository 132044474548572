import { graphql } from "react-relay/hooks";
import { getEdgeNodes, useLazyLoadQuery } from "@workflows/runtime-web";
import { useRolesQuery } from "~/__graphql__/useRolesQuery.graphql";
import { useTenantId } from "@workflows/ui";

export const useRoles = (subtenantId?: string) => {
  const tenantId = useTenantId();
  const { roles } = useLazyLoadQuery<useRolesQuery>(rolesQuery, {
    tenantId: subtenantId || tenantId,
  });
  return getEdgeNodes(roles || null);
};

const rolesQuery = graphql`
  query useRolesQuery($tenantId: ID!) {
    roles(tenantId: $tenantId) {
      edges {
        node {
          id
          identifier
          name
        }
      }
    }
  }
`;
