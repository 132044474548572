import { RouteComponentProps } from "@workflows/runtime-web";
import { useDocumentTitle } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";
import { PreloadedQuery } from "react-relay";
import { MattersColumnQuery } from "~/__graphql__/MattersColumnQuery.graphql";
import { MattersColumn, MattersColumnQueryRequest } from "./MattersColumn";
import { MattersColumnSkeleton } from "./MattersColumnSkeleton";
import { MattersFilterColumn } from "./MattersFilterColumn";

export const MattersRouteQuery = MattersColumnQueryRequest;

export interface MattersRouteEntryPointQueries {
  matters: PreloadedQuery<MattersColumnQuery>;
}

export interface MattersRouteProps extends RouteComponentProps {
  queries: MattersRouteEntryPointQueries;
}

export function MattersRoute({ queries }: MattersRouteProps): JSX.Element {
  const { t } = useTranslation("ai.workflows.matters");

  useDocumentTitle(t("MattersRoute.pageTitle"));

  return (
    <React.Suspense fallback={<MattersColumnSkeleton />}>
      <MattersFilterColumn />
      <MattersColumn queryRef={queries.matters} />
    </React.Suspense>
  );
}
