/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MattersTableView_matter = {
    readonly id: string;
    readonly title: string;
    readonly number: number;
    readonly dueDate: string | null;
    readonly insertedAt: string;
    readonly creator: {
        readonly id: string;
        readonly name: string;
    } | null;
    readonly " $fragmentRefs": FragmentRefs<"AssigneesPreview_node" | "Status_status">;
    readonly " $refType": "MattersTableView_matter";
};
export type MattersTableView_matter$data = MattersTableView_matter;
export type MattersTableView_matter$key = {
    readonly " $data"?: MattersTableView_matter$data;
    readonly " $fragmentRefs": FragmentRefs<"MattersTableView_matter">;
};



const node: ReaderFragment = (function () {
    var v0 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any;
    return {
        "argumentDefinitions": [],
        "kind": "Fragment",
        "metadata": null,
        "name": "MattersTableView_matter",
        "selections": [
            (v0 /*: any*/),
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "title",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "number",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dueDate",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "insertedAt",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "creator",
                "plural": false,
                "selections": [
                    (v0 /*: any*/),
                    {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssigneesPreview_node"
            },
            {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Status_status"
            }
        ],
        "type": "Matter",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '16e0bd3d1b2f59af9c5d1bf542487bc7';
export default node;
