import {
  createPatch,
  newId,
  useRecoilCallback,
  useRecoilState,
  waitForAll,
} from "@workflows/runtime-web";
import { useForm, useToast } from "@workflows/ui";
import { useTranslation } from "react-i18next";
import { graphql, useMutation } from "react-relay/hooks";
import { idFamily, valueFamily } from "~/documents/state";
import * as Values from "~/documents/values";
import { WizardSyncMutation } from "~/__graphql__/WizardSyncMutation.graphql";
import { wizardStepIndexState } from "./state";

export interface UseWizardSync {
  isPending: boolean;
  sync: (_: { onCompleted?: () => void; onError?: () => void }) => void;
}

export function useWizardSync(
  tenantId: string,
  documentId: string
): UseWizardSync {
  const form = useForm(documentId);
  const [commit, isPending] = useMutation<WizardSyncMutation>(Mutation);
  const [currentStep] = useRecoilState(wizardStepIndexState);
  const { t } = useTranslation("de.smartconex.vertragsgenerator");
  const [addToast] = useToast();

  const displayToast = (message: string) => {
    addToast({
      id: "wizardSyncToast" + newId(),
      message: message,
      intent: "warning",
    });
  };

  const sync = useRecoilCallback(
    ({ snapshot }) =>
      async ({
        onCompleted,
        onError,
      }: {
        onCompleted?: () => void;
        onError?: () => void;
      }) => {
        const validation = await form.validate();

        if (!validation.isValid) {
          console.debug("Form is not valid");
          displayToast(t("WizardSync.toast.invalid"));
          return;
        }

        const formValues = form.getChanges();
        const identifiers = Object.keys(formValues);

        if (currentStep > 1 && identifiers.length === 0) {
          console.debug("No changed form fields to sync");
          onCompleted?.();
          return;
        }

        const valueKeys = await snapshot
          .getPromise(
            waitForAll(identifiers.map((i) => idFamily([documentId, i])))
          )
          .then((ids) => ids.filter(Boolean));

        const values = (await snapshot
          .getPromise(waitForAll(valueKeys.map(valueFamily)))
          .then((vals) => vals.filter(Boolean))) as any[];

        if (currentStep > 1 && values.length === 0) {
          console.debug("Nothing to sync");
          onCompleted?.();
          return;
        }

        if (identifiers.length !== values.length) {
          console.debug("Failed to map form fields to document values");
        }

        const [prev, next, reset] = Object.entries(formValues).reduce<any>(
          ([prev, next, reset], [identifier, field]) => {
            const val: any = values.find(
              (v: any) => v.identifier === identifier
            );

            if (val) {
              reset[val.id] = val;
              prev[val.id] = field.initialValue;
              next[val.id] = Values.dump(val);
            }

            return [prev, next, reset];
          },
          [{}, {}, {}]
        );

        // TODO: The api currently only supports support for replacing values
        const patches = createPatch(prev, next)
          .map((op) => {
            return {
              ...op,
              op: "replace",
              path: `/values${op.path}/value`,
              value: JSON.stringify((op as any).value),
            };
          })
          .filter((patch) => patch.value !== "null");

        if (currentStep > 1 && patches.length === 0) {
          console.debug("Nothing to sync");
          displayToast(t("WizardSync.toast.sync"));
          onCompleted?.();
        }

        commit({
          variables: {
            input: {
              clientMutationId: newId(),
              tenantId: tenantId,
              documentId: documentId,
              patch: patches,
            },
          },
          onCompleted(data) {
            if (data.patchDocument?.errors) {
              console.error(data.patchDocument?.errors);
              return;
            }

            // TODO: We should fetch the changed values from the server to take
            // changes by the server and other users into account.
            // Reset the form so all changes are marked for sync again
            form.reset(reset);
            onCompleted?.();
          },
          onError(err) {
            console.log(err);
            onError?.();
          },
        });
      }
  );

  return { isPending, sync };
}

// TODO: We currently refetch all values again because the `patchDocument` only
// returns the document and not the changed elements/values. But we need this or
// otherwise the form would be re-initialized with stale values when switching
// between sections due to the fact that the `DocumentViewer` is re-rendered.
// This also creates a dependency to the document renderer fragment.
const Mutation = graphql`
  mutation WizardSyncMutation($input: PatchDocumentInput!) {
    patchDocument(input: $input) {
      document {
        ...DocumentViewerBody_values
      }
      errors {
        code
        path
        message
      }
    }
  }
`;
