import { Value } from "./types";

export const type = "string";

export function load(val: Value<string>): Value<string> {
  return val;
}

export function dump(val: Value<string>): string | null {
  return val.value;
}
