import * as React from "react";
import { Tag } from "@workflows/ui";
import { Trans, useTranslation } from "react-i18next";
export interface UnassignTagActivityProps {
  activity: any;
}

export function UnassignTagActivity({
  activity,
}: UnassignTagActivityProps): JSX.Element {
  const { toObject, actor, insertedAt } = activity;
  const { t } = useTranslation("ai.workflows.activities");

  return (
    <div>
      <Trans i18nKey="UnassignedTagActivity" t={t}>
        {{ actorName: actor.name }} removed the{" "}
        <Tag color={toObject.color} textColor={toObject.textColor} size="sm">
          {{ objectName: toObject.name }} {{ insertedAt }}
        </Tag>
      </Trans>
    </div>
  );
}
