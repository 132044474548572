/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserFormQuery_assignableRoles = {
    readonly assignableRoles: {
        readonly edges: ReadonlyArray<{
            readonly cursor: string | null;
            readonly node: {
                readonly " $fragmentRefs": FragmentRefs<"UserFormQuery_assignableRole">;
            } | null;
        } | null> | null;
    } | null;
    readonly id: string;
    readonly " $refType": "UserFormQuery_assignableRoles";
};
export type UserFormQuery_assignableRoles$data = UserFormQuery_assignableRoles;
export type UserFormQuery_assignableRoles$key = {
    readonly " $data"?: UserFormQuery_assignableRoles$data;
    readonly " $fragmentRefs": FragmentRefs<"UserFormQuery_assignableRoles">;
};



const node: ReaderFragment = (function () {
    var v0 = [
        "assignableRoles"
    ];
    return {
        "argumentDefinitions": [
            {
                "defaultValue": 100,
                "kind": "LocalArgument",
                "name": "count"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "cursor"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "tenantId"
            },
            {
                "defaultValue": null,
                "kind": "LocalArgument",
                "name": "typeIdentifier"
            }
        ],
        "kind": "Fragment",
        "metadata": {
            "connection": [
                {
                    "count": "count",
                    "cursor": "cursor",
                    "direction": "forward",
                    "path": (v0 /*: any*/)
                }
            ],
            "refetch": {
                "connection": {
                    "forward": {
                        "count": "count",
                        "cursor": "cursor"
                    },
                    "backward": null,
                    "path": (v0 /*: any*/)
                },
                "fragmentPathInResult": [
                    "node"
                ],
                "operation": require('./UserFormPaginationQuery.graphql.ts'),
                "identifierField": "id"
            }
        },
        "name": "UserFormQuery_assignableRoles",
        "selections": [
            {
                "alias": "assignableRoles",
                "args": [
                    {
                        "kind": "Variable",
                        "name": "typeIdentifier",
                        "variableName": "typeIdentifier"
                    }
                ],
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "__UserFormQuery_assignableRoles_connection",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "RoleEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Role",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "__typename",
                                        "storageKey": null
                                    },
                                    {
                                        "args": null,
                                        "kind": "FragmentSpread",
                                        "name": "UserFormQuery_assignableRole"
                                    }
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    },
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                            },
                            {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
            }
        ],
        "type": "Tenant",
        "abstractKey": null
    } as any;
})();
(node as any).hash = '7d83a1da5fd66ec00c33b8852b946f6c';
export default node;
