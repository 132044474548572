import * as React from "react";
import { CollectionItemChooser } from "../collections/CollectionItemChooser";
import { CollectionItemChooserList } from "../collections/CollectionItemChooserList";

export interface DashboardCollectionWidgetProps {
  collectionId: string;
  start?: React.ReactNodeArray;
}

export function DashboardCollectionWidget({
  collectionId,
  start,
}: DashboardCollectionWidgetProps): JSX.Element {
  return (
    <React.Suspense fallback={<CollectionItemChooserList isPending={true} />}>
      <CollectionItemChooser collectionId={collectionId} start={start} />
    </React.Suspense>
  );
}
