/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type UserFormPaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    tenantId: string;
    typeIdentifier: string;
    id: string;
};
export type UserFormPaginationQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"UserFormQuery_assignableRoles">;
    } | null;
};
export type UserFormPaginationQuery = {
    readonly response: UserFormPaginationQueryResponse;
    readonly variables: UserFormPaginationQueryVariables;
};



/*
query UserFormPaginationQuery(
  $count: Int = 100
  $cursor: String
  $typeIdentifier: String!
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...UserFormQuery_assignableRoles_1mj6oi
    id
  }
}

fragment UserFormQuery_assignableRole on Role {
  id
  name
  identifier
}

fragment UserFormQuery_assignableRoles_1mj6oi on Tenant {
  assignableRoles(typeIdentifier: $typeIdentifier, first: $count, after: $cursor) {
    edges {
      cursor
      node {
        ...UserFormQuery_assignableRole
        id
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = {
        "defaultValue": 100,
        "kind": "LocalArgument",
        "name": "count"
    } as any, v1 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "cursor"
    } as any, v2 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "id"
    } as any, v3 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "tenantId"
    } as any, v4 = {
        "defaultValue": null,
        "kind": "LocalArgument",
        "name": "typeIdentifier"
    } as any, v5 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any
    ], v6 = {
        "kind": "Variable",
        "name": "typeIdentifier",
        "variableName": "typeIdentifier"
    } as any, v7 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v8 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v9 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any,
        (v6 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v2 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/)
            ],
            "kind": "Fragment",
            "metadata": null,
            "name": "UserFormPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "tenantId",
                                    "variableName": "tenantId"
                                },
                                (v6 /*: any*/)
                            ],
                            "kind": "FragmentSpread",
                            "name": "UserFormQuery_assignableRoles"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": [
                (v0 /*: any*/),
                (v1 /*: any*/),
                (v3 /*: any*/),
                (v4 /*: any*/),
                (v2 /*: any*/)
            ],
            "kind": "Operation",
            "name": "UserFormPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v5 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v7 /*: any*/),
                        (v8 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                {
                                    "alias": null,
                                    "args": (v9 /*: any*/),
                                    "concreteType": "RoleConnection",
                                    "kind": "LinkedField",
                                    "name": "assignableRoles",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "RoleEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Role",
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v8 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "name",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "identifier",
                                                            "storageKey": null
                                                        },
                                                        (v7 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v9 /*: any*/),
                                    "filters": [
                                        "typeIdentifier"
                                    ],
                                    "handle": "connection",
                                    "key": "UserFormQuery_assignableRoles",
                                    "kind": "LinkedHandle",
                                    "name": "assignableRoles"
                                }
                            ],
                            "type": "Tenant",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "7107705b5abf21eb8d2a6f46f814b086",
            "id": null,
            "metadata": {},
            "name": "UserFormPaginationQuery",
            "operationKind": "query",
            "text": "query UserFormPaginationQuery(\n  $count: Int = 100\n  $cursor: String\n  $typeIdentifier: String!\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...UserFormQuery_assignableRoles_1mj6oi\n    id\n  }\n}\n\nfragment UserFormQuery_assignableRole on Role {\n  id\n  name\n  identifier\n}\n\nfragment UserFormQuery_assignableRoles_1mj6oi on Tenant {\n  assignableRoles(typeIdentifier: $typeIdentifier, first: $count, after: $cursor) {\n    edges {\n      cursor\n      node {\n        ...UserFormQuery_assignableRole\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
        }
    } as any;
})();
(node as any).hash = '7d83a1da5fd66ec00c33b8852b946f6c';
export default node;
