/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type ResetUserPasswordInput = {
    clientMutationId: string;
    email: string;
    tenantId: string;
};
export type AccountRecoverPasswordRouteMutationVariables = {
    input: ResetUserPasswordInput;
};
export type AccountRecoverPasswordRouteMutationResponse = {
    readonly resetUserPassword: {
        readonly clientMutationId: string;
    } | null;
};
export type AccountRecoverPasswordRouteMutation = {
    readonly response: AccountRecoverPasswordRouteMutationResponse;
    readonly variables: AccountRecoverPasswordRouteMutationVariables;
};



/*
mutation AccountRecoverPasswordRouteMutation(
  $input: ResetUserPasswordInput!
) {
  resetUserPassword(input: $input) {
    clientMutationId
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "input"
        } as any
    ], v1 = [
        {
            "alias": null,
            "args": [
                {
                    "kind": "Variable",
                    "name": "input",
                    "variableName": "input"
                }
            ],
            "concreteType": "ResetUserPasswordPayload",
            "kind": "LinkedField",
            "name": "resetUserPassword",
            "plural": false,
            "selections": [
                {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clientMutationId",
                    "storageKey": null
                }
            ],
            "storageKey": null
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "AccountRecoverPasswordRouteMutation",
            "selections": (v1 /*: any*/),
            "type": "RootMutationType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "AccountRecoverPasswordRouteMutation",
            "selections": (v1 /*: any*/)
        },
        "params": {
            "cacheID": "a7601f4fb5db1e5bba0e2f3c10aaf12e",
            "id": null,
            "metadata": {},
            "name": "AccountRecoverPasswordRouteMutation",
            "operationKind": "mutation",
            "text": "mutation AccountRecoverPasswordRouteMutation(\n  $input: ResetUserPasswordInput!\n) {\n  resetUserPassword(input: $input) {\n    clientMutationId\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '663a79b9b2f193812a7f0c61663b663b';
export default node;
