import { ErrorGenericIllustration, NonIdealState } from "@workflows/ui";
import * as React from "react";
import { useTranslation } from "react-i18next";

export function ActionNotFoundState(): JSX.Element {
  const { t } = useTranslation("ai.workflows.actions");

  return (
    <NonIdealState
      title={t("ActionNotFoundState.title")}
      description={t("ActionNotFoundState.description")}
      header={<ErrorGenericIllustration />}
    />
  );
}
