/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type CollectionItemChooserListPaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    id: string;
};
export type CollectionItemChooserListPaginationQueryResponse = {
    readonly node: {
        readonly " $fragmentRefs": FragmentRefs<"CollectionItemChooserList_collection">;
    } | null;
};
export type CollectionItemChooserListPaginationQuery = {
    readonly response: CollectionItemChooserListPaginationQueryResponse;
    readonly variables: CollectionItemChooserListPaginationQueryVariables;
};



/*
query CollectionItemChooserListPaginationQuery(
  $count: Int = 100
  $cursor: String
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...CollectionItemChooserList_collection_1G22uz
    id
  }
}

fragment CollectionItemChooserListFileItem_item on File {
  id
  name
  description
  downloadUrl
}

fragment CollectionItemChooserListWorkflowItem_item on Workflow {
  id
  name
  descriptionText
  icon
  color
}

fragment CollectionItemChooserList_collection_1G22uz on Collection {
  name
  items(after: $cursor, first: $count) {
    edges {
      cursor
      node {
        __typename
        ...CollectionItemChooserListFileItem_item
        ...CollectionItemChooserListWorkflowItem_item
        id
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  id
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": 100,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "id"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "id"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v5 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "CollectionItemChooserListPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "args": [
                                {
                                    "kind": "Variable",
                                    "name": "count",
                                    "variableName": "count"
                                },
                                {
                                    "kind": "Variable",
                                    "name": "cursor",
                                    "variableName": "cursor"
                                }
                            ],
                            "kind": "FragmentSpread",
                            "name": "CollectionItemChooserList_collection"
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "CollectionItemChooserListPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v3 /*: any*/),
                        {
                            "kind": "InlineFragment",
                            "selections": [
                                (v4 /*: any*/),
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "concreteType": "CollectionItemConnection",
                                    "kind": "LinkedField",
                                    "name": "items",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "CollectionItemEdge",
                                            "kind": "LinkedField",
                                            "name": "edges",
                                            "plural": true,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "cursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": null,
                                                    "kind": "LinkedField",
                                                    "name": "node",
                                                    "plural": false,
                                                    "selections": [
                                                        (v2 /*: any*/),
                                                        (v3 /*: any*/),
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "description",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "downloadUrl",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "File",
                                                            "abstractKey": null
                                                        },
                                                        {
                                                            "kind": "InlineFragment",
                                                            "selections": [
                                                                (v4 /*: any*/),
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "descriptionText",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "icon",
                                                                    "storageKey": null
                                                                },
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "kind": "ScalarField",
                                                                    "name": "color",
                                                                    "storageKey": null
                                                                }
                                                            ],
                                                            "type": "Workflow",
                                                            "abstractKey": null
                                                        }
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "PageInfo",
                                            "kind": "LinkedField",
                                            "name": "pageInfo",
                                            "plural": false,
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "endCursor",
                                                    "storageKey": null
                                                },
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "kind": "ScalarField",
                                                    "name": "hasNextPage",
                                                    "storageKey": null
                                                }
                                            ],
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": (v5 /*: any*/),
                                    "filters": null,
                                    "handle": "connection",
                                    "key": "CollectionItemChooserList_items",
                                    "kind": "LinkedHandle",
                                    "name": "items"
                                }
                            ],
                            "type": "Collection",
                            "abstractKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "f1099d0a05d83f4490c520c9df8643e8",
            "id": null,
            "metadata": {},
            "name": "CollectionItemChooserListPaginationQuery",
            "operationKind": "query",
            "text": "query CollectionItemChooserListPaginationQuery(\n  $count: Int = 100\n  $cursor: String\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CollectionItemChooserList_collection_1G22uz\n    id\n  }\n}\n\nfragment CollectionItemChooserListFileItem_item on File {\n  id\n  name\n  description\n  downloadUrl\n}\n\nfragment CollectionItemChooserListWorkflowItem_item on Workflow {\n  id\n  name\n  descriptionText\n  icon\n  color\n}\n\nfragment CollectionItemChooserList_collection_1G22uz on Collection {\n  name\n  items(after: $cursor, first: $count) {\n    edges {\n      cursor\n      node {\n        __typename\n        ...CollectionItemChooserListFileItem_item\n        ...CollectionItemChooserListWorkflowItem_item\n        id\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  id\n}\n"
        }
    } as any;
})();
(node as any).hash = '8659024a2797d13bf0a1ee0c57dce8d7';
export default node;
