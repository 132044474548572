/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
export type useDashboardDataQueryVariables = {
    tenantId: string;
};
export type useDashboardDataQueryResponse = {
    readonly tenant: {
        readonly id: string;
        readonly statuses?: {
            readonly edges: ReadonlyArray<{
                readonly node: {
                    readonly id: string;
                    readonly name: string;
                    readonly identifier: string;
                    readonly textColor: string;
                    readonly color: string;
                } | null;
            } | null> | null;
        } | null;
    } | null;
    readonly documents: {
        readonly edges: ReadonlyArray<{
            readonly node: {
                readonly id: string;
                readonly title: string;
                readonly status: {
                    readonly color: string;
                    readonly textColor: string;
                    readonly identifier: string;
                    readonly name: string;
                };
                readonly description: string | null;
                readonly color: string | null;
            } | null;
        } | null> | null;
    } | null;
};
export type useDashboardDataQuery = {
    readonly response: useDashboardDataQueryResponse;
    readonly variables: useDashboardDataQueryVariables;
};



/*
query useDashboardDataQuery(
  $tenantId: ID!
) {
  tenant: node(id: $tenantId) {
    __typename
    id
    ... on Tenant {
      statuses {
        edges {
          node {
            id
            name
            identifier
            textColor
            color
          }
        }
      }
    }
  }
  documents(tenantId: $tenantId, sort: {field: TITLE, order: ASC}) {
    edges {
      node {
        id
        title
        status {
          color
          textColor
          identifier
          name
          id
        }
        description
        color
      }
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = [
        {
            "kind": "Variable",
            "name": "id",
            "variableName": "tenantId"
        } as any
    ], v2 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "identifier",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "textColor",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "color",
        "storageKey": null
    } as any, v7 = {
        "kind": "InlineFragment",
        "selections": [
            {
                "alias": null,
                "args": null,
                "concreteType": "StatusConnection",
                "kind": "LinkedField",
                "name": "statuses",
                "plural": false,
                "selections": [
                    {
                        "alias": null,
                        "args": null,
                        "concreteType": "StatusEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                            {
                                "alias": null,
                                "args": null,
                                "concreteType": "Status",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                    (v2 /*: any*/),
                                    (v3 /*: any*/),
                                    (v4 /*: any*/),
                                    (v5 /*: any*/),
                                    (v6 /*: any*/)
                                ],
                                "storageKey": null
                            }
                        ],
                        "storageKey": null
                    }
                ],
                "storageKey": null
            }
        ],
        "type": "Tenant",
        "abstractKey": null
    } as any, v8 = [
        {
            "kind": "Literal",
            "name": "sort",
            "value": {
                "field": "TITLE",
                "order": "ASC"
            }
        } as any,
        {
            "kind": "Variable",
            "name": "tenantId",
            "variableName": "tenantId"
        } as any
    ], v9 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "title",
        "storageKey": null
    } as any, v10 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "description",
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "useDashboardDataQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        (v2 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "DocumentConnection",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Document",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Status",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v5 /*: any*/),
                                                (v4 /*: any*/),
                                                (v3 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "useDashboardDataQuery",
            "selections": [
                {
                    "alias": "tenant",
                    "args": (v1 /*: any*/),
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                        },
                        (v2 /*: any*/),
                        (v7 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v8 /*: any*/),
                    "concreteType": "DocumentConnection",
                    "kind": "LinkedField",
                    "name": "documents",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "DocumentEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Document",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v2 /*: any*/),
                                        (v9 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "Status",
                                            "kind": "LinkedField",
                                            "name": "status",
                                            "plural": false,
                                            "selections": [
                                                (v6 /*: any*/),
                                                (v5 /*: any*/),
                                                (v4 /*: any*/),
                                                (v3 /*: any*/),
                                                (v2 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v10 /*: any*/),
                                        (v6 /*: any*/)
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                }
            ]
        },
        "params": {
            "cacheID": "d9d054180ea5e6d06294cc3c01cf8d98",
            "id": null,
            "metadata": {},
            "name": "useDashboardDataQuery",
            "operationKind": "query",
            "text": "query useDashboardDataQuery(\n  $tenantId: ID!\n) {\n  tenant: node(id: $tenantId) {\n    __typename\n    id\n    ... on Tenant {\n      statuses {\n        edges {\n          node {\n            id\n            name\n            identifier\n            textColor\n            color\n          }\n        }\n      }\n    }\n  }\n  documents(tenantId: $tenantId, sort: {field: TITLE, order: ASC}) {\n    edges {\n      node {\n        id\n        title\n        status {\n          color\n          textColor\n          identifier\n          name\n          id\n        }\n        description\n        color\n      }\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'bea1bfb7df080edbc8bcd2eadb6b3b49';
export default node;
