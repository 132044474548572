import { ConnectionHandler, graphql } from "react-relay/hooks";
import { newId, useMutation } from "@workflows/runtime-web";
import {
  useDeleteUserMutation,
  useDeleteUserMutationResponse,
} from "~/__graphql__/useDeleteUserMutation.graphql";

interface useDeleteUserProps {
  onError: (error: Error) => void;
  onCompleted: (data: useDeleteUserMutationResponse) => void;
  input: {
    userId: string;
    tenantId: string;
  };
}

interface useDeleteUserValue {
  deleteUser: (props: useDeleteUserProps) => void;
  isPending: boolean;
}

export const useDeleteUser = (): useDeleteUserValue => {
  const [mutation, isPending] =
    useMutation<useDeleteUserMutation>(deleteUserMutation);

  const deleteUser = (props: useDeleteUserProps) => {
    const {
      onError,
      onCompleted,
      input: { userId, tenantId },
    } = props;

    const input = {
      clientMutationId: newId(),
      userId,
      tenantId,
    };

    const connectionId = ConnectionHandler.getConnectionID(
      "root",
      "useTenantUsers_users",
      { tenantId }
    );

    mutation({
      variables: {
        input,
        connections: [connectionId],
      },
      onError,
      onCompleted,
    });
  };

  return { deleteUser, isPending };
};

const deleteUserMutation = graphql`
  mutation useDeleteUserMutation(
    $connections: [ID!]!
    $input: DeleteUserInput!
  ) {
    deleteUser(input: $input) {
      user {
        id @deleteEdge(connections: $connections)
      }
      errors {
        code
        path
        message
        type
      }
    }
  }
`;
