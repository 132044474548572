/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type WorkflowChooserListPaginationQueryVariables = {
    count?: number | null;
    cursor?: string | null;
    tenantId: string;
};
export type WorkflowChooserListPaginationQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"WorkflowChooserList_workflows">;
};
export type WorkflowChooserListPaginationQuery = {
    readonly response: WorkflowChooserListPaginationQueryResponse;
    readonly variables: WorkflowChooserListPaginationQueryVariables;
};



/*
query WorkflowChooserListPaginationQuery(
  $count: Int = 100
  $cursor: String
  $tenantId: ID!
) {
  ...WorkflowChooserList_workflows_Nd6Pz
}

fragment WorkflowChooserListItem_item on Workflow {
  id
  name
  descriptionText
  color
  icon
}

fragment WorkflowChooserList_workflows_Nd6Pz on RootQueryType {
  workflows(tenantId: $tenantId, after: $cursor, first: $count) {
    edges {
      cursor
      node {
        ...WorkflowChooserListItem_item
        id
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": 100,
            "kind": "LocalArgument",
            "name": "count"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "cursor"
        } as any,
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Variable",
            "name": "after",
            "variableName": "cursor"
        } as any,
        {
            "kind": "Variable",
            "name": "first",
            "variableName": "count"
        } as any,
        (v1 /*: any*/)
    ];
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "WorkflowChooserListPaginationQuery",
            "selections": [
                {
                    "args": [
                        {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                        },
                        {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                        },
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "WorkflowChooserList_workflows"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "WorkflowChooserListPaginationQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "WorkflowConnection",
                    "kind": "LinkedField",
                    "name": "workflows",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "WorkflowEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "cursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Workflow",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "id",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "name",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "descriptionText",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "color",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "icon",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "__typename",
                                            "storageKey": null
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "endCursor",
                                    "storageKey": null
                                },
                                {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "hasNextPage",
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        }
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "WorkflowChooserList_workflows",
                    "kind": "LinkedHandle",
                    "name": "workflows"
                }
            ]
        },
        "params": {
            "cacheID": "c848b051774fe12b7160a1133044758b",
            "id": null,
            "metadata": {},
            "name": "WorkflowChooserListPaginationQuery",
            "operationKind": "query",
            "text": "query WorkflowChooserListPaginationQuery(\n  $count: Int = 100\n  $cursor: String\n  $tenantId: ID!\n) {\n  ...WorkflowChooserList_workflows_Nd6Pz\n}\n\nfragment WorkflowChooserListItem_item on Workflow {\n  id\n  name\n  descriptionText\n  color\n  icon\n}\n\nfragment WorkflowChooserList_workflows_Nd6Pz on RootQueryType {\n  workflows(tenantId: $tenantId, after: $cursor, first: $count) {\n    edges {\n      cursor\n      node {\n        ...WorkflowChooserListItem_item\n        id\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = '36f4fb3e1b6bbc702c174ca8f6c60e75';
export default node;
