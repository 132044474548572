/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from "relay-runtime";
import { FragmentRefs } from "relay-runtime";
export type MattersColumnQueryVariables = {
    tenantId: string;
};
export type MattersColumnQueryResponse = {
    readonly " $fragmentRefs": FragmentRefs<"MattersTableView_matters">;
};
export type MattersColumnQuery = {
    readonly response: MattersColumnQueryResponse;
    readonly variables: MattersColumnQueryVariables;
};



/*
query MattersColumnQuery(
  $tenantId: ID!
) {
  ...MattersTableView_matters_1GYzvY
}

fragment AssigneesPreview_node on HasAssignees {
  __isHasAssignees: __typename
  assignees(first: 3) {
    edges {
      node {
        id
        actor {
          __typename
          id
          ... on User {
            name
          }
          ... on Group {
            name
          }
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  ... on Node {
    __isNode: __typename
    id
  }
}

fragment MattersTableView_matters_1GYzvY on RootQueryType {
  matters(tenantId: $tenantId, first: 100) {
    edges {
      cursor
      node {
        id
        title
        number
        dueDate
        insertedAt
        creator {
          id
          name
        }
        ...AssigneesPreview_node
        ...Status_status
        __typename
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment Status_status on HasStatus {
  __isHasStatus: __typename
  status {
    name
    textColor
    color
    id
  }
}
*/

const node: ConcreteRequest = (function () {
    var v0 = [
        {
            "defaultValue": null,
            "kind": "LocalArgument",
            "name": "tenantId"
        } as any
    ], v1 = {
        "kind": "Variable",
        "name": "tenantId",
        "variableName": "tenantId"
    } as any, v2 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 100
        } as any,
        (v1 /*: any*/)
    ], v3 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "cursor",
        "storageKey": null
    } as any, v4 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
    } as any, v5 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
    } as any, v6 = {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
    } as any, v7 = [
        {
            "kind": "Literal",
            "name": "first",
            "value": 3
        } as any
    ], v8 = [
        (v5 /*: any*/)
    ], v9 = {
        "alias": null,
        "args": null,
        "concreteType": "PageInfo",
        "kind": "LinkedField",
        "name": "pageInfo",
        "plural": false,
        "selections": [
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
            },
            {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
            }
        ],
        "storageKey": null
    } as any;
    return {
        "fragment": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Fragment",
            "metadata": null,
            "name": "MattersColumnQuery",
            "selections": [
                {
                    "args": [
                        (v1 /*: any*/)
                    ],
                    "kind": "FragmentSpread",
                    "name": "MattersTableView_matters"
                }
            ],
            "type": "RootQueryType",
            "abstractKey": null
        },
        "kind": "Request",
        "operation": {
            "argumentDefinitions": (v0 /*: any*/),
            "kind": "Operation",
            "name": "MattersColumnQuery",
            "selections": [
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "concreteType": "MatterConnection",
                    "kind": "LinkedField",
                    "name": "matters",
                    "plural": false,
                    "selections": [
                        {
                            "alias": null,
                            "args": null,
                            "concreteType": "MatterEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                                (v3 /*: any*/),
                                {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Matter",
                                    "kind": "LinkedField",
                                    "name": "node",
                                    "plural": false,
                                    "selections": [
                                        (v4 /*: any*/),
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "title",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "number",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "dueDate",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "insertedAt",
                                            "storageKey": null
                                        },
                                        {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "creator",
                                            "plural": false,
                                            "selections": [
                                                (v4 /*: any*/),
                                                (v5 /*: any*/)
                                            ],
                                            "storageKey": null
                                        },
                                        (v6 /*: any*/),
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": (v7 /*: any*/),
                                                    "concreteType": "AssigneeConnection",
                                                    "kind": "LinkedField",
                                                    "name": "assignees",
                                                    "plural": false,
                                                    "selections": [
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "concreteType": "AssigneeEdge",
                                                            "kind": "LinkedField",
                                                            "name": "edges",
                                                            "plural": true,
                                                            "selections": [
                                                                {
                                                                    "alias": null,
                                                                    "args": null,
                                                                    "concreteType": "Assignee",
                                                                    "kind": "LinkedField",
                                                                    "name": "node",
                                                                    "plural": false,
                                                                    "selections": [
                                                                        (v4 /*: any*/),
                                                                        {
                                                                            "alias": null,
                                                                            "args": null,
                                                                            "concreteType": null,
                                                                            "kind": "LinkedField",
                                                                            "name": "actor",
                                                                            "plural": false,
                                                                            "selections": [
                                                                                (v6 /*: any*/),
                                                                                (v4 /*: any*/),
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": (v8 /*: any*/),
                                                                                    "type": "User",
                                                                                    "abstractKey": null
                                                                                },
                                                                                {
                                                                                    "kind": "InlineFragment",
                                                                                    "selections": (v8 /*: any*/),
                                                                                    "type": "Group",
                                                                                    "abstractKey": null
                                                                                }
                                                                            ],
                                                                            "storageKey": null
                                                                        },
                                                                        (v6 /*: any*/)
                                                                    ],
                                                                    "storageKey": null
                                                                },
                                                                (v3 /*: any*/)
                                                            ],
                                                            "storageKey": null
                                                        },
                                                        (v9 /*: any*/)
                                                    ],
                                                    "storageKey": "assignees(first:3)"
                                                },
                                                {
                                                    "alias": null,
                                                    "args": (v7 /*: any*/),
                                                    "filters": null,
                                                    "handle": "connection",
                                                    "key": "AssigneesPreview_assignees",
                                                    "kind": "LinkedHandle",
                                                    "name": "assignees"
                                                },
                                                {
                                                    "kind": "TypeDiscriminator",
                                                    "abstractKey": "__isNode"
                                                }
                                            ],
                                            "type": "HasAssignees",
                                            "abstractKey": "__isHasAssignees"
                                        },
                                        {
                                            "kind": "InlineFragment",
                                            "selections": [
                                                {
                                                    "alias": null,
                                                    "args": null,
                                                    "concreteType": "Status",
                                                    "kind": "LinkedField",
                                                    "name": "status",
                                                    "plural": false,
                                                    "selections": [
                                                        (v5 /*: any*/),
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "textColor",
                                                            "storageKey": null
                                                        },
                                                        {
                                                            "alias": null,
                                                            "args": null,
                                                            "kind": "ScalarField",
                                                            "name": "color",
                                                            "storageKey": null
                                                        },
                                                        (v4 /*: any*/)
                                                    ],
                                                    "storageKey": null
                                                }
                                            ],
                                            "type": "HasStatus",
                                            "abstractKey": "__isHasStatus"
                                        }
                                    ],
                                    "storageKey": null
                                }
                            ],
                            "storageKey": null
                        },
                        (v9 /*: any*/)
                    ],
                    "storageKey": null
                },
                {
                    "alias": null,
                    "args": (v2 /*: any*/),
                    "filters": [
                        "tenantId"
                    ],
                    "handle": "connection",
                    "key": "MattersTableView_matters",
                    "kind": "LinkedHandle",
                    "name": "matters"
                }
            ]
        },
        "params": {
            "cacheID": "dfed0a504ad8d0eebfaf53dc16b45f1c",
            "id": null,
            "metadata": {},
            "name": "MattersColumnQuery",
            "operationKind": "query",
            "text": "query MattersColumnQuery(\n  $tenantId: ID!\n) {\n  ...MattersTableView_matters_1GYzvY\n}\n\nfragment AssigneesPreview_node on HasAssignees {\n  __isHasAssignees: __typename\n  assignees(first: 3) {\n    edges {\n      node {\n        id\n        actor {\n          __typename\n          id\n          ... on User {\n            name\n          }\n          ... on Group {\n            name\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ... on Node {\n    __isNode: __typename\n    id\n  }\n}\n\nfragment MattersTableView_matters_1GYzvY on RootQueryType {\n  matters(tenantId: $tenantId, first: 100) {\n    edges {\n      cursor\n      node {\n        id\n        title\n        number\n        dueDate\n        insertedAt\n        creator {\n          id\n          name\n        }\n        ...AssigneesPreview_node\n        ...Status_status\n        __typename\n      }\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Status_status on HasStatus {\n  __isHasStatus: __typename\n  status {\n    name\n    textColor\n    color\n    id\n  }\n}\n"
        }
    } as any;
})();
(node as any).hash = 'f36c386e21674630dcebf10341528970';
export default node;
