import {
  useUpdateDocumentTemplateMutation,
  useUpdateDocumentTemplateMutationResponse,
} from "~/__graphql__/useUpdateDocumentTemplateMutation.graphql";
import { newId, useMutation } from "@workflows/runtime-web";
import { graphql } from "react-relay/hooks";
import { useTenantId } from "@workflows/ui";

interface useUpdateDocumentTemplateProps {
  onError: (error: Error) => void;
  onCompleted: (data: useUpdateDocumentTemplateMutationResponse) => void;
  input: {
    title?: string;
    description?: string;
    documentTemplateId: string;
  };
}

interface useUpdateDocumentTemplateValue {
  updateDocumentTemplate: (props: useUpdateDocumentTemplateProps) => void;
  isPending: boolean;
}

export const useUpdateDocumentTemplate = (): useUpdateDocumentTemplateValue => {
  const tenantId = useTenantId();
  const [mutation, isPending] = useMutation<useUpdateDocumentTemplateMutation>(
    updateDocumentTemplateMutation
  );

  const updateDocumentTemplate = (props: useUpdateDocumentTemplateProps) => {
    const {
      onError,
      onCompleted,
      input: { title, description, documentTemplateId },
    } = props;

    mutation({
      variables: {
        input: {
          clientMutationId: newId(),
          description,
          documentTemplateId,
          tenantId,
          title,
        },
      },
      onError,
      onCompleted,
    });
  };

  return { updateDocumentTemplate, isPending };
};

const updateDocumentTemplateMutation = graphql`
  mutation useUpdateDocumentTemplateMutation(
    $input: UpdateDocumentTemplateInput!
  ) {
    updateDocumentTemplate(input: $input) {
      errors {
        code
        path
        message
        type
      }
      documentTemplate {
        id
        description
        title
      }
    }
  }
`;
