import { Card, CardBody, CardHeader, Text, Skeleton } from "@workflows/ui";
import * as React from "react";

export function WorkflowChooserListItemSkeleton(): JSX.Element {
  return (
    <Card>
      <CardHeader>
        <Skeleton />
      </CardHeader>
      <CardBody>
        <Text variant="headingMd">
          <Skeleton width={100} />
        </Text>
      </CardBody>
    </Card>
  );
}
